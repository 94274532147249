/* 引入styled components */
import styled from "styled-components";
/* 下面放圖片 */
import avatar from "@/assets/profile/circle.png";
import check from "@/assets/profile/check.png";
import help from "@/assets/profile/help.png";
//轉向網址
import { useEffect, useState } from "react"
//page-style compoents start
import useStackNavigate from "@/hooks/useStackNavigate";
import Drawer from "@/components/UI/Drawer";
import cancel from "@/assets/icon/cancel.png";
import { COLORS } from "@/constants/colors";
import { useTranslation } from "react-i18next";
import { SwapOutlined, EditOutlined } from "@ant-design/icons";
import { useAppSelector } from "@/hooks";
import { UserOtcOrderReport } from "@/interfaces/otc.interface";
import { userService } from "@/services";
import { UserSecurity } from "@/interfaces/exchange.interface";
import CommonPage from "@/components/LayoutPage";

const Email = styled.div`
  font-family: SF Pro Text;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 22px;
  color: ${COLORS.Black};
`;

const ID = styled.div`
  font-family: PingFang TC;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: ${COLORS.Black};
`;

const AvatarImg = styled.img`
  width: 52px;
  height: 52px;
  margin-bottom: 10px;
`;

const TopAreaItems = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  background: ${COLORS.Primary};
  padding: 0px 10px 10px 10px;
`;

const Avatar = styled.div`
  margin-left: 16px;
  margin-top: 10px;

`;

const EmailSetting = styled.div`
  margin-left: 16px;
`;

const DrawerFullWarehouseTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const DrawerFullWarehouseImage = styled.img`
  width: 28px;
  height: 28px;
`;
const DrawerFullWarehouseTitle = styled.p`
  flex: 1;
  height: 24px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${COLORS.Dark_gray};
  text-align: center;
`;

const MemberCenter = () => {
    const user = useAppSelector((state)=>state.user.otc)
    const { stackNavigate } = useStackNavigate()
    const [role, setRole] = useState("user");
    const [modalVisible, setModalVisible] = useState(false);
    const [userOtcOrderReport, setUserOtcOrderReport] = useState<UserOtcOrderReport>({
        completeOrders30daysCount: 0,
        completeOrders30daysRate: 0,
        averageConfirmTime30days: 0,
        averagePayTime30days: 0
    } as UserOtcOrderReport);

    const { t } = useTranslation();
    const [userSecurity, setUserSecurity] = useState<UserSecurity>({} as UserSecurity);

    useEffect(() => {
        userService.getOtcOrderReport(user.account, {
            type: "advertiser"
        }).then((response) => {
            setUserOtcOrderReport(response)
        })
        userService.getSecurity().then((response) => {
            setUserSecurity(response.data)
        })

    }, [user.account])

    useEffect(() => {
        userService.getOtcOrderReport(user.account, {
            type: role
        }).then((response) => {
            setUserOtcOrderReport(response)
        })

    }, [role, user.account])

    const getSwapButton = () => {
        return (
            <>
                <span style={{ color: COLORS.Black, fontFamily: "PingFang TC", fontSize: 13, fontWeight: 500 }}>{t(`page.menu.c2cManage.info.button.${role === "user" ? "switchMch" : "switchUser"}`)}</span>
                <SwapOutlined style={{ marginLeft: 5 }} onClick={() => { setRole(role === "user" ? "advertiser" : "user") }} />
            </>
        )
    }
    return (
        <CommonPage title="" option={getSwapButton()} returnPath={"/c2c"} headerStyle={{ backgroundColor: COLORS.Primary }}>
            <TopAreaItems>
                <Avatar>
                    <AvatarImg src={avatar} alt="avatar" />
                </Avatar>
                <EmailSetting>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <Email>{user.nickName ? user.nickName : user.account}</Email>
                        <EditOutlined style={{ marginLeft: 5 }} onClick={() => { stackNavigate("/editName") }} />

                    </div>
                    {role === "user" ?
                        <ID>{t("page.menu.c2cManage.info.label.verifiedUser")}</ID> :
                        <ID>{t("page.menu.c2cManage.info.label.verifiedMch")}</ID>}
                </EmailSetting>
            </TopAreaItems>

            <div style={{ background: "#F0F0F0", flex: 1, overflow: "scroll", height: "100vh", paddingBottom: 200, width: "100%" }}>
                <div style={{ width: "100%", background: "white", height: 52, marginTop: 16, marginBottom: 10, display: "flex", justifyContent: "space-between", alignItems: "center", paddingLeft: 16, paddingRight: 16 }}>
                    <p style={{ fontFamily: "PingFang TC", fontSize: 13, fontWeight: 500, color: "#8F8DA2" }}>{t("page.menu.c2cManage.info.label.verifyStatus")}</p>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        {userSecurity.phone && <>
                            <p style={{ fontFamily: "PingFang TC", fontSize: 12, fontWeight: 400, color: "#383743" }}>{t("page.menu.c2cManage.info.label.phone")}</p>
                            <img src={check} alt="" style={{ width: 14, height: 14, marginLeft: 5, marginRight: 5 }} />
                        </>}
                        <p style={{ fontFamily: "PingFang TC", fontSize: 12, fontWeight: 400, color: "#383743" }}>{t("page.menu.c2cManage.info.label.email")}</p>
                        <img src={check} alt="" style={{ width: 14, height: 14, marginLeft: 5, marginRight: 5 }} />
                        {userSecurity.kyc && <>
                            <p style={{ fontFamily: "PingFang TC", fontSize: 12, fontWeight: 400, color: "#383743" }}>{t("page.menu.c2cManage.info.label.detail")}</p>
                            <img src={check} alt="" style={{ width: 14, height: 14, marginLeft: 5, marginRight: 5 }} />
                        </>}
                        {/* <p style={{ fontFamily: "PingFang TC", fontSize: 12, fontWeight: 400, color: "#383743" }}>質押</p>
            <img src={check} alt="" style={{ width: 14, height: 14, marginLeft: 5, marginRight: 5 }} /> */}
                    </div>
                </div>
                <div style={{ width: "100%", background: "white", display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <div style={{ width: "100%", display: "flex", flexDirection: "column", padding: 16 }}>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <p style={{ fontFamily: "PingFang TC", fontSize: 13, fontWeight: 500, color: "#8F8DA2" }}>{t("page.menu.c2cManage.info.label.thirtyDaysDeal")}</p>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <p style={{ fontFamily: "PingFang TC", fontSize: 13, fontWeight: 600, color: "#383743" }}>{userOtcOrderReport.completeOrders30daysCount}</p>
                                <p style={{ fontFamily: "PingFang TC", fontSize: 13, fontWeight: 500, color: "#8F8DA2", marginLeft: 8 }}>{t("page.menu.c2cManage.info.label.times")}</p>
                            </div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: 16 }}>
                            <p style={{ fontFamily: "PingFang TC", fontSize: 13, fontWeight: 500, color: "#8F8DA2" }}>{t("page.menu.c2cManage.info.label.thirtyDaysDealRate")}</p>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <p style={{ fontFamily: "PingFang TC", fontSize: 13, fontWeight: 600, color: "#383743" }}>{userOtcOrderReport.completeOrders30daysRate.toFixed(2)}</p>
                                <p style={{ fontFamily: "PingFang TC", fontSize: 13, fontWeight: 500, color: "#8F8DA2", marginLeft: 8 }}>%</p>
                            </div>
                        </div>
                    </div>
                    <div style={{ width: "93%", height: 1, backgroundColor: "#F4F4F6" }}></div>
                    <div style={{ width: "100%", display: "flex", flexDirection: "column", padding: 16 }}>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <p style={{ fontFamily: "PingFang TC", fontSize: 13, fontWeight: 500, color: "#8F8DA2" }}>{t("page.menu.c2cManage.info.label.thirtyDaysAvgSellTime")}</p>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <p style={{ fontFamily: "PingFang TC", fontSize: 13, fontWeight: 600, color: "#383743" }}>{(userOtcOrderReport.averageConfirmTime30days > 0 ? userOtcOrderReport.averageConfirmTime30days / (1000 * 60) : userOtcOrderReport.averageConfirmTime30days).toFixed(2)}</p>
                                <p style={{ fontFamily: "PingFang TC", fontSize: 13, fontWeight: 500, color: "#8F8DA2", marginLeft: 8 }}>{t("page.menu.c2cManage.info.label.minutes")}</p>
                            </div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: 16 }}>
                            <p style={{ fontFamily: "PingFang TC", fontSize: 13, fontWeight: 500, color: "#8F8DA2" }}>{t("page.menu.c2cManage.info.label.thirtyDaysAvgBuyTime")}</p>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <p style={{ fontFamily: "PingFang TC", fontSize: 13, fontWeight: 600, color: "#383743" }}>{(userOtcOrderReport.averagePayTime30days > 0 ? userOtcOrderReport.averagePayTime30days / (1000 * 60) : userOtcOrderReport.averagePayTime30days).toFixed(2)}</p>
                                <p style={{ fontFamily: "PingFang TC", fontSize: 13, fontWeight: 500, color: "#8F8DA2", marginLeft: 8 }}>{t("page.menu.c2cManage.info.label.minutes")}</p>
                            </div>
                        </div>
                    </div>
                    <div style={{ width: "93%", height: 1, backgroundColor: "#F4F4F6" }}></div>

                    <div style={{ width: "100%", display: "flex", flexDirection: "column", padding: 16 }}>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <p style={{ fontFamily: "PingFang TC", fontSize: 13, fontWeight: 500, color: "#8F8DA2" }}>{t("page.menu.c2cManage.info.label.accountEst")}</p>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <p style={{ fontFamily: "PingFang TC", fontSize: 13, fontWeight: 600, color: "#383743" }}>{userOtcOrderReport.accountCreatedTime > 0 ? (userOtcOrderReport.accountCreatedTime / (1000 * 60 * 60 * 24)).toFixed(0) : userOtcOrderReport.accountCreatedTime}</p>
                                <p style={{ fontFamily: "PingFang TC", fontSize: 13, fontWeight: 500, color: "#8F8DA2", marginLeft: 8 }}>{t("page.menu.c2cManage.info.label.days")}</p>
                            </div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: 16 }}>
                            <p style={{ fontFamily: "PingFang TC", fontSize: 13, fontWeight: 500, color: "#8F8DA2" }}>{t("page.menu.c2cManage.info.label.firstDealtill")}</p>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <p style={{ fontFamily: "PingFang TC", fontSize: 13, fontWeight: 600, color: "#383743" }}>{userOtcOrderReport.firstCompleteTime > 0 ? (userOtcOrderReport.firstCompleteTime / (1000 * 60 * 60 * 24)).toFixed(0) : userOtcOrderReport.firstCompleteTime}</p>
                                <p style={{ fontFamily: "PingFang TC", fontSize: 13, fontWeight: 500, color: "#8F8DA2", marginLeft: 8 }}>{t("page.menu.c2cManage.info.label.days")}</p>
                            </div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: 16 }}>
                            <p style={{ fontFamily: "PingFang TC", fontSize: 13, fontWeight: 500, color: "#8F8DA2" }}>{t("page.menu.c2cManage.info.label.dealPeopleNum")}</p>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <p style={{ fontFamily: "PingFang TC", fontSize: 13, fontWeight: 600, color: "#383743" }}>{userOtcOrderReport.completeUsers}</p>
                                <p style={{ fontFamily: "PingFang TC", fontSize: 13, fontWeight: 500, color: "#8F8DA2", marginLeft: 8 }}>{t("page.menu.c2cManage.info.label.people")}</p>
                            </div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: 16 }}>
                            <p style={{ fontFamily: "PingFang TC", fontSize: 13, fontWeight: 500, color: "#8F8DA2" }}>{t("page.menu.c2cManage.info.label.dealNum")}</p>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <p style={{ fontFamily: "PingFang TC", fontSize: 13, fontWeight: 600, color: "#383743" }}>{userOtcOrderReport.buyCompleteOrdersCount + userOtcOrderReport.sellCompleteOrdersCount}</p>
                                <p style={{ fontFamily: "PingFang TC", fontSize: 13, fontWeight: 500, color: "#8F8DA2", marginLeft: 8 }}>{t("page.menu.c2cManage.info.label.times")}</p>
                            </div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: 16 }}>
                            <p style={{ fontFamily: "PingFang TC", fontSize: 13, fontWeight: 500, color: "#8F8DA2" }}></p>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <p style={{ fontFamily: "PingFang TC", fontSize: 13, fontWeight: 600, color: "#383743" }}>{userOtcOrderReport.buyCompleteOrdersCount}</p>
                                <p style={{ fontFamily: "PingFang TC", fontSize: 13, fontWeight: 500, color: "#8F8DA2", marginLeft: 8 }}>{t("page.menu.c2cManage.info.label.fiatOrderBuy")}</p>
                                <p style={{ fontFamily: "PingFang TC", fontSize: 13, fontWeight: 500, color: "#8F8DA2", marginLeft: 5, marginRight: 5 }}>|</p>
                                <p style={{ fontFamily: "PingFang TC", fontSize: 13, fontWeight: 600, color: "#383743" }}>{userOtcOrderReport.sellCompleteOrdersCount}</p>
                                <p style={{ fontFamily: "PingFang TC", fontSize: 13, fontWeight: 500, color: "#8F8DA2", marginLeft: 8 }}>{t("page.menu.c2cManage.info.label.fiatOrderSell")}</p>
                            </div>
                        </div>
                    </div>
                    <div style={{ width: "93%", height: 1, backgroundColor: "#F4F4F6" }}></div>
                    <div style={{ width: "100%", display: "flex", flexDirection: "column", padding: 16 }}>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <p style={{ fontFamily: "PingFang TC", fontSize: 13, fontWeight: 500, color: "#8F8DA2" }}>{t("page.menu.c2cManage.info.label.thirtyDaysDealAmount")}</p>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <p style={{ fontFamily: "PingFang TC", fontSize: 13, fontWeight: 600, color: "#383743" }}>{userOtcOrderReport.completeAmount30days}</p>
                                <p style={{ fontFamily: "PingFang TC", fontSize: 13, fontWeight: 500, color: "#8F8DA2", marginLeft: 8 }}>USDT</p>
                            </div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: 16 }}>
                            <p style={{ fontFamily: "PingFang TC", fontSize: 13, fontWeight: 500, color: "#8F8DA2" }}>{t("page.menu.c2cManage.info.label.totalDealAmount")}</p>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <p style={{ fontFamily: "PingFang TC", fontSize: 13, fontWeight: 600, color: "#383743" }}>{userOtcOrderReport.buyCompleteAmount + userOtcOrderReport.sellCompleteAmount}</p>
                                <p style={{ fontFamily: "PingFang TC", fontSize: 13, fontWeight: 500, color: "#8F8DA2", marginLeft: 8 }}>USDT</p>
                            </div>
                        </div>
                    </div>
                    <div style={{ width: "93%", height: 1, backgroundColor: "#F4F4F6" }}></div>
                    {role === "advertiser" && <>
                        <div style={{ width: "100%", display: "flex", flexDirection: "column", padding: 16 }}>
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <p style={{ fontFamily: "PingFang TC", fontSize: 13, fontWeight: 500, color: "#8F8DA2" }}>{t("page.menu.c2cManage.info.label.stackLevel")}</p>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <p style={{ fontFamily: "PingFang TC", fontSize: 13, fontWeight: 600, color: "#383743" }}>{user.advertiserLevel ? user.advertiserLevel.name : t("page.menu.c2cManage.info.label.noLevel")}</p>
                                    <p style={{ fontFamily: "PingFang TC", fontSize: 13, fontWeight: 500, color: "#8F8DA2", marginLeft: 8 }}>{t("page.menu.c2cManage.info.label.times")}</p>
                                </div>
                            </div>
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: 16 }}>
                                <p style={{ fontFamily: "PingFang TC", fontSize: 13, fontWeight: 500, color: "#8F8DA2" }}>{t("page.menu.c2cManage.info.label.maxAmountPer")}</p>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <p style={{ fontFamily: "PingFang TC", fontSize: 13, fontWeight: 600, color: "#383743" }}>0</p>
                                    <p style={{ fontFamily: "PingFang TC", fontSize: 13, fontWeight: 500, color: "#8F8DA2", marginLeft: 8 }}>{t("page.menu.c2cManage.info.label.times")}</p>
                                </div>
                            </div>
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: 16 }}>
                                <p style={{ fontFamily: "PingFang TC", fontSize: 13, fontWeight: 500, color: "#8F8DA2" }}>{t("page.menu.c2cManage.info.label.maxAmountPerDay")}</p>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <p style={{ fontFamily: "PingFang TC", fontSize: 13, fontWeight: 600, color: "#383743" }}>0</p>
                                    <p style={{ fontFamily: "PingFang TC", fontSize: 13, fontWeight: 500, color: "#8F8DA2", marginLeft: 8 }}>{t("page.menu.c2cManage.info.label.times")}</p>
                                </div>
                            </div>
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: 16 }}>
                                <p style={{ fontFamily: "PingFang TC", fontSize: 13, fontWeight: 500, color: "#8F8DA2" }}>{t("page.menu.c2cManage.info.label.fiatBuyFee")}</p>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <p style={{ fontFamily: "PingFang TC", fontSize: 13, fontWeight: 600, color: "#383743" }}>0</p>
                                    <p style={{ fontFamily: "PingFang TC", fontSize: 13, fontWeight: 500, color: "#8F8DA2", marginLeft: 8 }}>{t("page.menu.c2cManage.info.label.times")}</p>
                                </div>
                            </div>
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: 16 }}>
                                <p style={{ fontFamily: "PingFang TC", fontSize: 13, fontWeight: 500, color: "#8F8DA2" }}>{t("page.menu.c2cManage.info.label.fiatSellFee")}</p>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <p style={{ fontFamily: "PingFang TC", fontSize: 13, fontWeight: 600, color: "#383743" }}>0</p>
                                    <p style={{ fontFamily: "PingFang TC", fontSize: 13, fontWeight: 500, color: "#8F8DA2", marginLeft: 8 }}>{t("page.menu.c2cManage.info.label.times")}</p>
                                </div>
                            </div>
                        </div>
                        <div style={{ width: "93%", height: 1, backgroundColor: "#F4F4F6" }}></div>
                        <div style={{ width: "100%", display: "flex", flexDirection: "column", padding: 16 }}>
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <p style={{ fontFamily: "PingFang TC", fontSize: 13, fontWeight: 500, color: "#8F8DA2" }}>{t("page.menu.c2cManage.info.label.restrictTime")}</p>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <img src={help} alt="" style={{ width: 24, height: 24 }} onClick={() => { setModalVisible(true) }} />
                                </div>
                            </div>
                        </div>
                    </>}
                </div>
                <Drawer
                    isVisible={modalVisible}
                    selectVisible={() => { }}
                    height={200}
                >
                    <DrawerFullWarehouseTitleContainer>
                        <DrawerFullWarehouseImage
                            src={cancel}
                            alt="cancel"
                            onClick={() => { setModalVisible(false) }}
                        />
                        <DrawerFullWarehouseTitle>
                            {t("page.menu.c2cManage.info.label.restrictTime")}
                        </DrawerFullWarehouseTitle>
                    </DrawerFullWarehouseTitleContainer>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <p style={{ color: "#8F8DA2", fontWeight: 500, fontSize: 13 }}>{t("page.menu.c2cManage.info.label.adsRestrict")}</p>
                        <p style={{ color: "#383743", fontWeight: 500, fontSize: 13 }}>0</p>
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <p style={{ color: "#8F8DA2", fontWeight: 500, fontSize: 13 }}>{t("page.menu.c2cManage.info.label.fiatRestrict")}</p>
                        <p style={{ color: "#383743", fontWeight: 500, fontSize: 13 }}>0</p>
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <p style={{ color: "#8F8DA2", fontWeight: 500, fontSize: 13 }}>{t("page.menu.c2cManage.info.label.transferRestrict")}</p>
                        <p style={{ color: "#383743", fontWeight: 500, fontSize: 13 }}>0</p>
                    </div>
                </Drawer>
            </div>
        </CommonPage>
    );
};

export default MemberCenter;
