import { useState } from "react";
import styled from "styled-components";
import { COLORS } from "@/constants/colors";
import useStackNavigate from "@/hooks/useStackNavigate";
// import JKO_pay from "@/assets/icon/Deal/JKO_pay.png";
import check from "@/assets/icon/Deal/check.png";
import Modal from "@/components/UI/CoverModal";
import unVisibility from "@/assets/icon/Deal/unvisibility.png";
import visibility from "@/assets/icon/Deal/visibility.png";
import { useTranslation } from "react-i18next";
import { Tag } from "antd";
import { useAppDispatch, useAppSelector } from "@/hooks";
import { advertisementActions } from "@/store/slice";
import { advertisementService } from "@/services";
import CommonPage from "@/components/LayoutPage";
import { BaseButton } from "@/components/Button";
import { AdvertisementStatus } from "@/constants";

// status bar style
const StatusContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  margin-top: 8px;
  position: relative;
  width: 100%;
`;
const StatusItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;
const StatusIcon = styled.div`
  width: 16px;
  height: 16px;
  border: 4px solid #dedde3;
  border-radius: 8px;
`;
const StatusDes = styled.p`
  font-weight: 700;
  font-size: 13px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: 0.4px;
  color: #dedde3;
  margin-top: 6px;
`;
const StatusSplit = styled.div`
  height: 2px;
  width: 32%;
  background: #dedde3;
  border-radius: 3px;
  margin-bottom: 8%;
  position: absolute;
  bottom: 9%;
  left: 14.7%;
`;

const DescriptionItem = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;
const DescriptionItemSplit = styled.div`
  width: 100%;
  height: 1px;
  background: ${COLORS.EXLight_gray};
`;

const DescriptionText = styled.div`
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.004rem;
  color: ${COLORS.Mid_gray};
`;

const DealCompleteContainer = styled.div`
  background: #fff;
  padding: 22px 16px 24px 16px;
  margin: 16px 16px 49px 16px;
  border-radius: 8px;
  width: 100%;
`;

const DealPayItemContainer = styled.div`
  display: flex;
  align-items: center;
`;

const DealPayItemDes = styled.p`
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.4px;
  color: ${COLORS.Gray};
`;

const ReadConfirmContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const ReadConfirmIcon = styled.img`
  margin-right: 8.5px;
  width: 24px;
  height: 24px;
`;

const ReadConfirmDes = styled.p`
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.004em;
  color: ${COLORS.Gray};
`;

const SubmitContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 19px 0 0px 0;
  padding: 0 16px;
`;

const TradeFunctionCheckBox = styled.div<{ check: boolean }>`
  width: 18px;
  height: 18px;
  background-color: ${(props) => (props.check ? "#5F5C70" : "#fff")};
  border: 1.5px solid #dedde3;
  border-radius: 9px;
  margin-right: 8.5px;
`;

const ConfirmBox = styled.div<{ isValid: boolean }>`
  width: 270px;
  height: ${(props) => (props.isValid ? 168 : 140)}px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

const ConfirmBoxTitle = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #262626;
  margin-top: 16px;
`;

const ConfirmBoxContent = styled.p`
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #595959;
  margin-top: 8px;
  padding: 0 16px;
  text-align: center;
`;

const ConfirmBoxInput = styled.input`
  width: 238px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  backdrop-filter: blur(8px);
  border-radius: 4px;
  padding: 4px 55px 4px 12px;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.004em;
  margin-top: 14px;
`;

const BoxCheckContainer = styled.div`
  flex: 1;
  width: 100%;
  height: 43px;
  display: flex;
  align-items: center;
  padding-top: 16px;
`;

const UnValidBoxCheckContainer = styled.div`
  width: 270px;
  height: 43px;
  margin: auto 0 0 0;
  border-radius: 0 0 18px 18px;
  background: #fff;
  color: #296df1;
  text-align: center;
  line-height: 43px;
  border-top: 1px solid #d9d9d9;
`;

const BoxCheckContent = styled.button`
  width: 50%;
  height: 100%;
  font-weight: 400;
  font-size: 16px;
  line-height: 43px;
  text-align: center;
  color: #8c8c8c;
  border: none;
  background-color: #fff;
  border-top: 1px solid #d9d9d9;
`;

const FiatAdDealHint = () => {
    const { stackNavigate } = useStackNavigate()
    const [isReadPrivacy, setIsReadPrivacy] = useState(false);
    const [modalVisible, setIsModalVisible] = useState(false);
    const [passwordIsVisible, setPasswordIsVisible] = useState(false);
    const [passwordIsValid, setPasswordIsValid] = useState(true);
    const { t } = useTranslation();
    const createAdvertisementRequest = useAppSelector((state) => state.advertisement.creating)
    const editId = useAppSelector((state) => state.advertisement.editId)
    const dispatch = useAppDispatch()
    return (
        <CommonPage title={t(`page.menu.advertisementManage.label.${editId === "" ? "publish" : "edit"}`)} returnPath={"/advertisement/step-two"}>
            <StatusContainer>
                <StatusItem>
                    <StatusIcon
                        style={{ border: "none", background: COLORS.Gray }}
                    />
                    <StatusDes style={{ color: `${COLORS.Dark_gray}` }}>
                        {t("page.menu.advertisementManage.label.priceNumber")}
                    </StatusDes>
                </StatusItem>
                <StatusSplit style={{ background: COLORS.Gray }} />
                <StatusItem>
                    <StatusIcon
                        style={{ border: "none", background: COLORS.Gray }}
                    />
                    <StatusDes style={{ color: `${COLORS.Dark_gray}` }}>
                        {t("page.menu.advertisementManage.label.tradeType")}
                    </StatusDes>
                </StatusItem>
                <StatusSplit style={{ left: "53.3%", background: COLORS.Gray }} />
                <StatusItem>
                    <StatusIcon style={{ border: `4px solid ${COLORS.Red}` }} />
                    <StatusDes style={{ color: `${COLORS.Dark_gray}` }}>
                        {t("page.menu.advertisementManage.label.adsConfirm")}
                    </StatusDes>
                </StatusItem>
            </StatusContainer>
            <DealCompleteContainer>
                <DescriptionItem>
                    <DescriptionText>{t("page.menu.advertisementManage.label.dealPrice")}</DescriptionText>
                    <DescriptionText
                        style={{
                            color: COLORS.Dark_gray,
                            fontWeight: 700,
                            fontSize: 16,
                            lineHeight: "20px",
                            letterSpacing: "0.004em",
                        }}
                    >
                        {`${createAdvertisementRequest.price} TWD/USDT`}
                    </DescriptionText>
                </DescriptionItem>
                <DescriptionItem>
                    <DescriptionText>{t("page.menu.advertisementManage.label.priceMethod")}</DescriptionText>
                    <DescriptionText
                        style={{
                            color: COLORS.Dark_gray,
                            fontWeight: 700,
                            fontSize: 16,
                            lineHeight: "20px",
                            letterSpacing: "0.004em",
                        }}
                    >
                        {t(`page.menu.advertisementManage.label.${ createAdvertisementRequest.priceType === 0 ? "fixedPrice" : "floatPrice"}`)}
                    </DescriptionText>
                </DescriptionItem>
                {/* <DescriptionItem>
          <DescriptionText>{t("unitPrice")}</DescriptionText>
          <DescriptionText
            style={{
              color: COLORS.Dark_gray,
              fontWeight: 700,
              fontSize: 16,
              lineHeight: "20px",
              letterSpacing: "0.004em",
            }}
          >
            {`${1000} USDT`}
          </DescriptionText>
        </DescriptionItem> */}
                <DescriptionItem style={{ justifyContent: "center" }}>
                    <DescriptionItemSplit />
                </DescriptionItem>
                <DescriptionItem>
                    <DescriptionText>{t("page.menu.advertisementManage.label.onceLimit")}</DescriptionText>
                    <DescriptionText style={{ color: COLORS.Dark_gray }}>
                        NT$ {createAdvertisementRequest.orderLimitMin} - {createAdvertisementRequest.orderLimitMax}
                    </DescriptionText>
                </DescriptionItem>
                <DescriptionItem>
                    <DescriptionText>{t("page.menu.advertisementManage.label.permitTime")}</DescriptionText>
                    <DescriptionText style={{ color: COLORS.Dark_gray }}>
                        {createAdvertisementRequest.paymentTimeLimit / (60 * 1000)} {t("page.menu.advertisementManage.label.minutes")}
                    </DescriptionText>
                </DescriptionItem>
                <DescriptionItem>
                    <DescriptionText>{t("page.menu.advertisementManage.label.payments")}</DescriptionText>
                    <DealPayItemContainer>
                        {
                            createAdvertisementRequest.payments.map((payment) => {
                                return (
                                    <Tag>{t(`page.menu.c2cManage.payment.title.${payment.type}`)}</Tag>
                                )
                            })
                        }
                    </DealPayItemContainer>
                </DescriptionItem>
                <DescriptionItem
                    style={{
                        flexDirection: "column",
                        justifyContent: "space-between",
                        alignItems: "flex-start",
                    }}
                >
                    <DescriptionText>{t("page.menu.advertisementManage.label.tradeMemo")}</DescriptionText>
                    <DealPayItemDes>{createAdvertisementRequest.terms}</DealPayItemDes>
                </DescriptionItem>
                <DescriptionItem
                    style={{
                        flexDirection: "column",
                        justifyContent: "space-between",
                        alignItems: "flex-start",
                    }}
                >
                    <DescriptionText>{t("page.menu.advertisementManage.label.tradeCondition")}</DescriptionText>
                    <DealPayItemDes>
                        {t("page.menu.advertisementManage.label.dealAtLeast")} {createAdvertisementRequest.conditionCompleteOrders} {t("page.menu.advertisementManage.label.dealTimes")}、{t("page.menu.advertisementManage.label.registerAtLeast")} {createAdvertisementRequest.conditionRegisteredDays} {t("page.menu.advertisementManage.label.days")}
                    </DealPayItemDes>
                </DescriptionItem>
            </DealCompleteContainer>
            <ReadConfirmContainer
                onClick={() => {
                    setIsReadPrivacy((v) => !v);
                }}
            >
                {isReadPrivacy ? (
                    <ReadConfirmIcon src={check} alt="check" />
                ) : (
                    <TradeFunctionCheckBox check={false} />
                )}
                <ReadConfirmDes>{t("page.menu.advertisementManage.label.iAgree")}</ReadConfirmDes>
                <ReadConfirmDes style={{ color: COLORS.Red }}>
                    {t("page.menu.advertisementManage.label.tradeRegulations")}
                </ReadConfirmDes>
            </ReadConfirmContainer>
            <SubmitContainer>
                <BaseButton
                    style={{width: "100%"}}
                    onClick={() => {
                        setIsModalVisible(true);
                    }}
                >
                    {t("page.menu.advertisementManage.button.confirm")}
                </BaseButton>
            </SubmitContainer>
            {modalVisible && (
                <Modal>
                    <ConfirmBox isValid={passwordIsValid}>
                        <ConfirmBoxTitle>
                            {t(`page.menu.advertisementManage.notice.${passwordIsValid ? "financePassword" : "error"}`)}
                        </ConfirmBoxTitle>
                        <ConfirmBoxContent>
                            {t(`page.menu.advertisementManage.notice.${passwordIsValid ? "confirmSell" : "lock"}`)}
                        </ConfirmBoxContent>
                        {passwordIsValid && (
                            <ConfirmBoxInput
                                placeholder={t("page.menu.advertisementManage.placeholder.financePassword")}
                                onChange={(e) => {
                                    dispatch(advertisementActions.set({
                                        ...createAdvertisementRequest,
                                        financePassword: e.target.value
                                    }))
                                }}
                                value={createAdvertisementRequest.financePassword}
                                type={passwordIsVisible ? "text" : "password"}
                            />
                        )}
                        {passwordIsVisible
                            ? passwordIsValid && (
                                <img
                                    src={unVisibility}
                                    alt="visible"
                                    style={{
                                        width: 24,
                                        height: 24,
                                        position: "absolute",
                                        right: 28,
                                        top: 81,
                                    }}
                                    onClick={() => setPasswordIsVisible((v) => !v)}
                                />
                            )
                            : passwordIsValid && (
                                <img
                                    src={visibility}
                                    alt="visible"
                                    style={{
                                        width: 24,
                                        height: 24,
                                        position: "absolute",
                                        right: 28,
                                        top: 81,
                                    }}
                                    onClick={() => setPasswordIsVisible((v) => !v)}
                                />
                            )}
                        {passwordIsValid ? (
                            <BoxCheckContainer>
                                <BoxCheckContent
                                    style={{
                                        borderRight: "1px solid #D9D9D9",
                                        borderRadius: "0 0 0px 18px",
                                    }}
                                    onClick={() => {
                                        setIsModalVisible((v) => !v);
                                    }}
                                >
                                    {t("page.menu.advertisementManage.button.cancel")}
                                </BoxCheckContent>
                                <BoxCheckContent
                                    style={{
                                        borderRadius: "0 0 18px 0",
                                        color: "#296DF1",
                                    }}
                                    onClick={() => {
                                        if (editId === "") {
                                            advertisementService.create(createAdvertisementRequest).then(() => {
                                                alert(t("page.menu.advertisementManage.alert.success"))
                                                stackNavigate("jump");
                                                dispatch(advertisementActions.clean())
                                            }).catch(() => {
                                                alert(t("page.menu.advertisementManage.alert.fail"))
                                            })
                                        }
                                        else {
                                            advertisementService.edit(editId, createAdvertisementRequest).then(() => {
                                                alert(t("page.menu.advertisementManage.alert.success"))
                                                stackNavigate("jump", { state: AdvertisementStatus.OFFLINE });
                                                dispatch(advertisementActions.clean())
                                                dispatch(advertisementActions.cleanEdit())
                                            }).catch(() => {
                                                alert(t("page.menu.advertisementManage.alert.fail"))
                                            })
                                        }
                                    }}
                                >
                                    {t("page.menu.advertisementManage.button.OK")}
                                </BoxCheckContent>
                            </BoxCheckContainer>
                        ) : (
                            <UnValidBoxCheckContainer
                                onClick={() => {
                                    setPasswordIsValid((v) => !v);
                                }}
                            >
                                {t("page.menu.advertisementManage.button.OK")}
                            </UnValidBoxCheckContainer>
                        )}
                    </ConfirmBox>
                </Modal>
            )}
        </CommonPage>
    );
};

export default FiatAdDealHint;
