import { useAppDispatch, useAppSelector } from '@/hooks';
import { MarketEntity, MarketPrice } from '@/interfaces/market.interface';
import { formatNumberWithChineseUnit, parsePrice, toMarketEntity } from '@/utils/parse';
import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';

import {
    DealOrderUnit,
    DealName,
    DealRatio
} from '@/styled-components';
import { COLORS } from '@/constants/colors';
import { useTranslation } from 'react-i18next';
import BaseNoData from '@/components/BaseNoData';
import { tradingActions } from '@/store/slice';
import useStackNavigate from '@/hooks/useStackNavigate';

const MarketList: React.FC<{
    type: "up" | "down" | "volume" | "favorite" | "hot",
    marketPrice: MarketPrice[]
}> = ({ type, marketPrice }) => {
    const [priceData, setPriceData] = useState<MarketEntity[]>([])
    const currency = useAppSelector((state) => state.user.currency)
    const { t } = useTranslation();
    const { stackNavigate } = useStackNavigate()
    const dispatch = useAppDispatch();

    useEffect(() => {
        setPriceData(
            marketPrice.map((p) => toMarketEntity(p)).filter(s => {
                switch (type) {
                    case "down": return s.priceChangePercent < 0;
                    case "up": return s.priceChangePercent > 0;
                    default: return true;
                }
            }).sort((a, b) => {
                switch (type) {
                    case "down": return a.priceChangePercent - b.priceChangePercent;
                    case "up": return b.priceChangePercent - a.priceChangePercent;
                    case "volume": return b.volume * b.latestPrice - a.volume * a.latestPrice;
                    default: return 0;
                }
            }).slice(0, 10)
        )
    }, [marketPrice, type])

    return (
        <>
            {
                (
                    !isEmpty(priceData)
                    && priceData[0].symbol !== ''
                ) ? (
                    priceData.map((item, index) => (
                        <tr
                            key={index}
                            onClick={() => {
                                dispatch(tradingActions.setRequestSymbol(item.symbol))
                                stackNavigate("/deal");
                            }}
                        >
                            <td style={{ textAlign: "left" }}>
                                <DealName>
                                    {item.symbol.split("-")[0]}
                                </DealName>

                                <DealOrderUnit>
                                    {` /USDT`}
                                </DealOrderUnit>
                            </td>

                            <td style={{ textAlign: "right" }}>
                                {parsePrice(item.latestPrice)}
                                <div style={{ color: COLORS.Mid_gray, fontSize: 10, paddingTop: 2, whiteSpace: "nowrap" }}>
                                    {`${t(`currencyUnit.${currency.name}.name`)}${t(`currencyUnit.${currency.name}.unit`)} ${parsePrice(item.latestPrice * currency.rate)}`}
                                </div>
                            </td>
                            <td style={{ textAlign: "right" }}>
                                {
                                    type === "volume" ? <div style={{ fontSize: 12, fontWeight: 700 }}>{formatNumberWithChineseUnit(item.volume * item.latestPrice / 1000)}</div> :
                                        <DealRatio
                                            change={item.priceChangePercent}
                                        >
                                            {`${item.priceChangePercent.toFixed(2)}%`}
                                        </DealRatio>
                                }

                            </td>
                        </tr>
                    ))
                ) : type === "favorite" && (
                    <tr>
                        <td style={{textAlign: "center"}} colSpan={3}><BaseNoData onClick={() => stackNavigate("/quote", { state: "fundFutures" })} /></td>
                    </tr>
                )
            }
        </>
    )
}

export default MarketList