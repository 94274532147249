import React, {
    useState,
    useEffect,
    useCallback
} from "react";

import { FundPage, ValuePair} from '@/pages/fund/components';
import useStackNavigate from "@/hooks/useStackNavigate";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { CurrencyTopText } from '@/styled-components/fund';
import { investorService, userService } from "@/services";
import { useAppSelector } from "@/hooks";
import { RightOutlined } from "@ant-design/icons";

const CurrencyContainer = styled.div`
  width: 100%;
  height: 73px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
`;

export const Fund = () => {
    const { t } = useTranslation();
    const token = useAppSelector((state) => state.user.token)
    const account = useAppSelector((state) => state.user.detail.account)
    const { stackNavigate } = useStackNavigate()

    // # states
    const [spotTotal, setSpotTotal] = useState(0);
    const [fiatTotal, setFiatTotal] = useState(0);
    const [contractTotal, setContractTotal] = useState(0);

    const getProperty = useCallback(() => {
        if (!token) {
            stackNavigate("/login")
        }
        investorService.getProperty().then((response) => {
            setSpotTotal(response.data.spot.equityValue)
            setContractTotal(response.data.futures.balance)
        })
        userService.getOtcUserInfo(account).then(response => {
            const usdtBalance = response.wallet.coins.find(c => c.symbol === "USDT")?.balance
            setFiatTotal(usdtBalance ?? 0);
        });
    }, [token, account, stackNavigate])

    const list = [
        {
            key: "spot",
            value: spotTotal
        },
        {
            key: "future",
            value: contractTotal
        },
        {
            key: "otc",
            value: fiatTotal
        }
    ]

    // * hooks
    useEffect(() => {
        getProperty();
    }, [getProperty]);

    return (
        <FundPage active={"overview"} total={spotTotal + contractTotal + fiatTotal}>
            {
                list.map((item) => (
                    <CurrencyContainer key={item.key} onClick={() => {
                        stackNavigate(`/fund/${item.key}`)
                    }}>
                        <div>
                            <CurrencyTopText>{t(`page.fund.label.${item.key}`)}</CurrencyTopText>
                        </div>
                        <ValuePair value={item.value} symbol="USDT"/>
                        <div style={{ fontSize: 12 }}>
                            <RightOutlined />
                        </div>
                    </CurrencyContainer>
                ))
            }
        </FundPage>
    );
};

export default Fund;