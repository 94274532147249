import { COLORS } from "@/constants/colors";
import { ArrowLeftOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { CSSProperties, ReactNode } from "react";
import useStackNavigate from "@/hooks/useStackNavigate";
import { NavigateOptions } from "react-router-dom";

const HeaderContainer = styled.header`
    display: flex;
    justify-content: space-between;
    background-color: ${COLORS.White};
    height: 5vh;
    padding: 16px 20px 40px 20px;
    box-shadow: 0px 2px 4px rgba(143, 141, 162, 0.07);
`;

const HeaderLeft = styled.div`
    display: flex;
    width: 33%;
`;

const HeaderCenter = styled.div`
    display: flex;
    justify-content: center;
    width: 33%;
`;

const HeaderRight = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 33%;
`;

const TitleText = styled.div`
  color: ${COLORS.Dark_gray};
  font-weight: 600;
`;

const TitleHeader: React.FC<{ 
    title: string | ReactNode
    returnPath?: string | -1
    returnOption?: NavigateOptions
    option?: ReactNode
    headerStyle?: CSSProperties 
}> = ({
    title,
    returnPath,
    returnOption,
    option,
    headerStyle
}) => {
    const { back } = useStackNavigate()

    return (
        <HeaderContainer style={headerStyle}>
            <HeaderLeft>
                {
                    returnPath && <ArrowLeftOutlined
                        onClick={() => back(returnPath, returnOption) }
                    />
                }

            </HeaderLeft>
            <HeaderCenter>
                {
                    typeof title === "string" ? <TitleText>{title}</TitleText> : <div>{title}</div>
                }
            </HeaderCenter>
            <HeaderRight>
                {option}
            </HeaderRight>
        </HeaderContainer>
    )
}

export default TitleHeader