import styled from "styled-components";
import GoogleAuthIcon from "@/assets/icon/google-auth.svg";
import useStackNavigate from "@/hooks/useStackNavigate";
import { useTranslation } from "react-i18next";
import CommonPage from "@/components/LayoutPage";
import { BaseButton } from "@/components/Button";
import { COLORS } from "@/constants/colors";

const AreaWord = styled.div`
    margin: 10px 0px;
    color:${COLORS.Mid_gray};
    font-size:13px;
`;

const GoogleCode = () => {
    const { stackNavigate } = useStackNavigate()
    const { t } = useTranslation();
    return (
        <CommonPage title={t("page.setGoogleAuth.title")} returnPath={-1}>
            <img src={GoogleAuthIcon} alt="google-auth" style={{ width: "50vw", padding: "2vh 0px" }} />
            <AreaWord>{t("page.setGoogleAuth.notice.tutorial")}</AreaWord>
            <AreaWord style={{ padding: "30px 0px" }}>(Google Authenticator)</AreaWord>
            <BaseButton color="gray" onClick={() => stackNavigate("/google-verify")}>
                {t("page.setGoogleAuth.button.download")}
            </BaseButton>
            <BaseButton
                style={{ width: "90%", margin: "60px 10px" }}
                onClick={() => {
                    stackNavigate("/google-verify")
                }}
            >
                {t("page.setGoogleAuth.button.next")}
            </BaseButton>
        </CommonPage >
    );
}

export default GoogleCode
