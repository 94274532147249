import React, { CSSProperties, ReactNode } from "react";
import Footer from "@/components/footer/HomeFooter";
import TotalContent from '@/pages/fund/components/TotalContent';

import {
    FundPageContainer
} from '@/styled-components/fund';

export const Fund: React.FC<{ children: ReactNode, active: string, total: number, style?: CSSProperties }> = ({
    children,
    active,
    total,
    style
}) => {
    return (
        <FundPageContainer style={style}>
            <div>
                <TotalContent active={active} total={total} />
                <div>
                    {children}
                </div>
            </div>
            <Footer locationPage={"/fund/overview"} />
        </FundPageContainer>
    );
};

export default Fund;