import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import useStackNavigate from "@/hooks/useStackNavigate";
import styled from "styled-components";
import { COLORS } from "@/constants/colors";
import { useAppDispatch } from "@/hooks";
import { fetchUser } from "@/store/slice/user";
import LinePayBanner from "@/assets/member/line-pay.svg"
import CashBanner from "@/assets/member/cash.svg"
import StreetPayBanner from "@/assets/member/street-pay.svg"
import BankBanner from "@/assets/member/bank.svg"
import CommonPage from "@/components/LayoutPage";
const SecurityZone = styled.div`
  width: 100%;
  padding: 0px 10px;
  position: relative;
`;

const SecurityBg = styled.img`
    width: 100%;
    border-radius: 8px;
    margin: 7px 0px;
`;

const SecurityTitle = styled.h3`
  width: 60%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 8%;
  color: ${COLORS.White};
  font-size: 16px;
`;

const ChoosePayType = () => {
    const dispatch = useAppDispatch()
    const { t } = useTranslation();
    const { stackNavigate } = useStackNavigate()
    const payType: string[] = ["CASH", "BANK", "LINE_PAY", "STREET_PAY"]
    const payBanner: string[] = [CashBanner, BankBanner, LinePayBanner, StreetPayBanner]

    useEffect(()=>{
        dispatch(fetchUser())
    },[dispatch])

    return (
        <CommonPage title={t("page.menu.c2cManage.payment.title.choosePayType")} returnPath={"/account-setting"} headerStyle={{marginBottom: 30}}>
            {
                payType.map((type, index) => {
                    return (
                        <SecurityZone
                            key={type}
                            onClick={() => {
                                stackNavigate(`/pay/${type}`);
                            }}
                        >
                            <SecurityBg src={payBanner[index]} />

                            <SecurityTitle>{t(`page.menu.c2cManage.payment.title.${type}`)}</SecurityTitle>
                        </SecurityZone>
                    )
                })
            }
        </CommonPage>
    )
}

export default ChoosePayType;