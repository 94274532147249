import { C2cOrderType } from "@/constants";
import { COLORS } from "@/constants/colors";
import styled from "styled-components";

export const OtcOrderInfoContainer = styled.div`
    width: 100%;
    padding: 15px;
`

export const OtcOrderInfo = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0px;
    font-size: small;
    font-weight: 500;
`

export const CardContainer = styled.div`
    width: 100%;
    padding: 15px;
`

export const Card = styled.div<{color?: string}>`
    background-color: ${props => props.color ?? COLORS.White};
    border-radius: 10px;
`

export const CardContent = styled.div`
    padding: 5px 10px;
`

export const TabContent = styled.div`
    padding: 5px 0px;
`

export const InfoText = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0px;
    font-size: small;
    font-weight: 500;
`

export const TimeLimitInfo = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: small;
    font-weight: 500;
    padding: 15px 0px;
    color: ${COLORS.Mid_gray};
`

export const CurrencyCardName = styled.span`
    margin-left: 12px;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #383743;
    letter-spacing: 0.004em;
    font-family:Open Sans;
`; 

export const MerchantInfo = styled.div`
    padding: 10px 0px;
    width: 100%;
    display: flex;
    align-items: center;
`

export const PaymentInfo = styled.div`
    padding: 5px 0px;
`

export const InfoContent = styled.p`
    padding: 8px;
    font-size: 12px;
    color: ${COLORS.Mid_gray};
    font-weight: 500;
`

export const StatusContainer = styled.div`
    padding: 5px 15px;
    width: 100%;
`

export const StatusTitle = styled.div`
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
`;

export const PayTimeTitle = styled.span`
    font-weight: 600;
    font-size: 12px;
    color: ${COLORS.Mid_gray};
`;

export const PayTime = styled.span`
    font-weight: 600;
    font-size: 12px;
    padding: 0px 3px;
    color: ${COLORS.Blue};
`;

export const PayStepContainer = styled.div<{visible: boolean}>`
    padding: 5px 15px;
    width: 100%;
    display: ${ props => props.visible ? "" : "none"};
`


export const BottomContainer = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  padding: 20px;
`;

export const StepCircle = styled.div`
    background-color: ${COLORS.LightBlue};
    color: ${COLORS.Blue};
    border-radius: 60px;
    padding: 4px 6px;
    font-size: 8px;
    font-weight: 600;
`;

export const StepText = styled.div`
    padding: 0px 5px;
    font-size: 13px;
    font-weight: 600;
`;

export const StepContainer = styled.div`
    display: flex;
    padding: 5px 3px;
    align-items: center;
    flex-wrap: wrap;
`

export const PayInfo = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3px 0px;
`

export const PayInfoText = styled.div`
    font-size: 14px;
    font-weight: 600;
    color: ${COLORS.Mid_gray};
`

export const ConfirmContainer = styled.div`
  width: 270px;
  height: 140px;
  background: rgba(255, 255, 255, 0.95);
  backdrop-filter: blur(8px);
  border-radius: 18px;
  margin-bottom: 20%;
`;

export const ConfirmContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 16px 0 16px;
`;

export const ConfirmContentTitle = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #262626;
  margin: 0 0 8px 0;
`;

export const ConfirmContent = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #595959;
`;

export const ConfirmCheckbox = styled.div`
    margin-top: 13px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CheckboxCancel = styled.div`
  width: 50%;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #8c8c8c;
  padding: 12px 51px;
  border-top: 1px solid #d9d9d9;
  border-right: 1px solid #d9d9d9;
`;
export const CheckboxConfirm = styled.div`
  width: 50%;
  padding: 12px 51px;
  border-top: 1px solid #d9d9d9;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #296df1;
`;

export const SideText = styled.div<{side: C2cOrderType | ""}>`
    padding: 0px 5px;
    color: ${props => props.side!=="" && (props.side==="buy" ? COLORS.Green: COLORS.Red)}
`