import {
    useState,
    useEffect
} from "react";

// ? Self-packed Components || Functions
import { COLORS } from "@/constants/colors";

// - Images
import Cancel from "@/assets/icon/cancel.png";
import searchIcon from "@/assets/icon/Deal/search.png";
import noSearch from "@/assets/icon/no-data.svg";
import Footer from "@/components/footer/HomeFooter";
import selectIcon from "@/assets/icon/cramstar.png";
import nonSelectIcon from "@/assets/icon/Deal/emptyStar.png";
// ^ Plugins
import useStackNavigate from "@/hooks/useStackNavigate";
import { orderBy } from "lodash"
import { useTranslation } from "react-i18next";

// = Styled Component
import {
    PageContainer,
    SearchContainer,
    LinkText,
    SelectIcon,
    SearchDealTitleContainer,
    TitleButton,
    CurrencyList,
    CurrencyItem,
    ContractItem,
    LatestItem,
    RatioItem
} from '@/styled-components/search-deal'
import { useAppDispatch, useAppSelector } from "@/hooks";
import { investorService } from "@/services";
import { tradingActions } from "@/store/slice";
import { MarketPrice } from "@/interfaces/market.interface";
import { useLocation } from "react-router-dom";

/**
 * @author odin
 * @level Layout/Deal/SearchDeal
 * @description 交易的搜尋頁面
*/
const SearchDeal = () => {
    const { stackNavigate } = useStackNavigate()
    const path = useLocation().state;
    // $ init data
    const { t } = useTranslation();
    const dispatch = useAppDispatch()
    // % context
    const marketArr = useAppSelector((state) => state.market.price)
    // # states
    const [searchText, setSearchText] = useState("");
    const [market, setMarket] = useState<MarketPrice[]>([]);
    const [favorite, setFavorite] = useState([""]);

    // - methods
    const getFavorite = () => {
        investorService.getFavoriteSymbol().then((response) => {
            setFavorite(response.data)
        })
    };

    // * hooks
    useEffect(() => {
        const gfg = orderBy(marketArr, ["s"]);
        setMarket(gfg);
        getFavorite();
    }, [marketArr]);

    return (
        <PageContainer>
            <SearchContainer>
                <SelectIcon
                    style={{
                        width: 28,
                        height: 28,
                        position: "absolute",
                        left: 16,
                        top: 22,
                    }}
                    src={searchIcon}
                    alt="cancel"
                />
                <input
                    style={{
                        width: 307,
                        height: 32,
                        paddingLeft: 40,
                        background: COLORS.EXLight_gray,
                        borderRadius: 4,
                        borderColor: "transparent",
                    }}
                    placeholder={t("page.searchDeal.placeholder.search")}
                    value={searchText}
                    onChange={(e) => {
                        setSearchText(e.target.value.trim());
                    }}
                />
                <LinkText onClick={() => stackNavigate(-1)}>
                    <SelectIcon src={Cancel} alt="cancel" />
                </LinkText>
            </SearchContainer>
            <SearchDealTitleContainer>
                <TitleButton>
                    <p>{t("page.searchDeal.label.contract")}</p>
                </TitleButton>
                <TitleButton
                    style={{ justifyContent: "center" }}
                >
                    <p>{t("page.searchDeal.label.lastPrice")}</p>
                </TitleButton>
                <TitleButton
                    style={{
                        marginRight: 5,
                        justifyContent: "flex-end",
                    }}
                >
                    <p>{t("page.searchDeal.label.24Hchg")}</p>
                </TitleButton>
            </SearchDealTitleContainer>
            <CurrencyList>
                {market
                    .filter((item: any) => {
                        if (!searchText) {
                            return true;
                        } else {
                            return item.s
                                .toLowerCase()
                                .indexOf(searchText.toLowerCase()) === -1
                                ? false
                                : true;
                        }
                    })
                    .map((e: any) => {
                        return (
                            <div>
                                <CurrencyItem key={e.id}>
                                    <ContractItem>
                                        {favorite.includes(e.s) ? <img
                                            onClick={() => {
                                                investorService.deleteFavoriteSymbol({ symbol: e.s }).then((response) => {
                                                    getFavorite();
                                                })
                                            }}
                                            style={{ width: 24, height: 24, marginRight: 10 }}
                                            src={selectIcon}
                                            alt="cancel"
                                        /> :
                                            <img
                                                onClick={() => {
                                                    investorService.addFavoriteSymbol({ symbol: e.s }).then((response) => {
                                                        getFavorite();
                                                    })
                                                }}
                                                style={{ width: 24, height: 24, marginRight: 10 }}
                                                src={nonSelectIcon}
                                                alt="cancel"
                                            />}
                                        <div
                                            style={{
                                                fontSize: 14,
                                                color: COLORS.Gray,
                                                fontWeight: 600,
                                            }}
                                            onClick={() => {
                                                dispatch(tradingActions.setRequestSymbol(e.s))
                                                stackNavigate("/deal");
                                            }}
                                        >
                                            {e.s}
                                        </div>
                                    </ContractItem>
                                    <LatestItem onClick={() => stackNavigate("/deal")}>{`${(parseFloat(e.c) < 10 && parseFloat(e.c) > 1) ? e.c.slice(0, -3) : parseFloat(e.c) < 10 ? e.c.slice(0, -2) : e.c.slice(0, -4)}`}</LatestItem>
                                    <RatioItem ratio={e.P} onClick={() => stackNavigate("/deal")}>{`${parseFloat(e.P) > 0 ? "+" : ""}${e.P}%`}</RatioItem>
                                </CurrencyItem>
                            </div>
                        );
                    })}
                {market.filter((item: any) => {
                    if (!searchText) {
                        return true;
                    } else {
                        return item.s.toLowerCase().indexOf(searchText.toLowerCase()) ===
                            -1
                            ? false
                            : true;
                    }
                }).length === 0 && (
                        <div
                            style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                marginTop: 48,
                            }}
                        >
                            <img
                                src={noSearch}
                                alt="no answer"
                                style={{ width: 135, height: 135 }}
                            />
                            <p
                                style={{
                                    fontWeight: 500,
                                    fontSize: 13,
                                    color: COLORS.Light_gray,
                                    marginTop: 24,
                                }}
                            >
                                {t("page.searchDeal.label.empty")}
                            </p>
                        </div>
                    )}
            </CurrencyList>
            <Footer locationPage={path ?? "/home"} />
        </PageContainer>
    );
};

export default SearchDeal;
