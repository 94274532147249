import { useAppDispatch, useAppSelector } from "@/hooks";
import { infoService } from "@/services";
import { userActions } from "@/store/slice";
import {
    ReactNode,
    useEffect,
} from "react";

interface LayoutProps {
    children: ReactNode;
}

const Layout = ({ children }: LayoutProps) => {
    const dispatch = useAppDispatch()
    const currency = useAppSelector((state)=>state.user.currency)

    useEffect(()=>{
        if ( !currency.rate ){
            infoService.getFiatCurrency().then((response)=>{
                dispatch(userActions.setCurrency({
                    name: "TWD",
                    rate: response.data["TWD"]
                }))
            }).catch(()=>{
                dispatch(userActions.setCurrency({
                    name: "NT$",
                    rate: 30
                }))
            })
        }
    },[currency, dispatch])

    return (
        <>{children}</>
    );
};

export default Layout;
