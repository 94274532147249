import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
    history: [] as string[],
    returnTarget: ""
}

const pathStackSlice = createSlice({
    name: "pathStack",
    initialState: initialState,
    reducers: {
        push(state, action: PayloadAction<string>) {
            state.history.push(action.payload)
        },
        pushStage(state, action: PayloadAction<string>) {
            state.returnTarget = action.payload;
            state.history.push(action.payload)
        },
        pop(state) {
            if (state.history.length - 1 > 0) {
                state.history = state.history.slice(0, state.history.length - 1)
            }
            else {
                state.history = []
            }
        },
        jump(state, action: PayloadAction<string>) {
            if ( state.history.lastIndexOf(action.payload)!==-1 ) {
                state.history = state.history.slice(0, state.history.lastIndexOf(action.payload) )
                state.returnTarget = "";
            }
            else {
                state.history = []
            }
        },
        clean(state) {
            state.history = []
        },
        setTarget(state, action: PayloadAction<string>) {
            state.returnTarget = action.payload;
            console.log(state.returnTarget)
            console.log(state.history)
        },
        returnToTarget(state) {
            state.returnTarget = "";
        },
    }
});

export const pathStackActions = pathStackSlice.actions;

export default pathStackSlice.reducer;