import CommonPage from "@/components/LayoutPage";
import GuideImage from "@/assets/icon/guide.svg"
import RegisterGuideImage from "@/assets/icon/register-guide.svg"
import DepositGuideImage from "@/assets/icon/deposit-guide.svg"
import TradeGuide from "@/assets/icon/trade-guide.svg"
import { Header } from "@/components/typography/Header";
import { useTranslation } from "react-i18next";


const Question = () => {
    const { t } = useTranslation();

    return (
        <CommonPage title={t("page.menu.question.title")} returnPath={"/member"}>
            <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                <img src={GuideImage} alt="guide"  style={{width: "70%"}}/>
                <div>
                    <div style={{ width: "100%", display: "flex", justifyContent: "space-around", alignItems: "center", padding: "0px 40px" }}>
                        <img src={RegisterGuideImage} alt="guide" style={{ width: "50%" }} />
                        <div style={{ width: "50%", padding: "0px 20px" }}>
                            <Header size="md">{t("page.menu.question.label.register")}</Header>
                            <Header size="sm">{t("page.menu.question.label.registerDescription")}</Header>
                        </div>
                    </div>
                    <div style={{ width: "100%", display: "flex", justifyContent: "space-around", alignItems: "center", padding: "0px 40px" }}>
                        <img src={DepositGuideImage} alt="guide" style={{ width: "50%" }} />
                        <div style={{ width: "50%", padding: "0px 20px" }}>
                            <Header size="md">{t("page.menu.question.label.deposit")}</Header>
                            <Header size="sm">{t("page.menu.question.label.depositDescription")}</Header>
                        </div>
                    </div>
                    <div style={{ width: "100%", display: "flex", justifyContent: "space-around", alignItems: "center", padding: "0px 40px" }}>
                        <img src={TradeGuide} alt="guide" style={{ width: "50%" }} />
                        <div style={{ width: "50%", padding: "0px 20px" }}>
                            <Header size="md">{t("page.menu.question.label.trade")}</Header>
                            <Header size="sm">{t("page.menu.question.label.tradeDescription")}</Header>
                        </div>
                    </div>
                </div>
            </div>
        </CommonPage>
    );
};

export default Question;
