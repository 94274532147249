import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { investorService, userService } from "@/services";
import { FinanceType } from "@/constants";
import { FinanceRecord } from "@/interfaces/exchange.interface";
import CommonPage from "@/components/LayoutPage";
import { WalletType } from "@/constants";
import { useAppSelector } from "@/hooks";
import { BalanceFlowInfo } from "@/interfaces/otc.interface";
import { useLocation } from "react-router-dom";
import { parsePrice } from "@/utils/parse";

const Container = styled.div`
    display: block;
    width: 100%;
`;

const ListItem = styled.div`
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #f4f4f6;
    height: 60px;
    align-items: center;
    padding: 0px 10px;
`;

const ItemTitle = styled.div`
    color: #383743;
    font-size: 15px;
    margin-bottom: 10px;
`;

const ItemDate = styled.div`
    color: #8F8DA2;
    font-size: 12px;
`;

const ItemPayment = styled.div`
    color: #383743;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
`;

const FundHistory = () => {

    const account = useAppSelector((state) => state.user.detail.account)
    const fundType = useLocation().state as WalletType

    const { t } = useTranslation();

    const [records, setRecords] = useState<FinanceRecord[]>([]);
    const [balanceFlowInfo, setBalanceFlowInfo] = useState<BalanceFlowInfo[]>([]);

    const getBalanceFlow = useCallback(() => {
        switch (fundType) {
            case "spot":
                investorService.getOwnFinanceRecord({
                    type: FinanceType.SPOT
                }).then((response) => {
                    setRecords(response.data);
                })
                break;
            case "future":
                investorService.getOwnFinanceRecord({
                    type: FinanceType.CONTRACT
                }).then((response) => {
                    setRecords(response.data.filter(record => record.type !== FinanceType.CONTRACT_MARGIN))
                })
                break;
            case "otc":
                userService.getOtcBalanceFlow(account).then((response) => {
                    setBalanceFlowInfo(response)
                })
                break;
            default: break;
        }
    },[account, fundType]);

    useEffect(() => {
        getBalanceFlow();
    }, [getBalanceFlow]);

    return (
        <CommonPage title={t("page.fund.title.history")} returnPath={-1}>
            <Container>
                {
                    records && records.map((record) => {
                        return (
                            <ListItem>
                                <div>
                                    <ItemTitle>{record.remark.replace(/\(.*\)/, "")}</ItemTitle>
                                    <ItemDate>
                                        {" "}
                                        {new Date(record.createdDate).getFullYear()}-
                                        {new Date(record.createdDate).getMonth() + 1 < 10
                                            ? "0" + (new Date(record.createdDate).getMonth() + 1)
                                            : new Date(record.createdDate).getMonth() + 1}
                                        -
                                        {new Date(record.createdDate).getDate() < 10
                                            ? "0" + new Date(record.createdDate).getDate()
                                            : new Date(record.createdDate).getDate()}{" "}
                                        {new Date(record.createdDate).getHours() < 10
                                            ? "0" + new Date(record.createdDate).getHours()
                                            : new Date(record.createdDate).getHours()}
                                        :
                                        {new Date(record.createdDate).getMinutes() < 10
                                            ? "0" + new Date(record.createdDate).getMinutes()
                                            : new Date(record.createdDate).getMinutes()}
                                    </ItemDate>
                                </div>
                                <div>
                                    <ItemPayment>
                                        {parsePrice(record.payment)} {record.coin}
                                    </ItemPayment>
                                </div>
                            </ListItem>
                        );
                    })
                }
                {
                    balanceFlowInfo.map(balanceFlow => {
                        return (
                            <ListItem>
                                <div>
                                    <ItemTitle>
                                        {balanceFlow.message.replace(/\(.*\)/, "")}
                                    </ItemTitle>
                                    <ItemDate>
                                        {" "}
                                        {new Date(balanceFlow.date).getFullYear()}-
                                        {new Date(balanceFlow.date).getMonth() + 1 < 10
                                            ? "0" + (new Date(balanceFlow.date).getMonth() + 1)
                                            : new Date(balanceFlow.date).getMonth() + 1}
                                        -
                                        {new Date(balanceFlow.date).getDate() < 10
                                            ? "0" + new Date(balanceFlow.date).getDate()
                                            : new Date(balanceFlow.date).getDate()}{" "}
                                        {new Date(balanceFlow.date).getHours() < 10
                                            ? "0" + new Date(balanceFlow.date).getHours()
                                            : new Date(balanceFlow.date).getHours()}
                                        :
                                        {new Date(balanceFlow.date).getMinutes() < 10
                                            ? "0" + new Date(balanceFlow.date).getMinutes()
                                            : new Date(balanceFlow.date).getMinutes()}
                                    </ItemDate>
                                </div>
                                <div>
                                    <ItemPayment>
                                        {balanceFlow.value.toFixed(2)} USDT
                                    </ItemPayment>
                                </div>
                            </ListItem>
                        );
                    })
                }
            </Container>
        </CommonPage>
    );
};

export default FundHistory;
