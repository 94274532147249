import React from 'react';

// ? Self-packed Components || Functions
import { COLORS } from '@/constants/colors';

// ^ Plugins
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// = Styled Component
import styled from "styled-components";
import { faCircleArrowUp } from '@fortawesome/free-solid-svg-icons';

const UploadPhotoZoneContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-width: 100px;
  min-height: 100px;
  padding: 30px 8px 20px 8px;
  background-color: ${COLORS.EXLight_gray};
  border-radius: 3px;
  border: dotted;
  border-color: ${COLORS.Light_gray};
  cursor: pointer;
`;

const IconContainer = styled.div`
  width: 100%;
  margin-bottom: 5px;
  color: ${COLORS.Mid_gray};
  text-align: center;
  font-size: large;
`;

const TitleContainer = styled.div`
  width: 100%;
  font-weight: bold;
  font-size: 10px;
  color: ${COLORS.Mid_gray};
  text-align: center;
`;

interface UploadPhotoZoneProps {
  title: string;
}

/**
 * @author odin
 * @level Layout/IDVerify/IDDocument/UploadPhotoZone
 * @description 身份驗證中的一個 複用型元件
*/
export const UploadPhotoZone = ({ title }: UploadPhotoZoneProps) => {
  return (
    <UploadPhotoZoneContainer>
      <IconContainer
        children={
          <FontAwesomeIcon icon={faCircleArrowUp}/>
        }
      />
      <TitleContainer children={title} />
    </UploadPhotoZoneContainer>
  )
};

export default UploadPhotoZone;
