import styled from "styled-components";
import cancel from "@/assets/icon/cancel.png";
import Drawer from "@/components/UI/Drawer";
import { useState } from "react";
import useStackNavigate from "@/hooks/useStackNavigate";
import { COLORS } from "@/constants/colors";
import { useTranslation } from "react-i18next";
import CommonPage from "@/components/LayoutPage";
import { Header } from "@/components/typography/Header";
import CustomInput from "@/components/Input/CustomInput";
import { BaseButton } from "@/components/Button";
import { RightOutlined } from "@ant-design/icons";

const BottomContainer = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  padding: 20px;
`;

const DrawerFullWarehouseTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const DrawerFullWarehouseImage = styled.img`
  width: 28px;
  height: 28px;
`;

const DrawerFullWarehouseTitle = styled.p`
  flex: 1;
  height: 24px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${COLORS.Dark_gray};
  text-align: center;
`;
const CancelButton = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #8f8da2;
`;
const DrawerPickerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 250px;
  margin-top: 20px;
`;
const DrawerDeadTimeContainer = styled.div`
  width: 33.3%;
  height: 200px;
  overflow: scroll;
`;
const DepthUnitItem = styled.div`
  width: 100%;
  text-align: center;
  height: 55px;
  line-height: 55px;
  color: ${COLORS.Dark_gray};
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
`;
const DrawerSplitStart = styled.div`
  width: 100%;
  height: 1px;
  background: ${COLORS.EXLight_gray};
  position: absolute;
  top: 134px;
`;
const DrawerSplitEnd = styled.div`
  width: 100%;
  height: 1px;
  background: ${COLORS.EXLight_gray};
  position: absolute;
  top: 178px;
`;

const FormBox = styled.div`
    width: 100%;
    padding: 0px 20px;
`

const IdVerify = () => {
    const { stackNavigate } = useStackNavigate()
    const [Arrow2, setArrow2] = useState(false);
    const [selectYear, setSelectYear] = useState(1951);
    const [selectMouth, selectSelectMouth] = useState(2);
    const [selectDate, setSelectDate] = useState(2);
    const [profileDate, setProfileDate] = useState({
        year: 0,
        month: 0,
        date: 0,
    });
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [address, setAddress] = useState("");
    const { t } = useTranslation();
    const handleDrawer = () => {
        setArrow2((prev) => !prev);
    };

    const deadTimePayDrawerHandler = () => {
        setProfileDate((prev) => {
            return {
                ...prev,
                year: selectYear,
                month: selectMouth,
                date: selectDate,
            };
        });

        setArrow2((prev) => !prev);
    };
    const years = Array.from({ length: 75 }, (v, i) => 1950 + i);
    const months = Array.from({ length: 14 }, (v, i) => 1 + i);
    const dates = Array.from({ length: 33 }, (v, i) => 1 + i);
    const NowYear = new Date().getFullYear();

    return (
        <CommonPage title={""} returnPath={-1}>
            <FormBox>
                <Header size={"lg"}>{t("page.setKyc.title")}</Header>
                <Header size={"md"}>{t("page.setKyc.input.firstName")}</Header>
                <CustomInput
                    valueType="text"
                    inputType="solid"
                    style={{ marginTop: 10 }}
                    left={<div style={{ color: COLORS.Light_gray }}>_</div>}
                    value={firstName}
                    onChange={(e) => { setFirstName(e.target.value) }}
                    placeholder={t("page.setKyc.placeholder.firstName")}
                />

                <Header size={"md"}>{t("page.setKyc.input.lastName")}</Header>
                <CustomInput
                    valueType="text"
                    inputType="solid"
                    style={{ marginTop: 10 }}
                    left={<div style={{ color: COLORS.Light_gray }}>_</div>}
                    value={lastName}
                    onChange={(e) => {
                        setLastName(e.target.value)
                    }}
                    placeholder={t("page.setKyc.placeholder.lastName")}
                />

                <Header size={"md"}>{t("page.setKyc.input.address")}</Header>
                <CustomInput
                    valueType="text"
                    inputType="solid"
                    style={{ marginTop: 10 }}
                    left={<div style={{ color: COLORS.Light_gray }}>_</div>}
                    value={address}
                    onChange={(e) => {
                        setAddress(e.target.value)
                    }}
                    placeholder={t("page.setKyc.placeholder.address")}
                />

                <Header size={"md"}>{t("page.setKyc.input.birthday")}</Header>
                <CustomInput
                    valueType="text"
                    inputType="solid"
                    style={{ marginTop: 10 }}
                    left={<div style={{ color: COLORS.Light_gray }}>_</div>}
                    right={
                        <RightOutlined style={{ padding: "0px 10px", fontWeight: "700", color: COLORS.Mid_gray }} />
                    }
                    onClick={handleDrawer}
                    value={
                        (!!profileDate.year && !!profileDate.month && !!profileDate.date) ?
                            `${profileDate.year}${t("page.setKyc.notice.year")} ${profileDate.month}${t("page.setKyc.notice.month")} ${profileDate.date}${t("page.setKyc.notice.date")}` : ""
                    }
                    placeholder={t("page.setKyc.placeholder.birthday")}
                />
            </FormBox>
            <BottomContainer>
                <BaseButton
                    style={{ width: "100%" }}
                    onClick={() => {
                        if (!firstName) {
                            alert(t("page.setKyc.alert.firstName"))
                        }
                        else if (!lastName) {
                            alert(t("page.setKyc.alert.lastName"))
                        }
                        else if (!address) {
                            alert(t("page.setKyc.alert.address"))
                        }
                        else if (profileDate.year === 0) {
                            alert(t("page.setKyc.alert.birthday"))
                        } else {
                            let data = {
                                name: firstName + lastName,
                                address: address,
                                birthday: new Date(`${profileDate.year}-${profileDate.month < 10 ? "0" + profileDate.month : profileDate.month}-${profileDate.date < 10 ? "0" + profileDate.date : profileDate.date}`).getTime()
                            }
                            stackNavigate("/id-documents", { state: { verify: data } })
                        }
                    }}
                >
                    {t("page.setKyc.button.submit")}
                </BaseButton>
            </BottomContainer>
            <Drawer isVisible={Arrow2} selectVisible={handleDrawer} height={309}>
                <DrawerFullWarehouseTitleContainer>
                    <DrawerFullWarehouseImage
                        src={cancel}
                        alt="cancel"
                        onClick={handleDrawer}
                    />
                    <DrawerFullWarehouseTitle>{t("page.setKyc.input.birthday")}</DrawerFullWarehouseTitle>
                    <CancelButton
                        onClick={deadTimePayDrawerHandler}
                    >{t("page.setKyc.button.accept")}</CancelButton>
                </DrawerFullWarehouseTitleContainer>
                <DrawerPickerContainer>
                    <DrawerSplitStart />
                    <DrawerSplitEnd />
                    <DrawerDeadTimeContainer>
                        {years.map((item, i) => {
                            return (
                                <DepthUnitItem
                                    key={item.toString()}
                                    onClick={() => {
                                        setSelectYear(item);
                                    }}
                                    style={{ flexDirection: "row", justifyContent: "center" }}
                                >
                                    <p
                                        style={{
                                            textAlign: "center",
                                            color:
                                                item > NowYear
                                                    ? "#fff"
                                                    : selectYear === item
                                                        ? COLORS.Primary
                                                        : COLORS.Gray,
                                            marginRight: 25,
                                        }}
                                    >
                                        {item}
                                    </p>
                                    {item < NowYear + 1 && (
                                        <p
                                            style={{
                                                textAlign: "center",
                                                color: COLORS.Gray,
                                            }}
                                        >
                                            {t("page.setKyc.notice.year")}
                                        </p>
                                    )}
                                </DepthUnitItem>
                            );
                        })}
                    </DrawerDeadTimeContainer>
                    <DrawerDeadTimeContainer>
                        {months.map((item, i) => {
                            return (
                                <DepthUnitItem
                                    key={item.toString()}
                                    onClick={() => {
                                        selectSelectMouth(item);
                                    }}
                                    style={{ flexDirection: "row", justifyContent: "center" }}
                                >
                                    <p
                                        style={{
                                            textAlign: "center",
                                            color:
                                                i > 11
                                                    ? "#fff"
                                                    : selectMouth === item
                                                        ? COLORS.Primary
                                                        : COLORS.Gray,
                                            marginRight: 25,
                                        }}
                                    >
                                        {item}
                                    </p>
                                    {i < 12 && (
                                        <p
                                            style={{
                                                textAlign: "center",
                                                color: COLORS.Gray,
                                            }}
                                        >
                                            {t("page.setKyc.notice.month")}
                                        </p>
                                    )}
                                </DepthUnitItem>
                            );
                        })}
                    </DrawerDeadTimeContainer>
                    <DrawerDeadTimeContainer>
                        {dates.map((item, i) => {
                            return (
                                <DepthUnitItem
                                    key={item.toString()}
                                    onClick={() => {
                                        setSelectDate(item);
                                    }}
                                    style={{ flexDirection: "row", justifyContent: "center" }}
                                >
                                    <p
                                        style={{
                                            textAlign: "center",
                                            color:
                                                i > 30
                                                    ? "#fff"
                                                    : selectDate === item
                                                        ? COLORS.Primary
                                                        : COLORS.Gray,
                                            marginRight: 25,
                                        }}
                                    >
                                        {item}
                                    </p>
                                    {i < 31 && (
                                        <p
                                            style={{
                                                textAlign: "center",
                                                color: COLORS.Gray,
                                            }}
                                        >
                                            {t("page.setKyc.notice.date")}
                                        </p>
                                    )}
                                </DepthUnitItem>
                            );
                        })}
                    </DrawerDeadTimeContainer>
                </DrawerPickerContainer>
            </Drawer>
        </CommonPage>
    );
};

export default IdVerify;
