import { useAppSelector } from "@/hooks";
import styled from "styled-components";
import useStackNavigate from "@/hooks/useStackNavigate";
import arrowRight from "@/assets/icon/Deal/arrowRight.png";
import { COLORS } from "@/constants/colors";
import { CheckCircleOutlined, CopyOutlined } from "@ant-design/icons";
import NotLoginIcon from "@/assets/icon/not-login.svg"
import LoginIcon from "@/assets/icon/login.svg"
import { useEffect, useState } from "react";
import { userService } from "@/services";
import { useTranslation } from "react-i18next";

const ProfileContainer = styled.div`
  width: 100%;
  height: 60px;
  padding: 3vw 2vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "Open Sans";
`;

const AccountEmail = styled.div`
  color: #5f5c70;
  font-size: 3.5vw;
  font-weight: 700;
  margin-bottom: 4px;
  white-space: nowrap;
`;

const AccountId = styled.div`
  color: ${COLORS.Mid_gray};
  font-size: 13px;
  font-weight: 600;
`;

const LoginLink = styled.span`
  font-size: 20px;
  font-weight: 600;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
`;

const VerifiedIconBox = styled.div<{isVerified: boolean}>`
    align-self: flex-start;
    color: ${props => props.isVerified ? COLORS.GreenActive : COLORS.Mid_gray}; 
    padding: 6px;
    border-radius: 90px;
    background: ${props => props.isVerified ? COLORS.LIGHT_GREEN : COLORS.Light_gray};
    font-size: 3vw;
    font-weight: 900;
    white-space: nowrap;
`

const UserInfo = () => {
    const { stackNavigate } = useStackNavigate()
    const user = useAppSelector(state => state.user.detail)
    const token = useAppSelector((state=> state.user.token))
    const { t } = useTranslation()
    const [kyc, setKyc] = useState<string | undefined>();

    const copyToClipboard = (text) => {
        var textField = document.createElement('textarea')
        textField.innerText = text
        document.body.appendChild(textField)
        textField.select()
        document.execCommand('copy')
        textField.remove()
    }

    const getStatus = () => {
        userService.getSecurity().then((response) => {
            setKyc(response.data.kyc);
        })
    };

    useEffect(() => {
        getStatus();
        if ( !token ){
            setKyc("")
        }
    }, [token]);

    return (
        <ProfileContainer>
            <img src={user.account ? LoginIcon : NotLoginIcon} alt="icon" style={{ width: "20%" }} />
            <ContentContainer>
                <AccountEmail
                    onClick={() => {
                        const target: string = user.account ? "/member/detail" : "/login"
                        stackNavigate(target)
                    }}
                >
                    {
                        user.account ? user.account : <LoginLink>{t("page.menu.user.notice.login")}</LoginLink>
                    }
                    <img
                        src={arrowRight}
                        alt=""
                        style={{
                            width: "10px",
                            height: "10px",
                            marginLeft: "5px",
                        }}
                    />
                </AccountEmail>
                {
                    user.account &&
                    <AccountId>
                        ID:{" "}
                        {user.investor && user.investor.investorId}
                        <CopyOutlined
                            style={{
                                marginLeft: 2
                            }}
                            onClick={() => {
                                copyToClipboard(user.investor.investorId)
                                alert(t("page.menu.user.notice.copied"))
                            }}
                        />
                    </AccountId>
                }
            </ContentContainer>
            {
                token &&
                <VerifiedIconBox isVerified={kyc === "PERMIT"}>
                    <CheckCircleOutlined style={{ marginRight: 3 }} />
                    <span>{t(`page.menu.user.label.${kyc === "PERMIT" ? "verified" : "unverified"}`)}</span>
                </VerifiedIconBox>
            }
        </ProfileContainer>
    )
}

export default UserInfo