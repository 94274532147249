import CustomCard from "@/components/CustomCard";
import CommonPage from "@/components/LayoutPage"
import { useTranslation } from "react-i18next"
import { Announcement } from "@/interfaces/exchange.interface";
import { useEffect, useState } from "react";
import { infoService } from "@/services";
import { useAppSelector } from "@/hooks";
import { COLORS } from "@/constants/colors";
import useStackNavigate from "@/hooks/useStackNavigate";

const Activity = () => {
    const { t } = useTranslation();
    const language = useAppSelector((state) => state.user.language)
    const { stackNavigate } = useStackNavigate()

    const [announcements, setAnnouncements] = useState<Announcement[]>([])

    useEffect(() => {
        infoService.getAnnouncements({ topic: "ACTIVITY", lang: language }).then((response) => {
            setAnnouncements(response.data)
        })
    }, [language]);

    return (
        <CommonPage title={t("")} returnPath={-1}>
            <div style={{ width: "100%", padding: "10px 15px" }}>
                <div style={{ fontSize: 24, fontWeight: 700 }}>{t("page.activity.title")}</div>
                {
                    announcements.map(announcement => {
                        return (
                            <CustomCard style={{ borderRadius: 10, border: `2px solid ${COLORS.Light_gray}`, margin: "20px 0px" }} contentStyle={{padding: 0}}>
                                <div>
                                    <img src={announcement.thumbnail} alt="img" style={{width: "100%"}} onClick={() => {
                                        stackNavigate("/announcement/" + announcement.id);
                                    }}/>
                                </div>
                                <div style={{padding: "15px 10px", fontSize: 20, fontWeight: 700}}>
                                    {announcement.subject}
                                </div>
                                <div style={{padding: "15px 10px", fontSize: 14, fontWeight: 400}}>
                                    {announcement.outline}
                                </div>
                            </CustomCard>
                        )
                    })
                }
            </div>
        </CommonPage>
    )
}

export default Activity