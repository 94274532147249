import { useState, useEffect } from "react";
import styled from "styled-components";
import { COLORS } from "@/constants/colors";
import copy from "@/assets/icon/profile/copy.png";
import check from "@/assets/icon/profile/check.png";
import nodata from "@/assets/icon/no-data.svg";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useTranslation } from "react-i18next";
import { agentService, investorService } from "@/services";
import { AgentApplication, Commission } from "@/interfaces/exchange.interface";
import CommonPage from "@/components/LayoutPage";

const RecommandCard = styled.div`
  height: 211px;
  padding: 0px 16px 16px 16px;
  background: #fff;
  border-radius: 8px;
  width: 100%;
`;

const RecommandCardTitleContainer = styled.div`
  height: 86px;
  width: 100%;
  background: ${COLORS.Dark_gray};
  border-radius: 8px 8px 0 0;
  padding: 16px;
`;

const RecommandCardContentContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const RecommandCardTitle = styled.p`
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.4px;
  color: ${COLORS.EXLight_gray};
`;

const RecommandCardTitleCode = styled.p`
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  color: #ff786b;
`;

const RecommandCardCodeCopy = styled.img`
  width: 28px;
  height: 28px;
`;
const RecommandCardstatusContainer = styled.div`
  width: 100%;
  height: 125px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const ModalCover = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(100, 100, 100, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalPopOut = styled.div`
  width: 91px;
  height: 91px;
  background: rgba(56, 55, 67, 0.6);
  backdrop-filter: blur(8px);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ModalCheckIcon = styled.img`
  width: 33px;
  height: 33px;
`;
const ModalCheckContent = styled.p`
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.4px;
  color: #ffffff;
  margin-top: 9px;
`;

const RecommandCardItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const RecommandCardItemTitle = styled.p`
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.4px;
  color: ${COLORS.Mid_gray};
`;
const RecommandCardItemUnit = styled.div`
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.004em;
  color: ${COLORS.Gray};
  display: flex;
  align-items: center;
`;
const RecommandContainerList = styled.div`
  width: 100%;
  padding: 16px;
  background: #fff;
  padding-bottom: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const RecommandButtonContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;
const RecommandButton = styled.button<{ status: string; select: string }>`
  border: none;
  background: ${props =>
        props.status === props.select ? COLORS.Gray : "#fff"};
  padding: 5px 12px;
  border-radius: 16px;
  margin-right: 8px;
`;
const RecommandButtonText = styled.p<{ status: string; select: string }>`
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.012em;
  color: ${props =>
        props.status === props.select ? "#fff" : COLORS.Mid_gray};
`;
const RecommandListItem = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0;
`;

const ListItemContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ListItemUnit = styled.p`
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.004em;
  color: ${COLORS.Dark_gray};
`;
const ItemNameID = styled.p`
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.004em;
  color: ${COLORS.Dark_gray};
`;
const ItemDate = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.004em;
  color: ${COLORS.Mid_gray};
`;
const NoitemFindIcon = styled.img`
  width: 135px;
  height: 135px;
  margin: 60px auto 24px auto;
`;

const NoitemFindContent = styled.p`
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.4px;
  color: ${COLORS.Light_gray};
  margin-bottom: 30%;
`;

const CommissionManage = () => {
    const [isCopy, setIsCopy] = useState(false);
    const [optionSelect, setOptionSelect] = useState("rebate");
    const [memberNumber, setMemberNumber] = useState(0);
    const [records, setRecord] = useState<Commission[]>([]);
    const [tradeMembers, setTradeMembers] = useState<string[]>([]);
    const [sum, setSum] = useState(0);
    const [code, setCode] = useState("");
    const { t } = useTranslation();
    const [, setApplication] = useState({} as AgentApplication);
    useEffect(() => {
        investorService.getCommission().then((response) => {
            setMemberNumber(response.data.memberNumber);
            setRecord(response.data.records.reverse());
            setTradeMembers(response.data.tradeMembers);
            let sum = 0;
            response.data.records.map(record => record.amount).reduce((previousValue, currentValue) => previousValue + currentValue, sum)
            setSum(sum);
        })
        investorService.getInviteCode().then((response) => {
            setCode(response.data);
        })
        agentService.get().then(response => {
            setApplication(response.data)
        })
    }, []);
    return (
        <CommonPage title={t("page.menu.commissionManage.title")} returnPath={"/member"}>
            <RecommandCard>
                <RecommandCardTitleContainer>
                    <RecommandCardTitle>{t("page.menu.commissionManage.label.inviteCode")}</RecommandCardTitle>
                    <RecommandCardContentContainer>
                        <RecommandCardTitleCode>{code}</RecommandCardTitleCode>
                        <CopyToClipboard text={code}>
                            <RecommandCardCodeCopy
                                src={copy}
                                alt="copy"
                                onClick={() => {
                                    setIsCopy(v => !v);
                                    setTimeout(() => {
                                        setIsCopy(v => !v);
                                    }, 800);
                                }}
                            />
                        </CopyToClipboard>
                    </RecommandCardContentContainer>
                </RecommandCardTitleContainer>
                <RecommandCardstatusContainer>
                    <RecommandCardItem>
                        <RecommandCardItemTitle>
                            {t("page.menu.commissionManage.label.income")}
                        </RecommandCardItemTitle>
                        <RecommandCardItemUnit>
                            <p
                                style={{
                                    fontSize: 16,
                                    fontWeight: 700,
                                    lineHeight: "20px",
                                    letterSpacing: "0.004em",
                                    color: "#383743",
                                    marginRight: 4
                                }}
                            >
                                {sum.toFixed(6)}
                            </p>
                            USDT
                        </RecommandCardItemUnit>
                    </RecommandCardItem>
                    <RecommandCardItem>
                        <RecommandCardItemTitle>
                            {t("page.menu.commissionManage.label.activeMember")}
                        </RecommandCardItemTitle>
                        <RecommandCardItemUnit>
                            <p
                                style={{
                                    fontSize: 16,
                                    fontWeight: 700,
                                    lineHeight: "20px",
                                    letterSpacing: "0.004em",
                                    color: "#383743",
                                    marginRight: 4
                                }}
                            >
                                {tradeMembers.length}
                            </p>
                            {t("page.menu.commissionManage.label.people")}
                        </RecommandCardItemUnit>
                    </RecommandCardItem>
                    <RecommandCardItem>
                        <RecommandCardItemTitle>
                            {t("page.menu.commissionManage.label.allMember")}
                        </RecommandCardItemTitle>
                        <RecommandCardItemUnit>
                            <p
                                style={{
                                    fontSize: 16,
                                    fontWeight: 700,
                                    lineHeight: "20px",
                                    letterSpacing: "0.004em",
                                    color: "#383743",
                                    marginRight: 4
                                }}
                            >
                                {memberNumber}
                            </p>
                            {t("page.menu.commissionManage.label.people")}
                        </RecommandCardItemUnit>
                    </RecommandCardItem>
                </RecommandCardstatusContainer>
            </RecommandCard>
            <RecommandContainerList>
                <RecommandButtonContainer>
                    <RecommandButton
                        status={"rebate"}
                        select={optionSelect}
                        onClick={() => setOptionSelect("rebate")}
                    >
                        <RecommandButtonText status={"rebate"} select={optionSelect}>
                            {t("page.menu.commissionManage.label.history")}
                        </RecommandButtonText>
                    </RecommandButton>
                    <RecommandButton
                        status={"recommand"}
                        select={optionSelect}
                        onClick={() => setOptionSelect("recommand")}
                    >
                        <RecommandButtonText status={"recommand"} select={optionSelect}>
                            {t("page.menu.commissionManage.label.allMember")}
                        </RecommandButtonText>
                    </RecommandButton>
                </RecommandButtonContainer>
                {optionSelect === "rebate" ? (
                    <>
                        {records.map((x: any) => {
                            return (
                                <RecommandListItem>
                                    <ListItemContainer>
                                        <ItemNameID>ID {x.childAccount}</ItemNameID>
                                        <ItemDate>
                                            {new Date(x.createdDate).toISOString().split("T")[0]}{" "}
                                            {
                                                new Date(x.createdDate)
                                                    .toISOString()
                                                    .split("T")[1]
                                                    .split(".")[0]
                                            }
                                        </ItemDate>
                                    </ListItemContainer>
                                    <ListItemUnit>{x.amount} USDT</ListItemUnit>
                                </RecommandListItem>
                            );
                        })}
                        {records.length === 0 && (
                            <>
                                <NoitemFindIcon src={nodata} alt="no item" />
                                <NoitemFindContent>
                                   {t("page.menu.commissionManage.label.empty")}
                                </NoitemFindContent>
                            </>
                        )}
                    </>
                ) : (
                    <>
                        {tradeMembers.map((x: any) => {
                            return (
                                <RecommandListItem>
                                    <ListItemContainer>
                                        <ItemNameID>{t("page.menu.commissionManage.label.id")} {x}</ItemNameID>
                                        {/* <ItemDate>{new Date(x.createdDate).toISOString().split("T")[0]} {new Date(x.createdDate).toISOString().split("T")[1].split(".")[0]}</ItemDate> */}
                                    </ListItemContainer>
                                    <ListItemUnit>{t("page.menu.commissionManage.label.deal")}</ListItemUnit>
                                </RecommandListItem>
                            );
                        })}
                        {tradeMembers.length === 0 && (
                            <>
                                <NoitemFindIcon src={nodata} alt="no item" />
                                <NoitemFindContent>{t("page.menu.commissionManage.label.noMember")}</NoitemFindContent>
                            </>
                        )}
                    </>
                )}
            </RecommandContainerList>
            {isCopy && (
                <ModalCover>
                    <ModalPopOut>
                        <ModalCheckIcon src={check} alt="check" />
                        <ModalCheckContent>{t("page.menu.commissionManage.notice.copiedSuccess")}</ModalCheckContent>
                    </ModalPopOut>
                </ModalCover>
            )}
        </CommonPage>
    );
};

export default CommissionManage;
