import { ReactComponent as DoneIcon } from "@/assets/icon/done.svg";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import CommonPage from "@/components/LayoutPage";
import { useAppDispatch, useAppSelector } from "@/hooks";
import { userActions } from "@/store/slice";

const SelectContainer = styled.div`
  flex: 1;
  padding: 11px 16px;
  flex: 1;
`;

const SelectTable = styled.ul`
  width: 100%;
`;

const SelectItem = styled.li`
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #f4f4f6;
  font-weight: 400;
  font-size: 15px;
  color: #383743;
`;

export const LanguageSetting = () => {
    const language = useAppSelector((state)=>state.user.language)
    const dispatch = useAppDispatch()
    const { t, i18n } = useTranslation();

    return (
        <CommonPage title={t("page.menu.selectLanguage.title")} returnPath={"/member"} style={{ alignItems: "flex-start" }}>
            <SelectContainer>
                <SelectTable>
                    <SelectItem
                        onClick={() => {
                            dispatch(userActions.setLanguage("en"))
                            i18n.changeLanguage("en");
                        }}
                    >
                        <p>{t("page.menu.selectLanguage.label.english")}</p>
                        {language === "en" && <DoneIcon />}
                    </SelectItem>
                    <SelectItem
                        onClick={() => {
                            dispatch(userActions.setLanguage("tw"))
                            i18n.changeLanguage("tw");
                        }}
                    >
                        <p>{t("page.menu.selectLanguage.label.traditionalChinese")}</p>
                        {language === "tw" && <DoneIcon />}
                    </SelectItem>
                    <SelectItem
                        onClick={() => {
                            dispatch(userActions.setLanguage("cn"))
                            i18n.changeLanguage("cn");
                        }}
                    >
                        <p>{t("page.menu.selectLanguage.label.simplifiedChinese")}</p>
                        {language === "cn" && <DoneIcon />}
                    </SelectItem>
                </SelectTable>
            </SelectContainer>
        </CommonPage>
    );
};

export default LanguageSetting;
