import { Payment } from "@/interfaces/exchange.interface";
import { InfoText, PayInfoText } from "@/styled-components/deal-fiat";
import { useTranslation } from "react-i18next";

const PaymentDetail: React.FC<{ payment: Payment }> = ({
    payment
}) => {
    const { t } = useTranslation()

    if (Object.keys(payment).length === 0) return <></>;

    const columns = {
        "LINE_PAY": ["lineId", "phone", "qrCode"],
        "BANK": ["code", "account", "bankName"],
        "CASH": ["contract"],
        "STREET_PAY": ["streetId", "phone", "qrCode"]
    }
    return (
        <>
            {
                columns[payment.type].map((column) => {
                    return (
                        <InfoText>
                            <PayInfoText>
                                {t(`page.menu.c2cManage.payment.input.${column}`)}
                            </PayInfoText>
                            <PayInfoText>
                                {column === "qrCode" ? (
                                    <img src={payment.qrCode} alt="qrCode" style={{ height: 120, width: 120 }} />
                                ) : payment[column]}
                            </PayInfoText>
                        </InfoText>
                    )
                })
            }
        </>
    )
}

export default PaymentDetail