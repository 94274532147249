import { useState, useEffect } from "react";
import { ReactComponent as DoneIcon } from "@/assets/icon/done.svg";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "@/hooks";
import { infoService } from "@/services";
import CommonPage from "@/components/LayoutPage";
import { userActions } from "@/store/slice";
import { Currency } from "@/interfaces/system.interface";
import { CURRENCY_ICONS } from "@/constants/CurrencyIcon";
import useStackNavigate from "@/hooks/useStackNavigate";

const SelectContainer = styled.div`
  padding: 11px 16px;
  flex: 1;
  align-items: flex-start;
`;

const SelectTable = styled.ul`
  width: 100%;
`;

const SelectItem = styled.li`
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #f4f4f6;
  font-weight: 500;
  font-size: 15px;
  color: #383743;
`;

const CurrencyDescription = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

const CurrencyIcon = styled.img`
  width: 13%;
  border-radius: 12px;
  display: relative;
  margin-right: 12px;
`;

export const CurrencySetting = () => {
    const { stackNavigate } = useStackNavigate()
    const userCurrency = useAppSelector((state)=>state.user.currency)
    const dispatch = useAppDispatch()
    const { t } = useTranslation();
    const [currencyList, setCurrencyList] = useState<Currency[]>([]);

    useEffect(() => {
        infoService.getFiatCurrency().then((response)=>{
            setCurrencyList(Object.keys(response.data).map( name =>{
                return {
                    name: name,
                    rate: response.data[name]
                }
            }));
        })
    }, []);

    return (
        <CommonPage title={t("page.menu.selectCurrency.title")} returnPath={"/member"} style={{alignItems: "flex-start"}}>
            <SelectContainer>
                <SelectTable>
                    {currencyList.map((currency) => {
                        return (
                            <SelectItem
                                key={currency.name}
                                onClick={() => {
                                    dispatch(userActions.setCurrency(currency))
                                    stackNavigate(-1)
                                }}
                            >
                                <CurrencyDescription>
                                    <CurrencyIcon src={CURRENCY_ICONS.get(currency.name) ?? ""} alt={currency.name}/>
                                    <p>{`${currency.name} ${t(`currencyUnit.${currency.name}.mandarin`)}`}</p>
                                </CurrencyDescription>
                                {userCurrency.name === currency.name && <DoneIcon />}
                            </SelectItem>
                        );
                    })}
                </SelectTable>
            </SelectContainer>
        </CommonPage>
    );
};

export default CurrencySetting;
