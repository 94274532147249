/* 引入styled components */
import styled from "styled-components";

/* 下面放圖片 */

import Search from "@/assets/profile/Search.png";
// import Tick from "@/assets/profile/Tick.png";
import useStackNavigate from "@/hooks/useStackNavigate";
import { COUNTRY } from "@/constants/Country";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "@/hooks";
import { userActions } from "@/store/slice";
import { useState } from "react";
import CommonPage from "@/components/LayoutPage";

const Button = styled.button`
  background: #ffffff;
  border-style: none;
  box-shadow: 0.5px 0.5px 2px #bebebe;
  height: 56px;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 50px;
`;

const ButtonP = styled.p`
  text-align: left;
  color: #383743;
  font-size: 15px;
`;

const SearchImg = styled.img`
  width: 12px;
  height: 12px;
  margin-top: 12px;
  margin-left: 10px;
`;

const KeyInBox = styled.div`
  width: 90%;
  margin-left: 20px;
  border-radius: 4px;
  background: #f4f4f6;
  margin-bottom: 10px;
`;

const SearchDiv = styled.div`
  display: flex;
  justify-content: center;
  width: 343px;
`;

const InputBox = styled.input`
  width: 343px;
  display: flex;
  justify-content: center;
  border-style: none;
  margin-left: 10px;
  line-height: 20px;
  background: #f4f4f6;
  padding: 8px;
  font-size: 15px;
`;

const ItemBox = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`
const CountryCallingCode = () => {
    const { stackNavigate } = useStackNavigate()
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const [searchCondition, setSearchCondition] = useState("");
    return (
        <CommonPage title={t("page.countryCallingCode.title")} returnPath={-1}>
            <KeyInBox>
                <SearchDiv>
                    <SearchImg src={Search} alt="Search" />
                    <InputBox placeholder={t("page.countryCallingCode.placeholder.search")} onChange={(e) => {
                        setSearchCondition(e.target.value)
                    }} />
                </SearchDiv>
            </KeyInBox>
            <ItemBox>
                {COUNTRY.filter(country => country.callingCode.includes(searchCondition) || t(`country.${country.code}.countryName`).includes(searchCondition)).map((country) => {
                    return (
                        <Button onClick={() => {
                            dispatch(userActions.setCountry(country))
                            stackNavigate(-1)
                        }}>
                            <ButtonP>{` ${t(`country.${country.code}.countryName`)} +${country.callingCode}`}</ButtonP>
                        </Button>
                    );
                })}
            </ItemBox>
        </CommonPage>
    );
};

export default CountryCallingCode;
