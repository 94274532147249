import CNY from "@/assets/currency/CNY.png";
import EUR from "@/assets/currency/EUR.png";
import IDR from "@/assets/currency/IDR.png";
import JPY from "@/assets/currency/JPY.png";
import PHP from "@/assets/currency/PHP.png";
import RUB from "@/assets/currency/RUB.png";
import TRY from "@/assets/currency/TRY.png";
import TWD from "@/assets/currency/TWD.png";
import USD from "@/assets/currency/USD.png";
import VND from "@/assets/currency/VND.png";

export const CURRENCY_ICONS: Map<string, string> = new Map<string, string>([
    ["CNY", CNY],
    ["EUR", EUR],
    ["IDR", IDR],
    ["JPY", JPY],
    ["PHP", PHP],
    ["RUB", RUB],
    ["TRY", TRY],
    ["TWD", TWD],
    ["USD", USD],
    ["VND", VND],
])