import { COLORS } from "@/constants/colors"
import { CSSProperties, ChangeEventHandler, HTMLInputTypeAttribute, MouseEventHandler, ReactNode } from "react"
import styled from "styled-components"

const LineBox = styled.div`
    margin: 7px 0px;
    display: flex;
    justify-content: center;
    width: 100%;
`

const InputLine = styled.div`
    width: 97%;
    border-bottom: 2px solid ${COLORS.EXLight_gray};
`

const InputContainer = styled.div<{ inputType: "box" | "line" | "solid", valid: boolean }>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: auto;
    padding: 10px 0px;
    border: ${props => props.inputType === "line" ? "none" : `2px solid ${!props.valid ? COLORS.Red : COLORS.EXLight_gray}`};
    border-radius: 5px;
    flex-wrap: ${props => props.inputType === "line" ? "wrap" : ""};
    background-color: ${props => props.inputType === "solid" ? COLORS.EXLight_gray : COLORS.White};
    margin: 5px 0px;
    max-width: 100%;

    &:focus-within {
        border-color: ${props => !props.valid ? COLORS.Red : COLORS.EXLight_gray};

        ${InputLine} {
            border-color: ${props => !props.valid ? COLORS.Red : COLORS.EXLight_gray};
        }
    }
`

const InputLeft = styled.div`
    font-size: 14px;
    font-weight: 500;
    padding: 0px 3px;
`

const InputBox = styled.input<{ inputType: "box" | "line" | "solid" }>`
    flex-grow: 1;
    border: none;
    outline: none;
    background-color: ${props => props.inputType === "solid" ? COLORS.EXLight_gray : COLORS.White};
    /* cancel input default width */
    width: 0px;

    ::placeholder {
        color: ${COLORS.Mid_gray} !important;;
        font-size: 16px !important;
        padding: 0px 3px;
    }
`

const InputRight = styled.div`
    font-size: 14px;
    font-weight: 500;
    padding: 0px 3px;
`

const InvalidMessage = styled.div<{ valid: boolean }>`
    color: ${COLORS.Red}; 
    padding: 5px 0px;
    font-size: 12px; 
    max-width: 100%;
    visibility: ${({ valid }) => (!valid ? 'visible' : 'hidden')};

    &:empty::before {
        content: "\00a0";
        visibility: visible;
    }
`

const TextLabel = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
  color: ${COLORS.Gray};
  font-size: 13px;
  max-width: 100%;
`;

const CustomInput: React.FC<{
    left?: ReactNode,
    placeholder?: string,
    right?: ReactNode,
    down?: ReactNode,
    value?: string,
    onChange?: ChangeEventHandler<HTMLInputElement>
    onClick?: MouseEventHandler<HTMLDivElement>
    style?: CSSProperties,
    inputStyle?: CSSProperties,
    valueType?: HTMLInputTypeAttribute,
    inputType: "box" | "line" | "solid",
    valid?: boolean
    invalidMessage?: string
    label?: string
    disable?: boolean
}> = ({
    placeholder,
    left,
    right,
    down,
    value,
    style,
    valueType,
    inputType,
    inputStyle,
    valid,
    invalidMessage,
    label,
    disable,
    onChange,
    onClick
}) => {
        return (
            <div style={{display: "flex", flexDirection: "column", justifyContent: "space-between", width: "100%"}}>
                {
                    label && <TextLabel>{label}</TextLabel>
                }
                <InputContainer style={style} inputType={inputType} onClick={(e) => onClick && onClick(e)} valid={valid===undefined ? true : valid}>
                    <InputLeft>{left}</InputLeft>
                    <InputBox 
                        type={valueType} 
                        inputType={inputType} 
                        value={value} 
                        placeholder={placeholder} 
                        style={inputStyle} 
                        disabled={disable}
                        onChange={(e) => onChange && onChange(e)} 
                    />
                    {
                        right && <InputRight>{right}</InputRight>
                    }
                    {
                        inputType === "line" &&
                        <LineBox>
                            <InputLine />
                        </LineBox>
                    }
                </InputContainer>
                {
                    down && down
                }
                {
                    valid !== undefined && <InvalidMessage valid={valid}>{invalidMessage}</InvalidMessage>
                }
            </div>
        )
    }

export default CustomInput