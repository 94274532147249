export enum OtcOrderStatus {
    APPEAL = -2,
    CANCEL,
    WAIT_FOR_PAID,
    WAIT_FOR_SEND,
    FINISH,
    WAIT_FOR_BUYER,
    WAIT_FOR_SELLER,
    WAIT_FOR_BOTH
}

export enum AdvertisementType {
    BUY,
    SELL
}

export enum AdvertisementStatus { 
    OFFLINE, 
    ONLINE 
}

export enum FinanceType {
    SPOT_DEPOSIT = 1,
    SPOT_WITHDRAW,
    SPOT_TRANSFER,
    SPOT = 10,
    CONTRACT_DEPOSIT,
    CONTRACT_TRANSFER,
    CONTRACT_MARGIN,
    CONTRACT_BENEFIT_AND_LOSS,
    CONTRACT_HANDLING_FEE,
    CONTRACT_COMMISSION,
    CONTRACT = 20
}

export type ContractSide = "BUY" | "SELL"

export type ContractType = "LIMIT" | "MARKET" | "STOP_LIMIT" | "STOP_MARKET"

export type C2cOrderType = "buy" | "sell"

export type LanguageType = "tw" | "cn" | "en"

export type FutureStatus = "CREATE" | "TRADING" | "DEAL" | "CANCEL" | "FAIL"

export type PayType = "CASH" | "BANK" | "LINE_PAY" | "STREET_PAY"

export type WalletType = "spot" | "otc" | "future"

export type KycStatus = "CREATE" | "PERMIT" | "DENY"

export type AnnouncementType = "ACTIVITY" | "SYSTEM" | "NEWS" 

export const fiatCurrencySymbol = {
    "TWD": "NT$",
    "JPY": "¥",
    "EUR": "€",
    "IDR": "Rp",
    "USD": "$",
    "PHP": "₱",
    "TRY": "₤",
    "VND": "₫",
    "RUB": "₽",
    "CNY": "¥"
}