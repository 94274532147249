import { useState, useEffect } from "react";
// import HightLightIcon from "@/assets/icon/star.png";
import { useParams } from "react-router-dom";
import { infoService } from "@/services";
import { Announcement } from "@/interfaces/exchange.interface";
import CommonPage from "@/components/LayoutPage";
import { Header } from "@/components/typography/Header";
import { Text } from "@/components/typography/Text";
import { formatDateTime } from "@/utils/parse";
import { COLORS } from "@/constants/colors";

const AnnouncementDetail = () => {
    const [announcement, setAnnouncement] = useState<Announcement>({} as Announcement);
    let { id } = useParams();

    useEffect(() => {
        infoService.getAnnouncement(id ?? "").then().then((response) => {
            setAnnouncement(response.data)
        })
    }, [id]);

    return (
        <CommonPage title={""} returnPath={-1}>
            <div style={{ width: "100%", padding: "0px 15px" }}>
                <Header size="lg">{announcement.subject}</Header>
                <Text size="sm">{formatDateTime(announcement.createdDate)}</Text>
                {
                    announcement.thumbnail && <img src={announcement.thumbnail} style={{width: "100%"}} alt="thumbnail"/>
                }
                <Text size="sm"
                    style={{lineHeight: 2, color: COLORS.Black}}
                    dangerouslySetInnerHTML={{
                        __html: announcement.content,
                    }}
                />
            </div>
        </CommonPage>
    );
};

export default AnnouncementDetail;
