import { COLORS } from "@/constants/colors";
import React from "react";
import styled from "styled-components";

export const TextLabel = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: 23px;
  color: ${COLORS.Gray};
  font-size: 13px;
`;

export const InputContainer = styled.p`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const TextAreaInput = styled.textarea`
  padding: 20px;
  background: none;
  opacity: 0.6;
  border-radius: 4px;
  width: 90%;
  margin-left: 20px;
  background: ${COLORS.EXLight_gray};
  color: ${COLORS.Gray};
  border: none;
  height: 100px;
  resize: none;
`;

const LabelTextArea: React.FC<{
    label: string, 
    value?: any,
    placeholder?: string
    disable?: boolean
    onChange?: Function
    onClick?: Function
}> = ({label, value, placeholder, disable, onChange, onClick}) => {
    return (
        <div style={{ width: "100%" }}>
            <TextLabel>{label}</TextLabel>
            <InputContainer>
                <TextAreaInput 
                    value={value} 
                    placeholder={placeholder}
                    disabled={disable} 
                    onClick={()=>onClick && onClick()}
                    onChange={(e)=>onChange && onChange(e)}
                />
            </InputContainer>
        </div>
    )
}

export default LabelTextArea