import { COLORS } from "@/constants/colors";
import styled from "styled-components";

export const BaseButton = styled.button<{ color?: string, disabled?: boolean }>`
    height: 32px;
    border-radius: 4px;
    background-color: ${p => {
        if ( p.disabled ){
            return COLORS.Mid_gray;
        }
        switch (p.color) {
            case "white": return COLORS.White;
            case "gray": return COLORS.EXLight_gray;
            case "dark-gray": return COLORS.Mid_gray;
            case "red": return COLORS.Red;
            case "yellow": return COLORS.BinanceYellow;
            case "light-green": return COLORS.Primary;
            case "gray-ban": return COLORS.EXLight_gray;
            default: return COLORS.Green;
        }
    }};
    color: ${p => {
        switch (p.color) {
            case "white": return COLORS.Black;
            case "gray": return COLORS.Black;
            case "gray-ban": return COLORS.Mid_gray;
            case "dark-gray": return COLORS.White;
            case "red": return COLORS.White;
            case "yellow": return COLORS.Black;
            case "light-green": return COLORS.White;
            default: return COLORS.White;
        }
    }};
    font-size: 14px;
    font-weight: 500;
    font-family: PingFang TC;
    text-align: center;
    line-height: 32px;
    border: transparent;

    &:active {
        background-color: ${p => {
            if (p.disabled){
                return ;
            }
            switch (p.color) {
                case "white": return COLORS.EXLight_gray;
                case "gray": return COLORS.Light_gray;
                case "gray-ban": return COLORS.Light_gray;
                case "dark-gray": return COLORS.Dark_gray;
                case "red": return COLORS.RedActive;
                case "yellow": return COLORS.BinanceYellowActive;
                case "light-green": return COLORS.PrimaryActive;
                default: return COLORS.GreenActive;
            }
        }} !important;
    }

`;