
import { useTranslation } from "react-i18next";
import CommonPage from "@/components/LayoutPage";
import { useState } from "react";
import { Header } from "@/components/typography/Header";
import { Radio, message } from "antd";
import { BottomContainer } from "@/components/Other";
import { BaseButton } from "@/components/Button";
import CustomCard from "@/components/CustomCard";
import { useLocation } from "react-router-dom";
import { investorService } from "@/services";
import useStackNavigate from "@/hooks/useStackNavigate";

const Exam = () => {
    const { t } = useTranslation();
    const { stackNavigate } = useStackNavigate()
    const [userAnswers, setUserAnswers] = useState<OptionType[]>([-1, -1, -1, -1, -1, -1, -1, -1, -1, -1]);
    const quizDetail = useLocation().state as { questions: Question[], quizName: string }
    const questions = quizDetail.questions

    const handleAnswerChange = (selectedAnswer: OptionType, index: number) => {
        setUserAnswers((prev) => {
            prev[index] = selectedAnswer;
            return prev;
        })
    };

    const handleConfirm = () => {
        for (let i = 0; i < questions.length; i++) {
            const question = questions[i];
            if (userAnswers[i] === -1) {
                message.error(`${t("page.quiz.alert.number")} ${i + 1} ${t("page.quiz.alert.question")}`)
                return;
            }
            if (question.answer !== userAnswers[i]) {
                message.error(`${question.subject} ${t("page.quiz.alert.answer")} ${question.answer + 1}`)
                return;
            }
        }
        investorService.acceptQuiz(quizDetail.quizName).then(() => {
            message.success(t("page.quiz.alert.pass"))
            stackNavigate(-1)
        })
    }


    return (
        <CommonPage title={t("page.quiz.title")} returnPath={"/quiz"}>
            <div style={{ width: "100%", padding: "10px 15px" }}>
                <Header size="lg">{t(`page.quiz.label.${quizDetail.quizName}`)}</Header>
                {
                    questions.map((question, index) => {
                        return (
                            <CustomCard>
                                <div style={{ display: "flex", justifyContent: "space-between", flexDirection: "column" }}>
                                    <Header size="md" style={{ display: "flex", justifyContent: "space-between" }}>
                                        <div style={{ width: "6%" }}>{`${index + 1}. `}</div>
                                        <div style={{ width: "94%" }}>{question.subject}</div>
                                    </Header>
                                    <Radio.Group value={userAnswers[index]} onChange={(e) => handleAnswerChange(e.target.value, index)}>
                                        {/* 將選項轉換成 Radio 組件 */}
                                        {questions[index].options.map((value, index) => (
                                            <Radio key={index} value={index}>
                                                {`(${index + 1}) ${value}`}
                                            </Radio>
                                        ))}
                                    </Radio.Group>
                                </div>
                            </CustomCard>
                        )
                    })
                }
            </div>
            <BottomContainer>
                <BaseButton style={{ width: "100%" }} onClick={handleConfirm}>
                    {t("page.quiz.button.submit")}
                </BaseButton>
            </BottomContainer>
        </CommonPage>
    );
};

export default Exam;
