import React from 'react'

// = Styled Component
import {
    DealPriceBlockContainer,
    DealPriceCurrentPrice,
    DealPriceRemarkPrice
} from '@/styled-components/deal';
import { parsePrice } from '@/utils/parse';

interface DealPriceAndRemarkPriceProps {
    price: string;
    remarkPrice: string;
}

export const DealPriceAndRemarkPrice = ({
    price,
    remarkPrice
}: DealPriceAndRemarkPriceProps) => {
    return (
        <DealPriceBlockContainer>
            <DealPriceCurrentPrice>
                {
                    parsePrice(parseFloat(price))
                }
            </DealPriceCurrentPrice>

            <DealPriceRemarkPrice>
                {
                    parsePrice(parseFloat(remarkPrice))
                }
            </DealPriceRemarkPrice>
        </DealPriceBlockContainer>
    )
}

export default DealPriceAndRemarkPrice;
