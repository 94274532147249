import { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next";
import { MoreOutlined, PlusOutlined, RightOutlined } from "@ant-design/icons";
import { advertisementService, userService } from "@/services";
import { useAppSelector, useAppDispatch } from "@/hooks";
import { Advertisement } from "@/interfaces/otc.interface";
import CommonPage from "@/components/LayoutPage";
import { COLORS } from "@/constants/colors";
import Tab, { TabPaneProps } from "@/components/Tab";
import CustomCard from "@/components/CustomCard";
import { RecordHeaderContainer, RecordHeaderLeft, RecordHeaderRight } from "@/styled-components/order";
import { Divider, Dropdown, Menu } from "antd";
import { formatDateTime } from "@/utils/parse";
import { PaymentItem } from "@/styled-components/deal";
import { advertisementActions } from "@/store/slice";
import { checkSecurity } from "@/utils";
import useStackNavigate from "@/hooks/useStackNavigate";
import { useLocation } from "react-router-dom";
import { AdvertisementStatus } from "@/constants";

const AdvertisementManage = () => {
    const { t } = useTranslation();
    const currentStatus = useLocation().state as AdvertisementStatus;
    const [advertisementList, setAdvertisementList] = useState<Advertisement[]>([])
    const [selectedStatus, setSelectedStatus] = useState(currentStatus===null ? AdvertisementStatus.ONLINE : currentStatus)
    const [buyFeeRate, setBuyFeeRate] = useState(0)
    const [sellFeeRate, setSellFeeRate] = useState(0)
    const dispatch = useAppDispatch();
    const account = useAppSelector((state) => state.user.detail.account)
    const items: TabPaneProps[] = [
        {
            key: AdvertisementStatus.ONLINE,
            title: t("page.menu.advertisementManage.label.online"),
            onClick: () => {
                setSelectedStatus(AdvertisementStatus.ONLINE)
            }
        },
        {
            key: AdvertisementStatus.OFFLINE,
            title: t("page.menu.advertisementManage.label.offline"),
            onClick: () => {
                setSelectedStatus(AdvertisementStatus.OFFLINE)
            }
        }
    ];

    const { stackNavigate, stageStackNavigate } = useStackNavigate()

    const getAdvertisements = () => {
        advertisementService.getAdvertisement({
            my: true
        }).then((response) => {
            setAdvertisementList(response)
        })
    }

    const getUserInfo = useCallback(() => {
        userService.getOtcUserInfo(account).then((response) => {
            setBuyFeeRate(response.buyFeeRate);
            setSellFeeRate(response.sellFeeRate);
        })
    }, [account]);

    useEffect(() => {
        getAdvertisements()
        getUserInfo()
    }, [getUserInfo])

    const getPlusOption = () => {
        return (
            <PlusOutlined
                onClick={() => {
                    userService.getSecurity().then((response) => {
                        checkSecurity(response.data)
                        userService.getOtcUserInfo(account).then((response) => {
                            if (!response.advertiserDeposit) {
                                alert(t("page.menu.advertisementManage.alert.createAdvertisement.advertiserDeposit"))
                                stackNavigate("/c2cApply")
                            }
                            else {
                                const currentTime = Date.now();
                                if ( response.forbiddens.find( f => f.privilege==="OTC_ADVERTISEMENT_CREATE" && f.untilDate > currentTime) ){
                                    alert(t("page.menu.advertisementManage.alert.createAdvertisement.contact"))
                                }
                                else {
                                    userService.getPayments().then((response) => {
                                        if (!response.data.length) {
                                            throw new Error(t("page.menu.advertisementManage.alert.createAdvertisement.payment"))
                                        }
                                        else {
                                            stageStackNavigate("/deal/FiatAdPage")
                                        }
                                    }).catch((e) => {
                                        alert(e.message)
                                        stackNavigate("/account-setting")
                                    })
                                }
                            }
                        })
                    }).catch((e) => {
                        alert(t(`page.menu.advertisementManage.alert.createAdvertisement.${e.message}`))
                        switch (e.message) {
                            case "DENY": 
                            case "kyc": 
                                stageStackNavigate("/id-verify"); 
                                break;
                            case "phone": stackNavigate("/reset-phone-number"); break;
                            case "googleAuth": stageStackNavigate("/google-code"); break;
                            case "financePwd": stackNavigate("/set-the-fund-password"); break;
                            default: console.log(e.message)
                        }
                    })
                }}
            />
        )
    }

    const getOption = (advertisement: Advertisement) => {
        return (
            <Dropdown trigger={["click"]} overlay={
                <Menu>
                    {
                        advertisement.status === 0 &&
                        <Menu.Item
                            onClick={() => {
                                advertisementService.online(advertisement.id).then(() => {
                                    getAdvertisements()
                                })
                            }}
                        >
                            {t("page.menu.advertisementManage.button.online")}
                        </Menu.Item>
                    }
                    {
                        advertisement.status === 1 &&
                        <Menu.Item
                            onClick={() => {
                                advertisementService.offline(advertisement.id).then(() => {
                                    getAdvertisements()
                                })
                            }}
                        >
                            {t("page.menu.advertisementManage.button.offline")}
                        </Menu.Item>
                    }
                    {
                        advertisement.status === 0 &&
                        <Menu.Item
                            onClick={() => {
                                dispatch(advertisementActions.setEdit(advertisement.id))
                                dispatch(advertisementActions.set({
                                    type: advertisement.type,
                                    priceType: advertisement.priceType,
                                    price: `${advertisement.price}`,
                                    totalTradingAmount: `${advertisement.totalTradingAmount}`,
                                    orderLimitMin: `${advertisement.orderLimitMin}`,
                                    orderLimitMax: `${advertisement.orderLimitMax}`,
                                    paymentTimeLimit: advertisement.paymentTimeLimit,
                                    conditionCompleteOrders: advertisement.conditionCompleteOrders,
                                    conditionRegisteredDays: advertisement.conditionRegisteredDays,
                                    financePassword: "",
                                    terms: advertisement.terms,
                                    payments: []
                                }))
                                userService.getPayments().then((response) => {
                                    const userPayments = response.data.filter((payment)=>advertisement.payments.map((p)=>p.type).includes(payment.type))
                                    dispatch(advertisementActions.setPayments(userPayments))
                                })
                                
                                stageStackNavigate("/deal/FiatAdPage")
                            }}
                        >
                            {t("page.menu.advertisementManage.button.edit")}
                        </Menu.Item>
                    }
                    {
                        advertisement.status !== -1 &&
                        <Menu.Item
                            onClick={() => {
                                advertisementService.close(advertisement.id).then(() => {
                                    getAdvertisements()
                                })
                            }}
                        >
                            {t("page.menu.advertisementManage.button.close")}
                        </Menu.Item>
                    }
                </Menu>
            }>
                <MoreOutlined rotate={90} style={{ fontSize: 20 }} />
            </Dropdown>
        )
    }
    
    useEffect(()=>{
        dispatch(advertisementActions.clean())
        dispatch(advertisementActions.cleanEdit())
    },[dispatch])

    return (
        <CommonPage title={""} returnPath={"/member"} option={getPlusOption()} style={{ backgroundColor: COLORS.EXLight_gray }}>
            <div style={{ width: "100%", padding: "0px 15px", backgroundColor: COLORS.White }}>
                <div style={{ fontSize: 24, fontWeight: 700, padding: "15px 15px 25px" }}>{t("page.menu.advertisementManage.title")}</div>
                <Tab defaultKey={selectedStatus} panes={items} paneStyle={{ padding: "10px 15px", fontSize: 16 }} color={COLORS.Primary} />
            </div>
            <div style={{ width: "100%", fontFamily: "Open Sans" }}>
                {
                    advertisementList.filter(advertisement => advertisement.status === selectedStatus).map((advertisement) => {
                        return (
                            <div style={{ padding: 15 }}>
                                <CustomCard style={{ borderRadius: 5 }}>
                                    <RecordHeaderContainer style={{ padding: "10px 5px" }}>
                                        <RecordHeaderLeft isBuy={advertisement.type !== 0}>
                                            <span>{advertisement.type === 1 ? t("page.menu.advertisementManage.label.sell") : t("page.menu.advertisementManage.label.buy")}</span>
                                            <span style={{ marginLeft: 3, color: COLORS.Black }}>USDT</span>
                                        </RecordHeaderLeft>
                                        <RecordHeaderRight>
                                            <span style={{ color: COLORS.Primary }}>{t(`modal.advertisement._status.${advertisement.status}`)}</span>
                                            <RightOutlined style={{ fontSize: 10, marginLeft: 3 }} />
                                        </RecordHeaderRight>
                                    </RecordHeaderContainer>
                                    <Divider style={{ margin: "2px 0px", color: COLORS.Dark_gray }} />
                                    <div style={{ display: "flex", justifyContent: "space-between", fontSize: 14, fontWeight: 600, color: COLORS.Mid_gray, padding: "10px 5px", alignItems: "center" }}>
                                        <div>{t("modal.advertisement.price")}</div>
                                        <div style={{ color: COLORS.Black }}>
                                            <span style={{ fontSize: 10 }}>NT$</span>
                                            <span style={{ fontSize: 20, marginLeft: 3 }}>{advertisement.price}</span>
                                        </div>
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "space-between", fontSize: 14, fontWeight: 600, color: COLORS.Mid_gray, padding: "10px 5px", alignItems: "center" }}>
                                        <div>{t("modal.advertisement.totalTradingAmount")}</div>
                                        <div>{advertisement.totalTradingAmount}</div>
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "space-between", fontSize: 14, fontWeight: 600, color: COLORS.Mid_gray, padding: "10px 5px", alignItems: "center" }}>
                                        <div>{t("modal.advertisement.limit")}</div>
                                        <div>NT$ {advertisement.orderLimitMin} - {advertisement.orderLimitMax}</div>
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "space-between", fontSize: 14, fontWeight: 600, color: COLORS.Mid_gray, padding: "10px 5px", alignItems: "center" }}>
                                        <div>{t("modal.advertisement.handlingFee")}</div>
                                        <div>{advertisement.type === 0 ? buyFeeRate : sellFeeRate}</div>
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "space-between", fontSize: 14, fontWeight: 600, color: COLORS.Mid_gray, padding: "10px 5px", alignItems: "center" }}>
                                        <div>{t("modal.advertisement.payment")}</div>
                                        <div>
                                            {advertisement.payments.map((item) => {
                                                return <PaymentItem payment={item.type}>{t(`page.menu.c2cManage.payment.title.${item.type}`)}</PaymentItem>;
                                            })}
                                        </div>
                                    </div>
                                    <Divider style={{ margin: "2px 0px", color: COLORS.Dark_gray }} />
                                    <div style={{ display: "flex", justifyContent: "space-between", fontSize: 14, fontWeight: 600, color: COLORS.Mid_gray, padding: "10px 5px", alignItems: "center" }}>
                                        <div>{formatDateTime(advertisement.createdDate)}</div>
                                        <div>{getOption(advertisement)}</div>
                                    </div>
                                </CustomCard>
                            </div>
                        )
                    })
                }
            </div>
        </CommonPage>
    );
};

export default AdvertisementManage;