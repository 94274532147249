import CommonPage from "@/components/LayoutPage"
import { useTranslation } from "react-i18next"
import styled from "styled-components";

const BlockContainer = styled.div`
  flex: 1;
  width: 100%;
  background: #f4f4f6;
`;
const Block = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
  background: #fff;
  margin-top: 10px;
`;
const BlockTitle = styled.p`
  margin-bottom: 10px;
  font-weight: 600;
`;
const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-bottom: 20px;
`;
const ListContainer = styled.div`
  display: flex;
  align-items: center;
`;

const TermOfService = () => {
    const { t } = useTranslation()
    return (
        <CommonPage title={t("page.termOfService.title")} returnPath={"/register"} style={{ alignItems: "flex-start" }}>
            <BlockContainer>
                <Block>
                    <BlockTitle>請務必閱讀以下信息</BlockTitle>
                    <ContentContainer>
                        <ListContainer>
                            <p>Xebit(以下簡稱本公司)在您閱讀完下列信息後，即表示你為確認閱讀，以及理解並接受本使用條款規定的所有事項</p>
                        </ListContainer>
                    </ContentContainer>
                    <BlockTitle>服務</BlockTitle>
                    <ContentContainer>
                        <ListContainer>
                            <p>本公司僅為您通過本網站從事數字資產交易活動（包括但不限於數字資產交易等）提供線上交易平臺服務。</p>
                        </ListContainer>
                    </ContentContainer>
                    <BlockTitle>服務內容</BlockTitle>
                    <ContentContainer>
                        <ListContainer>
                            <table style={{ border: "none" }}>
                                <tr>
                                    <td>1.&nbsp;</td>
                                    <td>您有權在本網站流覽數字資產產品的即時報價和交易資訊，提交數字資產交易指令，並通過本網站完成數字資產交易。</td>
                                </tr>
                                <tr>
                                    <td>2.&nbsp;</td>
                                    <td>您有權在本網站查看會員帳號下的資訊並應用本網站提供的功能。</td>
                                </tr>
                                <tr>
                                    <td>3.&nbsp;</td>
                                    <td>您有權按照本網站公佈的活動規則參加本網站組織的網站活動。</td>
                                </tr>
                                <tr>
                                    <td>4.&nbsp;</td>
                                    <td>本網站承諾向您提供的其他服務。</td>
                                </tr>
                            </table>
                        </ListContainer>
                    </ContentContainer>
                    <BlockTitle>服務規則</BlockTitle>
                    <p>您承諾遵守本網站的以下服務規則：</p>
                    <br />
                    <ContentContainer>
                        <ListContainer>
                            <table style={{ border: "none" }}>
                                <tr>
                                    <td>1.&nbsp;</td>
                                    <td>您應遵守適用的法律、法規、規則和政策要求的規定，確保您帳號中所有數字資產的來源合法，不得從事任何非法活動或其他損害本網站或任何第三方的權利和利益的活動，例如發送或接收非法、不正當或侵犯任何他人權益的資訊，發送或接收傳銷資訊或造成其他損害的資訊或言論，未經授權使用或偽造本網站的電子郵件標題資訊等。</td>
                                </tr>
                                <tr>
                                    <td>2.&nbsp;</td>
                                    <td>您應遵守適用的法律法規，正確使用和保管您在本網站的帳號及登錄密碼、您的金融交易密碼、您在註冊帳號時提供的與您的帳號綁定的手機號碼，以及通過您的手機收到的驗證碼的安全性。您對使用您在本網站的帳號和登錄密碼、金融交易密碼、發送到您手機的驗證碼進行的任何及所有操作以及由此產生的一切後果承擔全部責任。當您發現您在本網站的帳號、登錄密碼、金融交易密碼或手機驗證碼被任何未經授權的第三方使用，發現其他與您的帳號安全相關的問題時，您應及時通知本網站及時有效的方式，並要求本網站暫停向您在本網站的帳號提供服務。本網站有權在合理時間內對您的請求採取行動；但是，本網站對在採取該等行動之前已經產生的後果，包括但不限於您可能遭受的任何損失不承擔任何責任。未經本網站同意，您不得以捐贈、出借、出租、轉讓或其他方式將您在本網站的帳號轉讓給任何其他人。</td>
                                </tr>
                                <tr>
                                    <td>3.&nbsp;</td>
                                    <td>您同意對使用您在本網站的帳號和密碼進行的所有活動（包括但不限於資訊披露、資訊發佈、線上點擊審批或提交各類規則協議、線上續簽協議或購買服務）承擔責任。</td>
                                </tr>
                                <tr>
                                    <td>4.&nbsp;</td>
                                    <td>您在本網站進行數字資產交易時，不得惡意干擾數字資產交易的正常進行或擾亂交易秩序；您不得使用任何技術手段或其他方式干擾本網站的正常運行或干擾其他用戶對服務的使用；您不得基於虛假事實惡意誹謗本網站的商譽。</td>
                                </tr>
                                <tr>
                                    <td>5.&nbsp;</td>
                                    <td>如您與任何其他用戶就網上交易發生任何爭議，您不得通過司法或政府手段以外的任何方式要求本網站提供相關資訊。</td>
                                </tr>
                                <tr>
                                    <td>6.&nbsp;</td>
                                    <td>您在使用本網站提供的服務過程中發生的所有應繳稅費以及與硬體、軟體和服務相關的所有費用均由您自行承擔。</td>
                                </tr>
                                <tr>
                                    <td>7.&nbsp;</td>
                                    <td>您應遵守本協議及本網站不時發佈的其他服務條款和操作規則，您有權隨時終止使用本網站提供的服務。</td>
                                </tr>
                            </table>
                        </ListContainer>
                    </ContentContainer>
                    <BlockTitle>註冊資格</BlockTitle>
                    <ContentContainer>
                        <div>您確認並承諾：當您完成註冊流程或以其他本網站允許的方式使用本網站提供的服務時，您是根據相關法律規定有能力簽署本協議並使用本網站服務的自然人、法人或其他組織。點擊同意註冊按鈕，即視為您本人或您的授權代理人同意本協議內容，您的授權代理人將註冊本網站並代表您使用本網站提供的服務。如您不是具有上述能力的自然人、法人或組織，您和您的授權代理人應承擔由此產生的一切後果，本公司保留註銷或永久凍結您的帳號並追究您和您的授權代理人責任的權利。</div>
                        <div>同時，通過訪問和使用服務，您陳述並保證您未被列入 FATF (金融行動特別工作組）名單，或OFAC（美國財政部外國資產控制辦公室）認定的“特別指定國家”名單或任何其他貿易或經濟制裁名單，例如聯合國安理會制裁名單。我們保留選擇市場和司法管轄區進行運營的權利，並可自行決定限制或拒絕在某些國家/地區提供服務。</div>
                    </ContentContainer>
                    <BlockTitle>註冊目的</BlockTitle>
                    <ContentContainer>
                        <div>您確認並承諾，在註冊本網站並非為了違反任何適用的法律法規或破壞本網站數字資產交易秩序。</div>
                    </ContentContainer>
                    <BlockTitle>註冊流程</BlockTitle>
                    <ContentContainer>
                        <ListContainer>
                            <table style={{ border: "none" }}>
                                <tr>
                                    <td>1.&nbsp;</td>
                                    <td>您同意按照本網站用戶註冊頁面的要求提供有效的電子郵件地址、手機號碼等資訊。您可以使用電子郵件地址、手機號碼或本網站允許的任何其他方式登錄本網站。必要時並根據相關司法管轄區適用法律法規的要求，您應提供您的真實姓名、身份證等適用法律法規、隱私條款和反洗錢條款要求的資訊，並不斷更新您的註冊數據，以便它們能夠及時、詳細和準確地滿足要求。所有原始鍵入的數據都將視為註冊資訊作為參考。您應對此類資訊的真實性、完整性和準確性負責，並承擔由此產生的任何直接或間接損失和不利後果。</td>
                                </tr>
                                <tr>
                                    <td>2.&nbsp;</td>
                                    <td>如您所在的主權國家或地區適用的法律、法規、規章、命令等規範性檔要求手機帳號必須實名，您在此確認您為註冊而提供的手機號碼已通過實名註冊流程。如果您無法提供所要求的手機號碼，由此產生的任何直接或間接損失和不利後果以及對您的影響由您自行承擔。</td>
                                </tr>
                                <tr>
                                    <td>3.&nbsp;</td>
                                    <td>在您以合法、完整、有效的方式提供所需的註冊資訊並通過相關驗證後，您有權獲得本網站的帳號和密碼。獲得該帳號和密碼後，您的註冊即視為成功，您可以作為本網站的會員登錄本網站。</td>
                                </tr>
                                <tr>
                                    <td>4.&nbsp;</td>
                                    <td>您同意接收本網站發送的與本網站管理和運營相關的電子郵件和/或短信。</td>
                                </tr>
                                <tr>
                                    <td>5.&nbsp;</td>
                                    <td>您承認並理解，在您完成普通用戶註冊後，如您想成為我們認證的商戶，您需要提供更多的商戶註冊材料和資訊。我們可能要求的進一步資料和資訊包括但不限於您的電話號碼、微信帳號、telegram 帳號、收入證明、以往在本網站或任何其他場外交易所的交易經驗、風險控制策略及我們認為有必要提供且適用法律可能要求的其他資訊和資料。另外，註冊為認證商戶後，為保障您參與的交易安全，部分數字資產將作為保證金鎖定在您的 OTC 帳戶中，您在交易過程中無法進行轉賬和提現。您承認並同意，如您作為經過驗證的商戶，被證明應對任何交易期間的任何損害、損失和任何其他付款承擔責任但您拒絕賠付，我們可能會處理您的保證金，以此來彌補任何第三方的損害、損失以及您根據適用法律本應支付的任何其他款項。在您完成認證商戶註冊後，我們會為您提供認證商戶標誌，以區別於其他未完成認證商戶註冊的用戶。您可以向本網站提交註銷申請以取消您的驗證，如您不存在與任何第三方的未決爭議記錄或負面歷史記錄，本網站將在 3 個工作日內處理您的申請並解鎖您的保證金。</td>
                                </tr>
                            </table>
                        </ListContainer>
                    </ContentContainer>
                    <BlockTitle>條款生效</BlockTitle>
                    <ContentContainer>
                        <ListContainer>
                            <table style={{ border: "none" }}>
                                <tr>
                                    <td>1.&nbsp;</td>
                                    <td>本協議自您點擊本網站註冊頁面，完成註冊流程並獲得本網站帳號和密碼時生效，對您和本網站均具有約束力。</td>
                                </tr>
                                <tr>
                                    <td>2.&nbsp;</td>
                                    <td>本協議的最終解釋權歸本網站所有。</td>
                                </tr>
                            </table>
                        </ListContainer>
                    </ContentContainer>
                </Block>
            </BlockContainer>
        </CommonPage>
    )
}

export default TermOfService