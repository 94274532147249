import React from "react";

const Modal = (props: { children: React.ReactNode }) => {
    return (
        <div
            style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100vw",
                height: "100vh",
                background: "rgba(56, 55, 67,0.24)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            {props.children}
        </div>
    );
};

export default Modal;
