import { Country } from "@/interfaces/system.interface";

export const COUNTRY: Country[] = [
    {
        code: "US",
        callingCode: "1",
        phoneRegExp: `/.*/`
    },
    {
        code: "CA",
        callingCode: "1",
        phoneRegExp: `/.*/`
    },
    {
        code: "UK",
        callingCode: "44",
        phoneRegExp: `/.*/`
    },
    {
        code: "AF",
        callingCode: "93",
        phoneRegExp: `/.*/`
    },
    {
        code: "AR",
        callingCode: "54",
        phoneRegExp: `/.*/`
    },
    {
        code: "AT",
        callingCode: "43",
        phoneRegExp: `/.*/`
    },
    {
        code: "AU",
        callingCode: "61",
        phoneRegExp: `/.*/`
    },
    {
        code: "BH",
        callingCode: "973",
        phoneRegExp: `/.*/`
    },
    {
        code: "BD",
        callingCode: "880",
        phoneRegExp: `/.*/`
    },
    {
        code: "BE",
        callingCode: "32",
        phoneRegExp: `/.*/`
    },
    {
        code: "BT",
        callingCode: "975",
        phoneRegExp: `/.*/`
    },
    {
        code: "BO",
        callingCode: "591",
        phoneRegExp: `/.*/`
    },
    {
        code: "BR",
        callingCode: "55",
        phoneRegExp: `/.*/`
    },
    {
        code: "KH",
        callingCode: "855",
        phoneRegExp: `/.*/`
    },
    {
        code: "CM",
        callingCode: "237",
        phoneRegExp: `/.*/`
    },
    {
        code: "CN",
        callingCode: "86",
        phoneRegExp: `/.*/`
    },
    {
        code: "AI",
        callingCode: "1264",
        phoneRegExp: `/.*/`
    },
    {
        code: "AG",
        callingCode: "1268",
        phoneRegExp: `/.*/`
    },
    {
        code: "AW",
        callingCode: "297",
        phoneRegExp: `/.*/`
    },
    {
        code: "BM",
        callingCode: "1441",
        phoneRegExp: `/.*/`
    },
    {
        code: "DO",
        callingCode: "1767",
        phoneRegExp: `/.*/`
    },
    {
        code: "GD",
        callingCode: "1473",
        phoneRegExp: `/.*/`
    },
    {
        code: "LC",
        callingCode: "1758",
        phoneRegExp: `/.*/`
    },
    {
        code: "CO",
        callingCode: "57",
        phoneRegExp: `/.*/`
    },
    {
        code: "SG",
        callingCode: "ˊ65",
        phoneRegExp: `/.*/`
    },
    {
        code: "CG",
        callingCode: "243",
        phoneRegExp: `/.*/`
    },
    {
        code: "CH",
        callingCode: "41",
        phoneRegExp: `/.*/`
    },
    {
        code: "DE",
        callingCode: "49",
        phoneRegExp: `/.*/`
    },
    {
        code: "DK",
        callingCode: "45",
        phoneRegExp: `/.*/`
    },
    {
        code: "EG",
        callingCode: "20",
        phoneRegExp: `/.*/`
    },
    {
        code: "ES",
        callingCode: "34",
        phoneRegExp: `/.*/`
    },
    {
        code: "SV",
        callingCode: "503",
        phoneRegExp: `/.*/`
    },
    {
        code: "FI",
        callingCode: "358",
        phoneRegExp: `/.*/`
    },
    {
        code: "FJ",
        callingCode: "679",
        phoneRegExp: `/.*/`
    },
    {
        code: "FR",
        callingCode: "33",
        phoneRegExp: `/.*/`
    },
    {
        code: "GE",
        callingCode: "995",
        phoneRegExp: `/.*/`
    },
    {
        code: "GH",
        callingCode: "233",
        phoneRegExp: `/.*/`
    },
    {
        code: "GR",
        callingCode: "30",
        phoneRegExp: `/.*/`
    },
    {
        code: "GT",
        callingCode: "502",
        phoneRegExp: `/.*/`
    },
    {
        code: "GY",
        callingCode: "967",
        phoneRegExp: `/.*/`
    },
    {
        code: "HT",
        callingCode: "509",
        phoneRegExp: `/.*/`
    },
    {
        code: "HN",
        callingCode: "504",
        phoneRegExp: `/.*/`
    },
    {
        code: "HK",
        callingCode: "852",
        phoneRegExp: `/.*/`
    },
    {
        code: "IN",
        callingCode: "91",
        phoneRegExp: `/.*/`
    },
    {
        code: "IS",
        callingCode: "354",
        phoneRegExp: `/.*/`
    },
    {
        code: "ID",
        callingCode: "62",
        phoneRegExp: `/.*/`
    },
    {
        code: "IQ",
        callingCode: "964",
        phoneRegExp: `/.*/`
    },
    {
        code: "IE",
        callingCode: "353",
        phoneRegExp: `/.*/`
    },
    {
        code: "IT",
        callingCode: "39",
        phoneRegExp: `/.*/`
    },
    {
        code: "JM",
        callingCode: "1876",
        phoneRegExp: `/.*/`
    },
    {
        code: "JO",
        callingCode: "962",
        phoneRegExp: `/.*/`
    },
    {
        code: "KZ",
        callingCode: "7",
        phoneRegExp: `/.*/`
    },
    {
        code: "KE",
        callingCode: "254",
        phoneRegExp: `/.*/`
    },
    {
        code: "JP",
        callingCode: "81",
        phoneRegExp: `/.*/`
    },
    {
        code: "KP",
        callingCode: "82",
        phoneRegExp: `/.*/`
    },
    {
        code: "KW",
        callingCode: "965",
        phoneRegExp: `/.*/`
    },
    {
        code: "LU",
        callingCode: "352",
        phoneRegExp: `/.*/`
    },
    {
        code: "MO",
        callingCode: "853",
        phoneRegExp: `/.*/`
    },
    {
        code: "MK",
        callingCode: "389",
        phoneRegExp: `/.*/`
    },
    {
        code: "MG",
        callingCode: "261",
        phoneRegExp: `/.*/`
    },
    {
        code: "ML",
        callingCode: "60",
        phoneRegExp: `/.*/`
    },
    {
        code: "MV",
        callingCode: "960",
        phoneRegExp: `/.*/`
    },
    {
        code: "MX",
        callingCode: "52",
        phoneRegExp: `/.*/`
    },
    {
        code: "MA",
        callingCode: "212",
        phoneRegExp: `/.*/`
    },
    {
        code: "NO",
        callingCode: "47",
        phoneRegExp: `/.*/`
    },
    {
        code: "NR",
        callingCode: "674",
        phoneRegExp: `/.*/`
    },
    {
        code: "NZ",
        callingCode: "64",
        phoneRegExp: `/.*/`
    },
    {
        code: "NI",
        callingCode: "505",
        phoneRegExp: `/.*/`
    },
    {
        code: "NG",
        callingCode: "234",
        phoneRegExp: `/.*/`
    },
    {
        code: "PK",
        callingCode: "92",
        phoneRegExp: `/.*/`
    },
    {
        code: "PA",
        callingCode: "507",
        phoneRegExp: `/.*/`
    },
    {
        code: "PG",
        callingCode: "675",
        phoneRegExp: `/.*/`
    },
    {
        code: "PT",
        callingCode: "351",
        phoneRegExp: `/.*/`
    },
    {
        code: "PY",
        callingCode: "595",
        phoneRegExp: `/.*/`
    },
    {
        code: "RO",
        callingCode: "40",
        phoneRegExp: `/.*/`
    },
    {
        code: "RU",
        callingCode: "7",
        phoneRegExp: `/.*/`
    },
    {
        code: "RW",
        callingCode: "250",
        phoneRegExp: `/.*/`
    },
    {
        code: "SA",
        callingCode: "966",
        phoneRegExp: `/.*/`
    },
    {
        code: "SY",
        callingCode: "381",
        phoneRegExp: `/.*/`
    },
    {
        code: "SC",
        callingCode: "248",
        phoneRegExp: `/.*/`
    },
    {
        code: "LK",
        callingCode: "94",
        phoneRegExp: `/.*/`
    },
    {
        code: "SG",
        callingCode: "65",
        phoneRegExp: `/.*/`
    },
    {
        code: "SD",
        callingCode: "249",
        phoneRegExp: `/.*/`
    },
    {
        code: "SE",
        callingCode: "46",
        phoneRegExp: `/.*/`
    },
    {
        code: "TL",
        callingCode: "66",
        phoneRegExp: `/.*/`
    },
    {
        code: "TW",
        callingCode: "886",
        phoneRegExp: `^(09\\d{8}|9\\d{8})$`
    },
    {
        code: "TO",
        callingCode: "676",
        phoneRegExp: `/.*/`
    },
    {
        code: "TR",
        callingCode: "90",
        phoneRegExp: `/.*/`
    },
    {
        code: "UG",
        callingCode: "256",
        phoneRegExp: `/.*/`
    },
    {
        code: "UA",
        callingCode: "380",
        phoneRegExp: `/.*/`
    },
    {
        code: "AE",
        callingCode: "971",
        phoneRegExp: `/.*/`
    },
    {
        code: "UY",
        callingCode: "598",
        phoneRegExp: `/.*/`
    },
    {
        code: "UZ",
        callingCode: "998",
        phoneRegExp: `/.*/`
    },
    {
        code: "VE",
        callingCode: "58",
        phoneRegExp: `/.*/`
    },
    {
        code: "YE",
        callingCode: "967",
        phoneRegExp: `/.*/`
    },
]