import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FundPage, ValuePair } from '@/pages/fund/components';

import {
    Line,
    CurrencyContainer,
    CurrencyLeft,
    CurrencyRight,
    Currency,
    PriceText
} from '@/styled-components/fund-contract'
import { investorService } from "@/services";
import PositionList from "@/pages/Deal/PositionList";
import { useAppSelector } from "@/hooks";
import { Property } from "@/interfaces/exchange.interface";

const Contract = () => {
    const profitAndLoss = useAppSelector((state) => state.positions.totalProfitAndLoss)
    const { t } = useTranslation();
    const [balance, setBalance] = useState(0);
    const [property, setProperty] = useState({} as Property);

    const getBalance = () => {
        investorService.getAvailableBalance().then((response) => {
            setBalance(response.data)
        })
    };

    function getProperty() {
        investorService.getProperty().then((response) => {
            setProperty(response.data)
        })
    }

    // * hooks
    useEffect(() => {
        getBalance();
        getProperty();
        const timer = setInterval(() => {
            getBalance();
            getProperty();
        }, 1000);
        return () => clearInterval(timer)
    }, []);

    return (
        <FundPage active={"future"} total={property.futures && property.futures.balance}>
            <CurrencyContainer>
                <CurrencyLeft>
                    <Currency>
                        <PriceText>{t("page.fund.label.marginBalance")}</PriceText>
                    </Currency>
                </CurrencyLeft>
                <CurrencyRight>
                    <ValuePair value={balance} symbol="USDT" />
                </CurrencyRight>
            </CurrencyContainer>
            <CurrencyContainer>
                <CurrencyLeft>
                    <Currency>
                        <PriceText>{t("page.fund.label.futureBalance")}</PriceText>
                    </Currency>
                </CurrencyLeft>
                <CurrencyRight>
                    <ValuePair value={property.futures && property.futures.balance} symbol="USDT" />
                </CurrencyRight>
            </CurrencyContainer>
            <CurrencyContainer>
                <CurrencyLeft>
                    <Currency>
                        <PriceText>{t("page.fund.label.benefitAndLoss")}</PriceText>
                    </Currency>
                </CurrencyLeft>
                <CurrencyRight>
                    <ValuePair value={profitAndLoss} symbol="USDT" />
                </CurrencyRight>
            </CurrencyContainer>
            <Line></Line>
            <div style={{ padding: "5px 16px" }}>
                <PositionList />
            </div>
        </FundPage>
    );
};

export default Contract;
