// ? Self-packed Components || Functions
import { COLORS } from "@/constants/colors";

// - Images
// import MyProfile from "@/assets/icon/Tab/profile.png";
// import SelectMyProfile from "@/assets/icon/Tab/select-profile.png";

import Home from '@/assets/footer/home.svg';
import SelectHome from '@/assets/footer/home_active.svg';
import Quote from '@/assets/footer/quote.svg';
import SelectQuote from '@/assets/footer/quote_active.svg';
import Deal from '@/assets/footer/deal.svg';
import SelectDeal from '@/assets/footer/deal_active.svg';
import Fund from '@/assets/footer/fund.svg';
import SelectFund from '@/assets/footer/fund_active.svg';
import Order from '@/assets/footer/order.svg';
import SelectOrder from '@/assets/footer/order_active.svg';
import { useTranslation } from "react-i18next";
import { isEmpty } from 'lodash';

// = Styled Component
import styled from "styled-components";
import { useAppSelector } from "@/hooks";
import useStackNavigate from "@/hooks/useStackNavigate";

const HeaderContainer = styled.footer`
  background-color: ${COLORS.White};
  height: 70px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  box-shadow: 0px -2px 4px rgba(143, 141, 162, 0.07);
  padding-bottom: 10px;
  position: fixed;
  width: 100%;
  bottom: 0;
  @media (min-width: 1200px) {
    background-color: #191919;
    height: 200px;
    justify-content: space-between;
    padding: 60px 149px 67px 148px;
  }
`;
const TabImage = styled.img`
  width: 31px;
  height: 28px;
`;
const TabDescription = styled.p<{ isLocate: boolean }>`
  font-family: PingFang TC;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 15px;
  text-align: center;
  color: ${props => (props.isLocate ? "#383743" : "#BDBCC8")};
`;
const LinkText = styled.div`
  margin-top: 10.5px;
  text-decoration: none;
  @media (min-width: 1200px) {
    display: none;
  }
`;

interface FooterProps {
    locationPage: string
}

const Footer = (props: FooterProps) => {
    const token = useAppSelector((state)=>state.user.token)
    const { stackNavigate } = useStackNavigate()
    // $ init data
    const { t } = useTranslation();
    const { locationPage } = props;
    // & handled data
    const links = [
        // 首頁
        {
            key: 0,
            alt: 'home',
            img: Home,
            selectedImg: SelectHome,
            path: '/home',
            text: t("footer.label.home")
        },
        // 市場 || 行情
        {
            key: 1,
            alt: 'quote',
            img: Quote,
            selectedImg: SelectQuote,
            path: '/quote',
            text: t("footer.label.market")
        },
        // 交易
        {
            key: 2,
            alt: 'trade',
            img: Deal,
            selectedImg: SelectDeal,
            path: '/deal',
            text: t("footer.label.trade")
        },
        // 資金
        {
            key: 3,
            alt: 'funds',
            img: Fund,
            selectedImg: SelectFund,
            path: token ? '/fund/overview' : '/login',
            text: t("footer.label.wallet")
        },
        // 訂單
        {
            key: 4,
            alt: 'orders',
            img: Order,
            selectedImg: SelectOrder,
            path: token ? '/orders' : '/login',
            text: t("footer.label.order")
        },
    ];

    return (
        <HeaderContainer>
            {/* 目前 Footer 的按鈕 */}
            {
                !isEmpty(links) &&
                links.map(item => {
                    const isActive = locationPage === item.path;
                    return (
                        <LinkText key={item.key} onClick={() => stackNavigate(item.path)}>
                            {isActive ? (
                                <TabImage src={item.selectedImg} alt={item.alt} />
                            ) : (
                                <TabImage src={item.img} alt={item.alt} />
                            )}
                            <TabDescription isLocate={isActive}>
                                {item.text}
                            </TabDescription>
                        </LinkText>
                    )
                })
            }
        </HeaderContainer>
    );
};

export default Footer;
