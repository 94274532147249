import React, { useState } from "react";
import styled from "styled-components";
import CommonPage from "@/components/LayoutPage";
import { useTranslation } from "react-i18next";
import { COLORS } from "@/constants/colors";
import { DownOutlined, RightOutlined } from "@ant-design/icons";
import HelpIcon from "@/assets/help.svg"

const NavContainer = styled.div`
    display: flex;
    padding: 0px 10px;
    width: 100%;
    border-bottom: 3px solid ${COLORS.EXLight_gray};
    margin: 10px 0px;
`

const NavItem = styled.span<{ active: boolean }>`
    padding: 5px 8px;
    font-size: 14px;
    font-weight: ${props => props.active ? 600 : 400};
    border-bottom: 3px solid ${props => props.active ? COLORS.Green : COLORS.White};
`

const Question = styled.div`
    font-size: 14px;
    font-weight: 700;
    padding: 20px 15px;
    width: 100%;
    border-bottom: 1px solid ${COLORS.EXLight_gray};
    display: flex;
    justify-content: space-between;
`

const Answer = styled.div<{ show: boolean }>`
    padding: 15px;
    width: 100%;
    border-bottom: 1px solid ${COLORS.EXLight_gray};
    display: ${props => props.show ? "inline" : "none"};
    line-height: 35px;
`

const C2cNotification = () => {
    const { t } = useTranslation();
    const qaTypes = ["newer", "ad", "other"]
    const questions = [11, 8, 3]
    const [selectedQaType, setSelectedQaType] = useState("newer");
    const [selectedQuestion, setSelectedQuestion] = useState(0);


    const getQuestion = () => {
        const qaItems: JSX.Element[] = [];

        for (let i = 1; i <= questions[qaTypes.indexOf(selectedQaType)]; i++) {
            qaItems.push(
                <>
                    <Question key={i} onClick={() => { setSelectedQuestion((prev) => prev === 0 ? i : 0) }}>
                        {t(`page.menu.c2cManage.help.${selectedQaType}.q${i}`)}
                        {
                            i === selectedQuestion ? <DownOutlined style={{ padding: "0px 5px", color: COLORS.Mid_gray }} /> : <RightOutlined style={{ padding: "0px 5px", color: COLORS.Mid_gray }} />
                        }
                    </Question>
                    <Answer show={i === selectedQuestion}>
                        {t(`page.menu.c2cManage.help.${selectedQaType}.a${i}`)}
                    </Answer>
                </>
            );
        }

        return qaItems;
    }
    return (
        <CommonPage title={""} returnPath={-1}>
            <div style={{ width: "95%", background: COLORS.EXLight_gray, display: "flex", justifyContent: "space-between" }}>
                <div>
                    <div style={{ padding: "20px 15px 5px 15px", fontSize: 12 }}>{t("page.menu.c2cManage.help.label.welcome")}</div>
                    <div style={{ padding: "5px 15px 20px 15px", fontWeight: 800, fontSize: 30 }}>{t("page.menu.c2cManage.help.label.helpPage")}</div>
                </div>
                <img src={HelpIcon} alt="help" style={{ marginRight: 20, width: "15%" }}/>
            </div>

            <NavContainer>
                {
                    qaTypes.map((qaType) => {
                        return (
                            <NavItem
                                active={selectedQaType === qaType}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setSelectedQaType(qaType)
                                }}
                            >
                                {t(`page.menu.c2cManage.help.label.${qaType}`)}
                            </NavItem>
                        )
                    })
                }
            </NavContainer>
            {
                getQuestion()
            }
        </CommonPage>
    );
};

export default C2cNotification;
