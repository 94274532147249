
import { COLORS } from '@/constants/colors';
import { BaseDrawer } from '@/components/BaseDrawer';
import { isEmpty } from 'lodash';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const SwitchOrderItem = styled.div<{ active: boolean }>`
  width: 100%;
  padding: 20px 16px;
  font-size: 16px;
  color: ${p => p.active ? COLORS.Primary : COLORS.Mid_gray};
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-bottom: 1px solid ${COLORS.Light_gray};
`;

interface SwitchOrderDrawerProps {
    isOpen: boolean;
    onClose: () => void;
    status: number;
    handleSwitchOrder: (status: number) => void;
}

/**
 * @author odin
 * @level Deal/SwitchOrderDrawer
 * @description 改變交易對顯示的 Drawer
*/
export const SwitchOrderDrawer = ({
    isOpen,
    onClose,
    status,
    handleSwitchOrder
}: SwitchOrderDrawerProps) => {
    const { t } = useTranslation()
    // $ init data
    const options = [
        {
            key: 0,
            text: t("page.deal.contract.label.default")
        },
        {
            key: 1,
            text: t("page.deal.contract.label.buyOrder")
        },
        {
            key: 2,
            text: t("page.deal.contract.label.sellOrder")
        }
    ];

    return (
        <BaseDrawer
            isOpen={isOpen}
            onClose={onClose}
            isShowTitleBox={false}
            bodyStyle={{
                padding: 0
            }}
        >
            {
                !isEmpty(options) && (
                    options.map(item => (
                        <SwitchOrderItem
                            key={item.key}
                            children={item.text}
                            active={item.key === status}
                            onClick={() => {
                                handleSwitchOrder(item.key);
                            }}
                        />
                    ))
                )
            }
        </BaseDrawer>
    );
};

export default SwitchOrderDrawer;
