import { COLORS } from "./colors"

export type Size = "lg" | "md" | "sm" | "xs"

interface FontProperties {
    size: string
    color: string
    padding: string
    weight: string
}

export const HEADER_SIZE_NUMBER: Map<Size, FontProperties> = new Map<Size, FontProperties>([
    ["lg", { size: "24px", color: COLORS.Black, padding: "20px 0px", weight: "900"}],
    ["md", { size: "16px", color: COLORS.Black, padding: "15px 0px", weight: "700"}],
    ["sm", { size: "14px", color: COLORS.Black, padding: "10px 0px", weight: "500"}],
    ["xs", { size: "12px", color: COLORS.Black, padding: "5px 0px", weight: "400"}]
])

export const TEXT_SIZE_NUMBER: Map<Size, FontProperties> = new Map<Size, FontProperties>([
    ["lg", { size: "24px", color: COLORS.Mid_gray, padding: "20px 0px", weight: "900"}],
    ["md", { size: "16px", color: COLORS.Mid_gray, padding: "15px 0px", weight: "700"}],
    ["sm", { size: "14px", color: COLORS.Mid_gray, padding: "10px 0px", weight: "500"}],
    ["xs", { size: "12px", color: COLORS.Mid_gray, padding: "5px 0px", weight: "400"}]
])