import { useEffect, useState } from "react";

// ? Self-packed Components || Functions
import { COLORS } from "@/constants/colors";

// ^ Types or Interfaces
import { MenuOption } from "@//constants/type";

// - Images
import Cancel from "@/assets/icon/cancel.png";
import SecurityBgSrc from "@/assets/member/security_bg.svg";
import useStackNavigate from "@/hooks/useStackNavigate";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "@/hooks";
// = Styled Component
import styled from "styled-components";
import UserInfo from "@/pages/menu/user-info/UserInfo";
import { userActions } from "@/store/slice";
import { CustomerServiceOutlined, RightOutlined } from "@ant-design/icons";
import { userService } from "@/services";
import CommonPage from "@/components/LayoutPage";
import CustomListItem from "@/components/List";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook, faBullhorn, faCircleInfo, faCoins, faEnvelope, faGlobe, faRectangleAd, faUserGroup, faUserShield, faUsers } from "@fortawesome/free-solid-svg-icons";


const HeaderContainer = styled.header`
  background-color: ${COLORS.White};
  height: 44px;
`;

const HeaderLeft = styled.div`
  float: Left;
  display: flex;
  align-items: center;
  height: 44px;
`;
const HeaderRight = styled.div`
  float: Right;
  display: flex;
  align-items: center;
  height: 44px;
`;
const SelectIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-left: 7px;
`;

const FooterContainer = styled.div`
  height: 154px;
  padding: 0px 16px;
`;

const LogoutButton = styled.button`
  width: 100%;
  height: 44px;
  border: none;
  background: #f4f4f6;
  border-radius: 4px;
  color: #fff;
  font-size: 14px;
`;

const SecurityZone = styled.div`
  width: 100%;
  padding: 0px 10px;
  position: relative;
`;

const SecurityBg = styled.img`
  width: 100%;
`;

const SecurityTitle = styled.h3`
  width: 60%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 22%;
  color: ${COLORS.White};
  font-size: 16px;
`;

/**
 * @author odin
 * @level Layout/Member
 * @description 會員頁面
 */
const Member = () => {
    // $ init data
    const { stackNavigate, stageStackNavigate } = useStackNavigate()
    const { t } = useTranslation();
    // redux 
    const token = useAppSelector(state => state.user.token)
    const dispatch = useAppDispatch();

    // # states
    const [isLoginLocal, setIsLoginLocal] = useState(false);
    const [isRegister, setIsRegister] = useState(false);
    const [loading, setLoading] = useState(false);
    const [kyc, setKyc] = useState<string | undefined>();

    const getStatus = () => {
        userService.getSecurity().then((response) => {
            setKyc(response.data.kyc);
        })
    };

    useEffect(() => {
        getStatus();
    }, []);

    // & handled data
    const optionList: MenuOption[] = [
        {
            text: t("page.menu.kyc.title"),
            needToken: true,
            icon: <FontAwesomeIcon icon={faUserShield} style={{ marginRight: 15 }} />,
            onClick: () => {
                if ((!kyc || kyc==="DENY") && token) {
                    stageStackNavigate("/id-verify")
                }
            },
            option: <div>{kyc ? t(`modal.kyc._status.${kyc}`) : t(`modal.kyc._status.EMPTY`)}</div>
        },
        // 通知中心
        {
            text: t("page.menu.label.noticeCenter"),
            needToken: true,
            icon: <FontAwesomeIcon icon={faEnvelope} style={{ marginRight: 15 }} />,
            onClick: () => stackNavigate(token ? "/message-box" : "/login"),
        },
        // C2C管理
        {
            text: t("page.menu.label.fiatManage"),
            needToken: true,
            icon: <FontAwesomeIcon icon={faUserGroup} style={{ marginRight: 15 }} />,
            onClick: () => stackNavigate(token ? "/c2c" : "/login"),
        },
        // 我的廣告
        {
            text: t("page.menu.label.myAds"),
            needToken: true,
            icon: <FontAwesomeIcon icon={faRectangleAd} style={{ marginRight: 15 }} />,
            onClick: () => stackNavigate(token ? "/advertisement-manage" : "/login"),
        },
        // 代理返佣
        {
            text: t("page.menu.label.referralManage"),
            needToken: true,
            icon: <FontAwesomeIcon icon={faUsers} style={{ marginRight: 15 }} />,
            onClick: () => stackNavigate(token ? "/profile/Rebate" : "/login"),
        },
        // 公告
        {
            text: t("page.menu.label.announcement"),
            needToken: false,
            icon: <FontAwesomeIcon icon={faBullhorn} style={{ marginRight: 15 }} />,
            onClick: () => stackNavigate("/announcement"),
        },
        // 常見問題
        {
            text: t("page.menu.label.guide"),
            needToken: false,
            icon: <FontAwesomeIcon icon={faBook} style={{ marginRight: 15 }} />,
            onClick: () => stackNavigate("/question"),
        },
        // 關於我們
        {
            text: t("page.menu.label.aboutUs"),
            needToken: false,
            icon: <FontAwesomeIcon icon={faCircleInfo} style={{ marginRight: 15 }} />,
            onClick: () => stackNavigate("/about"),
        },
        // 選擇語言
        {
            text: t("page.menu.selectLanguage.title"),
            needToken: false,
            icon: <FontAwesomeIcon icon={faGlobe} style={{ marginRight: 15 }} />,
            onClick: () => stackNavigate("/language-setting")
        },
        // 選擇法幣
        {
            text: t("page.menu.selectCurrency.title"),
            needToken: false,
            icon: <FontAwesomeIcon icon={faCoins} style={{ marginRight: 15 }} />,
            onClick: () => stackNavigate("/currency-setting")
        },
    ];

    const getOption = () => {
        return (
            <CustomerServiceOutlined
                alt="language"
                style={{ fontSize: "20px" }}
                onClick={() => {
                    if (!token) {
                        alert(t("page.menu.login.alert.loginFirst"))
                        return;
                    }
                    stackNavigate("/customer-service")
                }}
            />
        )
    }

    return (
        <CommonPage active={loading} title="" returnPath={"/home"} option={getOption()}>
            <div style={{ width: "100%" }}>
                {!isLoginLocal && !isRegister && (
                    <>
                        <UserInfo />
                        {
                            token && <SecurityZone
                                onClick={() => {
                                    stackNavigate("/safe-setting");
                                }}
                            >
                                <SecurityBg src={SecurityBgSrc} />

                                <SecurityTitle>{t("page.menu.label.securityTitle")}</SecurityTitle>
                            </SecurityZone>
                        }

                        {
                            optionList.filter(item => (item.needToken && token) || !item.needToken).map((item: MenuOption, index) => (
                                <CustomListItem
                                    key={index}
                                    leftIcon={item.icon}
                                    style={{ padding: "14px 15px", borderBottom: `1px solid ${COLORS.EXLight_gray}` }}
                                    content={item.text}
                                    rightIcon={
                                        item.option ?? <RightOutlined style={{ padding: "0px 3px" }} />
                                    }
                                    onClick={() => item.onClick && item.onClick()}
                                />
                            ))
                        }

                        {/* <SelectContainer>
                            {
                                optionList.filter(item => (item.needToken && token) || !item.needToken).map((item: MemberOption, index) => (
                                    <SelectItem
                                        key={index}
                                        onClick={get(item, "onClick", () => { })}
                                    >
                                        <div>{item.text}</div>
                                        {
                                            item.option ?? <RightOutlined style={{ padding: "0px 3px" }} />
                                        }
                                    </SelectItem>
                                ))
                            }
                        </SelectContainer> */}

                        {token &&
                            <FooterContainer>
                                <LogoutButton
                                    onClick={() => {
                                        setLoading(true);
                                        dispatch(userActions.logout())
                                        setLoading(false);
                                    }}
                                    style={{ marginTop: "30px" }}
                                >
                                    <p style={{ color: "#5F5C70", fontSize: "14px" }}>
                                        {t("page.menu.label.logOut")}
                                    </p>
                                </LogoutButton>
                            </FooterContainer>
                        }
                    </>
                )}
                {isRegister && (
                    <div>
                        <HeaderContainer>
                            <HeaderLeft>
                                <SelectIcon
                                    style={{ width: 28, height: 28 }}
                                    src={Cancel}
                                    alt="cancel"
                                    onClick={() => {
                                        stackNavigate("/home");
                                    }}
                                />
                            </HeaderLeft>
                            <HeaderRight>
                                <button
                                    style={{
                                        color: COLORS.Primary,
                                        fontSize: "16px",
                                        border: "none",
                                        backgroundColor: "transparent",
                                        fontWeight: 600,
                                    }}
                                    onClick={() => {
                                        setIsRegister(false);
                                        setIsLoginLocal(true);
                                    }}
                                >
                                    <p>{t("page.menu.label.login")}</p>
                                </button>
                            </HeaderRight>
                        </HeaderContainer>
                    </div>
                )}
            </div>
        </CommonPage>
    );
};

export default Member;
