import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { ChangeEventHandler, CSSProperties, useState } from "react";
import styled from "styled-components"

const InputWrapper = styled.div`
    width: 100%;
    padding:5px;
    background-color: #F4F4F6;
    font-size: 15px;
    border-radius: 4px;
    border: none;
    display: flex;
    align-items: center;
`
const Input = styled.input`
    border: none;
    outline: none;
    background: #F4F4F6 0% 0%  padding-box;
    margin: 0px 0px 0px 10px;
    padding: 0px;
    width: 100%;
`
const PasswordInput: React.FC<{
    placeholder: string;
    onChange: ChangeEventHandler<HTMLInputElement>;
    style?: CSSProperties
}> = ({ placeholder, onChange, style }) => {
    const [passwordVisible, setPasswordVisible] = useState(false)
    return (
        <InputWrapper>
            <Input onChange={onChange} placeholder={placeholder} type={passwordVisible ? "text" : "password"} style={style}/>
            <span onClick={() => { setPasswordVisible((prev) => { return !prev }) }}>
                {passwordVisible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
            </span>
        </InputWrapper>
    )
}

export default PasswordInput