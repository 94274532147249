import { useState } from "react";
import UploadPhotoZone from '../components/UploadPhotoZone'
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import CommonPage from "@/components/LayoutPage";
import { userService } from "@/services";
import { BaseButton } from "@/components/Button";
import useStackNavigate from "@/hooks/useStackNavigate";

const NoticeP = styled.p`
  font-size: 15px;
  color: #5f5c70;
  text-align: center;
  margin-top: 20px;
`;

const PhotoButtonContainer = styled.div`
  margin-top: 50px;
  display: flex;
  justify-content: center;
`;

const PhotoButtonContent = styled.label`
  background: #f4f4f6;
  width: 311px;
  border: none;
  border-radius: 8px;
`;

const PhotoPNG = styled.img`
  z-index: 2;
  width: 311px;
  height: 186px;
`;

const BottomContainer = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  padding: 20px;
`;

const IDdocuments = () => {

    // $ init data
    const { stackNavigate } = useStackNavigate()
    const { t } = useTranslation();
    const [file, setFile] = useState(null);
    const [file2, setFile2] = useState(null);
    const [file3, setFile3] = useState(null);

    const [src, setSrc] = useState("");
    const [src2, setSrc2] = useState("");
    const [src3, setSrc3] = useState("");
    const [loading, setLoading] = useState(false);
    const { verify } = useLocation().state

    const readFile = (e: any) => {
        setFile(e.target.files[0])
        var reader = new FileReader();
        reader.onload = function (event: any) {
            // The file's text will be printed here
            setSrc(event.target.result);
        };
        reader.readAsDataURL(e.target.files[0]);
    };

    const readFile2 = (e: any) => {
        setFile2(e.target.files[0])
        var reader = new FileReader();
        reader.onload = function (event: any) {
            // The file's text will be printed here
            setSrc2(event.target.result);
        };
        reader.readAsDataURL(e.target.files[0]);
    };

    const readFile3 = (e: any) => {
        setFile3(e.target.files[0])
        var reader = new FileReader();
        reader.onload = function (event: any) {
            setSrc3(event.target.result);
        };
        reader.readAsDataURL(e.target.files[0]);
    };

    const handleSubmit = () => {
        if (!file) {
            alert(t("page.setKyc.alert.idCardFront"))
        } else if (!file2) {
            alert(t("page.setKyc.alert.idCardBack"))
        } else if (!file3) {
            alert(t("page.setKyc.alert.idCardSelfie"))
        } else {
            setLoading(true)

            const formData: any = new FormData();
            const data = {
                idCardFront: file,
                idCardBack: file2,
                selfie: file3,
                name: verify.name,
                address: verify.address,
                birthday: verify.birthday
            };

            for (const [key, value] of Object.entries(data)) {
                formData.append(key, value)
            }

            userService.applyKyc(formData).then(() => {
                alert(t("page.setKyc.alert.apply"))
                stackNavigate("jump")
            }).catch((error) => {
                alert(error.response.data.msg)
            }).finally(() => {
                setLoading(false)
            })
        }
    }

    return (
        <CommonPage active={loading} title={t("page.setKyc.title")} returnPath={"/id-verify"}>
            <NoticeP>{t("page.setKyc.notice.upload")}</NoticeP>

            <PhotoButtonContainer>
                <PhotoButtonContent onChange={e => readFile(e)} htmlFor="formId">
                    <input name="" type="file" id="formId" hidden />

                    {
                        src ? (
                            <PhotoPNG src={src} alt="照片" />
                        ) : (
                            <UploadPhotoZone title={t('page.setKyc.input.idCardFront')} />
                        )
                    }
                </PhotoButtonContent>
            </PhotoButtonContainer>

            <PhotoButtonContainer>
                <PhotoButtonContent onChange={e => readFile2(e)} htmlFor="formId2">
                    <input name="" type="file" id="formId2" hidden />

                    {
                        src2 ? (
                            <PhotoPNG src={src2} alt="照片" />
                        ) : (
                            <UploadPhotoZone title={t('page.setKyc.input.idCardBack')} />
                        )
                    }
                </PhotoButtonContent>
            </PhotoButtonContainer>

            <PhotoButtonContainer>
                <PhotoButtonContent onChange={e => readFile3(e)} htmlFor="formId3">
                    <input name="" type="file" id="formId3" hidden />

                    {
                        src3 ? (
                            <PhotoPNG src={src3} alt="照片" />
                        ) : (
                            <UploadPhotoZone title={t('page.setKyc.input.idCardSelfie')} />
                        )
                    }
                </PhotoButtonContent>
            </PhotoButtonContainer>

            <BottomContainer>
                <BaseButton
                    style={{ width: "100%" }}
                    onClick={handleSubmit}
                >
                    {t("page.setKyc.button.accept")}
                </BaseButton>
            </BottomContainer>
        </CommonPage>
    );
};

export default IDdocuments;
