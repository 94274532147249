import styled from "styled-components";
import { COLORS } from "@/constants/colors";
import { NavLink } from "react-router-dom";
import { BaseButton } from "@/components/Button";

export const PageContainer = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const HeaderArea = styled.header`
  background-color: ${COLORS.White};
  display: flex;
  flex-direction: column;
  border-bottom: 0.5px solid #f4f4f6;
  box-shadow: 0px 2px 4px rgba(143, 141, 162, 0.07);
  width: 100%;
`;

export const HeaderLeft = styled.div`
  float: Left;
  display: flex;
  align-items: center;
  height: 44px;
  width: 100%;
`;

export const NavImage = styled.img`
  width: 24px;
  height: 24px;
  margin-left: 12px;
`;

export const LinkText = styled(NavLink)`
  text-decoration: none;
  display: flex;
`;

export const HeaderTitle = styled.p`
  font-weight: 600;
  font-size: 16px;
  color: #383743;
  flex: 1;
  text-align: center;
  padding: 0 30px 0 0;
`;

export const FormContainer = styled.div`
    background: #ffffff;
    flex: 1;
`;

export const TextLabel = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: 23px;
  color: #5f5c70;
  font-size: 13px;
`;

export const TextInput = styled.input`
  padding: 20px;
  background: none;
  opacity: 0.6;
  border-radius: 4px;
  width: 90%;
  margin-left: 20px;
  background: #f4f4f6;
  color: #bdbcc8;
  border: none;
  height: 20px;
`;

export const TextAreaInput = styled.textarea`
  padding: 20px;
  background: none;
  opacity: 0.6;
  border-radius: 4px;
  width: 90%;
  margin-left: 20px;
  background: #f4f4f6;
  color: #bdbcc8;
  border: none;
  height: 100px;
`;

export const InputContainer = styled.p`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const SubmitButton = styled(BaseButton)`
  width: 90%;
`;

export const PhotoButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 5px 20px;
`;

export const PhotoButtonContent = styled.label`
  background: #f4f4f6;
  width: 100px;
  height: 100px;
  border: none;
  border-radius: 8px;
`;

export const PhotoPNG = styled.img`
  z-index: 2;
  width: 100px;
  height: 100px;
`;