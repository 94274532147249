import React from "react";
import XebitLogo from "@/assets/xebit_logo.svg";
import CommonPage from "@/components/LayoutPage";
import { MenuOption } from "@/constants/type";
import { useTranslation } from "react-i18next";
import CustomListItem from "@/components/List";
import useStackNavigate from "@/hooks/useStackNavigate";
import { COLORS } from "@/constants/colors";
import { RightOutlined } from "@ant-design/icons";

const AboutModal: React.FC = () => {
    const { t } = useTranslation()
    const { stackNavigate } = useStackNavigate()

    const optionList: MenuOption[] = [
        {
            text: t("page.menu.about.label.xebit"),
            needToken: true,
            onClick: () => stackNavigate("/about/xebit")
        },
        {
            text: t("page.menu.about.privacy.title"),
            needToken: true,
            onClick: () => stackNavigate("/about/privacy"),
        },
        {
            text: t("page.menu.about.label.rule"),
            needToken: true,
            onClick: () => stackNavigate("/about/terms")
        },
        {
            text: t("page.menu.about.label.contract"),
            needToken: true,
            onClick: () => stackNavigate("/about/feedback")
        }
    ];

    return (
        <CommonPage title={""} returnPath={"/member"}>
            <div style={{ width: "100%", padding: "0px 15px", margin: "20px 0px" }}>
                <img src={XebitLogo} alt="logo" width={"40%"} />
            </div>
            {
                optionList.map((item: MenuOption, index) => (
                    <CustomListItem
                        key={index}
                        style={{ padding: "14px 15px", borderBottom: `1px solid ${COLORS.EXLight_gray}` }}
                        content={item.text}
                        rightIcon={<RightOutlined style={{ padding: "0px 3px" }} />}
                        onClick={() => item.onClick && item.onClick()}
                    />
                ))
            }
        </CommonPage>
    );
};

export default AboutModal;
