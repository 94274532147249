import { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { COLORS } from "@/constants/colors";
import Drawer from "@/components/UI/Drawer";
import useStackNavigate from "@/hooks/useStackNavigate";
import { useTranslation } from "react-i18next";
import { advertisementService, investorService, transferService, userService, walletService } from "@/services";
import { SpotOtcTransferRequest } from "@/interfaces/request.interface";
import { WalletType } from "@/constants";
import { useAppSelector } from "@/hooks";
import CommonPage from "@/components/LayoutPage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { RightOutlined } from "@ant-design/icons";
import {
    CurrencyContainer,
    CurrencyLeft,
    Currency,
    CurrencyTopText,
    CurrencyBottomText,
} from '@/styled-components/fund';
import CryptoIcon from "./CryptoIcon";
import { Divider, Slider } from "antd";
import { BaseButton } from "@/components/Button";

const DepthTitle = styled.div`
  width: 100%;
  text-align: center;
  line-height: 24px;
  font-weight: 600;
`;
const DepthItem = styled.div<{ active: boolean }>`
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #f4f4f6;
  height: 55px;
  line-height: 55px;
  color: ${(props) =>
        props.active ? COLORS.Red : "#5f5c70"};
`;

const InputWrapper = styled.div`
    width: 90%;
    padding:10px;
    background-color: #F4F4F6;
    font-size: 15px;
    border-radius: 4px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
`

const Input = styled.input`
    border: none;
    outline: none;
    background: #F4F4F6 0% 0%  padding-box;
    padding: 0px;
    width: 70%;
`

const AllButton = styled.div`
    color: ${COLORS.Green};

    &:active {
        color: #F4F4F6 !important;
    }
`

const BottomContainer = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  padding: 20px;
`;

const FundTransfer = () => {
    const { t } = useTranslation()
    const [from, setFrom] = useState<WalletType>("spot")
    const [to, setTo] = useState<WalletType>("future")
    const [transferSymbol] = useState("USDT")
    const [availableBalance, setAvailableBalance] = useState(0)
    const [transferAmount, setTransferAmount] = useState(0)
    const [walletTypes] = useState<WalletType[]>(["spot", "future", "otc"])

    const account = useAppSelector((state) => state.user.detail.account)
    const { stackNavigate } = useStackNavigate()
    const [selectChange, setSelectChange] = useState(false);
    const [drawerHeight, setDrawerHeight] = useState(0);
    const [loading, setLoading] = useState(false);
    const [selectStatus, setSelectStatus] = useState("");

    const [sliderMark, setSliderMark] = useState({})
    const selectDrawerHandler = (select: string) => {
        setSelectChange((v) => !v);
        setSelectStatus(select);
        setDrawerHeight(200);
    };

    const transferTotalHandler = () => {
        if (!transferAmount) {
            alert(t(`page.fund.notice.transferAmount`))
            return;
        }

        if (from === "otc" || to === "otc") {
            let obj: SpotOtcTransferRequest = {
                sourceServer: from,
                targetServer: to,
                sourceUser: account,
                targetUser: account,
                symbol: "USDT",
                value: transferAmount.toFixed(2)
            }
            setLoading(true)
            if (from === "otc") {
                transferService.transferOtc(obj).then(() => {
                    alert(t(`page.fund.notice.transferSuccess`))
                    stackNavigate(-1)
                }).catch((error) => {
                    alert(error.response.data.msg)
                }).finally(() => {
                    setLoading(false)
                })
            } else {
                transferService.transferMaster(obj).then(() => {
                    alert(t(`page.fund.notice.transferSuccess`))
                    stackNavigate(-1)
                }).catch((error) => {
                    alert(error.response.data.msg)
                }).finally(() => {
                    setLoading(false)
                })
            }
        }
        else {
            let obj = {
                from: from.toLocaleUpperCase(),
                to: to.toLocaleUpperCase(),
                amount: transferAmount.toFixed(6)
            }
            setLoading(true)
            walletService.transfer(obj).then(() => {
                alert(t(`page.fund.notice.transferSuccess`))
                stackNavigate(-1);
            }).catch((error) => {
                alert(error.response.data.msg)
            }).finally(() => {
                setLoading(false)
            })
        }
    };

    const getFromAvailableBalance = useCallback(() => {
        switch (from) {
            case "spot":
                investorService.getProperty().then((response) => {
                    const balance = response.data.spot.equityValue;
                    setAvailableBalance(balance)
                    let marks = {}
                    for (let min = 0; min <= balance && balance !== 0; min = min + balance * 0.25) {
                        marks[min] = ''
                    }
                    setSliderMark(marks)
                }).finally(() => {
                    console.log("hit")
                })
                break;
            case "future":
                investorService.getAvailableBalance().then((response) => {
                    const balance = response.data;
                    setAvailableBalance(balance)
                    let marks = {}
                    for (let min = 0; min <= balance && balance !== 0; min = min + balance * 0.25) {
                        marks[min] = ''
                    }
                    setSliderMark(marks)
                })
                break;
            case "otc":
                userService.getOtcUserInfo(account).then(response => {
                    advertisementService.getAdvertisement({ my: true, type: "sell" }).then((advertisements) => {
                        const activeAd = advertisements.find(advertisement => advertisement.status !== -1)
                        const balance = response.wallet.coins.find(c => c.symbol === transferSymbol)?.balance ?? 0;
                        const freeze = response.wallet.coins.find(c => c.symbol === transferSymbol)?.freeze ?? 0;
                        setAvailableBalance(balance - freeze - (activeAd ? activeAd.totalTradingAmount : 0) - (response.advertiserDeposit ?? 0))

                        let marks = {}
                        for (let min = 0; min <= balance && balance !== 0; min = min + balance * 0.25) {
                            marks[min] = ''
                        }
                        setSliderMark(marks)
                    })
                });
                break;
        }
    }, [from, account, transferSymbol])

    useEffect(() => {
        getFromAvailableBalance()
    }, [getFromAvailableBalance])

    return (
        <CommonPage title={t("page.fund.title.transfer")} returnPath={-1} active={loading}>
            <div style={{ display: "flex", justifyContent: "space-between", width: "100%", padding: "5px 10px" }}>
                <div style={{ width: "90%" }}>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "10px 0px" }}>
                        <div style={{ fontSize: 10, color: COLORS.Mid_gray, width: "10%", textAlign: "center" }}>{t("page.fund.label.from")}</div>
                        <div style={{ width: "80%" }}>
                            <div style={{ fontSize: 14, fontWeight: 700 }} onClick={() => selectDrawerHandler("from")}>{t(`page.fund.walletType.${from}`)}</div>
                            <div style={{ fontSize: 10, color: COLORS.Mid_gray }}>{transferSymbol}</div>
                        </div>
                        <div style={{ fontSize: 10, color: COLORS.Mid_gray, width: "10%", textAlign: "center" }}>
                            <RightOutlined />
                        </div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "10px 0px" }}>
                        <div style={{ fontSize: 10, color: COLORS.Mid_gray, width: "10%", textAlign: "center" }}>{t("page.fund.label.to")}</div>
                        <div style={{ width: "80%" }}>
                            <div style={{ fontSize: 14, fontWeight: 700 }} onClick={() => selectDrawerHandler("to")}>{t(`page.fund.walletType.${to}`)}</div>
                            <div style={{ fontSize: 10, color: COLORS.Mid_gray }}>{transferSymbol}</div>
                        </div>
                        <div style={{ fontSize: 10, color: COLORS.Mid_gray, width: "10%", textAlign: "center" }}>
                            <RightOutlined />
                        </div>
                    </div>
                </div>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "10%" }}>
                    <FontAwesomeIcon
                        icon={faArrowRightArrowLeft}
                        size="sm"
                        rotation={90}
                        style={{ color: COLORS.Green, border: "1px solid", borderRadius: "50%", padding: 7 }}
                        onClick={() => {
                            setFrom(to)
                            setTo(from)
                            setTransferAmount(0)
                        }}
                    />
                </div>
            </div>
            <div style={{ width: "100%", height: "8px", backgroundColor: COLORS.EXLight_gray }}></div>
            <CurrencyContainer>
                <CurrencyLeft>
                    <CryptoIcon name={transferSymbol.toLowerCase()} size={30} />
                    <Currency>
                        <CurrencyTopText>{transferSymbol}</CurrencyTopText>
                        <CurrencyBottomText>
                            {"Tether"}
                        </CurrencyBottomText>
                    </Currency>
                </CurrencyLeft>
            </CurrencyContainer>
            <div style={{ width: "100%", height: "8px", backgroundColor: COLORS.EXLight_gray }}></div>
            <div style={{ width: "100%", marginLeft: "40px" }}>
                <div style={{ margin: "20px 0px 10px 0px", fontSize: 14 }}>{t("page.fund.input.transferAmount")}</div>
                <InputWrapper>
                    <Input
                        type={"number"}
                        step={"0.00001"}
                        value={`${transferAmount}`}
                        onChange={(e) => {
                            const amount = e.target.value !== '' ? Number.parseFloat(e.target.value) : 0;
                            setTransferAmount(amount > availableBalance ? availableBalance : amount)
                        }}
                    />
                    <div style={{ color: COLORS.Mid_gray }}>
                        {transferSymbol}
                    </div>
                    <Divider type="vertical" />
                    <AllButton onClick={() => { setTransferAmount(availableBalance) }}>
                        {t("page.fund.button.all")}
                    </AllButton>
                </InputWrapper>
                <Slider
                    value={transferAmount}
                    onChange={(value) => setTransferAmount(value)}
                    min={0}
                    max={availableBalance}
                    marks={sliderMark}
                    style={{ width: "85%", marginBottom: "10px" }}
                    trackStyle={{ backgroundColor: COLORS.Green }}
                    handleStyle={{ borderColor: COLORS.Green }}
                />
                <div style={{ color: COLORS.Mid_gray, fontSize: 12, fontWeight: 600 }}>{`${t("page.fund.label.availableAmount")} ${availableBalance.toFixed(6)} ${transferSymbol}`}</div>
            </div>
            <BottomContainer>
                <BaseButton
                    style={{ width: "100%" }}
                    onClick={() => {
                        transferTotalHandler()
                    }}
                >
                    {t("page.fund.button.confirmTransfer")}
                </BaseButton>
            </BottomContainer>

            <Drawer
                isVisible={selectChange}
                selectVisible={selectDrawerHandler.bind(null, selectStatus)}
                height={selectStatus === "unit" ? 150 : drawerHeight}
            >
                <DepthTitle>{t(`page.fund.label.${selectStatus}`)}</DepthTitle>
                {
                    walletTypes.filter((type) => selectStatus === "from" ? type !== to : type !== from).map((type) => {
                        return (
                            <DepthItem
                                active={selectStatus === "from" ? type === from : type === to}
                                onClick={() => {
                                    selectStatus === "from" ? setFrom(type) : setTo(type)
                                    setSelectChange((prev) => !prev)
                                    setTransferAmount(0)
                                }}
                            >
                                {t(`page.fund.walletType.${type}`)}
                            </DepthItem>
                        )
                    })
                }
            </Drawer>
        </CommonPage>
    )
}

export default FundTransfer