import React from "react";
import styled from "styled-components";
import { COLORS } from "@/constants/colors";
import { useAppSelector } from "@/hooks";
import { fiatCurrencySymbol } from "@/constants";
import { parsePrice } from "@/utils/parse";

const TotalValue = styled.div`
  color: ${COLORS.Black};
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 10px;
`;

const FiatValue = styled.div`
  color: ${COLORS.Mid_gray};
  font-size: 10px;
  font-weight: 700;
  text-align: right;
`;

export const ValuePair: React.FC<{value: number, symbol: string}> = ({value, symbol}) => {

    const currency = useAppSelector((state) => state.user.currency)

    return (
        <div style={{ marginLeft: "auto", marginRight: 10 }}>
            <TotalValue>{`${value!==undefined && parsePrice(value)} ${symbol}`}</TotalValue>
            <FiatValue>≈ {`${fiatCurrencySymbol[currency.name]} ${parsePrice(value * currency.rate)}`}</FiatValue>
        </div>
    );
};

export default ValuePair;