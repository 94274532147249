import { useState, useEffect } from "react";
// import AnnouncementModal from "@/components/modal/AnnouncementModal";
import styled from "styled-components";
// import HightLightIcon from "@/assets/icon/star.png";
import { useTranslation } from "react-i18next";
import { infoService } from "@/services";
import { Announcement } from "@/interfaces/exchange.interface";
import CommonPage from "@/components/LayoutPage";
import Tab from "@/components/Tab";
import { AnnouncementType } from "@/constants";
import useStackNavigate from "@/hooks/useStackNavigate";

const NavItem = styled.div`
  display: flex;
  padding: 0px 16px;
  margin-bottom: 10px;
`;
// const HighLightLogo = styled.img`
//   width: 28px;
//   height: 28px;
//   margin-right: 12px;
// `;
const NavItemContainer = styled.div``;
const NavTitle = styled.p`
  font-size: 15px;
  color: #383743;
  margin-bottom: 15px;
  line-height: 25px;
`;
const NavDate = styled.div`
  font-size: 12px;
  color: #8f8da2;
`;

const Announcements = () => {
    const { stackNavigate } = useStackNavigate()
    const { t } = useTranslation()
    // const [navSelectItem, setNavSelectItem] = useState(false);
    const [announcements, setAnnouncements] = useState<Announcement[]>([]);
    const [activeKey, setActiveKey] = useState<AnnouncementType | "">("");
    // const [obj, setObj] = useState({
    //     subject: "",
    //     content: "",
    //     createDate: 0
    // });

    const tabItems = [
        {
            key: 0,
            title: t("page.announcement.label.ALL"),
            onClick: () => {
                setActiveKey("")
            }
        },
        {
            key: 1,
            title: t("page.announcement.label.SYSTEM"),
            onClick: () => {
                setActiveKey("SYSTEM")
            }
        },
        {
            key: 2,
            title: t("page.announcement.label.ACTIVITY"),
            onClick: () => {
                setActiveKey("ACTIVITY")
            }
        },
        {
            key: 3,
            title: t("page.announcement.label.NEWS"),
            onClick: () => {
                setActiveKey("NEWS")
            }
        }
    ];

    const { i18n } = useTranslation();

    // const selectContentHandler = (x: any) => {
    //     setNavSelectItem((v) => !v);
    //     setObj(x)
    // };

    useEffect(() => {
        if (activeKey === "") {
            infoService.getAnnouncements({ lang: i18n.language }).then((response) => {
                setAnnouncements(response.data)
            })
        } else {
            infoService.getAnnouncements({ lang: i18n.language, topic: activeKey }).then((response) => {
                setAnnouncements(response.data)
            })
        }
    }, [activeKey, i18n.language]);
    return (
        <CommonPage title={t(`page.announcement.title`)} returnPath={"/member"}>
            <Tab panes={tabItems} paneStyle={{ width: "100%" }} style={{ marginBottom: 15 }} />
            {/* {navSelectItem ? (
                <AnnouncementModal
                    navTitle={""}
                    itemTitle={obj.subject}
                    content={obj}
                    setNavSelectItem={setNavSelectItem}
                />
            ) : (
                <div style={{ flex: 1, width: "100%" }}>
                    {announcements.map((announcement) => {
                        return (
                            <NavItem key={announcement.id} onClick={() => { stackNavigate(`/announcement/${announcement.id}`) }}>
                                <NavItemContainer>
                                    <NavTitle>{announcement.subject}</NavTitle>
                                    <NavDate>{`${new Date(announcement.createdDate).toISOString().split("T")[0]}`}</NavDate>
                                </NavItemContainer>
                            </NavItem>
                        );
                    })}
                </div>
            )} */}
            <div style={{ flex: 1, width: "100%" }}>
                {announcements.map((announcement) => {
                    return (
                        <NavItem key={announcement.id} onClick={() => { stackNavigate(`/announcement/${announcement.id}`) }}>
                            <NavItemContainer>
                                <NavTitle>{announcement.subject}</NavTitle>
                                <NavDate>{`${new Date(announcement.createdDate).toISOString().split("T")[0]}`}</NavDate>
                            </NavItemContainer>
                        </NavItem>
                    );
                })}
            </div>
        </CommonPage>
    );
};

export default Announcements;
