import { useState, useEffect } from "react";
import styled from "styled-components";
import useStackNavigate from "@/hooks/useStackNavigate";
import { COLORS } from "@/constants/colors";
import { useTranslation } from "react-i18next";
import { Payment } from "@/interfaces/exchange.interface";
import { useAppSelector, useAppDispatch } from "@/hooks";
import { otcOrderService } from "@/services";
import CommonPage from "@/components/LayoutPage";
import { BottomContainer, PayInfo, PayInfoText, SideText, StatusContainer, StatusTitle } from "@/styled-components/deal-fiat";
import CustomCard from "@/components/CustomCard";
import { BaseButton } from "@/components/Button";
import { PaymentItem } from "@/styled-components/deal";
import { Divider } from "antd";
import { formatDateTime, parsePrice } from "@/utils/parse";
import { CryptoIcon } from "@/pages/fund/components";
import { otcOrderActions } from "@/store/slice";
import { OtcOrder } from "@/interfaces/otc.interface";
import PaymentDetail from "./PaymentDetail";

const InfoText = styled(PayInfo)`
    padding: 15px 0px;
`;

const FiatDealFinish = () => {
    const side = useAppSelector((state)=> state.c2c.dealingSide)
    const order = useAppSelector((state) => side==="buy" ? state.c2c.buying : state.c2c.selling)
    const dispatch = useAppDispatch()
    const { t } = useTranslation();
    const [usePayment, setUsePayment] = useState<Payment>({} as Payment);
    const { stackNavigate } = useStackNavigate()

    useEffect(() => {
        otcOrderService.getPayments(order.id).then((response) => {
            setUsePayment(response[0])
        })
    }, [order.id]);

    return (
        <CommonPage title={""}>
            <StatusContainer>
                <StatusTitle>{t(`page.deal.fiat.${side}.title.${order.status}`)}</StatusTitle>
                <CustomCard color={COLORS.LightBlue} style={{ padding: "15px 0px", margin: "10px 0px" }}>
                    <PayInfo>
                        <div style={{ display: "flex", fontWeight: 600, fontSize: 14, alignItems: "center" }}>
                            <CryptoIcon name={"usdt"} size={20} />
                            <SideText side={side}>{t(`page.deal.fiat.title.${side}`)}</SideText>
                            <div>USDT</div>
                        </div>
                    </PayInfo>
                    <Divider style={{ margin: "5px 0px" }} />
                    <InfoText>
                        <PayInfoText>{t(`page.deal.fiat.label.dealPrice`)}</PayInfoText>
                        <PayInfoText>{order.amount}</PayInfoText>
                    </InfoText>
                    <InfoText>
                        <PayInfoText>{t(`page.deal.fiat.label.price`)}</PayInfoText>
                        <PayInfoText>{order.price}</PayInfoText>
                    </InfoText>
                    <InfoText>
                        <PayInfoText>{t(`page.deal.fiat.label.quantity`)}</PayInfoText>
                        <PayInfoText>{parsePrice(order.amount / order.price)}</PayInfoText>
                    </InfoText>
                    <InfoText>
                        <PayInfoText>{t(`page.deal.fiat.label.orderId`)}</PayInfoText>
                        <PayInfoText>{order.id.substring(0, 20)} ...</PayInfoText>
                    </InfoText>
                    <InfoText>
                        <PayInfoText>{t(`page.deal.fiat.label.createdDate`)}</PayInfoText>
                        <PayInfoText>{formatDateTime(order.createdDate)}</PayInfoText>
                    </InfoText>
                </CustomCard>
                <CustomCard color={COLORS.LightBlue} style={{ padding: "15px 0px", margin: "10px 0px" }}>
                    <PayInfo>
                        <PayInfoText>{t(`page.deal.fiat.label.payType`)}</PayInfoText>
                        <PaymentItem payment={usePayment.type}>{t(`page.menu.c2cManage.payment.title.${usePayment.type}`)}</PaymentItem>
                    </PayInfo>
                    <Divider style={{ margin: "10px 0px" }} />
                    <InfoText>
                        <PayInfoText>{t(`page.deal.fiat.label.account`)}</PayInfoText>
                        <PayInfoText>{side==="buy" ? order.sellUser.account : order.buyUser.account}</PayInfoText>
                    </InfoText>
                    <PaymentDetail payment={usePayment}/>
                </CustomCard>
            </StatusContainer>
            <BottomContainer>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <BaseButton
                        style={{ width: "100%", fontSize: 14 }}
                        onClick={() => {
                            if ( side==="buy" ){
                                dispatch(otcOrderActions.updateBuying({} as OtcOrder))
                            }
                            else {
                                dispatch(otcOrderActions.updateSelling({} as OtcOrder))
                            }
                            dispatch(otcOrderActions.changeDealingSide(""))
                            stackNavigate("jump");
                        }}
                    >
                        {t(`page.deal.fiat.button.back`)}
                    </BaseButton>
                </div>
            </BottomContainer>
        </CommonPage>
    );
};

export default FiatDealFinish;
