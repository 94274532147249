import CommonPage from "@/components/LayoutPage";
import QuizBackground from "@/assets/quiz-background.svg"
import { Header } from "@/components/typography/Header";
import { useTranslation } from "react-i18next";

const QuizRule = () => {
    const { t } = useTranslation()
    return (
        <CommonPage title={""} returnPath={"/quiz"} style={{ backgroundImage: `url(${QuizBackground}`, backgroundSize: "cover" }} headerStyle={{backgroundColor: "#eafdf9"}}>
            <div style={{ width: "100%", padding: "0px 15px" }}>
                <Header size="md">新手測驗獎勵</Header>
                <Header size="sm" style={{fontWeight: "400", lineHeight: 2}}>加密貨幣的發展日益蓬勃，對於人們的需求量也日漸增加，利用新手獎勵的教育方式來完成用戶對於交易所更充沛的認知，是我們的計劃宗旨，用戶可以在測驗的過程中，更明白內容，並賺取獎勵</Header>
                <Header size="md">如何開始新手獎勵?</Header>
                <Header size="sm" style={{fontWeight: "400"}}>完成以下全部測驗後，即可開始</Header>
                <Header size="md">獎勵計劃要求</Header>
                <Header size="xs" style={{ display: "flex", marginBottom: 5, lineHeight: 2 }}>
                    <div>{t("page.menu.about.privacy.label.subject2-1")}</div>
                    <div>充值達1000Usdt，並且累計完成1000usdt合約交易額時，即可獲得10Usdt</div>
                </Header>
                <Header size="xs" style={{ display: "flex", marginBottom: 5, lineHeight: 2 }}>
                    <div>{t("page.menu.about.privacy.label.subject2-2")}</div>
                    <div>充值達10000Usdt，並且累計完成10000usdt合約交易額時，即可獲得100Usdt</div>
                </Header>
                <Header size="xs" style={{ display: "flex", marginBottom: 5, lineHeight: 2 }}>
                    <div>{t("page.menu.about.privacy.label.subject2-3")}</div>
                    <div>充值達10000Usdt，並且累計完成100000usdt合約交易額時，即可獲得1000Usdt</div>
                </Header>
                <Header size="md">完成測驗後如何領取獎勵?</Header>
                <Header size="sm" style={{fontWeight: "400", lineHeight: 2}}>當完成所有測驗並符合上述階層條件，可以聯繫客服完成領取</Header>
                <Header size="sm" style={{fontWeight: "400"}}>Line : @741lpgof</Header>
                <Header size="sm" style={{fontWeight: "400"}}>Telegram : xebitex</Header>
                <Header size="sm" style={{fontWeight: "400", lineHeight: 2}}>( 風險聲明 : 加密貨幣價格波動劇烈，您的數位資產可能因價值標的浮動而造成虧損，XEBIT不負責為投資者的損失負責 )</Header>
            </div>
        </CommonPage>
    );
};

export default QuizRule;

