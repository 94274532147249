// ^ Plugins
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CommonPage from "@/components/LayoutPage";
import Tab, { TabPaneProps } from "@/components/Tab";
import { investorService } from "@/services";
import { useCallback, useEffect, useState } from "react";
import { ContractSide, FutureStatus } from "@/constants";
import { Future } from "@/interfaces/exchange.interface";
import noItemIcon from "@/assets/icon/no-data.svg";
import styled from "styled-components";
import { COLORS } from "@/constants/colors";
import { parsePrice, formatDate, formatDateTime } from "@/utils/parse";
import { CaretDownOutlined, ClockCircleOutlined, RightOutlined } from "@ant-design/icons";
import { CenterLineItem, CenterListItem } from "@/styled-components/deal";
import { DatePicker, Drawer, List } from "antd";
import moment from 'moment';
import { BaseButton } from "@/components/Button";
import "@/pages/orders/css/order.css";
import useStackNavigate from "@/hooks/useStackNavigate";

const TradingItemContainer = styled.div`
    width: "100%";
    flex: 1;
    display: "flex";
    flex-direction: "column";
    align-items: "space-between";
    justify-content: "space-between";
    padding: 10px;
    text-align: "left";
    font-family: "Open Sans";
`;


const PositionBoxHeader = styled.div`
    display: flex;
    flex-direction: "row";
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
`;

const HeaderLeft = styled.div`
    font-weight: 800;
    text-align: left;
    display: flex;
    align-items: center;
`;

const SymbolTitle = styled.span<{ side: ContractSide }>`
    font-size: 18px;
    color: ${(props) => props.side === "BUY" ? COLORS.Green : COLORS.Red};
    margin-right: 3px;
`;

const HeaderRight = styled.div`
    text-align: right;
    font-size: 14px;
    justify-content: flex-end;
    display: flex;
    align-items: center;
`;

const FutureBoxBody = styled.div`
    display: flex;
    flex-direction: "row";
    justify-content: space-between;
    flex-wrap: wrap;
`

const FutureAttributeName = styled.div`
    font-size: 14px;
    color: ${COLORS.Mid_gray};
    font-weight: 500;
    margin-bottom: 5px;
`

const FutureAttributeValue = styled.div`
    font-size: 14px;
    color: ${COLORS.Gray};
    font-weight: 900;
    margin-bottom: 10px;
`

const FutureDataColumn = styled.div`
    width: 33.3%;
    padding: 5px 0px;
`

const FilterContainer = styled.div`
    display: flex;
    justify-content: space-around;
    width: 100%;
    padding: 10px 15px;
    font-size: 14px;
    font-weight: 500;
    color: ${COLORS.Mid_gray};
`

const FilterItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 33.3%;
`

const TimeRangeContainer = styled.div`
    width: 100%;
    padding: 4px 0px;
    background-color: ${COLORS.EXLight_gray};
`

const TimeRangeContent = styled.div`
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    padding: 8px 0px 0px 10px;
    color: ${COLORS.Mid_gray};
    background-color: ${COLORS.EXLight_gray};
`

const InfoTag = styled.span`
    background-color: ${COLORS.EXLight_gray};
    color: ${COLORS.Mid_gray};
    font-size: 14px;
    font-weight: 600;
    padding: 5px;
    margin-right: 4px;
`

const Orders = () => {
    const { t } = useTranslation();
    const { RangePicker } = DatePicker;
    const { stackNavigate } = useStackNavigate()
    
    const [start, setStart] = useState<number | undefined>(moment().subtract(1, 'months').valueOf());
    const [end, setEnd] = useState<number | undefined>(moment().valueOf());
    const [status, setStatus] = useState<FutureStatus | undefined>("CREATE");
    const [futures, setFutures] = useState<Future[]>([]);
    const [visible, setVisible] = useState("");
    const items: TabPaneProps[] = [
        {
            key: 0,
            title: t("page.record.label.currentOrder"),
            onClick: () => {
                setStatus("CREATE")
            }
        },
        {
            key: 1,
            title: t("page.record.label.historyOrder"),
            onClick: () => {
                setStatus(undefined)
            }
        },
        {
            key: 2,
            title: t("page.record.label.orderDetail"),
            onClick: () => {
                setStatus("DEAL")
            }
        }
    ];

    const [loading, setLoading] = useState(false);

    const getFutures = useCallback(() => {
        setLoading(true);
        investorService.getOwnFutures({
            start: start ? start : undefined,
            end: end ? end : undefined,
            status: status
        }).then((response) => {
            setFutures(response.data)
        }).finally(() => {
            setLoading(false);
        })
    }, [start, end, status])

    const showDrawer = (target: string) => {
        setVisible(target);
    };

    const onClose = () => {
        setVisible("");
    };

    const location = useLocation().pathname;

    useEffect(() => {
        getFutures()
    }, [getFutures])

    const getTitle = () => {
        return (
            <CenterLineItem onClick={() => {
                showDrawer("menu")
            }}>
                {t("page.record.title.contract")}
                <CaretDownOutlined style={{ fontSize: 8, padding: "0px 2px" }} />
            </CenterLineItem>
        )
    }

    return (
        <CommonPage active={loading} title={getTitle()} footer={location}>
            <Tab panes={items} paneStyle={{width: "100%"}}/>
            <FilterContainer>
                <FilterItem>
                    {/* <span>全部</span>
                    <CaretDownIcon/> */}
                </FilterItem>
                <FilterItem>
                    {/* <span>全部</span>
                    <CaretDownIcon/> */}
                </FilterItem>
                <FilterItem style={{ width: "30%" }}>
                    {/* <span>全部</span>
                    <CaretDownIcon/> */}
                </FilterItem>
                <FilterItem style={{ width: "3.3%" }}>
                    <ClockCircleOutlined style={{ fontSize: 18 }} onClick={() => showDrawer("clock")} />
                </FilterItem>
            </FilterContainer>
            <TimeRangeContainer>
                <TimeRangeContent>{start && `${formatDate(start)} ${t("page.record.label.to")} ${formatDate(end)}`}</TimeRangeContent>
            </TimeRangeContainer>
            <div style={{ width: "100%" }}>
                {
                    futures.map((future, index) => {
                        const tokens = future.symbol.split("-");
                        return (
                            <div key={index}>
                                <TradingItemContainer>
                                    <PositionBoxHeader>
                                        <HeaderLeft>
                                            <SymbolTitle side={future.side}>
                                                {`${t(`modal.future.side.${future.side}`)}·${future.status!=="DEAL" ? "" : `${future.leverage}X`}`}
                                            </SymbolTitle>
                                            {future.symbol.replace("-", "")}
                                        </HeaderLeft>
                                        <HeaderRight>
                                            <span style={{ fontWeight: 700, color: COLORS.Mid_gray }}>{t(`modal.future._status.${future.status}`)}</span>
                                            <RightOutlined style={{ fontSize: 12, marginLeft: 3 }} />
                                        </HeaderRight>
                                    </PositionBoxHeader>
                                    <CenterLineItem style={{ marginBottom: 15 }}>
                                        <InfoTag>{t(`modal.position.type.FULL`)}</InfoTag>
                                        <InfoTag>{tokens[1]}</InfoTag>
                                    </CenterLineItem>
                                    <FutureBoxBody>
                                        <FutureDataColumn>
                                            <FutureAttributeName>{`${t("modal.future.type")}(${tokens[0]})`}</FutureAttributeName>
                                            <FutureAttributeValue>{`${t(`modal.future._type.${future.type}`)}(${tokens[1]})`}</FutureAttributeValue>
                                        </FutureDataColumn>
                                        <FutureDataColumn style={{ paddingLeft: 30 }}>
                                            <FutureAttributeName>{`${t("modal.future.origQty")}(${tokens[0]})`}</FutureAttributeName>
                                            <FutureAttributeValue>{future.origQty}</FutureAttributeValue>
                                        </FutureDataColumn>
                                        <FutureDataColumn style={{ textAlign: "right" }}>
                                            <FutureAttributeName>{`${t("modal.future.executedQty")}(${tokens[0]})`}</FutureAttributeName>
                                            <FutureAttributeValue>{future.executedQty}</FutureAttributeValue>
                                        </FutureDataColumn>
                                        <FutureDataColumn>
                                            <FutureAttributeName>{`${t("modal.future.price")}(${tokens[0]})`}</FutureAttributeName>
                                            <FutureAttributeValue>{parsePrice(future.price)}</FutureAttributeValue>
                                        </FutureDataColumn>
                                        <FutureDataColumn style={{ paddingLeft: 30 }}>
                                            <FutureAttributeName>{`${t("modal.future.avgPrice")}(${tokens[0]})`}</FutureAttributeName>
                                            <FutureAttributeValue>{parsePrice(future.status === "CREATE" ? 0 : future.price)}</FutureAttributeValue>
                                        </FutureDataColumn>
                                        <FutureDataColumn style={{ textAlign: "right" }}>
                                            <FutureAttributeName>{`${t("modal.future.profitAndLoss")}(${tokens[0]})`}</FutureAttributeName>
                                            <FutureAttributeValue>{future.profitAndLoss ? parsePrice(future.profitAndLoss) : "---"}</FutureAttributeValue>
                                        </FutureDataColumn>
                                        {
                                            future.stopPrice &&
                                            <FutureDataColumn>
                                                <FutureAttributeName>{t("modal.future.avgPrice")}</FutureAttributeName>
                                                <FutureAttributeValue>{parsePrice(future.status === "CREATE" ? 0 : future.price)}</FutureAttributeValue>
                                            </FutureDataColumn>
                                        }
                                        <CenterLineItem style={{ fontSize: 14, color: COLORS.Mid_gray, padding: "5px 0px" }}>{formatDateTime(future.createdDate)}</CenterLineItem>
                                    </FutureBoxBody>
                                </TradingItemContainer>
                                {
                                    index !== futures.length - 1 && <div style={{ width: "100%", padding: "4px 0px", backgroundColor: COLORS.EXLight_gray }}></div>
                                }
                            </div>
                        )
                    })
                }
            </div>
            {(futures.length === 0) && (
                <div style={{ textAlign: "center" }}>
                    <img
                        style={{ width: 100, height: 100, marginTop: "30px" }}
                        src={noItemIcon}
                        alt="no info"
                    />
                    <p
                        style={{
                            padding: "30px 0",
                            color: "#BDBCC8",
                            fontWeight: 500,
                            fontSize: 13,
                        }}
                    >
                        {t("page.record.label.empty")}
                    </p>
                </div>
            )}
            <Drawer
                placement={"bottom"}
                closable={false}
                onClose={onClose}
                key={"menu"}
                visible={visible === "menu"}
                bodyStyle={{ padding: 0 }}
                height={"auto"}
            >
                <List style={{ width: "100%" }}>
                    <CenterListItem>{t("page.record.title.contract")}</CenterListItem>
                    <CenterListItem onClick={() => stackNavigate("/fiat-orders")}>{t("page.record.title.fiat")}</CenterListItem>
                </List>
            </Drawer>
            <Drawer
                placement={"bottom"}
                title={t(`page.deal.fiat.label.searchPrice`)}
                closable={false}
                onClose={onClose}
                key={"clock"}
                visible={visible === "clock"}
                height={"auto"}
                footer={
                    <BaseButton
                        style={{ width: "100%" }}
                        onClick={() => {
                            setStart(undefined)
                            setEnd(undefined)
                        }}
                    >
                        {t("page.deal.fiat.button.clean")}
                    </BaseButton>
                }
            >
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <CenterLineItem style={{ width: "100%" }}>
                        <RangePicker
                            picker={"date"}
                            value={[moment(start), moment(end)]}
                            placeholder={[t(`page.record.placeholder.startDate`), t(`page.record.placeholder.endDate`)]}
                            onChange={(date) => {
                                setStart(date ? date[0]?.valueOf() : undefined)
                                setEnd(date ? date[1]?.valueOf() : undefined)
                            }}
                            onPanelChange={(date) => {
                                setStart(date ? date[0]?.valueOf() : undefined)
                                setEnd(date ? date[1]?.valueOf() : undefined)
                            }}
                            style={{ width: "100%" }}
                        />
                        {/* <DatePicker
                            placeholder={t(`page.record.placeholder.startDate`)}
                            value={moment(start)}
                            onChange={(date) => handleChange("start", date)}
                            onPanelChange={(date) => handleChange("start", date)}
                            style={{ width: "100%" }}
                        />
                        <span style={{ padding: "0px 10px" }}>-</span>
                        <DatePicker
                            placeholder={t(`page.deal.fiat.placeholder.highestPrice`)}
                            value={moment(end)}
                            onChange={(date) => handleChange("end", date)}
                            onPanelChange={(date) => handleChange("end", date)}
                            style={{ width: "100%" }}
                        /> */}
                    </CenterLineItem>
                </div>
            </Drawer>
        </CommonPage>
    );
};

export default Orders;
