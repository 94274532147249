import React, {
    useState
} from "react";

// ^ Plugins
import useStackNavigate from "@/hooks/useStackNavigate";
import { useTranslation } from "react-i18next";
import { CryptoIcon } from "@/pages/fund/components"
import Drawer from "@/components/UI/Drawer";
import {
    SpotContainer,
    SpotContainerHeader,
    SpotTitle,
    SpotContent,
    SpotCurrency,
    SpotUSDTPrice,
    SpotTWDPrice,
    Header,
    HeaderButton,
    ButtonGroup
} from '@/styled-components/fund';
import { useAppSelector } from "@/hooks";
import { EyeInvisibleOutlined, EyeOutlined, FileDoneOutlined } from "@ant-design/icons";
import { fiatCurrencySymbol } from "@/constants";
import { BaseButton } from "@/components/Button";
import { parsePrice } from "@/utils/parse";

export const TotalContent: React.FC<{ active: string; total: number }> = ({
    active,
    total
}) => {
    const { t } = useTranslation();
    const { stackNavigate } = useStackNavigate()
    const currency = useAppSelector((state) => state.user.currency)
    const [totalVisible, setTotalVisible] = useState(true);
    const operation = active === "overview" ? "spot" : active;
    const headerButtons = ["overview", "spot", "future", "otc", "manageFinances"];

    const [currencyDrawer, setCurrencyDrawer] = useState(false);
    const [isWithdraw, setIsWithdraw] = useState(false);

    return (
        <Header>
            <div style={{ padding: "0px 0px 20px 10px" }}>
                {
                    headerButtons.map((key) => (
                        <HeaderButton
                            key={key}
                            isActive={key === active}
                            onClick={() => {
                                if (key!=="manageFinances") {
                                    stackNavigate(`/fund/${key}`)
                                }
                            }}
                        >
                            {t(`page.fund.label.${key}`)}
                        </HeaderButton>
                    ))
                }
            </div>
            <SpotContainer>
                <SpotContainerHeader>
                    <SpotTitle>
                        {t(`page.fund.label.total.${active}`)}
                        <span onClick={() => { setTotalVisible((prev) => { return !prev }) }} style={{ marginLeft: 3 }}>
                            {totalVisible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                        </span>
                    </SpotTitle>
                    {
                        active !== "overview" && <FileDoneOutlined style={{ fontSize: 15 }} onClick={() => stackNavigate("/fund/history", {state: active})} />
                    }
                </SpotContainerHeader>

                <SpotContent>
                    <SpotUSDTPrice>
                        {totalVisible ? parsePrice(total) : "******"}
                        <SpotCurrency>USDT</SpotCurrency>
                    </SpotUSDTPrice>

                    <SpotTWDPrice>
                        ≈ {`${fiatCurrencySymbol[currency.name]}`} {totalVisible ? parsePrice(total * currency.rate) : "******"}
                    </SpotTWDPrice>
                </SpotContent>
            </SpotContainer>
            <ButtonGroup>
                {operation === "spot" && (
                    <>
                        <BaseButton
                            style={{width: "30%"}}
                            onClick={() => {
                                setCurrencyDrawer(true)
                                setIsWithdraw(false)
                            }}
                        >
                            {t("page.fund.button.deposit")}
                        </BaseButton>
                        <BaseButton
                            color="white"
                            style={{width: "30%"}}
                            onClick={() => {
                                setCurrencyDrawer(true)
                                setIsWithdraw(true)
                            }}
                        >
                            {t("page.fund.button.withdraw")}
                        </BaseButton>
                    </>
                )}
                <BaseButton
                    color="white"
                    style={{width: "30%"}}
                    onClick={() => {
                        stackNavigate(`/fund/transfer`)
                    }}
                >
                    {t("page.fund.button.transfer")}
                </BaseButton>
            </ButtonGroup>
            <Drawer isVisible={currencyDrawer} height={220} selectVisible={()=> setCurrencyDrawer( v => !v)}>
                <h2
                    style={{
                        textAlign: "center",
                        color: "#383743",
                        fontSize: "16px",
                        fontWeight: 600,
                    }}
                >
                    {isWithdraw ? t("page.fund.label.withdraw") : t("page.fund.label.deposit.title")}
                </h2>
                <div style={{margin: "10px 0px"}}>
                    <button
                        style={{
                            height: "35px",
                            marginBottom: "10px",
                            border: "none",
                            borderBottom: "1px solid #F4F4F6",
                            width: "100%",
                            backgroundColor: "transparent",
                        }}
                        onClick={() => {
                            if (isWithdraw) {
                                stackNavigate("/withdraw?symbol=USDT", {
                                    state: { currency: "USDT" },
                                });
                            } else {
                                stackNavigate("/deposit?symbol=USDT", {
                                    state: { currency: "USDT" },
                                });
                            }
                        }}
                    >
                        <CryptoIcon name="usdt" size="30" />
                        <p style={{ marginLeft: "10px", float: "left" }}>USDT</p>
                    </button>
                </div>
                <button
                    style={{
                        height: "40px",
                        backgroundColor: "#F4F4F6",
                        border: "none",
                        color: "#5F5C70",
                        fontSize: "14px",
                    }}
                    onClick={() => {
                        setCurrencyDrawer(false);
                    }}
                >
                    {t("page.fund.button.cancel")}
                </button>
            </Drawer>
        </Header>
    );
};

export default TotalContent;