import { useState, useEffect } from "react";
// import api from "@/common/api"
import useStackNavigate from "@/hooks/useStackNavigate";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "@/hooks";
import { authService, userService } from "@/services";
import CommonPage from "@/components/LayoutPage";
import { BaseButton } from "@/components/Button";
import { message } from "antd";
import { COLORS } from "@/constants/colors";
import { LoginRequest } from "@/interfaces/request.interface";
import { userActions } from "@/store/slice";
import { fetchOtcUser, fetchUser } from "@/store/slice/user";


const EmailVerify = () => {
    const [loading, setLoading] = useState(false)
    const register = useAppSelector((state) => state.register)
    const dispatch = useAppDispatch();
    const [verifyCode, setVerifyCode] = useState("");
    // const [code,setCode] = useState(false)
    const [count, setCount] = useState(180)
    const { stackNavigate } = useStackNavigate()
    const { t } = useTranslation();
    useEffect(() => {
        setTimeout(() => {
            if (count > 0) {
                setCount(c => c - 1)
            }
        }, 1000)
    }, [count])

    const checkSecurity = () => {
        userService.getSecurity().then((response) => {
            Object.getOwnPropertyNames(response.data).forEach((securityProperty) => {
                if (!response.data[securityProperty]) {
                    throw new Error(securityProperty)
                }
            })
        }).catch(() => {
            dispatch(userActions.setRemind())
        })
        stackNavigate("/home")
    }

    return (
        <CommonPage
            title={""}
            returnPath={-1}
            active={loading}
        >
            <div style={{ width: "100%" }}>
                <div style={{ display: "flex", flexDirection: "column", padding: 16 }}>
                    <h2 style={{ color: "#383743", fontSize: "24px", fontWeight: 600, marginBottom: "30px" }}>{t("page.menu.register.title.verify")}</h2>
                    <p style={{ color: "#5F5C70", fontSize: "15px", fontWeight: 400, marginBottom: "20px" }}>{t("page.menu.register.notice.email")} <span style={{ color: COLORS.Black, fontSize: "16px", fontWeight: 700 }}>{register.account}</span></p>
                    <p style={{ color: "#5F5C70", fontSize: "15px", fontWeight: 400, marginBottom: "34px" }}>{t("page.menu.register.notice.email2")}</p>
                    <p style={{ color: COLORS.Red, fontSize: "15px", fontWeight: 400, marginBottom: "34px" }}>{t("page.menu.register.notice.checkTrash")}</p>
                    <p style={{ color: "##5F5C70", fontSize: "13px", fontWeight: 500, marginBottom: "10px" }}>{t("page.menu.register.label.verifiedCode")}</p>
                    <input
                        placeholder={t("page.menu.register.placeholder.verifiedCode")}
                        value={verifyCode}
                        style={{
                            background: "#F4F4F6",
                            border: "none",
                            borderRadius: "4px",
                            color: "#BDBCC8",
                            padding: "12px 16px 12px 16px",
                            marginBottom: "10px"
                        }}
                        onChange={event => {
                            setVerifyCode(event.target.value)
                        }}
                    />
                    <p style={{ color: "#5F5C70", fontSize: "12px", fontWeight: 400, marginBottom: "20px" }}>
                        {`${t("page.menu.register.notice.alreadySend")}${Math.floor(count / 60)}:${(count - Math.floor(count / 60) * 60) < 10 ? "0" + (count - Math.floor(count / 60) * 60) : (count - Math.floor(count / 60) * 60)}${t("page.menu.register.notice.alreadySend2")}`}
                    </p>
                    {count === 0 &&
                        <BaseButton
                            style={{
                                width: "100%",
                                height: 40
                            }}
                            color="white"
                            onClick={() => {

                            }}
                        >
                            {t("page.menu.register.button.resend")}
                        </BaseButton>
                    }
                    <BaseButton
                        style={{ width: "100%", height: 40 }}
                        onClick={() => {
                            if (verifyCode.length !== 6) {
                                message.error(t("page.menu.register.alert.verifiedCode"))
                            }
                            setLoading(true)
                            authService.checkEmail({
                                email: register.account,
                                code: verifyCode
                            }).then(() => {
                                authService.register({
                                    account: register.account,
                                    password: register.password,
                                    password2: register.password2,
                                    inviteCode: register.inviteCode
                                }).then(() => {
                                    alert(t("page.menu.register.alert.success"))
                                    const loginRequest = {
                                        account: register.account,
                                        password: register.password
                                    } as LoginRequest
                                    authService.login(loginRequest).then((resp) => {
                                        dispatch(userActions.setToken(resp.data.token));
                                    }).then(() => {
                                        dispatch(fetchUser())
                                    }).then(() => {
                                        dispatch(fetchOtcUser(register.account))
                                    }).then(() => {
                                        checkSecurity()
                                    })
                                        .catch((error) => {
                                            alert(error.response.data.msg)
                                        })
                                        .finally(() => {
                                            setLoading(false)
                                        })
                                })
                            }).catch((error) => {
                                alert(error.response.data.msg)
                            }).finally(() => {
                                setLoading(false)
                            })
                        }}
                    >
                        {t("page.menu.register.button.register")}
                    </BaseButton>
                </div>
            </div>
        </CommonPage>

    );
};

export default EmailVerify;
