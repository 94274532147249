import { useState, useEffect, useCallback } from "react";
import { COLORS } from "@/constants/colors";
import { useTranslation } from "react-i18next";

// = Styled Component
import styled from "styled-components";
import { useAppSelector } from "@/hooks";
import { advertisementService, userService } from "@/services";
import FundPage from '@/pages/fund/components/FundPage';
import { CryptoIcon } from "../components";

const CurrencyContainer = styled.div`
  width: 100%;
  height: 73px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid ${COLORS.EXLight_gray};
`;

const CurrencyLeft = styled.div`
  display: flex;
`;

const CurrencyRight = styled.div``;
const Currency = styled.div`
  margin-left: 12px;
`;

const CurrencyTopText = styled.h2`
  color: ${COLORS.Dark_gray};
  font-size: 15px;
  font-weight: 500;
  font-family: Open Sans;
`;

const CurrencyBottomText = styled.h2`
  color: ${COLORS.Mid_gray};
  font-size: 12px;
  font-weight: 500;
  margin-top: 3px;
  font-family: Open Sans;
`;

const PriceText = styled.h2`
  color: ${COLORS.Dark_gray};
  font-size: 16px;
  font-weight: 700;
`;

const PriceText2 = styled.h2`
  color: ${COLORS.Gray};
  font-size: 15px;
  font-weight: 400;
`;

const Fiat = () => {
    const user = useAppSelector((state) => state.user.detail)
    // $ init data
    const { t } = useTranslation();

    // # states
    const [total, setTotal] = useState(0);
    const [freeze, setFreeze] = useState(0);

    // - methods
    const getUserInfo = useCallback(() => {
        userService.getOtcUserInfo(user.account).then(response => {
            for (let i = 0; i < response.wallet.coins.length; i++) {
                if (response.wallet.coins[i].symbol === "USDT") {
                    setTotal(response.wallet.coins[i].balance);
                    advertisementService.getAdvertisement({my: true, type: "sell"}).then((advertisements)=>{
                        const activeAd = advertisements.find( advertisement => advertisement.status!==-1 )
                        setFreeze(response.wallet.coins[i].freeze+ (response.advertiserDeposit ?? 0 ) + (activeAd ? activeAd.totalTradingAmount : 0));
                    })
                }
            }
        });
    }, [user.account])

    // * hooks
    useEffect(() => {
        getUserInfo()
    }, [getUserInfo]);

    return (
        <FundPage active={"otc"} total={total}>
            <CurrencyContainer>
                <CurrencyLeft>
                    <Currency>
                        <PriceText2>{t("page.fund.label.freezeAmount")}</PriceText2>
                    </Currency>
                </CurrencyLeft>
                <CurrencyRight>
                    <PriceText>{freeze}</PriceText>
                </CurrencyRight>
            </CurrencyContainer>
            <CurrencyContainer>
                <CurrencyLeft>
                    <CryptoIcon name={"usdt"} size={30} />
                    <Currency>
                        <CurrencyTopText>USDT</CurrencyTopText>
                        <CurrencyBottomText>Tether</CurrencyBottomText>
                    </Currency>
                </CurrencyLeft>
                <CurrencyRight>
                    <PriceText>{total}</PriceText>
                </CurrencyRight>
            </CurrencyContainer>
        </FundPage>
    );
};

export default Fiat;
