import { useState } from "react";
import styled from "styled-components";
import { COLORS } from "@/constants/colors";
import useStackNavigate from "@/hooks/useStackNavigate";
import BackIcon from "@/assets/icon/Deal/backArrow.png";
import Redadd from "@/assets/icon/Deal/Redadd.png";
import AddPayWay from "@/components/fund/addPayway";
import cancel_circle from "@/assets/icon/Deal/cancel_circle.png";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "@/hooks";
import { advertisementActions } from "@/store/slice";

// header style
const PageContainer = styled.div`
  height: 100vh;
  width: 100vw;
  background: ${COLORS.EXLight_gray};
  &[type="checkbox"] {
    ~ label {
      display: inline-block;
      font-weight: normal;
      margin: 0;
    }
  }
`;
const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  padding: 16px;
  justify-content: space-between;
  align-items: center;
`;

const NavImage = styled.img`
  width: 16px;
  height: 16px;
  margin-left: 12px;
`;

const LogoImage = styled.p`
  font-weight: 600;
  font-size: 16px;
  color: #383743;
  flex: 1;
  text-align: center;
  margin-left: 11%;
`;
// status bar style
const StatusContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  margin-top: 8px;
  position: relative;
`;
const StatusItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;
const StatusIcon = styled.div`
  width: 16px;
  height: 16px;
  border: 4px solid #dedde3;
  border-radius: 8px;
`;
const StatusDes = styled.p`
  font-weight: 700;
  font-size: 13px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: 0.4px;
  color: #dedde3;
  margin-top: 6px;
`;
const StatusSplit = styled.div`
  height: 2px;
  width: 32%;
  background: #dedde3;
  border-radius: 3px;
  margin-bottom: 8%;
  position: absolute;
  bottom: 9%;
  left: 14.7%;
`;
const NextButton = styled.div<{ isValid: boolean }>`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: right;
  letter-spacing: 0.4px;
  color: ${COLORS.Red};
  opacity: ${(props) => (props.isValid ? 1 : 0.5)};
  border: none;
`;
const PayWayContainer = styled.div`
  width: 100%;
  height: 104px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  margin-top: 16px;
  padding: 16px;
`;
const PayWayTitleContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  background: #fff;
`;
const PayWayTitle = styled.div`
  width: 100%;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: ${COLORS.Dark_gray};
`;
const PayWayAddIcon = styled.img`
  width: 28px;
  height: 28px;
`;
const PayWayDes = styled.p`
  width: 100%;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.004em;
  color: ${COLORS.Gray};
`;
const PayWayLabel = styled.div`
  height: 32px;
  background: ${COLORS.EXLight_gray};
  border-radius: 16px;
  padding: 5px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 1%;
`;
const PayWayLabelIcon = styled.img`
  width: 18px;
  height: 18px;
`;
const PayWayLabelText = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.012em;
  color: ${COLORS.Gray};
  margin-right: 8px;
`;
const DealHintContainer = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  margin-top: 16px;
  padding: 16px 16px 24px 16px;
  position: relative;
`;
const DealHintWrite = styled.textarea`
  width: 100%;
  height: 120px;
  background: rgb(244, 244, 246, 0.6);
  border-radius: 4px;
  border: none;
  padding: 12px 16px;
`;
const DealHintLabel = styled.p`
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.004em;
  color: ${COLORS.Mid_gray};
  position: absolute;
  bottom: 40px;
  right: 32px;
`;
const DealLimitContainer = styled.div`
  width: 100%;
  height: 168px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  margin-top: 16px;
  padding: 16px;
`;
const DealLimitItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  background: #fff;
`;
const DealLimitInput = styled.input`
  width: 60px;
  height: 36px;
  background: rgb(244, 244, 246, 0.6);
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.004em;
  color: ${COLORS.Dark_gray};
  padding: 8px 0;
  border: none;
  margin-right: 8px;
`;

const FiatAdDealHint = () => {
    const { stackNavigate } = useStackNavigate()
    const createAdvertisementRequest = useAppSelector((state) => state.advertisement.creating)
    const editId = useAppSelector((state) => state.advertisement.editId)
    const dispatch = useAppDispatch()
    const [addPayWayStatus, setAddPayWayStatus] = useState(false);
    // const [paySelect, setPaySelect] = useState([""]);
    const [dealHint, setDealHint] = useState(createAdvertisementRequest.terms ?? "");
    const [limitDealAmount, setLimitDealAmount] = useState(`${createAdvertisementRequest ? createAdvertisementRequest.conditionCompleteOrders ?? "0" : ""}`);
    const [limitRegisterDate, setLimitRegisterDate] = useState(`${createAdvertisementRequest ? createAdvertisementRequest.conditionRegisteredDays ?? "0" : ""}`);
    const { t } = useTranslation();

    return (
        <PageContainer>
            {addPayWayStatus ? (
                <AddPayWay
                    setAddPayWayStatus={setAddPayWayStatus}
                />
            ) : (
                <>
                    <TitleContainer>
                        <div onClick={()=> stackNavigate(-1)}>
                            <NavImage src={BackIcon} alt="language" />
                        </div>
                        <LogoImage>{t(`page.menu.advertisementManage.label.${editId === "" ? "publish" : "edit"}`)}</LogoImage>
                        <NextButton
                            isValid={createAdvertisementRequest && createAdvertisementRequest.type === 1 ?
                                !!createAdvertisementRequest.payments.length &&
                                // !!dealHint &&
                                !!limitDealAmount &&
                                !!limitRegisterDate :
                                // !!dealHint &&
                                !!limitDealAmount &&
                                !!limitRegisterDate
                            }
                            onClick={() => {
                                if ( !createAdvertisementRequest ){
                                    return ;
                                }
                                dispatch(advertisementActions.set({
                                    ...createAdvertisementRequest,
                                    conditionCompleteOrders: parseInt(limitDealAmount),
                                    conditionRegisteredDays: parseInt(limitRegisterDate),
                                    terms: dealHint
                                }))
                                if (createAdvertisementRequest.type === 1) {
                                    !!createAdvertisementRequest.payments.length &&
                                        !!limitDealAmount &&
                                        !!limitRegisterDate &&
                                        stackNavigate("/advertisement/step-three");
                                } else {
                                    !!limitDealAmount &&
                                        !!limitRegisterDate &&
                                        stackNavigate("/advertisement/step-three");
                                }

                            }}
                        >
                            {t("page.menu.advertisementManage.button.next")}
                        </NextButton>
                    </TitleContainer>
                    <StatusContainer>
                        <StatusItem>
                            <StatusIcon
                                style={{ border: "none", background: COLORS.Gray }}
                            />
                            <StatusDes style={{ color: `${COLORS.Dark_gray}` }}>
                                {t("page.menu.advertisementManage.label.priceNumber")}
                            </StatusDes>
                        </StatusItem>
                        <StatusSplit style={{ background: COLORS.Gray }} />
                        <StatusItem>
                            <StatusIcon style={{ border: `4px solid ${COLORS.Red}` }} />
                            <StatusDes>{t("page.menu.advertisementManage.label.tradeType")}</StatusDes>
                        </StatusItem>
                        <StatusSplit style={{ left: "53.3%" }} />
                        <StatusItem>
                            <StatusIcon />
                            <StatusDes>{t("page.menu.advertisementManage.label.adsConfirm")}</StatusDes>
                        </StatusItem>
                    </StatusContainer>
                    <PayWayContainer>
                        <PayWayTitleContainer>
                            <PayWayTitle>{t("page.menu.advertisementManage.label.payments")}</PayWayTitle>
                            <PayWayAddIcon
                                src={Redadd}
                                alt="add"
                                onClick={() => {
                                    setAddPayWayStatus((v) => !v);
                                }}
                            />
                        </PayWayTitleContainer>
                        <PayWayTitleContainer>
                            {
                                createAdvertisementRequest.payments && createAdvertisementRequest.payments.length !== 0 ? createAdvertisementRequest.payments.map((payment) => {
                                    return (
                                        <PayWayLabel>
                                            <PayWayLabelText>{t(`page.menu.c2cManage.payment.title.${payment.type}`)}</PayWayLabelText>
                                            <PayWayLabelIcon
                                                src={cancel_circle}
                                                alt="cancel"
                                                onClick={() => {
                                                    dispatch(advertisementActions.deletePayment(payment.id))
                                                }}
                                            />
                                        </PayWayLabel>
                                    )
                                }) : (
                                    <PayWayDes>{t(`page.menu.advertisementManage.alert.addOnePayment`)}</PayWayDes>
                                )

                            }
                        </PayWayTitleContainer>
                    </PayWayContainer>
                    <DealHintContainer style={{ marginTop: 10 }}>
                        <PayWayTitle>{t("page.menu.advertisementManage.label.tradeMemo")}</PayWayTitle>
                        <DealHintWrite
                            placeholder={t("page.menu.advertisementManage.placeholder.tradeMemo")} 
                            onChange={(e) => {
                                setDealHint(e.target.value);
                            }}
                            value={dealHint}
                        />
                        <DealHintLabel>{`${dealHint.length} / 100`}</DealHintLabel>
                    </DealHintContainer>
                    <DealLimitContainer style={{ marginTop: 10 }}>
                        <PayWayTitle>{t("page.menu.advertisementManage.label.tradeCondition")}</PayWayTitle>
                        <DealLimitItem style={{ marginTop: 16 }}>
                            <PayWayDes style={{ width: "70%", marginRight: 7 }}>
                                {t("page.menu.advertisementManage.label.dealAtLeast")}
                            </PayWayDes>
                            <DealLimitInput
                                placeholder="0"
                                onChange={(e) => setLimitDealAmount(e.target.value)}
                                value={limitDealAmount ?? 0}
                            />
                            <PayWayDes>{t("page.menu.advertisementManage.label.dealTimes")}</PayWayDes>
                        </DealLimitItem>
                        <DealLimitItem style={{ marginTop: 16 }}>
                            <PayWayDes style={{ width: "70%", marginRight: 7 }}>
                                {t("page.menu.advertisementManage.label.registerAtLeast")}
                            </PayWayDes>
                            <DealLimitInput
                                placeholder="0"
                                onChange={(e) => setLimitRegisterDate(e.target.value)}
                                value={limitRegisterDate ?? 0}
                            />
                            <PayWayDes>{t("page.menu.advertisementManage.label.days")}</PayWayDes>
                        </DealLimitItem>
                    </DealLimitContainer>
                </>
            )}
        </PageContainer>
    );
};

export default FiatAdDealHint;
