/* 引入styled components */
import { useState } from "react";
import styled from "styled-components";
import useStackNavigate from "@/hooks/useStackNavigate";
import { useTranslation } from "react-i18next";
import { authService } from "@/services";
import CommonPage from "@/components/LayoutPage";
import { BaseButton } from "@/components/Button";
import { LabelInput } from "@/components/Input";

const Inside = styled.div`
    padding: 0px 20px;
    width: 100%;
`;

const SetTheFundPassword = () => {

    const { stackNavigate } = useStackNavigate()
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState("")
    const { t } = useTranslation();


    return (
        <CommonPage title={t("page.menu.login.forgot.title")} active={loading} returnPath={"/login"}>
            <LabelInput
                label={t("page.menu.login.forgot.label.email")}
                placeholder={t("page.menu.login.forgot.placeholder.email")}
                onChange={(e) => {
                    setEmail(e.target.value)
                }}
            />
            <Inside>
                <BaseButton
                    style={{ width: "100%", marginTop: 20 }}
                    onClick={() => {
                        if (!email) {
                            alert(t("page.menu.login.forgot.alert.email"))
                        } else {
                            setLoading(true)
                            authService.forgotPassword({ account: email }).then(() => {
                                stackNavigate(-1)
                                alert(t("page.menu.login.forgot.alert.new"))
                            }).finally(() => {
                                setLoading(false)
                            })
                        }
                    }}
                >
                    {t("page.menu.login.forgot.button.ok")}
                </BaseButton>
            </Inside >
        </CommonPage>
    );
}

export default SetTheFundPassword 
