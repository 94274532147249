import { useEffect, useState } from 'react';
import { useAppSelector } from '@/hooks/redux-hooks'
import { MarketPrice } from '@/interfaces/market.interface';

const useGetPrice = (props: string) => {
    const market = useAppSelector((state)=>state.market.price)
    const [selected, setSelected] = useState(()=>  market ? market.find( p => p.s===props ) ?? {} as MarketPrice : {} as MarketPrice)

    useEffect(()=>{
        if (market){
            setSelected(()=> market.find( p => p.s===props ) ?? {} as MarketPrice)
        }
    },[props, market])

    return {
        selected
    }
}

export default useGetPrice