import { useTranslation } from "react-i18next";
import CommonPage from "@/components/LayoutPage";
import ProtectFundIcon from "@/assets/icon/google-auth.svg"
import { COLORS } from "@/constants/colors";

const ProtectFund = () => {
    const { t } = useTranslation();

    return (
        <CommonPage title={t("page.protectFund.title")} returnPath={-1}>
            <img src={ProtectFundIcon} alt="icon" style={{ width: "60%", padding: "40px 0px" }} />
            <div style={{ width: "100%", padding: "0px 15px" }}>
                <div style={{ fontSize: 20, fontWeight: 700, marginBottom: 5 }}>{t("page.protectFund.label.subject")}</div>
                <div style={{ fontSize: 14, fontWeight: 600, color: COLORS.Mid_gray, marginBottom: 20, lineHeight: "30px" }}>{t("page.protectFund.label.description")}</div>
                <div style={{ fontSize: 20, fontWeight: 700, marginBottom: 20, lineHeight: "30px" }}>{t("page.protectFund.label.feature")}</div>
                <div style={{ fontSize: 14, fontWeight: 600, marginBottom: 5, lineHeight: "30px" }}>{t("page.protectFund.label.security")}</div>
                <div style={{ fontSize: 12, fontWeight: 600, color: COLORS.Mid_gray, marginBottom: 20, lineHeight: "30px" }}>{t("page.protectFund.label.securityNote")}</div>
                <div style={{ fontSize: 14, fontWeight: 600, marginBottom: 5, lineHeight: "30px" }}>{t("page.protectFund.label.flexibility")}</div>
                <div style={{ fontSize: 12, fontWeight: 600, color: COLORS.Mid_gray, marginBottom: 20, lineHeight: "30px" }}>{t("page.protectFund.label.flexibilityNote")}</div>
                <div style={{ fontSize: 14, fontWeight: 600, marginBottom: 5, lineHeight: "30px" }}>{t("page.protectFund.label.stability")}</div>
                <div style={{ fontSize: 12, fontWeight: 600, color: COLORS.Mid_gray, marginBottom: 20, lineHeight: "30px" }}>{t("page.protectFund.label.stabilityNote")}</div>
            </div>

        </CommonPage>
    )
}

export default ProtectFund