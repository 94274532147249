import { COLORS } from "@/constants/colors";
import { CSSProperties } from "react";
import styled from "styled-components";

const CardContainer = styled.div<{ color?: string, colors?: string[] }>`
    background-color: ${props => props.color ?? COLORS.White};
    border-radius: 5px;
    width: 100%;
    background: ${props => {
        if ( !props.colors ) return "";
        if ( props.colors.length!==2 ) return "";

        return `linear-gradient(to bottom, ${props.colors[0]}, ${props.colors[1]});`
    }};
`

const CardContent = styled.div<{ color?: string }>`
    padding: 0px 10px;
`

const CustomCard: React.FC<{ style?: CSSProperties, contentStyle?: CSSProperties, color?: string, colors?: string[] }> = ({
    style,
    color,
    colors,
    children,
    contentStyle
}) => {
    return (
        <CardContainer style={style} color={color} colors={colors}>
            <CardContent style={contentStyle}>
                {children}
            </CardContent>
        </CardContainer>
    )
}

export default CustomCard;