import { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { COLORS } from "@/constants/colors";
import { useTranslation } from "react-i18next";
import { Advertisement, OtcUser } from "@/interfaces/otc.interface";
import { advertisementService, userService } from "@/services";
import { otcOrderActions, userActions } from "@/store/slice";
import { C2cOrderType, PayType } from "@/constants";
import { Divider, Drawer, Dropdown, Input, List, Menu, message } from "antd";
import { useAppDispatch, useAppSelector } from "@/hooks";
import CommonPage from "@/components/LayoutPage";
import { AdvertisementContainer, CardContent, CardContentLeft, CardFooter, CardHeader, CenterLineItem, CenterListItem, DetailLine, FiatTradeMenuContainer, FilterContainer, PaymentItem, SymbolNavContainer, SymbolNavItem } from "@/styled-components/deal";
import LetterAvatar from "react-letter-avatar";
import { CaretDownOutlined, FilterOutlined, MoreOutlined } from "@ant-design/icons";
import { CircleFlag } from 'react-circle-flags'
import { BaseButton } from "@/components/Button";
import { checkSecurity } from "@/utils";
import useStackNavigate from "@/hooks/useStackNavigate";
import { fetchOtcUser } from "@/store/slice/user";
import { useLocation } from "react-router-dom";

const CurrencyTitleButton = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  background: #fff;
  color: ${props => props.active ? COLORS.Black : COLORS.Mid_gray};
  font-size: 20px;
  font-weight: 600;
  padding: 5px;
  border-radius: 16px 16px 0px 0px;
`;

const CurrencyListCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: space-between;
  background: #fff;
  padding: 6px;
`;
const CurrencyCardName = styled.span`
  margin-left: 12px;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #383743;
  letter-spacing: 0.004em;
  font-family:Open Sans;
`;
const CurrencyCardUnit = styled.p`
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.004em;
  color: ${COLORS.Dark_gray};
  font-weight:500;
`;
const CurrencyCardPrice = styled.p`
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.002em;
  color: ${COLORS.Dark_gray};
  margin-left: 4px;
  font-family:Open Sans;
`;
const CurrencyCardAmount = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.004em;
  color: #8f8da2;
`;

const PaymentIconContainer = styled.div``;

const FiatDeal = () => {
    const account = useAppSelector((state) => state.user.detail.account);
    const otcUser = useAppSelector((state) => state.user.otc);
    const [advertisements, setAdvertisements] = useState([] as Advertisement[]);
    const clientSide = useLocation().state as C2cOrderType;
    const tradeType: C2cOrderType[] = ["buy", "sell"]
    const payTypes: PayType[] = ["CASH", "BANK", "LINE_PAY", "STREET_PAY"]
    const symbols = ["USDT", "BTC", "ETH", "USDC"]
    const [selectedSymbol,] = useState("USDT");
    const [selectedPayType, setSelectPayType] = useState<PayType | undefined>(undefined);
    const [selectedAdvertisements, setSelectAdvertisements] = useState([] as Advertisement[]);
    const [lowestPrice, setLowestPrice] = useState("");
    const [highestPrice, setHighestPrice] = useState("");

    const [orderStatus, setOrderStatus] = useState<C2cOrderType>(clientSide ? clientSide : "buy");
    const dispatch = useAppDispatch()
    const { stackNavigate, stageStackNavigate } = useStackNavigate()

    const [visible, setVisible] = useState("");

    const showDrawer = (target: string) => {
        setVisible(target);
    };

    const onClose = () => {
        setVisible("");
    };

    const getCheckErrorMsg = (advertisement: Advertisement, otcUser: OtcUser): string => {
        const dayAfterRegister = Math.floor((Date.now() - otcUser.createdDate) / (24 * 60 * 60 * 1000))

        if (dayAfterRegister < advertisement.conditionRegisteredDays) {
            return `${t("page.deal.fiat.alert.condition.register")}(${dayAfterRegister}/${advertisement.conditionRegisteredDays})`;
        }

        if (otcUser.finishedOrderCounter < advertisement.conditionCompleteOrders) {
            return `${t("page.deal.fiat.alert.condition.finishOrder")}(${otcUser.finishedOrderCounter}/${advertisement.conditionCompleteOrders})`;
        }

        return "";
    }

    const getAdvertisements = useCallback((cryptoAsset: string, type: C2cOrderType) => {
        dispatch(fetchOtcUser(account))
        
        advertisementService.getAdvertisement({
            all: false,
            my: false,
            type: type,
            cryptoAsset: cryptoAsset
        }).then((response) => {
            setAdvertisements(response);
        }).catch((error) => {
            if (error.response.status === 401) {
                dispatch(userActions.logout())
            }
        })
    }, [dispatch, account])

    const getOption = () => {
        return (
            <Dropdown trigger={["click"]} overlay={
                <Menu>
                    <Menu.Item onClick={() => stackNavigate("/c2c")}>{t("page.deal.fiat.label.c2cTrade")}</Menu.Item>
                    <Menu.Item onClick={() => stackNavigate("/advertisement-manage")}>{t("page.deal.fiat.label.myAd")}</Menu.Item>
                    <Menu.Item onClick={() => stackNavigate("/fiat-orders")}>{t("page.deal.fiat.label.toOrder")}</Menu.Item>
                </Menu>
            }>
                <MoreOutlined rotate={90} style={{ fontSize: 20 }} />
            </Dropdown>
        )
    }
    const { t } = useTranslation();
    useEffect(() => {
        getAdvertisements("USDT", orderStatus === "buy" ? "sell" : "buy")
    }, [getAdvertisements, orderStatus])

    const getTitle = () => {
        return (
            <CenterLineItem onClick={() => {
                showDrawer("menu")
            }}>
                {t("page.deal.fiat.title.deal")}
                <CaretDownOutlined style={{ fontSize: 8, padding: "0px 2px" }} />
            </CenterLineItem>
        )
    }

    useEffect(() => {
        setSelectAdvertisements(advertisements.filter(advertisement => selectedPayType ? advertisement.payments.find(payment => payment.type === selectedPayType) : true))
    }, [advertisements, selectedPayType])

    useEffect(() => {
        if (lowestPrice === "" && highestPrice === "") {
            setSelectAdvertisements(advertisements)
        }
        else if (lowestPrice === "" || highestPrice === "") {
            lowestPrice !== "" && setSelectAdvertisements(advertisements.filter(advertisement => advertisement.price >= parseInt(lowestPrice)))
            highestPrice !== "" && setSelectAdvertisements(advertisements.filter(advertisement => advertisement.price <= parseInt(highestPrice)))
        }
        else {
            setSelectAdvertisements(advertisements.filter(advertisement => advertisement.price >= parseInt(lowestPrice) && advertisement.price <= parseInt(highestPrice)))
        }
    }, [advertisements, lowestPrice, highestPrice])

    return (
        <CommonPage title={getTitle()} returnPath={"/deal"} option={getOption()}>
            <FiatTradeMenuContainer>
                <CenterLineItem style={{ marginBottom: 5 }}>
                    {
                        tradeType.map((type) => {
                            return (
                                <CurrencyTitleButton
                                    active={orderStatus === type}
                                    onClick={(e) => {
                                        setOrderStatus(type);
                                    }}
                                >
                                    {t(`page.deal.fiat.label.${type}`)}
                                </CurrencyTitleButton>
                            )
                        })
                    }
                </CenterLineItem>
                <CenterLineItem>
                    <CircleFlag countryCode="tw" height="16" />
                    <span style={{ marginLeft: 3, fontSize: 14, fontWeight: 500 }}>TWD</span>
                    <CaretDownOutlined style={{ fontSize: 8, padding: "0px 2px" }} />
                </CenterLineItem>
            </FiatTradeMenuContainer>
            <SymbolNavContainer style={{ marginBottom: 20 }}>
                {
                    symbols.map((symbol) => {
                        return (
                            <SymbolNavItem active={selectedSymbol === symbol} onClick={(e) => { e.stopPropagation() }}>{symbol}</SymbolNavItem>
                        )
                    })
                }
            </SymbolNavContainer>
            <FilterContainer>
                <CenterLineItem>
                    <span style={{ fontSize: 12, fontWeight: 500, padding: "0px 5px" }} onClick={() => showDrawer("payType")}>{t(`page.deal.fiat.label.payType`)}</span>
                    <CaretDownOutlined style={{ fontSize: 8, padding: "0px 2px" }} />
                    <span style={{ marginLeft: 15, fontSize: 12, fontWeight: 500, padding: "0px 5px" }} onClick={() => showDrawer("price")}>{t(`page.deal.fiat.label.dealPrice`)}</span>
                    <CaretDownOutlined style={{ fontSize: 8, padding: "0px 2px" }} />
                </CenterLineItem>
                <CenterLineItem>
                    <Divider type="vertical" />
                    <span style={{ fontSize: 12, fontWeight: 500, padding: "0px 5px" }}>{t(`page.deal.fiat.label.filter`)}</span>
                    <FilterOutlined style={{ fontSize: 13, padding: "0px 2px" }} />
                </CenterLineItem>
            </FilterContainer>
            <AdvertisementContainer>
                {
                    selectedAdvertisements.map((advertisement, index) => {
                        return (
                            <>
                                <CurrencyListCard key={advertisement.id}>
                                    <CardHeader>
                                        <CenterLineItem>
                                            <LetterAvatar name={!advertisement.owner.nickName ? advertisement.owner.account : advertisement.owner.nickName} size={25} radius={60} />
                                            <CurrencyCardName>{!advertisement.owner.nickName ? advertisement.owner.account : advertisement.owner.nickName}</CurrencyCardName>
                                        </CenterLineItem>
                                        <CenterLineItem>
                                            <div style={{ fontSize: 12, color: COLORS.Mid_gray }}>{t(`page.deal.fiat.label.dealAmount`)} 0 | 0%</div>
                                        </CenterLineItem>
                                    </CardHeader>
                                    <CenterLineItem>
                                        <CurrencyCardUnit>NT$</CurrencyCardUnit>
                                        <CurrencyCardPrice>{advertisement.price}</CurrencyCardPrice>
                                    </CenterLineItem>
                                    <CardContent>
                                        <CardContentLeft>
                                            <DetailLine>
                                                <CurrencyCardAmount>{t("page.deal.fiat.label.amount")}</CurrencyCardAmount>
                                                <CurrencyCardName
                                                    style={{
                                                        fontSize: 12,
                                                        lineHeight: "15px",
                                                        flex: 1,
                                                        fontWeight: 600,
                                                        fontFamily: "Open Sans"
                                                    }}
                                                >
                                                    {advertisement.totalTradingAmount}
                                                </CurrencyCardName>
                                            </DetailLine>
                                            <DetailLine>
                                                <CurrencyCardAmount>{t("page.deal.fiat.label.limitedAmount")}</CurrencyCardAmount>
                                                <CurrencyCardName
                                                    style={{
                                                        fontSize: 12,
                                                        lineHeight: "15px",
                                                        flex: 1,
                                                        fontWeight: 600,
                                                        fontFamily: "Open Sans"
                                                    }}
                                                >
                                                    {advertisement.orderLimitMin} - {advertisement.orderLimitMax}
                                                </CurrencyCardName>
                                            </DetailLine>
                                        </CardContentLeft>
                                        <BaseButton
                                            style={{ width: "25%" }}
                                            color={ getCheckErrorMsg(advertisement, otcUser)==="" ? orderStatus==="buy" ? "green" : "red" : "gray-ban" }
                                            onClick={() => {
                                                userService.getSecurity().then((response) => {
                                                    checkSecurity(response.data)
                                                    userService.getPayments().then((response) => {
                                                        if (!response.data.length) {
                                                            throw new Error(t("page.deal.fiat.alert.createAdvertisement.payment"))
                                                        }
                                                        userService.getOtcUserInfo(account).then((response) => {
                                                            const errorMsg = getCheckErrorMsg(advertisement, response);
                                                            if (errorMsg==="") {
                                                                dispatch(otcOrderActions.setSelectedAdvertisement(advertisement))
                                                                dispatch(otcOrderActions.changeDealingSide(orderStatus))
                                                                stageStackNavigate("/deal/fiat/create-otc-order");
                                                            }
                                                            else {
                                                                message.error(errorMsg)
                                                            }
                                                        })
                                                    }).catch((e) => {
                                                        alert(e.message)
                                                        stackNavigate("/account-setting")
                                                    })
                                                }).catch((e) => {
                                                    alert(t(`page.deal.fiat.alert.createAdvertisement.${e.message}`))
                                                    switch (e.message) {
                                                        case "DENY":
                                                        case "kyc":
                                                            stageStackNavigate("/id-verify");
                                                            break;
                                                        case "phone": stackNavigate("/reset-phone-number"); break;
                                                        case "googleAuth": stageStackNavigate("/google-code"); break;
                                                        case "financePwd": stackNavigate("/set-the-fund-password"); break;
                                                        default: console.log(e.message)
                                                    }
                                                })
                                            }}
                                        >
                                            {`${getCheckErrorMsg(advertisement, otcUser)==="" ? "" : t("page.deal.fiat.label.limitDeal")}${orderStatus === "buy" ? t("page.deal.fiat.label.buy") : t("page.deal.fiat.label.sell")}`}
                                        </BaseButton>
                                    </CardContent>
                                    <CardFooter>
                                        <PaymentIconContainer>
                                            {Array.from(new Set(advertisement.payments.map((item) => item.type))).map((item) => {
                                                return <PaymentItem payment={item}>{t(`page.menu.c2cManage.payment.title.${item}`)}</PaymentItem>;
                                            })}
                                        </PaymentIconContainer>
                                    </CardFooter>
                                </CurrencyListCard>
                                {
                                    index !== advertisements.length - 1 && <div style={{ width: "100%", height: "2px", backgroundColor: COLORS.EXLight_gray, margin: "5px 0px" }}></div>
                                }
                            </>
                        );
                    })
                }
            </AdvertisementContainer>
            <Drawer
                placement={"bottom"}
                closable={false}
                onClose={onClose}
                key={"menu"}
                visible={visible === "menu"}
                bodyStyle={{ padding: 0 }}
                height={"auto"}
            >
                <List style={{ width: "100%" }}>
                    <CenterListItem>{t("page.deal.fiat.label.fast")}</CenterListItem>
                    <CenterListItem onClick={() => stackNavigate("/deposit?symbol=USDT")}>{t("page.deal.fiat.label.deposit")}</CenterListItem>
                </List>
            </Drawer>
            <Drawer
                placement={"bottom"}
                closable={false}
                onClose={onClose}
                key={"payType"}
                visible={visible === "payType"}
                bodyStyle={{ padding: 0 }}
                height={"auto"}
            >
                <List style={{ width: "100%" }}>
                    <CenterListItem
                        onClick={() => {
                            setSelectPayType(undefined)
                            onClose()
                        }}
                    >
                        {t(`page.deal.fiat.label.all`)}
                    </CenterListItem>
                    {
                        payTypes.map((payType) => {
                            return (
                                <CenterListItem
                                    onClick={() => {
                                        setSelectPayType(payType)
                                        onClose()
                                    }}
                                >
                                    {t(`page.menu.c2cManage.payment.title.${payType}`)}
                                </CenterListItem>
                            )
                        })
                    }
                </List>
            </Drawer>
            <Drawer
                placement={"bottom"}
                title={t(`page.deal.fiat.label.searchPrice`)}
                closable={false}
                onClose={onClose}
                key={"price"}
                visible={visible === "price"}
                height={"auto"}
                footer={
                    <BaseButton
                        style={{ width: "100%" }}
                        onClick={() => {
                            setLowestPrice("")
                            setHighestPrice("")
                        }}
                    >
                        {t("page.deal.fiat.button.clean")}
                    </BaseButton>
                }
            >
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <CenterLineItem>
                        <Input
                            placeholder={t(`page.deal.fiat.placeholder.lowestPrice`)}
                            type="number"
                            value={lowestPrice}
                            onChange={(e) => setLowestPrice(e.target.value)}
                        />
                        <span style={{ padding: "0px 10px" }}>-</span>
                        <Input
                            placeholder={t(`page.deal.fiat.placeholder.highestPrice`)}
                            type="number"
                            value={highestPrice}
                            onChange={(e) => setHighestPrice(e.target.value)}
                        />
                    </CenterLineItem>
                </div>
            </Drawer>
        </CommonPage>
    );
};

export default FiatDeal;
