import styled from "styled-components";
import { useState, useEffect } from "react"
import useStackNavigate from "@/hooks/useStackNavigate";
import { useTranslation } from "react-i18next";
import { authService, userService } from "@/services";
import { useAppSelector } from "@/hooks";
import CommonPage from "@/components/LayoutPage";
import PasswordInput from "@/components/UI/PasswordInput";
import { Header } from "@/components/typography/Header";


const AreaSpace = styled.div`
  padding: 10px;
  background: #fff ;
  opacity: 0.6;
  border-radius: 4px;
  background:#F4F4F6;
`;

const AreaContent = styled.div`
  display:flex;
  justify-content: space-between;
`;

const Words = styled.input`
  font-size:15px;
  color: #383743;
  padding:5px;
  border:none;
  background:none;
  width:100%;
`;

const SaveButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none ;
  border-radius: 4px ;
  height: 44px ;
  width: 100% ;
  background: rgb(211,47,47,1);
  margin-top:40px;
`;

const SaveButtonWord = styled.div`
  color:#FFFFFF;
  font-size:14px;
  text-align:center;
  border: none ;
`;

const FormBox = styled.div`
    width: 100%;
    margin-top: 20px;
    padding: 0px 15px;
`
const SetTheFundPassword = () => {
    const user = useAppSelector((state) => state.user.detail)
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const { stackNavigate } = useStackNavigate()
    const [loading, setLoading] = useState(false);
    const [count, setCount] = useState(0)
    const [emailCode, setEmailCode] = useState("");
    const [googleCode, setGoogleCode] = useState("");
    const [emailSend, setEmailSend] = useState(false);
    const { t } = useTranslation();
    useEffect(() => {
        if (emailSend) {
            setTimeout(() => {
                if (count > 0) {
                    setCount(c => c - 1)
                }
            }, 1000)
        }
        if (count === 0) {
            setEmailSend(false)
        }
    }, [count, emailSend])

    return (
        <CommonPage
            active={loading}
            title={t("page.resetFundPassword.title")}
            returnPath={"/safe-setting"}
        >
            <FormBox>
                <Header size={"sm"}>{t("page.resetFundPassword.input.password")}</Header>
                <AreaSpace>
                    <AreaContent>
                        <PasswordInput placeholder={t("page.resetFundPassword.placeholder.password")} onChange={(e) => {
                            setPassword(e.target.value)
                        }} />
                    </AreaContent>
                </AreaSpace>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Header size={"sm"}>{t("page.resetFundPassword.input.emailCode")}</Header>
                    {emailSend ? <Header size={"sm"}>{count}s</Header> : <Header size={"sm"} onClick={() => {
                        setLoading(true)
                        authService.sendEmail({ email: user.account }).then(() => {
                            alert(t("page.resetFundPassword.alert.send"))
                            setCount(60)
                            setEmailSend(true)
                        }).catch((error) => {
                            alert(error.response.data.msg)
                        }).finally(() => {
                            setLoading(false)
                        })
                    }}>{t("page.resetFundPassword.button.sendEmail")}</Header>}

                </div>
                <AreaSpace
                >
                    <AreaContent>
                        <Words placeholder={t("page.resetFundPassword.placeholder.emailCode")} onChange={(e) => {
                            setEmailCode(e.target.value)
                        }} />
                    </AreaContent>
                </AreaSpace>

                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Header size={"sm"}>{t("page.resetFundPassword.input.googleCode")}</Header>
                </div>
                <AreaSpace
                >
                    <AreaContent>
                        <Words placeholder={t("page.resetFundPassword.placeholder.googleCode")} onChange={(e) => {
                            setGoogleCode(e.target.value)
                        }} />
                    </AreaContent>
                </AreaSpace>

                <Header size={"sm"}>{t("page.resetFundPassword.input.newPassword")}</Header>
                <AreaSpace
                >
                    <AreaContent>
                        <PasswordInput placeholder={t("page.resetFundPassword.placeholder.newPassword")} onChange={(e) => {
                            setConfirmPassword(e.target.value)
                        }} />
                    </AreaContent>
                </AreaSpace>

                <SaveButton onClick={() => {
                    if (!password) {
                        alert(t("page.resetFundPassword.alert.password"))
                    } else if (!emailCode) {
                        alert(t("page.resetFundPassword.alert.emailCode"))
                    } else if (!googleCode) {
                        alert(t("page.resetFundPassword.alert.googleCode"))
                    } else if (!confirmPassword) {
                        alert(t("page.resetFundPassword.alert.newPassword"))
                    } else {
                        setLoading(true)
                        userService.resetFinancePassword({
                            password: password,
                            mailCode: emailCode,
                            code: googleCode,
                            financePassword: confirmPassword
                        }).then(() => {
                            alert(t("page.resetFundPassword.alert.success"))
                            stackNavigate(-1)
                        }).catch((error) => {
                            alert(error.response.data.msg)
                        }).finally(() => {
                            setLoading(false)
                        })
                    }
                }}>
                    <SaveButtonWord>{t("page.resetFundPassword.button.modify")}</SaveButtonWord>
                </SaveButton>
            </FormBox>
        </CommonPage>
    );
}

export default SetTheFundPassword 
