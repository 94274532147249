import { useState, useEffect } from "react";
import CryptoIcon from "@/pages/fund/components/CryptoIcon";
import { FundPage, ValuePair } from '@/pages/fund/components';
import {
    CurrencyContainer,
    CurrencyLeft,
    CurrencyRight,
    Currency,
    CurrencyTopText,
    CurrencyBottomText,
} from '@/styled-components/fund';
import { investorService } from "@/services";

const Spot = () => {
    // # states
    const [total, setTotal] = useState(0);
    const [, setBtc] = useState(0);
    const [usdt, setUsdt] = useState(0);
    // const [busd, setBusd] = useState(0);
    const [, setEth] = useState(0);

    // crypto map
    const cryptoCurrencyMap = {
        usdt: { name: "Tether", price: usdt },
        aave: { name: "AAVE", price: 0 },
        ada: { name: "Cardano", price: 0 },
        ape: { name: "ApeCoin", price: 0 },
        atom: { name: "Cosmos", price: 0 },
        avax: { name: "Avalanche", price: 0 },
        bch: { name: "BCH", price: 0 },
        bnb: { name: "Binance Coin", price: 0 },
        btc: { name: "Bitcoin", price: 0 },
        chz: { name: "Chiliz", price: 0 },
        comp: { name: "Compound", price: 0 },
        crv: { name: "Curve DAO Token", price: 0 },
        dash: { name: "Dash", price: 0 },
        doge: { name: "Dogecoin", price: 0 },
        dot: { name: "Polkadot", price: 0 },
        eos: { name: "EOS", price: 0 },
        etc: { name: "Ethereum Classic", price: 0 },
        eth: { name: "Ethereum", price: 0 },
        fil: { name: "Filecoin", price: 0 },
        hot: { name: "Holo", price: 0 },
        link: { name: "ChainLink", price: 0 },
        ltc: { name: "Litecoin", price: 0 },
        mana: { name: "Decentraland", price: 0 },
        matic: { name: "Polygon", price: 0 },
        rvn: { name: "Ravencoin", price: 0 },
        sand: { name: "The Sandbox", price: 0 },
        sol: { name: "Solana", price: 0 },
        trx: { name: "TRON", price: 0 },
        uni: { name: "Uniswap", price: 0 },
        vet: { name: "VeChain", price: 0 },
        xmr: { name: "Monero", price: 0 },
    };

    let containerList = Object.keys(cryptoCurrencyMap).map((key) => {
        return (
            <CurrencyContainer>
                <CurrencyLeft>
                    <CryptoIcon name={key} size={30} />
                    <Currency>
                        <CurrencyTopText>{key.toUpperCase()}</CurrencyTopText>
                        <CurrencyBottomText>
                            {cryptoCurrencyMap[key].name}
                        </CurrencyBottomText>
                    </Currency>
                </CurrencyLeft>
                <CurrencyRight>
                    <ValuePair value={cryptoCurrencyMap[key].price} symbol={key.toUpperCase()}/>
                </CurrencyRight>
            </CurrencyContainer>
        );
    });

    // - methods
    const getProperty = () => {
        investorService.getProperty().then((response) => {
            setTotal(response.data.spot.equityValue)
            setBtc(
                response.data.spot.coins.sort(function (a, b) {
                    return a.symbol > b.symbol ? 1 : -1;
                })[0].balance
            );
            setUsdt(
                response.data.spot.coins.sort(function (a, b) {
                    return a.symbol > b.symbol ? 1 : -1;
                })[2].balance
            );
            setEth(
                response.data.spot.coins.sort(function (a, b) {
                    return a.symbol > b.symbol ? 1 : -1;
                })[1].balance
            );
        })
    };

    useEffect(() => {
        const timer = setInterval(() => {
            getProperty();
        }, 1000);
        getProperty();
        return () => clearInterval(timer)
    }, []);

    return (
        <FundPage active={"spot"} total={total} style={{height: "100%"}}>
            {containerList}
        </FundPage>
    );
};

export default Spot;
