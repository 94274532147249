import CommonPage from "@/components/LayoutPage";
import { Header } from "@/components/typography/Header";
import { Text } from "@/components/typography/Text";
import { useTranslation } from "react-i18next";
import XebitIcon from "@/assets/xebit_icon.png";

const Terms = () => {
    const { t } = useTranslation()
    const numbers = Array.from({ length: 10 }, (_, index) => index + 1);
    return (
        <CommonPage title={""} returnPath={"/about"}>
            <div style={{ padding: "0px 15px" }}>
                <Header size="lg">{t("page.menu.about.terms.title")}</Header>
                <div style={{ width: "100%%", margin: "20px 0px", display: "flex" }}>
                    <img src={XebitIcon} alt="logo" width={40} height={40} />
                    <div style={{ marginLeft: 10 }}>
                        <div>XEBIT</div>
                        <Text size="xs">{t("page.menu.about.terms.label.createdDate")}</Text>
                    </div>
                </div>
                <Header size="md">{t("page.menu.about.terms.label.subject1")}</Header>
                <Header size="sm" style={{ lineHeight: 2 }}>{t("page.menu.about.terms.label.subject2")}</Header>
                {
                    numbers.map(num => {
                        return (
                            <Header size="xs" style={{ display: "flex", marginBottom: 5, lineHeight: 2 }}>
                                <div>{t(`page.menu.about.privacy.terms.label.subject2-${num}`)}</div>
                                <div>{t(`page.menu.about.privacy.terms.label.content2-${num}`)}</div>
                            </Header>
                        )
                    })
                }
                <Header size="md">{t("page.menu.about.privacy.terms.label.subject3")}</Header>
                <Header size="sm" style={{ lineHeight: 2 }}>{t("page.menu.about.privacy.terms.label.content3")}</Header>
            </div>
        </CommonPage>
    );
};

export default Terms;
