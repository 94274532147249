import { useState } from "react";
import styled from "styled-components";
import { COLORS } from "@/constants/colors";
import Cancel from "@/assets/icon/cancel.png";
import LoadingOverlay from 'react-loading-overlay-ts';
// import useStackNavigate from "@/hooks/useStackNavigate";
import useStackNavigate from "@/hooks/useStackNavigate";
import { useTranslation } from "react-i18next";
import AuthService from "@/services/auth.service";
import { useAppDispatch } from "@/hooks/redux-hooks"
import { LoginRequest } from "@/interfaces/request.interface";
import { fetchOtcUser, fetchUser } from "@/store/slice/user";
import { userActions } from "@/store/slice";
import PasswordInput from "@/components/UI/PasswordInput";
import { userService } from "@/services";
import { BaseButton } from "@/components/Button";

// import { isNil } from 'lodash'

const HeaderContainer = styled.header`
  background-color: ${COLORS.White};
  height: 44px;
`;

const HeaderLeft = styled.div`
  float: Left;
  display: flex;
  align-items: center;
  height: 44px;
`;
const HeaderRight = styled.div`
  float: Right;
  display: flex;
  align-items: center;
  height: 44px;
`;
const SelectIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-left: 7px;
`;

const Login = () => {
    // const { t } = useTranslation();
    const [username, setUsername] = useState("")
    const [loading, setLoading] = useState(false)
    const [isLoginPhone, setIsLoginPhone] = useState(false)
    const [password, setPassword] = useState("")
    const { stackNavigate } = useStackNavigate()
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const checkSecurity = () => {
        userService.getSecurity().then((response) => {
            if ( response.data["kyc"]==="DENY" || response.data["kyc"]===null ){
                throw new Error()
            }
        }).catch(() => {
            dispatch(userActions.setRemind())
        })
        stackNavigate("/home")
    }

    return (
        <LoadingOverlay
            active={loading}
            spinner
        >
            <div style={{ height: "100vh" }}>
                {true &&
                    <div>
                        <HeaderContainer>
                            <HeaderLeft>
                                <SelectIcon
                                    style={{ width: 28, height: 28 }}
                                    src={Cancel}
                                    alt="cancel"
                                    onClick={() => { stackNavigate("/home") }}
                                />
                            </HeaderLeft>
                            <HeaderRight>
                                <button style={{ color: COLORS.Green, fontSize: "16px", border: "none", backgroundColor: "transparent", fontWeight: 600 }} onClick={() => {
                                    // setIsRegister(true)
                                    // setIsLogin(false)
                                    stackNavigate("/register")
                                }}>
                                    <p>{t("page.menu.login.label.signUp")}</p>
                                </button>
                            </HeaderRight>
                        </HeaderContainer>
                        <div style={{ padding: "16px" }}>
                            <h2 style={{ color: "#383743", fontSize: "32px", fontWeight: 600, marginBottom: 40 }}>{t("page.menu.login.button.login")}</h2>
                            <div style={{ marginBottom: "24px" }}>
                                {/* <button style={isLoginPhone ? {backgroundColor:"#5F5C70",borderRadius:"16px",padding:"5px 12px 5px 12px",border:"none",color:"#F4F4F6",fontSize:"14px",marginRight:"20px"}: {color:"#8F8DA2",fontSize:"14px",border:"none",backgroundColor:"transparent",marginRight:"20px"}} onClick={()=>{setIsLoginPhone(true)}}>手機</button> */}
                                {/* <button style={!isLoginPhone ? {backgroundColor:"#5F5C70",borderRadius:"16px",padding:"5px 12px 5px 12px",border:"none",color:"#F4F4F6",fontSize:"14px",marginRight:"20px"}: {color:"#8F8DA2",fontSize:"14px",border:"none",backgroundColor:"transparent"}} onClick={()=>{setIsLoginPhone(false)}}>信箱</button> */}
                            </div>
                            {
                                isLoginPhone ?
                                    <div style={{ display: "flex", flexDirection: "row", width: "100%", marginBottom: "24px", justifyContent: "space-between" }}>
                                        <div style={{ width: "30%" }}>
                                            <h2 style={{ color: "#5F5C70", fontSize: "13px", fontWeight: 500, marginBottom: "5px" }}>{t("page.menu.login.label.code")}</h2>
                                            <button style={{ width: "100%", padding: "16px", backgroundColor: "#F4F4F6", fontSize: "15px", border: "none", borderRadius: "4px" }}>+886</button>
                                        </div>
                                        <div style={{ width: "60%" }}>
                                            <h2 style={{ color: "#5F5C70", fontSize: "13px", fontWeight: 500, marginBottom: "5px" }}>{t("page.menu.login.label.mobile")}</h2>
                                            <input
                                                placeholder={t("page.menu.login.label.mobile")}
                                                style={{
                                                    width: "100%",
                                                    padding: "13px",
                                                    backgroundColor: "#F4F4F6",
                                                    fontSize: "15px",
                                                    border: "none",
                                                    borderRadius: "4px"
                                                }}
                                                onChange={(event) => {
                                                    setUsername(event.target.value)
                                                }}
                                            />
                                        </div>
                                    </div>
                                    :
                                    <div style={{ marginBottom: "24px" }}>
                                        <h2 style={{ color: "#5F5C70", fontSize: "13px", fontWeight: 500, marginBottom: "5px" }}>{t("page.menu.login.label.email")}</h2>
                                        <input
                                            placeholder={t("page.menu.login.placeholder.email")}
                                            style={{
                                                width: "100%",
                                                padding: "13px",
                                                backgroundColor: "#F4F4F6",
                                                fontSize: "15px",
                                                border: "none",
                                                borderRadius: "4px"
                                            }}
                                            onChange={(event) => {
                                                setUsername(event.target.value)
                                            }}
                                        />
                                    </div>

                            }
                            <div style={{ marginBottom: "48px" }}>
                                <h2 style={{ color: "#5F5C70", fontSize: "13px", fontWeight: 500, marginBottom: "5px" }}>{t("page.menu.login.label.password")}</h2>
                                <PasswordInput style={{ padding: 9 }} placeholder={t("page.menu.login.placeholder.password")} onChange={event => setPassword(event.target.value)} />
                            </div>
                            <BaseButton
                                color="light-green"
                                style={{ width: "100%", height: 50 }}
                                onClick={() => {
                                    setLoading(true)
                                    const loginRequest = {
                                        account: username,
                                        password: password
                                    } as LoginRequest
                                    AuthService.login(loginRequest).then((resp) => {
                                        dispatch(userActions.setToken(resp.data.token));
                                    }).then(() => {
                                        dispatch(fetchUser())
                                    }).then(() => {
                                        dispatch(fetchOtcUser(username))
                                    }).then(() => {
                                        checkSecurity()
                                    })
                                        .catch((error) => {
                                            alert(error.response.data.msg)
                                        })
                                        .finally(() => {
                                            setLoading(false)
                                        })
                                }}
                            >
                                {t("page.menu.login.button.login")}
                            </BaseButton>
                            <div style={{ textAlign: "center", padding: 15, marginBottom: 25 }} onClick={() => setIsLoginPhone((prev) => !prev)}>{t(`page.menu.login.label.${isLoginPhone ? "emailLogin" : "phoneLogin"}`)}</div>
                            <button style={{ backgroundColor: "transparent", fontSize: "14px", border: "none", color: "#8F8DA2", marginTop: "10px" }} onClick={() => { stackNavigate("/forget") }}>{t("page.menu.login.label.forgetPass")}？</button>
                        </div>
                    </div>
                }
            </div>
        </LoadingOverlay>

    );
};

export default Login;
