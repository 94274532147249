import {
    useState,
    useEffect,
    useCallback
} from 'react';
// ^ Types
import {
    HotShowData,
    QuoteUnit,
    FavObj
} from '@/constants/type'

// ? Self-packed Components || Functions
import {
    CtrlBar,
    CtrlBarBtn,
    BaseNavContainer,
    BaseNavItem
} from '@/styled-components';
import Footer from '@/components/footer/HomeFooter';
import FavoritesListContent from './FavoritesListContent';
import FundFuturesContent from './FundFuturesContent';
import { handleLastPrice } from '@/common/methods';
import BaseSearch from '@/components/BaseSearch';

// ^ Plugins
import { useTranslation } from 'react-i18next';
import {
    useLocation
} from 'react-router-dom';
import { isEmpty, get } from 'lodash';
import { useAppSelector } from '@/hooks';
import { infoService, investorService } from '@/services';
import { COLORS } from '@/constants/colors';

/**
 * @author odin
 * @level Layout/Quote
 * @description 行情頁面
*/
const Quote = () => {

    // $ init data
    const { t } = useTranslation();
    const location = useLocation().pathname;
    const passActiveName = useLocation().state as string

    const market = useAppSelector((state) => state.market.price)
    const baseType = ["baseUSDT", "baseUSDC", "baseToken"]
    const [selectedBase, setSelectedBase] = useState("baseUSDT");

    // # state
    // 目前 active 的是誰(favoritesList: 自選 | fundFutures: 合約)
    const [activeName, setActiveName] = useState<string>(passActiveName ?? 'favoritesList');

    // 自選 - 我的最愛列表
    const [favoriteList, setFavoriteList] = useState<string[] | []>([]);

    // 自選 - 我的最愛列表 - 跟 marketObj 處理後的資料
    const [favMarketList, setFavMarketList] = useState<Array<QuoteUnit> | any>([]);

    // 自選要顯示的列表
    const [favShowList, setFavShowList] = useState<Array<QuoteUnit> | any>([]);

    // 自選搜尋文字
    const [favoritesListSearchText, setFavoritesListSearchText] = useState<string>('');

    const [favObj, setFavObj] = useState<FavObj>({})

    // 合約 - 所有交易對列表
    const [fundList, setFundList] = useState<string[]>(['']);

    // 合約 - 跟 marketObj 處理後的資料
    const [fundMarketList, setFundMarketList] = useState<Array<HotShowData> | any>([]);

    // 合約 - 搜尋文字
    const [fundFuturesSearchText, setFundFuturesSearchText] = useState<string>('');

    // 合約 - 要顯示的列表
    const [fundShowList, setFundShowList] = useState<Array<HotShowData> | any>([]);

    // - methods
    /**
     * @author odin
     * @description 取得我的最愛列表並且 setState
    */
    const handleGetFavoriteList = useCallback(async () => {
        investorService.getFavoriteSymbol().then((response) => {
            setFavoriteList(response.data);
        }).catch(() => {
        })
    }, []);

    /**
     * @author odin
     * @description 取得我的最愛列表並且 setState
    */
    const handleGetFundList = useCallback(async () => {
        infoService.getSymbol().then((response) => {
            setFundList(response.data)
        }).catch(() => {
        })
    }, []);

    /**
     * @author odin
     * @description 取得兩邊的資料更新
    */
    const getQuoteData = useCallback(() => {
        handleGetFavoriteList();
        handleGetFundList();
    }, [handleGetFavoriteList, handleGetFundList]);

    /**
     * @author odin
     * @param {string} dealName - 交易對的名稱
     * @description 加入我的最愛
    */
    const handleAddFav = useCallback(async (dealName: string) => {
        investorService.addFavoriteSymbol({ symbol: dealName }).then(() => {
            getQuoteData();
        }).catch((error) => {
            alert(error.response.data.msg)
        })
    }, [getQuoteData]);

    /**
     * @author odin
     * @param {string} dealName - 交易對的名稱
     * @description 移除我的最愛
    */
    const handleRemoveFav = useCallback(async (dealName: string) => {
        investorService.deleteFavoriteSymbol({ symbol: dealName }).then(() => {
            getQuoteData();
        }).catch((error) => {
            alert(error.response.data.msg)
        })
    }, [getQuoteData]);

    /**
     * @author odin
     * @description 決定目前是要顯示哪一個 Tab Content
     * @return {ReactNode} 要輸出的 Component
    */
    const renderQuoteContent = () => {
        switch (activeName) {
            case 'favoritesList':
                return <FavoritesListContent
                    showList={favShowList}
                    handleRemoveFav={handleRemoveFav}
                    setActiveName={setActiveName}
                />;

            case 'fundFutures':
                return <FundFuturesContent
                    showList={fundShowList}
                    handleAddFav={handleAddFav}
                    handleRemoveFav={handleRemoveFav}
                />;
        }
    };

    // & handled data
    // 控制 Bar 的按鈕
    const ctrlBarBtns = [
        {
            key: 0,
            name: 'favoritesList',
            text: t('page.market.label.favorites'),
            onClick: () => {
                setActiveName('favoritesList')
            }
        },
        {
            key: 1,
            name: 'fundFutures',
            text: t('page.market.label.contract'),
            onClick: () => {
                setActiveName('fundFutures')
            }
        },
        {
            key: 2,
            name: 'spot',
            text: t('page.market.label.spot'),
            onClick: () => {
                setActiveName('spot')
            }
        },
    ];

    // * hooks
    /**
     * @author odin
     * @description Quote Init
    */
    useEffect(() => {
        getQuoteData();
    }, [getQuoteData]);

    /**
     * @author odin
     * @description 根據 market 的資料重組需要的資料格式
    */
    useEffect(() => {
        // const favMarketResult : Array<QuoteUnit> = ['AAVE-USDT', 'AAV2-USDT'].map((name, index) => {
        const favMarketResult: Array<QuoteUnit> | any = favoriteList.map((name, index) => {

            const pairObj = market.find((m) => m.s === name)

            // console.log('name', name);
            // console.log('pairObj', pairObj);

            return {
                key: index,
                name: name,
                lastPrice: pairObj ? handleLastPrice(pairObj.c) : '0',
                percentageIn24H: pairObj ? pairObj.P : '0',
                isFav: true,
                volume: pairObj?.v
            }
        });

        // console.log('favMarketResult', favMarketResult);

        const favObjResult: FavObj = favMarketResult.reduce((acc, cur) => {
            return {
                ...acc,
                [cur.name]: true
            }
        }, {});

        // console.log('favObjResult', favObjResult);

        setFavMarketList(favMarketResult);
        setFavObj(favObjResult);
    }, [favoriteList, market]);

    /**
     * @author odin
     * @description 過濾 - 自選要顯示的列表
    */
    useEffect(() => {
        const result: Array<HotShowData> | any = favMarketList.filter(({ name }: any) => {
            return name.includes(favoritesListSearchText);
        });

        setFavShowList(result);
    }, [favMarketList, favoritesListSearchText]);

    /**
     * @author odin
     * @description 根據 market 的資料重組需要的資料格式
    */
    useEffect(() => {
        if (isEmpty(fundList)) return;

        const fundMarketResult: Array<HotShowData> | any = fundList.map((name, index) => {
            const pairObj = market.find((m) => m.s === name)
            return {
                key: index,
                name: name,
                lastPrice: pairObj ? handleLastPrice(pairObj.c) : '0',
                percentageIn24H: pairObj ? pairObj.P : '0',
                isFav: get(favObj, `${name}`, false),
                volume: pairObj?.v
            }
        }).filter((symbol) => symbol.lastPrice !== '0');

        setFundMarketList(fundMarketResult);
    }, [fundList, favObj, market]);

    /**
     * @author odin
     * @description 做過 - 自選要顯示的列表
    */
    useEffect(() => {
        if (isEmpty(fundMarketList)) return;

        const result: Array<HotShowData> | any = fundMarketList.filter(({ name }: any) => {
            return name.includes(fundFuturesSearchText);
        });

        setFundShowList(result);
    }, [fundMarketList, fundFuturesSearchText]);

    return (
        <>
            {/* 合約頁面 */}
            <div>
                <BaseSearch
                    searchText={activeName === "favoritesList" ? favoritesListSearchText : fundFuturesSearchText}
                    setSearchText={activeName === "favoritesList" ? setFavoritesListSearchText : setFundFuturesSearchText}
                />
            </div>

            {/* 控制 Bar */}
            <CtrlBar>
                {
                    !isEmpty(ctrlBarBtns) && (
                        ctrlBarBtns.map(item => (
                            <CtrlBarBtn
                                key={`ctr-${item.key}`}
                                active={item.name === activeName}
                                onClick={item.onClick}
                            >
                                {item.text}
                            </CtrlBarBtn>
                        ))
                    )
                }
            </CtrlBar>

            {
                activeName !== "spot" &&
                <BaseNavContainer>
                    {
                        baseType.map((baseType) => {
                            return (
                                <BaseNavItem active={selectedBase === baseType} onClick={(e) => { e.stopPropagation(); setSelectedBase(baseType) }}>{t(`page.market.label.${baseType}`)}</BaseNavItem>
                            )
                        })
                    }
                </BaseNavContainer>
            }
            <div style={{ width: "100%", padding: 10 }}>
                <table style={{ width: "100%", borderCollapse: "separate", borderSpacing: "0px 10px" }}>
                    <thead>
                        <tr>
                            <th style={{ fontSize: 12, color: COLORS.Mid_gray, width: "40%", textAlign: "left" }}>{t("page.home.label.marketPair")}</th>
                            <th style={{ fontSize: 12, color: COLORS.Mid_gray, width: "30%", textAlign: "right" }}>{t("page.home.label.lastPrice")}</th>
                            <th style={{ fontSize: 12, color: COLORS.Mid_gray, width: "30%", textAlign: "right" }}>{t(`page.home.label.24Hchg`)}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {selectedBase === "baseUSDT" && renderQuoteContent()}
                    </tbody>
                </table>
            </div>
            {/* 內容 */}
            <Footer locationPage={location} />
        </>
    );
};

export default Quote;
