import { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { userService } from "@/services";
import { Notification } from "@/interfaces/exchange.interface";
import CommonPage from "@/components/LayoutPage";
import CustomListItem from "@/components/List";
import { COLORS } from "@/constants/colors";
import MessageRead from "@/assets/icon/message-read.svg";
import Message from "@/assets/icon/message.svg";
import { Modal } from "antd";
import { Text } from "@/components/typography/Text";
import { formatDateTime } from "@/utils/parse";
import { Header } from "@/components/typography/Header";

export const MessageBox = () => {
    // $ init data
    const { t } = useTranslation();

    // # state
    const [, setCurNotice] = useState<Notification>({} as Notification);
    const [noticeList, setNoticeList] = useState<Notification[]>([]);
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState<Notification>({} as Notification);

    const showModal = (notification: Notification) => {
        setSelected(notification)
        setOpen(true);
    };

    const hideModal = () => {
        setOpen(false);
    };

    const getNotifications = useCallback(()=>{
        userService.getNotification().then((response) => {
            setNoticeList(response.data);
            setCurNotice(response.data[0]);
        });
    },[])
    useEffect(() => {
        getNotifications()
    }, [getNotifications]);

    return (
        <CommonPage title={""} returnPath={-1}>
            <div style={{ fontSize: 24, fontWeight: 700, width: "100%", padding: 20 }}>{t("page.menu.messageBox.title")}</div>
            {
                noticeList.map((notification) => {
                    return (
                        <CustomListItem
                            key={notification.id}
                            style={{ padding: "10px 15px", borderBottom: `1px solid ${COLORS.EXLight_gray}`, alignItems: "flex-start" }}
                            content={
                                <div style={{display: "flex", flexDirection: "column", flexGrow: 1}}>
                                    <Header size="xs">{notification.title}</Header>
                                    <Text size="xs">{notification.content}</Text>
                                    <Text size="xs">{formatDateTime(notification.createdDate)}</Text>
                                </div>
                            }
                            leftIcon={<img src={notification.isRead ? MessageRead : Message} alt="message" style={{ width: 20, padding: "10px 0px" }} />}
                            onClick={()=> {
                                userService.readNotification(notification.id).then(()=>{
                                    showModal(notification)
                                    getNotifications()
                                })
                            }}
                        />
                    );
                })
            }
            <Modal
                visible={open}
                footer={null}
                title={selected.title}
                onCancel={hideModal}
            >
                <div style={{ display: "flex", justifyContent: "space-around" }}>
                    <Text size="md">{selected.content}</Text>
                </div>
            </Modal>
        </CommonPage>
    );
};

export default MessageBox;
