import styled from "styled-components";
import TitleHeader from "@/components/header/TitleHeader";
import { CSSProperties, ReactNode } from "react";
import LoadingOverlay from 'react-loading-overlay-ts';
import Footer from "../footer/HomeFooter";
import RenderCounter from "../render-counter";
import { NavigateOptions, useLocation } from "react-router-dom";

const PageContainer = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Inside = styled.div`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  flex: 1;
  height: 95vh;
  align-items: center;
  width: 100%;
`;

const CommonPage: React.FC<{
    title: string | ReactNode
    returnPath?: string | -1
    returnOption?: NavigateOptions
    active?: boolean
    footer?: string
    style?: CSSProperties
    headerStyle?: CSSProperties
    children: ReactNode
    option?: ReactNode
}> = ({
    title,
    returnPath,
    returnOption,
    active,
    footer,
    style,
    headerStyle,
    children,
    option
}) => {
        const location = useLocation().pathname
        return (
            <LoadingOverlay
                active={active}
                spinner
            >
                <PageContainer>
                    <RenderCounter name={location} debug={false}/>
                    <TitleHeader 
                        title={title} 
                        returnPath={returnPath} 
                        returnOption={returnOption}
                        option={option} 
                        headerStyle={headerStyle}
                    />
                    <Inside style={style}>
                        {children}
                    </Inside>
                    {
                        footer && <Footer locationPage={footer} />
                    }
                </PageContainer>
            </LoadingOverlay>
        );
    };

export default CommonPage;
