import { Announcement, Carousal, Marquee } from "@/interfaces/exchange.interface";
import { GetAnnouncementRequest } from "@/interfaces/request.interface";
import { ExchangeResponse } from "@/interfaces/response.interface";
import http from "@/utils/http-common";

class InfoService {
    static serviceApiPrefix = "/info"

    getCarousals(lang: string) {
        return http.get<any, ExchangeResponse<Carousal[]>>(`${InfoService.serviceApiPrefix}/carousel?lang=${lang}`);
    }

    getAnnouncements(request: GetAnnouncementRequest){
        let queryParams = `?lang=${request.lang}`
        if (request.topic!==undefined) queryParams = `${queryParams}&topic=${request.topic}`
        return http.get<any, ExchangeResponse<Announcement[]>>(`${InfoService.serviceApiPrefix}/announcement${queryParams}`);
    }

    getAnnouncement(id: string){
        return http.get<any, ExchangeResponse<Announcement>>(`${InfoService.serviceApiPrefix}/announcement/${id}`);
    }

    getMarquees(lang: string){
        return http.get<any, ExchangeResponse<Marquee[]>>(`${InfoService.serviceApiPrefix}/marquee?lang=${lang}`);
    }

    getSymbol(isHot?: boolean){
        let queryParams = ""
        if ( isHot !==undefined ){
            queryParams = `?isHot=${isHot}`
        }
        return http.get<any, ExchangeResponse<string[]>>(`${InfoService.serviceApiPrefix}/future/symbol${queryParams}`);
    }

    getFiatCurrency() {
        return http.get<any, ExchangeResponse<Map<string, number>>>(`${InfoService.serviceApiPrefix}/fiat-currency`);
    }
}

export default new InfoService();