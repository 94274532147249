import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import Drawer from '@/components/UI/Drawer'
import QRCode from 'qrcode.react';
import { useTranslation } from "react-i18next";
import { investorService } from "@/services";
import CommonPage from "@/components/LayoutPage";
import { CopyOutlined } from "@ant-design/icons";

const Container = styled.div`
  padding: 16px 0 16px 0;
  height:65px;
  width:100%;
  margin:auto;
  background-color:white;
`;

const TextInfo = styled.button`
    width: 100%;
    background-color: #F4F4F6;
    border: none;
    border-radius: 4px;
    padding: 16px 10px;
    margin-top: 10px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const DepositSpot = () => {
    const search = useLocation().search
    const [currency, setCurrency] = useState("")
    const [isDrawer, setIsDrawer] = useState(false)
    const [address, setAddress] = useState("")
    const { t } = useTranslation();
    function getWallet() {
        investorService.getWallet().then((response) => {
            setAddress(response.data)
        })
    }
    function copyToClipboard(text) {
        var textField = document.createElement('textarea')
        textField.innerText = text
        document.body.appendChild(textField)
        textField.select()
        document.execCommand('copy')
        textField.remove()
    }
    useEffect(() => {
        setCurrency(search.split("=")[1])
        getWallet()
    }, [search])
    return (
        <CommonPage title={t("page.fund.title.deposit")} returnPath={-1}>
            <div style={{ flex: 1 }}>
                <Container>
                    <div style={{ backgroundColor: "white", paddingBottom: "10px" }}>
                        <div style={{ textAlign: "center" }}>
                            <QRCode
                                id="bill_qr_code_url"
                                value={address} //value引數為生成二維碼的連結 我這裡是由後端返回
                                size={140} //二維碼的寬高尺寸
                                fgColor="#000000"  //二維碼的顏色
                            />
                            <p style={{ color: "#8F8DA2", fontSize: "13px", fontWeight: 500, marginTop: "10px" }}>{t("page.fund.notice.onlyUsdt")}</p>
                        </div>
                        <div style={{ textAlign: "center", width: "100%", margin: "auto", marginTop: "15px", flex: 1 }}>
                            <h2 style={{ textAlign: "left", color: "#5F5C70", fontSize: "13px", fontWeight: 500 }}>{t("page.fund.label.network")}</h2>
                            <TextInfo>
                                <p style={{ float: "left" }}>TRC (TRC20)</p>
                            </TextInfo>
                            <h2 style={{ textAlign: "left", color: "#5F5C70", fontSize: "13px", fontWeight: 500, marginTop: "15px" }}>{currency}{t("page.fund.input.depositAddress")}</h2>
                            <TextInfo  onClick={() => {
                                copyToClipboard(address)
                                alert(t("page.fund.notice.copied"))
                            }}>
                                <p style={{ width: "70%", flexWrap: "wrap", fontSize: "14px" }}>{address}</p>
                                <CopyOutlined style={{ float: "right", marginTop: "-5px" }} />
                            </TextInfo>
                        </div>
                    </div>
                    <div style={{ padding: "16px", backgroundColor: "#F4F4F6" }}>
                        <h2 style={{ color: "#383743", fontSize: "16px", fontWeight: 600 }}>{t("page.fund.label.deposit.info")}</h2>
                        <div style={{ display: "flex", justifyContent: "space-between", flexDirection: "row", marginTop: "20px" }}>
                            <p style={{ color: "#8F8DA2", fontSize: "13px", fontWeight: 500 }}>{t("page.fund.label.deposit.minimum")}</p>
                            <p style={{ color: "#383743", fontSize: "15px", fontWeight: 400 }}>0.00000001 {currency}</p>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between", flexDirection: "row", marginTop: "20px" }}>
                            <p style={{ color: "#8F8DA2", fontSize: "13px", fontWeight: 500 }}>{t("page.fund.label.deposit.expectedReceived")}</p>
                            <p style={{ color: "#383743", fontSize: "15px", fontWeight: 400 }}>1 {t("page.fund.label.deposit.blockConfirm")}</p>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between", flexDirection: "row", marginTop: "20px" }}>
                            <p style={{ color: "#8F8DA2", fontSize: "13px", fontWeight: 500 }}>{t("page.fund.label.deposit.expectArriveBlock")}</p>
                            <p style={{ color: "#383743", fontSize: "15px", fontWeight: 400 }}>2 {t("page.fund.label.deposit.blockConfirm")}</p>
                        </div>
                    </div>
                </Container>
                <Drawer isVisible={isDrawer} selectVisible={() => { }} height={270}>
                    <div>
                        <h2 style={{ textAlign: "center", color: "#383743", fontSize: "16px", fontWeight: 600 }}>{t("page.fund.label.network")}</h2>
                    </div>
                    <button style={{ height: "35px", marginBottom: "10px", border: "none", borderBottom: "1px solid #F4F4F6", width: "100%", backgroundColor: "transparent" }} onClick={() => {
                        setIsDrawer(false)
                    }}>
                        <p style={{ marginLeft: "10px", color: "#BDBCC8", fontSize: "14px", fontWeight: 600 }}>Ethereum</p>
                    </button>
                    <button style={{ height: "35px", marginBottom: "10px", border: "none", borderBottom: "1px solid #F4F4F6", width: "100%", backgroundColor: "transparent" }} onClick={() => {
                        setIsDrawer(false)
                    }}>
                        <p style={{ marginLeft: "10px", color: "#BDBCC8", fontSize: "14px", fontWeight: 600 }}>BTC</p>

                    </button>
                    <button style={{ height: "35px", marginBottom: "10px", border: "none", borderBottom: "1px solid #F4F4F6", width: "100%", backgroundColor: "transparent" }} onClick={() => {
                        setIsDrawer(false)
                    }}>
                        <p style={{ marginLeft: "10px", color: "#BDBCC8", fontSize: "14px", fontWeight: 600 }}>Bitcoin Chain</p>

                    </button>
                    <button style={{ height: "35px", marginBottom: "10px", border: "none", width: "100%", backgroundColor: "transparent" }} onClick={() => {
                        setIsDrawer(false)
                    }}>
                        <p style={{ marginLeft: "10px", color: "#BDBCC8", fontSize: "14px", fontWeight: 600 }}>Bitcoin SmartChain</p>

                    </button>
                </Drawer>
            </div>
        </CommonPage>
    );
};

export default DepositSpot