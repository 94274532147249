import styled from "styled-components";
import { useState } from "react"
import useStackNavigate from "@/hooks/useStackNavigate";
import { useTranslation } from "react-i18next";
import CommonPage from "@/components/LayoutPage";
import { userService } from "@/services";
import PasswordInput from "@/components/UI/PasswordInput";
import { BaseButton } from "@/components/Button";
import { Text } from "@/components/typography/Text";
import { Header } from "@/components/typography/Header";

const AreaSpace = styled.div`
  padding: 10px;
  margin: 10px 0px;
  background: #fff ;
  opacity: 0.6;
  border-radius: 4px;
  background:#F4F4F6;
`;

const AreaContent = styled.div`
  display:flex;
  justify-content: space-between;
`;

const FormBox = styled.div`
    width: 100%;
    margin-top: 20px;
    padding: 0px 15px;
`

const SetTheFundPassword = () => {
    const [password, setPassword] = useState("")
    const [fundPassword, setFundPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const { stackNavigate } = useStackNavigate()
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();
    return (
        <CommonPage active={loading} title={t("page.setFundPassword.title")} returnPath={-1}>
            <FormBox>
                <Header size={"sm"}>{t("page.setFundPassword.input.password")}</Header>
                <AreaSpace>
                    <AreaContent>
                        <PasswordInput
                            placeholder={t("page.setFundPassword.placeholder.password")}
                            onChange={(event) => {
                                setPassword(event.target.value)
                            }}
                        />
                    </AreaContent>
                </AreaSpace>

                <Header size={"sm"}>{t("page.setFundPassword.input.fundPassword")}</Header>
                <AreaSpace>
                    <AreaContent>
                        <PasswordInput
                            placeholder={t("page.setFundPassword.placeholder.fundPassword")}
                            onChange={(event) => {
                                setFundPassword(event.target.value)
                            }}
                        />
                    </AreaContent>
                </AreaSpace>

                <Header size={"sm"}>{t("page.setFundPassword.input.checkPassword")}</Header>
                <AreaSpace>
                    <AreaContent>
                        <PasswordInput placeholder={t("page.setFundPassword.placeholder.checkPassword")}
                            onChange={(event) => {
                                setConfirmPassword(event.target.value)
                            }}
                        />
                    </AreaContent>
                </AreaSpace>

                <BaseButton
                    style={{ width: "100%", height: 40, marginTop: 15 }}
                    onClick={() => {
                        if (!password) {
                            alert(t("page.setFundPassword.alert.password"))
                        }
                        else if (!fundPassword) {
                            alert(t("page.setFundPassword.alert.fundPassword"))
                        }
                        else if (!confirmPassword) {
                            alert(t("page.setFundPassword.alert.checkPassword"))
                        }
                        else if (fundPassword !== confirmPassword) {
                            alert(t("page.setFundPassword.alert.difference"))
                        }
                        else {
                            setLoading(true)
                            userService.setFinancePassword({
                                originPassword: password,
                                password: fundPassword
                            }).then(() => {
                                alert(t("page.setFundPassword.alert.success"))
                                stackNavigate(-1)
                            }).catch((error) => {
                                alert(error.response.data.msg)
                            }).finally(() => {
                                setLoading(false)
                            })
                        }
                    }
                    }>
                    {t("page.setFundPassword.button.save")}
                </BaseButton>
            </FormBox>
        </CommonPage>
    );
}

export default SetTheFundPassword 
