import { COLORS } from "@/constants/colors";
import { CSSProperties, MouseEventHandler, ReactNode } from "react";
import styled from "styled-components";

const ListContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    padding: 15px;

    &:active {
        background-color: ${COLORS.EXLight_gray};
    }
`;

const IconBox = styled.div`
    padding: 5px;
    width: 10%;
`;

const CenterText = styled.div`
    flex: 1;
    font-weight: 400;
`;

const IconBoxRight = styled(IconBox)`
    font-size: 14px;
    color: ${COLORS.Mid_gray};
    font-weight: 600;
    width: 20%;
    text-align: right;
`

const CustomListItem:React.FC<{
    style?: CSSProperties, 
    leftIcon?: ReactNode, 
    rightIcon?: ReactNode,
    content: string | ReactNode,
    onClick?: MouseEventHandler<HTMLDivElement>
}> = ({style, leftIcon, rightIcon, content, onClick}) => {
    return (
        <ListContainer style={style} onClick={(e)=> onClick && onClick(e)}>
            {
                leftIcon && 
                <IconBox>
                    {leftIcon}
                </IconBox>
            }
            {
                typeof(content)==="string" ? <CenterText>{content}</CenterText> : content
            }
            {
                rightIcon && 
                <IconBoxRight>
                    {rightIcon}
                </IconBoxRight>
            }
        </ListContainer>
    )
}

export default CustomListItem;