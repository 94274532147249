import { useState } from "react";
import styled from "styled-components";
import { COLORS } from "@/constants/colors";
import Cancel from "@/assets/icon/cancel.png";
import LoadingOverlay from 'react-loading-overlay-ts';
import coolIconWhite from '@/assets/home/coolicon-white.png'
import coolIcon from '@/assets/home/coolicon.png'
import useStackNavigate from "@/hooks/useStackNavigate";
import { useTranslation } from "react-i18next";
import { authService } from "@/services";
import { useAppDispatch } from "@/hooks";
import { registerActions } from "@/store/slice";
import { RegisterRequest } from "@/interfaces/request.interface";
import PasswordInput from "@/components/UI/PasswordInput";
import { useParams } from "react-router-dom";

const HeaderContainer = styled.header`
  background-color: ${COLORS.White};
  height: 44px;
`;

const HeaderLeft = styled.div`
  float: Left;
  display: flex;
  align-items: center;
  height: 44px;
`;
const HeaderRight = styled.div`
  float: Right;
  display: flex;
  align-items: center;
  height: 44px;
`;
const SelectIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-left: 7px;
`;

const Register = () => {
    // const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [username, setUsername] = useState("")
    const [emailValidMsg ,setEmailValidMsg] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [registerPassword, setRegisterPassword] = useState("")
    const [loading, setLoading] = useState(false)
    const [read, setIsRead] = useState(false)
    const [emailVerify, setEmailVerify] = useState(false)
    const [promoCode, setPromoCode] = useState("")
    let { inviteCode } = useParams();
    const { t } = useTranslation();
    const { stackNavigate } = useStackNavigate()

    function isValidEmail(email: string): boolean {
        if ( email==="" ){
            setEmailValidMsg("")
            return false;
        }
        // 正規表示式來匹配大多數電子信箱格式
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if ( !regex.test(email) ){
            setEmailValidMsg(t("page.menu.register.alert.emailFormat"))
            return false
        }
        else if (email.length<8){
            setEmailValidMsg(t("page.menu.register.alert.emailLength"))
            return false
        }
        else {
            setEmailValidMsg("")
            return true
        }
    }

    return (
        <LoadingOverlay
            active={loading}
            spinner
        >
            <div style={{ height: "100vh" }}>
                <div>
                    <HeaderContainer>
                        <HeaderLeft>
                            <SelectIcon
                                style={{ width: 28, height: 28 }}
                                src={Cancel}
                                alt="cancel"
                                onClick={() => { stackNavigate("/member") }}
                            />
                        </HeaderLeft>
                        <HeaderRight>
                            <button style={{ color: COLORS.Primary, fontSize: "16px", border: "none", backgroundColor: "transparent", fontWeight: 600 }} onClick={() => {
                                stackNavigate("/login")
                            }}>
                                <p>{t("page.menu.register.button.login")}</p>
                            </button>
                        </HeaderRight>
                    </HeaderContainer>
                    <div style={{ padding: "16px" }}>
                        <h2 style={{ color: "#383743", fontSize: "32px", fontWeight: 600, marginBottom: "30px" }}>{t("page.menu.register.title.signUp")}</h2>
                        <div style={{ marginBottom: "24px" }}>
                            <h2 style={{ color: "#5F5C70", fontSize: "13px", fontWeight: 500, marginBottom: "5px" }}>{t("page.menu.register.label.email")}</h2>
                            <input placeholder={t("page.menu.register.placeholder.email")} style={{ width: "100%", padding: "13px", backgroundColor: "#F4F4F6", fontSize: "15px", border: "none", borderRadius: "4px" }} onChange={
                                event => {
                                    setEmailVerify(true)
                                    setUsername(event.target.value)
                                    if ( isValidEmail(event.target.value) ){
                                        authService.checkAccount({ account: event.target.value }).then((response) => {
                                            setEmailVerify(true)
                                        }).catch(() => {
                                            setEmailVerify(false)
                                        }).finally(() => {
                                            setLoading(false)
                                        })
                                    }
                                }
                            } />
                            {
                                (username.length > 8 && !emailVerify) && <p style={{ color: COLORS.Primary, fontSize: "12px", marginTop: "3px" }}>{t("page.menu.register.alert.alreadySignUp")}</p>
                            }
                            {
                                emailValidMsg!=="" && <p style={{ color: COLORS.Primary, fontSize: "12px", marginTop: "3px" }}>{emailValidMsg}</p>
                            }

                        </div>
                        <div style={{ marginBottom: "38px" }}>
                            <h2 style={{ color: "#5F5C70", fontSize: "13px", fontWeight: 500, marginBottom: "5px" }}>{t("page.menu.register.label.password")}</h2>
                            <PasswordInput
                                placeholder={t("page.menu.register.placeholder.password")}
                                style={{ padding: 9 }}
                                onChange={
                                    event => {
                                        setRegisterPassword(event.target.value)
                                    }
                                }
                            />
                            {registerPassword.length !== 0 && registerPassword.length < 8 &&
                                <p style={{ color: COLORS.Primary, fontSize: "12px", marginTop: "3px" }}>{t("page.menu.register.alert.passwordLength")}</p>
                            }
                        </div>
                        <div style={{ marginBottom: "38px" }}>
                            <h2 style={{ color: "#5F5C70", fontSize: "13px", fontWeight: 500, marginBottom: "5px" }}>{t("page.menu.register.label.passConfirm")}</h2>
                            <PasswordInput
                                placeholder={t("page.menu.register.placeholder.passConfirm")}
                                style={{ padding: 9 }}
                                onChange={
                                    event => {
                                        setConfirmPassword(event.target.value)
                                    }
                                }
                            />
                            {registerPassword && confirmPassword && registerPassword !== confirmPassword &&
                                <p style={{ color: COLORS.Primary, fontSize: "12px", marginTop: "3px" }}>{t("page.menu.register.alert.notSame")}</p>
                            }
                        </div>
                        <div style={{ marginBottom: "28px" }}>
                            <h2 style={{ color: "#5F5C70", fontSize: "13px", fontWeight: 500, marginBottom: "5px" }}>{t("page.menu.register.label.referralCode")}</h2>
                            <input
                                placeholder={t("page.menu.register.placeholder.optional")}
                                defaultValue={inviteCode ?? ""}
                                style={{
                                    width: "100%",
                                    padding: "13px",
                                    backgroundColor: "#F4F4F6",
                                    fontSize: "15px",
                                    border: "none",
                                    borderRadius: "4px"
                                }}
                                onChange={event => {
                                    setPromoCode(event.target.value)
                                }}
                            />
                        </div>
                        <div style={{ display: "flex", justifyContent: "center", marginBottom: "20px", alignItems: "center" }}>
                            <button style={{ border: "none", backgroundColor: "transparent" }}><img src={read ? coolIcon : coolIconWhite} alt="" style={{ width: "15px", height: "15px", marginTop: "2px" }} onClick={() => {
                                setIsRead(!read)
                            }} /></button>
                            <p style={{ color: "#5F5C70", fontSize: "12px" }}>{t("page.menu.register.label.iAgree")} <span style={{ color: "#A60008", fontSize: "12px" }} onClick={() => { stackNavigate("/term-of-service") }}>{t("page.menu.register.label.serviceRule")}</span></p>
                        </div>
                        <button style={{ width: "100%", padding: "12px", backgroundColor: COLORS.Primary, fontSize: "14px", border: "none", borderRadius: "4px", color: "white" }} onClick={() => {
                            if (!username) {
                                alert(t("page.menu.register.alert.email"))
                            } else if (!isValidEmail(username)) {
                                alert(emailValidMsg)
                            } else if (!registerPassword) {
                                alert(t("page.menu.register.alert.password"))
                            } else if (registerPassword.length < 8) {
                                alert(t("page.menu.register.alert.passwordLength"))
                            } else if (registerPassword !== confirmPassword) {
                                alert(t("page.menu.register.alert.notSame"))
                            } else if (!read) {
                                alert(t("page.menu.register.alert.agree"))
                            } else if (!emailVerify) {
                                alert(t("page.menu.register.alert.alreadySignUp"))
                            } else {
                                setLoading(true)
                                const registerRequest: RegisterRequest = {
                                    account: username,
                                    password: registerPassword,
                                    password2: confirmPassword,
                                    inviteCode: promoCode
                                }
                                authService.sendEmail({ email: username }).then(() => {
                                    dispatch(registerActions.setRequest(registerRequest))
                                    stackNavigate("/emailVerify")
                                }).finally(() => {
                                    setLoading(false)
                                })
                            }
                        }}>{t("page.menu.register.button.signUp")}</button>
                    </div>
                </div>
            </div>
        </LoadingOverlay>

    );
};

export default Register;
