import { useEffect, useState } from "react"
import styled from "styled-components";
import { COLORS } from "@/constants/colors";
import { useTranslation } from "react-i18next";
import useStackNavigate from "@/hooks/useStackNavigate";
import { Carousel } from 'antd';
import { Announcement } from "@/interfaces/exchange.interface";
import { infoService } from "@/services";
import { useAppSelector } from "@/hooks";
import CommonPage from "@/components/LayoutPage";
import CustomCard from "@/components/CustomCard";
import { formatDateTime } from "@/utils/parse";

const Title = styled.h1`
    font-weight: 600;
    font-size: 24px;
    margin: 10px;
`;

const SubTitle = styled.div`
    font-weight: 600;
    font-size: 16px;
    color: ${COLORS.Dark_gray};;
    margin: 30px 10px;
`;

const CardContainer = styled.div`
  width: 100%;
  background: #fff;
  display: flex;
  justify-content: space-between;
  padding: 5px 15px;
  margin-bottom: 10px;
`;

const CardTitle = styled.p`
    display: inline;
    font-weight: 700;
    margin-bottom: 3px;
    line-height: 25px;
`;

const CardContent = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: ${COLORS.Mid_gray};
  flex: 1;
`;

const CardFooter = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: ${COLORS.Mid_gray};
  text-align: right;
`;

const InstantTrend = () => {
    const { t } = useTranslation();
    const language = useAppSelector((state) => state.user.language)
    const { stackNavigate } = useStackNavigate()
    const [imgArr, setImgArr] = useState<string[]>([]);
    const [announcements, setAnnouncements] = useState<Announcement[]>([])
    useEffect(() => {
        infoService.getAnnouncements({ topic: "NEWS", lang: language }).then((response) => {
            const latest = response.data.sort((a, b) => b.createdDate - a.createdDate).slice(0, 8);
            setAnnouncements(latest)
            setImgArr(latest.map((announcement)=> announcement.thumbnail));
        })
    }, [language]);

    return (
        <CommonPage title={""} returnPath={-1} headerStyle={{ backgroundColor: COLORS.Primary }}>
            <CustomCard colors={[COLORS.Primary, COLORS.White]} style={{ padding: "10px 0px 25px 0px", display: "flex", justifyContent: "center", marginBottom: 10, borderRadius: 0 }}>
                <Title>{t("page.instantTrend.label.title")}</Title>
                <SubTitle>{t("page.instantTrend.label.subtitle")}</SubTitle>
            </CustomCard>
            <div style={{ width: "100%", padding: "0px 15px", marginBottom: 20 }}>
                <div style={{ fontSize: 16, fontWeight: 700, marginBottom: 20 }}>{t("page.instantTrend.label.spotlight")}</div>
                <Carousel autoplay={true}>
                    {imgArr.map((img, index) => {
                        return (
                            <img
                                key={index}
                                src={img}
                                style={{ width: "100%", borderRadius: 8 }}
                                alt=""
                            />
                        );
                    })}
                </Carousel>
            </div>
            <div style={{ fontSize: 16, fontWeight: 700, marginBottom: 20, width: "100%", padding: "0px 15px" }}>{t("page.instantTrend.label.new")}</div>
            {announcements.map((announcement) => {
                return (
                    <CardContainer onClick={() => stackNavigate(`/announcement/${announcement.id}`)}>
                        <div style={{ width: "33%" }}>
                            <img
                                src={announcement.thumbnail}
                                alt="icon"
                                style={{ width: "100%" }}
                            />
                        </div>
                        <div style={{ width: "65%", display: "flex", flexDirection: "column", alignContent: "space-between" }}>
                            <CardTitle>{announcement.subject.length>30 ? `${announcement.subject.substring(0,30)} ...` : announcement.subject}</CardTitle>
                            <CardContent dangerouslySetInnerHTML={{
                                __html: announcement.outline,
                            }} />
                            <CardFooter>{formatDateTime(announcement.createdDate)}</CardFooter>
                        </div>
                    </CardContainer>
                );
            })}
        </CommonPage>
    )
}

export default InstantTrend