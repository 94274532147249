import { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next";
// = Styled Component
import { useAppSelector } from "@/hooks";
import { AdvertisementCondition, AdvertisementLevel, RefundApplication } from "@/interfaces/otc.interface";
import { advertisementService, userService } from "@/services";
import CommonPage from "@/components/LayoutPage";
import { Modal, message } from "antd";
import { BaseButton } from "@/components/Button";
import GoogleAuth from "@/assets/icon/google-auth.svg"
import { Header } from "@/components/typography/Header";
import { checkSecurity } from "@/utils";
import useStackNavigate from "@/hooks/useStackNavigate";
import { AxiosError } from "axios";
import { ExchangeResponse } from "@/interfaces/response.interface";

const C2cApply = () => {
    const account = useAppSelector((state) => state.user.detail.account)

    // $ init data
    const { t } = useTranslation();
    const defaultLevel = {
        conditionCompleteAmount: 0,
        conditionCompleteOrders: 0,
        conditionCompleteRate: 0,
        conditionCompleteUsers: 0,
        deposit: 0,
        id: 0,
        isSpecial: false,
        maxAmountPerDay: 0,
        maxAmountPerOrder: 0,
        name: ""
    };

    // # states
    const [advertisementCondition, setAdvertisementCondition] = useState<AdvertisementCondition>({} as AdvertisementCondition);

    const [allLevels, setAllLevels] = useState([
        { ...defaultLevel }
    ]);

    const [currentLevel, setCurrentLevel] = useState({ ...defaultLevel });
    const [refundApplication, setRefundApplication] = useState<RefundApplication | null>();
    const [open, setOpen] = useState("");
    const { stageStackNavigate, stackNavigate } = useStackNavigate()

    // - methods
    const getData = useCallback(() => {
        userService.getRefundApplication(account).then((response)=>{
            setRefundApplication(response);
        })
        userService.getOtcUserInfo(account).then((otcUser)=>{
            advertisementService.getLevels().then((levels)=>{
                setAllLevels(levels.filter(level => !level.isSpecial ).sort((a, b)=> a.deposit-b.deposit));
                if ( otcUser.advertiserLevel ){
                    // levels.filter( level=> otcUser.advertiserLevel?.id===level.id ).forEach( (level,index) => {
                    //     if (index + 1 < levels.length) {
                    //         setCurrentLevel(levels[index + 1]);
                    //         setIndex(index + 1)
                    //     } else {
                    //         setCurrentLevel(levels[index]);
                    //         setIndex(index)
                    //     }
                    // })
                    setCurrentLevel(levels.find( level=> otcUser.advertiserLevel?.id===level.id ) ?? {} as AdvertisementLevel)
                }
            })

            userService.getAdvertisementCondition(account).then((response)=>{
                setAdvertisementCondition(response);
            })

        })
    },[account])

    const hideModal = () => {
        setOpen("");
    };

    const getButtonName = () => {
        if ( refundApplication ){
            return t("page.menu.c2cManage.apply.button.applied")
        }

        return currentLevel.id === allLevels[allLevels.length - 1].id ? t("page.menu.c2cManage.apply.alert.highestLevel") : t("page.menu.c2cManage.apply.button.levelUp")
    }

    // * hooks
    useEffect(() => {
        getData()
    }, [getData]);

    return (
        <CommonPage title={t("page.menu.c2cManage.apply.title")} returnPath={-1}>
            <div style={{ background: "white", flex: 1, padding: 16, width: "100%" }}>
                {(allLevels[0].id !== 0) &&
                    allLevels.map((level, i) => {
                        return (
                            <div style={{ 
                                display: "flex", 
                                flexDirection: "row", 
                                justifyContent: "space-between", 
                                padding: 16, 
                                border: "1px solid #DEDDE3", 
                                borderRadius: 4, 
                                marginBottom: 10, 
                                borderColor: 
                                    advertisementCondition.completeAmount >= currentLevel.conditionCompleteAmount && 
                                    advertisementCondition.completeOrders >= currentLevel.conditionCompleteOrders && 
                                    advertisementCondition.completeRate >= currentLevel.conditionCompleteRate && 
                                    advertisementCondition.completeUsers >= currentLevel.conditionCompleteUsers && 
                                    currentLevel.id === level.id ? "black" : "#DEDDE3" }}>
                                <p style={{ color: "#8F8DA2", fontWeight: 700, fontSize: 16 }}>{level.name}</p>
                                {currentLevel.id === level.id && (
                                    <p style={{ color: "#8F8DA2", fontWeight: 500, fontSize: 13 }}>{t("page.menu.c2cManage.apply.label.currentLevel")}</p>
                                )}
                            </div>
                        )
                    })}
                <div style={{ width: "100%", background: "white", display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <div style={{ width: "100%", display: "flex", flexDirection: "column", marginTop: 16 }}>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <p style={{ fontFamily: "PingFang TC", fontSize: 13, fontWeight: 500, color: "#8F8DA2" }}>{t("page.menu.c2cManage.apply.label.marginAmount")}</p>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <p style={{ fontFamily: "PingFang TC", fontSize: 13, fontWeight: 600, color: "#383743" }}>{currentLevel.deposit}</p>
                                <p style={{ fontFamily: "PingFang TC", fontSize: 13, fontWeight: 500, color: "#8F8DA2", marginLeft: 8 }}>USDT</p>
                            </div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: 16 }}>
                            <p style={{ fontFamily: "PingFang TC", fontSize: 13, fontWeight: 500, color: "#8F8DA2" }}>{t("page.menu.c2cManage.apply.label.orderMaxLimit")}</p>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <p style={{ fontFamily: "PingFang TC", fontSize: 13, fontWeight: 600, color: "#383743" }}>{currentLevel.maxAmountPerOrder}</p>
                                <p style={{ fontFamily: "PingFang TC", fontSize: 13, fontWeight: 500, color: "#8F8DA2", marginLeft: 8 }}>USDT</p>
                            </div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: 16 }}>
                            <p style={{ fontFamily: "PingFang TC", fontSize: 13, fontWeight: 500, color: "#8F8DA2" }}>{t("page.menu.c2cManage.apply.label.dayMaxLimit")}</p>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <p style={{ fontFamily: "PingFang TC", fontSize: 13, fontWeight: 600, color: "#383743" }}>{currentLevel.maxAmountPerDay}</p>
                                <p style={{ fontFamily: "PingFang TC", fontSize: 13, fontWeight: 500, color: "#8F8DA2", marginLeft: 8 }}>USDT</p>
                            </div>
                        </div>
                    </div>
                    <div style={{ width: "95%", height: 1, backgroundColor: "#F4F4F6", marginTop: 16, marginBottom: 16 }}></div>
                    <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                        <p style={{ fontFamily: "PingFang TC", fontSize: 13, fontWeight: 500, color: "#383743", marginBottom: 16 }}>{t("page.menu.c2cManage.apply.label.levelUpLimit")}</p>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <p style={{ fontFamily: "PingFang TC", fontSize: 13, fontWeight: 500, color: "#8F8DA2" }}>{t("page.menu.c2cManage.apply.label.dealUser")}</p>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <p style={{ fontFamily: "PingFang TC", fontSize: 13, fontWeight: 600, color: "#D32F2F" }}>{advertisementCondition.completeUsers}</p>
                                <p style={{ fontFamily: "PingFang TC", fontSize: 13, fontWeight: 500, color: "#8F8DA2", marginLeft: 8 }}>/{currentLevel.conditionCompleteUsers}</p>
                            </div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: 16 }}>
                            <p style={{ fontFamily: "PingFang TC", fontSize: 13, fontWeight: 500, color: "#8F8DA2" }}>{t("page.menu.c2cManage.apply.label.validOrder")}</p>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <p style={{ fontFamily: "PingFang TC", fontSize: 13, fontWeight: 600, color: "#D32F2F" }}>{advertisementCondition.completeOrders}</p>
                                <p style={{ fontFamily: "PingFang TC", fontSize: 13, fontWeight: 500, color: "#8F8DA2", marginLeft: 8 }}>/{currentLevel.conditionCompleteOrders}</p>
                            </div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: 16 }}>
                            <p style={{ fontFamily: "PingFang TC", fontSize: 13, fontWeight: 500, color: "#8F8DA2" }}>{t("page.menu.c2cManage.apply.label.totalAmount")}</p>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <p style={{ fontFamily: "PingFang TC", fontSize: 13, fontWeight: 600, color: "#D32F2F" }}> {advertisementCondition.completeAmount}</p>
                                <p style={{ fontFamily: "PingFang TC", fontSize: 13, fontWeight: 500, color: "#8F8DA2", marginLeft: 8 }}>/{currentLevel.conditionCompleteAmount}</p>
                            </div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: 16 }}>
                            <p style={{ fontFamily: "PingFang TC", fontSize: 13, fontWeight: 500, color: "#8F8DA2" }}>{t("page.menu.c2cManage.apply.label.successRate")}</p>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <p style={{ fontFamily: "PingFang TC", fontSize: 13, fontWeight: 600, color: "#D32F2F" }}>{`${((advertisementCondition.completeRate ?? 0)*100).toPrecision(2)}%`}</p>
                                <p style={{ fontFamily: "PingFang TC", fontSize: 13, fontWeight: 500, color: "#8F8DA2", marginLeft: 8 }}>{`/${currentLevel.conditionCompleteRate.toPrecision(2)}%`}</p>
                            </div>
                        </div>
                    </div>
                    <BaseButton
                        style={{width: "100%", margin: "15px 0px"}}
                        color={refundApplication ? "dark-gray" : "green"}
                        onClick={() => {
                            if ( refundApplication ) {
                                return;
                            }
                            userService.getSecurity().then((response) => {
                                checkSecurity(response.data)
                                if (currentLevel.id === allLevels[allLevels.length - 1].id) {
                                    alert(t("page.menu.c2cManage.apply.alert.highestLevel"))
                                } else {
                                    var yes = window.confirm(t("page.menu.c2cManage.apply.notice.levelUp"));
        
                                    if (yes) {
                                        userService.setAdvertisementLevel(account, allLevels[allLevels.findIndex( level => level.id === currentLevel.id)+1].id).then(()=>{
                                            getData()
                                        }).catch((e: AxiosError<ExchangeResponse<any>>)=>{
                                            if ( e.response ){
                                                switch(e.response.status){
                                                    case 400: alert(e.response.data.msg); break;
                                                    case 403: alert(t("page.menu.c2cManage.apply.alert.deny")); break;
                                                    default: alert("system error");
                                                }
                                            }
                                            else {
                                                alert("no response");
                                            }
                                        })
                                    }
                                }
                            }).catch((e) => {
                                setOpen(e.message)
                            })
    
                        }}
                    >
                        {getButtonName()}
                    </BaseButton>
                    {
                        currentLevel && !refundApplication &&
                        <BaseButton
                            color="red"
                            style={{width: "100%"}}
                            onClick={()=>{
                                var yes = window.confirm(t("page.menu.c2cManage.apply.notice.refund"));
    
                                if (yes) {
                                    userService.createRefundApplication().then(()=>{
                                        getData()
                                        alert(t("page.menu.c2cManage.apply.alert.refund"))
                                    }).catch((error)=>{
                                        alert(error.response.data.msg)
                                    })
                                }
                            }}
                        >
                            {t("page.menu.c2cManage.apply.button.refund")}
                        </BaseButton>
                    }
                </div>
            </div>
            <Modal
                visible={open!==""}
                footer={
                    open!=="CREATE" && <BaseButton 
                        style={{ width: "100%" }} 
                        onClick={()=> {
                            switch (open) {
                                case "kyc": 
                                case "DENY": 
                                stageStackNavigate("/id-verify");
                                    break;
                                case "CREATE": message.info(t("page.menu.c2cManage.apply.notice.CREATE")); break;
                                case "phone": stackNavigate("/reset-phone-number"); break;
                                case "googleAuth": stageStackNavigate("/google-code"); break;
                                case "financePwd": stackNavigate("/set-the-fund-password"); break;
                                
                            }
                        }}>
                        {t("page.menu.c2cManage.apply.button.goSetting")}
                    </BaseButton>
                }
                closable={false}
                onCancel={hideModal}
                width={"70%"}
                centered
            >
                <div style={{ width: "100%", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
                    <img src={GoogleAuth} alt="" />
                    <Header size="md">{t(`page.menu.c2cManage.apply.notice.${open}`)}</Header>
                </div>
            </Modal>
        </CommonPage>
    );
};

export default C2cApply;
