import { useState } from "react";
import { COLORS } from "@/constants/colors";
import { useTranslation } from "react-i18next";
import { useAppSelector, useAppDispatch } from "@/hooks";
import { createOtcOrder, otcOrderActions } from "@/store/slice/otcOrder";
import { CheckOutlined, ExclamationCircleFilled } from "@ant-design/icons";
import useStackNavigate from "@/hooks/useStackNavigate";
import { userService } from "@/services";
import { C2cOrderType } from "@/constants";
import CommonPage from "@/components/LayoutPage";
import { Card, CardContainer, CardContent, CurrencyCardName, MerchantInfo, OtcOrderInfo, OtcOrderInfoContainer, PaymentInfo, InfoText, TabContent, TimeLimitInfo, InfoContent } from "@/styled-components/deal-fiat";
import Tab from "@/components/Tab";
import CustomInput from "@/components/Input/CustomInput";
import { parsePrice } from "@/utils/parse";
import { BaseButton } from "@/components/Button";
import { PaymentItem } from "@/styled-components/deal";
import LetterAvatar from "react-letter-avatar";
import { message } from "antd";

const CreateOtcOrder = () => {
    const { t } = useTranslation()
    const advertisement = useAppSelector((state) => state.c2c.advertisement)

    const dispatch = useAppDispatch()
    const { stackNavigate } = useStackNavigate()
    const [textValue, setTextValue] = useState("");
    const clientSide: C2cOrderType = advertisement.type === 0 ? "sell" : "buy"
    const [activeKey, setActiveKey] = useState(0);
    const [selectedPayment, setSelectedPayment] = useState(advertisement.payments[0])
    const paneItems = [
        {
            key: 0,
            title: t("page.deal.fiat.label.byPrice"),
            onClick: () => {
                setActiveKey(0)
                setTextValue("")
            }
        },
        {
            key: 1,
            title: t("page.deal.fiat.label.byAmount"),
            onClick: () => {
                setActiveKey(1)
                setTextValue("")
            }
        },
    ];
    // const currency = useAppSelector((state) => state.user.currency)
    // const currencyUnit = `${t(`currencyUnit.${currency.name}.unit`)}${t(`currencyUnit.${currency.name}.name`)}`

    const getPlaceholder = () => {
        const min = activeKey === 0 ? advertisement.orderLimitMin : parsePrice(advertisement.orderLimitMin / advertisement.price);
        const max = activeKey === 0 ? advertisement.orderLimitMax : parsePrice(advertisement.orderLimitMax / advertisement.price);
        return (
            `${min} - ${max}`
        )
    }

    const handleCreate = () => {
        if (!selectedPayment) {
            alert(t("page.deal.fiat.alert.createOtcOrder.payment"))
            return;
        }

        if (textValue === "") {
            alert(t("page.deal.fiat.alert.createOtcOrder.amount"))
            return;
        }

        const inputAmount = activeKey === 0 ? parseFloat(textValue) : null;
        const inputQuantity = activeKey === 1 ? parseFloat(textValue) : null;

        if (inputAmount && (inputAmount < advertisement.orderLimitMin || inputAmount > advertisement.orderLimitMax)) {
            alert(t("page.deal.fiat.alert.createOtcOrder.amountLimit"))
            return;
        }

        if (inputQuantity && (inputQuantity * advertisement.price < advertisement.orderLimitMin || inputQuantity * advertisement.price > advertisement.orderLimitMax)) {
            alert(t("page.deal.fiat.alert.createOtcOrder.amountLimit"))
            return;
        }

        userService.getPayments().then((response) => {
            const payment = clientSide==="buy" ? selectedPayment : response.data.find((p) => p.type === selectedPayment.type)

            if (payment) {
                dispatch(createOtcOrder({
                    id: advertisement.id,
                    orderInfo: {
                        price: advertisement.price,
                        quantity: inputQuantity,
                        amount: inputAmount,
                        payments: [payment]
                    },
                    type: clientSide
                })).then((action) => {
                    if (createOtcOrder.rejected.match(action)) {
                        if ( action.payload?.response?.status===403 ) {
                            message.error(t("page.deal.fiat.alert.createOtcOrder.cancelLock"))
                        }
                        else {
                            message.error(action.payload?.response?.data.msg ?? "Error")
                        }
                    }
                    else {
                        dispatch(otcOrderActions.changeDealingSide(clientSide))
                        stackNavigate(`/deal/fiat`)
                    }
                })
            }
            else {
                alert(t("page.deal.fiat.alert.createOtcOrder.noPayment"))
            }
        })
    };

    return (
        <CommonPage title={`${t(`page.deal.fiat.title.${clientSide}`)} USDT`} returnPath={"jump"} returnOption={{state: clientSide}} style={{ backgroundColor: COLORS.EXLight_gray }}>
            <OtcOrderInfoContainer>
                <OtcOrderInfo>
                    <div>{t("page.deal.fiat.label.price")}</div>
                    <div>{`NT$ ${advertisement.price}`}</div>
                </OtcOrderInfo>
                <OtcOrderInfo>
                    <div>{t("page.deal.fiat.label.total")}</div>
                    <div>{`${advertisement.totalTradingAmount} USDT`}</div>
                </OtcOrderInfo>
                <OtcOrderInfo>
                    <div>{t("page.deal.fiat.label.limit")}</div>
                    <div>{`NT$ ${advertisement.orderLimitMin} - NT$ ${advertisement.orderLimitMax}`}</div>
                </OtcOrderInfo>
            </OtcOrderInfoContainer>
            <CardContainer>
                <Card>
                    <CardContent>
                        <Tab panes={paneItems} paneStyle={{ padding: 10 }} color={clientSide === "buy" ? COLORS.Green : COLORS.Red} />
                        <TabContent>
                            <CustomInput
                                valueType="number"
                                inputType="box"
                                style={{ marginTop: 10 }}
                                value={textValue}
                                onChange={(e) => {
                                    setTextValue(e.target.value)
                                }}
                                left={activeKey === 0 ? "NT$" : "USDT"}
                                right={
                                    <div onClick={() => {
                                        const max = activeKey === 0 ? advertisement.orderLimitMax : parsePrice(advertisement.orderLimitMax / advertisement.price);
                                        setTextValue(`${max}`)
                                    }}>
                                        {t("page.deal.fiat.button.max")}
                                    </div>
                                }
                                placeholder={getPlaceholder()}
                            />
                        </TabContent>
                        <InfoText>
                            <div>{t(`page.deal.fiat.label.${clientSide === "buy" ? "receive" : "send"}`)}</div>
                            <div>{`${textValue ? activeKey === 0 ? parsePrice(parseFloat(textValue) / advertisement.price) : parseFloat(textValue) : "--"} USDT`}</div>
                        </InfoText>
                        <BaseButton
                            style={{ width: "100%" }}
                            color={clientSide === "buy" ? "green" : "red"}
                            onClick={() => handleCreate()}
                        >
                            {t(`page.deal.fiat.button.${clientSide}`)}
                        </BaseButton>
                        <TimeLimitInfo>{t("page.deal.fiat.label.timeLimit")}</TimeLimitInfo>
                    </CardContent>
                </Card>
            </CardContainer>
            <CardContainer>
                <Card>
                    <CardContent>
                        <MerchantInfo>
                            <LetterAvatar name={advertisement.owner && !advertisement.owner.nickName ? advertisement.owner.account : advertisement.owner.nickName} size={25} radius={60} />
                            <CurrencyCardName>{advertisement.owner && !advertisement.owner.nickName ? advertisement.owner.account : advertisement.owner.nickName}</CurrencyCardName>
                        </MerchantInfo>
                        <InfoText>
                            <div>{t("page.deal.fiat.label.payType")}</div>
                        </InfoText>
                        <PaymentInfo>
                            {advertisement.payments.map((item) => {
                                return (
                                    <span style={{backgroundColor: selectedPayment.id === item.id ? COLORS.EXLight_gray : COLORS.White}}>
                                        <PaymentItem payment={item.type} onClick={() => setSelectedPayment(item)}>{t(`page.menu.c2cManage.payment.title.${item.type}`)}</PaymentItem>
                                        {
                                            selectedPayment.id === item.id && <CheckOutlined style={{ paddingRight: "5px", fontSize: 12, fontWeight: 700 }} />
                                        }
                                    </span>
                                );
                            })}
                        </PaymentInfo>
                        <Card color={COLORS.LightYellow} style={{marginTop: 10}}>
                            <CardContent style={{display: "flex", alignItems: "center", color: COLORS.Yellow, fontSize: 12, padding: "15px 5px", fontWeight: 500}}>
                                <ExclamationCircleFilled style={{padding: "0px 5px"}}/>
                                <div>{t("page.deal.fiat.alert.pay")}</div>
                            </CardContent>
                        </Card>
                        <InfoText style={{ marginTop: 5 }}>
                            <div>{t("page.deal.fiat.label.rule")}</div>
                        </InfoText>
                        <InfoContent>
                            <div>{advertisement.terms}</div>
                        </InfoContent>
                    </CardContent>
                </Card>
            </CardContainer>
        </CommonPage>
    );
};

export default CreateOtcOrder;
