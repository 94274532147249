import { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import CommonPage from "@/components/LayoutPage";
import { userService } from "@/services";
import CustomListItem from "@/components/List";
import { RightOutlined } from "@ant-design/icons";
import { COLORS } from "@/constants/colors";
import { Header } from "@/components/typography/Header";
import useStackNavigate from "@/hooks/useStackNavigate";


const SafeSetting = () => {
    const { stackNavigate, stageStackNavigate } = useStackNavigate()
    // const [kyc, setKyc] = useState<string | undefined>();
    const [phone, setPhone] = useState(false);
    const [googleAuth, setGoogleAuth] = useState(false);
    const [financePwd, setFinancePwd] = useState(false);
    const { t } = useTranslation();
    const getStatus = () => {
        userService.getSecurity().then((response) => {
            // setKyc(response.data.kyc);
            setPhone(response.data.phone);
            setGoogleAuth(response.data.googleAuth);
            setFinancePwd(response.data.financePwd);
        })
    };

    useEffect(() => {
        getStatus();
    }, []);

    return (
        <CommonPage title={""} returnPath={"/member"}>
            <Header size={"lg"} style={{width: "100%", padding: "20px 15px"}}>{t("page.menu.securitySetting.title")}</Header>
            <CustomListItem
                style={{ padding: "20px 15px" }}
                content={t("page.menu.securitySetting.label.password")}
                rightIcon={
                    <>
                        <span>{t(`page.menu.securitySetting.button.modify`)}</span>
                        <RightOutlined style={{marginLeft: 3}}/>
                    </>
                }
                onClick={() => stackNavigate("/reset-password")}
            />
            <CustomListItem
                style={{ padding: "20px 15px" }}
                content={t("page.menu.securitySetting.label.phone")}
                rightIcon={
                    <>
                        <span style={{ color: phone ? COLORS.Mid_gray : COLORS.Green }}>{t(`page.menu.securitySetting.button.${phone ? "modify" : "goBinding"}`)}</span>
                        <RightOutlined style={{marginLeft: 3}}/>
                    </>
                }
                onClick={() => stackNavigate("/reset-phone-number")}
            />
            <CustomListItem
                style={{ padding: "20px 15px" }}
                content={t("page.menu.securitySetting.label.email")}
                rightIcon={
                    <>
                        <span>{t(`page.menu.securitySetting.button.opening`)}</span>
                        <RightOutlined style={{marginLeft: 3}}/>
                    </>
                }
            />
            <CustomListItem
                style={{ padding: "20px 15px" }}
                content={t("page.menu.securitySetting.label.google")}
                rightIcon={
                    <>
                        <span style={{ color: googleAuth ? COLORS.Mid_gray : COLORS.Green }}>{t(`page.menu.securitySetting.button.${googleAuth ? "modify" : "goBinding"}`)}</span>
                        <RightOutlined style={{marginLeft: 3}}/>
                    </>
                }
                onClick={() => {
                    stageStackNavigate(googleAuth ? "/update-google" : "/google-code")
                }}
            />
            <CustomListItem
                style={{ padding: "20px 15px" }}
                content={t("page.menu.securitySetting.label.fundPassword")}
                rightIcon={
                    <>
                        <span style={{ color: financePwd ? COLORS.Mid_gray : COLORS.Green }}>{t(`page.menu.securitySetting.button.${financePwd ? "modify" : "setting"}`)}</span>
                        <RightOutlined style={{marginLeft: 3}}/>
                    </>
                }
                onClick={() => {
                    stageStackNavigate(financePwd ? "/update-the-fund-password" : "/set-the-fund-password")
                }}
            />
        </CommonPage>
    );
};

export default SafeSetting;
