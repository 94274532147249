import useStackNavigate from "@/hooks/useStackNavigate";
import { useTranslation } from "react-i18next";
import CommonPage from "@/components/LayoutPage";
import { MenuOption } from "@/constants/type";
import CustomListItem from "@/components/List";
import { RightOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faCircleQuestion, faCreditCard, faFileArrowUp, faIdBadge } from "@fortawesome/free-solid-svg-icons";
import { COLORS } from "@/constants/colors";

const C2c = () => {
    const { stackNavigate } = useStackNavigate()
    // $ init data
    const { t } = useTranslation();

    const optionList: MenuOption[] = [
        {
            text: t("page.menu.c2cManage.info.title"),
            needToken: true,
            onClick: () => stackNavigate("/memberCenter"),
            option: <FontAwesomeIcon icon={faIdBadge} style={{ padding: "0px 3px" }}/>
        },
        {
            text: t("page.menu.c2cManage.payment.title.setPayment"),
            needToken: true,
            onClick: () => stackNavigate("/account-setting"),
            option: <FontAwesomeIcon icon={faCreditCard} style={{ padding: "0px 3px" }}/>
        },
        {
            text: t("page.menu.c2cManage.apply.title"),
            needToken: true,
            onClick: () => stackNavigate("/c2cApply"),
            option: <FontAwesomeIcon icon={faFileArrowUp} style={{ padding: "0px 3px" }}/>
        },
        {
            text: t("page.menu.c2cManage.notification.title"),
            needToken: true,
            onClick: () => stackNavigate("/c2cNotification"),
            option: <FontAwesomeIcon icon={faBell} style={{ padding: "0px 3px" }}/>
        },
        {
            text: t("page.menu.c2cManage.help.title"),
            needToken: true,
            onClick: () => stackNavigate("/c2cHelp"),
            option: <FontAwesomeIcon icon={faCircleQuestion} style={{ padding: "0px 3px" }}/>
        }
    ];

    return (
        <CommonPage title={""} returnPath={"/member"}>
            <div style={{ fontSize: 24, fontWeight: 700, width: "100%", padding: "20px 15px" }}>{t("page.menu.c2cManage.title")}</div>
            {
                optionList.map((item: MenuOption, index) => (
                    <CustomListItem
                        key={index}
                        style={{ padding: "14px 15px", borderBottom: `1px solid ${COLORS.EXLight_gray}` }}
                        content={item.text}
                        rightIcon={<RightOutlined style={{ padding: "0px 3px" }} />}
                        leftIcon={item.option}
                        onClick={() => item.onClick && item.onClick()}
                    />
                ))
            }
        </CommonPage>
    );
};

export default C2c;
