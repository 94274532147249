
import { useTranslation } from "react-i18next";
import CommonPage from "@/components/LayoutPage";
import { Switch, message } from "antd";
import { COLORS } from "@/constants/colors";
import styled from "styled-components";
import { useCallback, useEffect, useState } from "react";
import { userService } from "@/services";
import { useAppDispatch, useAppSelector } from "@/hooks";
import { userActions } from "@/store/slice";

const OptionContainer = styled.div`
    border-bottom: 1px solid #F4F4F6;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 15px;
`
const C2cNotification = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch()
    const otc = useAppSelector((state)=>state.user.otc)
    const [isPhoneSet, setIsPhoneSet] = useState(false)

    const getSecuritySetting = useCallback(() => {
        userService.getSecurity().then((response) => {
            setIsPhoneSet(response.data["phone"])
        })
    }, [])

    useEffect(() => {
        getSecuritySetting()
    }, [getSecuritySetting])

    return (
        <CommonPage title={t("page.menu.c2cManage.notification.title")} returnPath={"/c2c"}>
            <div style={{ padding: "20px 15px", color: COLORS.Mid_gray, fontSize: 12, width: "100%" }}>
                <div style={{ padding: "2px 0px" }}>{t("page.menu.c2cManage.notification.label.order")}</div>
                <div style={{ padding: "2px 0px" }}>{t("page.menu.c2cManage.notification.label.note")}</div>
            </div>
            <OptionContainer>
                <div
                    style={{
                        fontWeight: 400,
                        fontSize: 15,
                        fontFamily: "PingFang TC",
                        display: "flex",
                        alignItems: "center",
                        color: "#383743"
                    }}
                >
                    {t("page.menu.c2cManage.notification.label.mail")}
                </div>
                <Switch
                    checked={otc.mailNotification}
                    onChange={() => {
                        userService.updateNotificationSetting(otc.account, "email").then(()=>{
                            dispatch(userActions.setOtcMailNotification(!otc.mailNotification))
                        })
                    }}
                />
            </OptionContainer>
            <OptionContainer>
                <div
                    style={{
                        fontWeight: 400,
                        fontSize: 15,
                        fontFamily: "PingFang TC",
                        display: "flex",
                        alignItems: "center",
                        color: "#383743"
                    }}
                >
                    {t("page.menu.c2cManage.notification.label.sms")}
                </div>
                <Switch 
                    checked={otc.smsNotification}
                    onChange={() => {
                        if (!isPhoneSet){
                            message.error(t("page.menu.c2cManage.notification.alert.phone"))
                        }
                        else {
                            userService.updateNotificationSetting(otc.account, "sms").then(()=>{
                                dispatch(userActions.setOtcSmsNotification(!otc.smsNotification))
                            })
                        }
                    }}
                />
            </OptionContainer>
        </CommonPage>
    );
};

export default C2cNotification;
