import { useAppSelector } from '@/hooks/redux-hooks'
import { NavigateOptions, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { pathStackActions } from '@/store/slice';

const useStackNavigate = () => {
    const { history, returnTarget } = useAppSelector((state)=>state.pathStack)
    const location = useLocation().pathname
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const back = (backPath: string | -1, options?: NavigateOptions) => {
        if( history.length===0 && backPath!==-1) {
            navigate(backPath, options)
        }
        else if ( history.length!==0 ){
            dispatch(pathStackActions.pop())
            navigate(history[history.length-1], options)
        }
        else {
            navigate(-1)
        }
    }

    const stackNavigate = (path: string | -1, options?: NavigateOptions)=> {
        if ( path==="jump" ){
            const target = returnTarget;
            if (returnTarget===""){
                dispatch(pathStackActions.jump("/"))
                return ;
            }
            dispatch(pathStackActions.jump(returnTarget))
            navigate(target, options)
        }
        else if ( path===-1 ){
            back(path)
        }
        else {
            dispatch(pathStackActions.push(location))
            navigate(path, options)
        }
    }
    
    const stageStackNavigate = (path: string, options?: NavigateOptions)=> {
        if ( !path.startsWith("/") ){
            return;
        }

        dispatch(pathStackActions.pushStage(location))
        navigate(path, options)
    }

    return {
        stackNavigate,
        stageStackNavigate,
        back
    }

}

export default useStackNavigate