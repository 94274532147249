import { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import useStackNavigate from "@/hooks/useStackNavigate";
import { COLORS } from "@/constants/colors";
import Modal from "@/components/UI/CoverModal";
import { useTranslation } from "react-i18next";
import { Payment } from "@/interfaces/exchange.interface";
import { useAppDispatch, useAppSelector } from "@/hooks";
import { otcOrderService } from "@/services";
import { C2cOrderType, OtcOrderStatus } from "@/constants";
import CommonPage from "@/components/LayoutPage";
import { BottomContainer, SideText, CheckboxCancel, CheckboxConfirm, ConfirmCheckbox, ConfirmContainer, ConfirmContent, ConfirmContentContainer, ConfirmContentTitle, PayInfo, PayInfoText, StatusContainer, StatusTitle, PayTimeTitle, PayTime } from "@/styled-components/deal-fiat";
import CustomCard from "@/components/CustomCard";
import { BaseButton } from "@/components/Button";
import { PaymentItem } from "@/styled-components/deal";
import { Divider } from "antd";
import { formatDateTime, formatTime, parsePrice } from "@/utils/parse";
import { CryptoIcon } from "@/pages/fund/components";
import { useLocation } from "react-router-dom";
import { otcOrderActions } from "@/store/slice";
import { OtcOrder } from "@/interfaces/otc.interface";

const InfoText = styled(PayInfo)`
    padding: 15px 0px;
`;

const MerchantBuying = () => {
    const side = useLocation().state as C2cOrderType
    const order = useAppSelector((state) => side==="buy" ? state.c2c.buying : state.c2c.selling)
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const [checkPaidModalVisible, setCheckPaidModalVisible] = useState(false);
    const [appealModalVisible, setAppealModalVisible] = useState(false);
    const [confirmModalVisible, setConfirmModalVisible] = useState(false);
    const [cancelModalVisible, setCancelModalVisible] = useState(false);
    const [blockButton, setBlockButton] = useState(false);
    const [blockCancelButton, setBlockCancelButton] = useState(false);
    const [usePayment, setUsePayment] = useState<Payment>({} as Payment);
    const { stackNavigate } = useStackNavigate()
    const [leftTime, setLeftTime] = useState(0)

    const getLeftTime = useCallback(() => {
        const current = Date.now();
        const createDate = order.createdDate;
        const limit = order.paymentTimeLimit;

        setLeftTime(limit - (current - createDate));
    }, [order.createdDate, order.paymentTimeLimit]);
    
    useEffect(() => {
        otcOrderService.getPayments(order.id).then((response) => {
            setUsePayment(response[0])
        })
    }, [order.id]);

    useEffect(() => {
        switch (order.status) {
            case OtcOrderStatus.WAIT_FOR_SELLER:
                setBlockButton(side==="buy" ? true : false); break;
            case OtcOrderStatus.WAIT_FOR_PAID:
                setBlockButton(side==="buy" ? false : true);
                setBlockCancelButton(side==="buy" ? false : true)
                break;
            case OtcOrderStatus.WAIT_FOR_BUYER:
                setBlockButton(side==="buy" ? false : true); 
                break;
            case OtcOrderStatus.WAIT_FOR_SEND:
                setBlockButton(side==="buy" ? true : false); 
                setBlockCancelButton(side==="buy" ? true : false)
                break;
            case OtcOrderStatus.FINISH:
                setBlockButton(false); 
                setBlockCancelButton(true);
                break;
            case OtcOrderStatus.CANCEL:
                alert("訂單已取消");
                stackNavigate("jump");
                break;
            case OtcOrderStatus.APPEAL:
                alert("訂單申訴中");
                stackNavigate("jump");
                break;
            default:
                alert(`otc order status error ${order.status}`)
        }
    }, [order.status, stackNavigate, side]);

    useEffect(() => {
        const timer = setInterval(() => {
            getLeftTime()
        }, 1000);

        return () => clearInterval(timer);
    }, [getLeftTime]);

    const getPaymentDetail = (payment: Payment) => {
        if (Object.keys(payment).length === 0) return;
        const columns = {
            "LINE_PAY": ["lineId", "phone", "qrCode"],
            "BANK": ["code", "account", "bankName"],
            "CASH": ["contract"],
            "STREET_PAY": ["streetId", "phone", "qrCode"]
        }
        return columns[payment.type].map((column) => {
            return (
                <InfoText>
                    <PayInfoText>
                        {t(`page.menu.c2cManage.payment.input.${column}`)}
                    </PayInfoText>
                    <PayInfoText>
                        {column === "qrCode" ? (
                            <img src={payment.qrCode} alt="qrCode" style={{ height: 120, width: 120 }} />
                        ) : payment[column]}
                    </PayInfoText>
                </InfoText>
            )
        })
    }

    return (
        <CommonPage title={""} returnPath={"jump"}>
            <StatusContainer>
                <StatusTitle>{t(`page.deal.fiat.${side}.title.${order.status}`)}</StatusTitle>
                <div>
                    <PayTimeTitle>
                        {t("page.deal.fiat.label.timeLeft")}
                    </PayTimeTitle>
                    <PayTime>
                        {formatTime(leftTime)}
                    </PayTime>
                </div>
                <CustomCard color={COLORS.LightBlue} style={{ padding: "15px 0px", margin: "10px 0px" }}>
                    <PayInfo>
                        <div style={{ display: "flex", fontWeight: 600, fontSize: 14, alignItems: "center" }}>
                            <CryptoIcon name={"usdt"} size={20} />
                            <SideText side={side}>{t(`page.deal.fiat.title.${side}`)}</SideText>
                            <div>USDT</div>
                        </div>
                    </PayInfo>
                    <Divider style={{ margin: "5px 0px" }} />
                    <InfoText>
                        <PayInfoText>{t(`page.deal.fiat.label.dealPrice`)}</PayInfoText>
                        <PayInfoText>{order.amount}</PayInfoText>
                    </InfoText>
                    <InfoText>
                        <PayInfoText>{t(`page.deal.fiat.label.price`)}</PayInfoText>
                        <PayInfoText>{order.price}</PayInfoText>
                    </InfoText>
                    <InfoText>
                        <PayInfoText>{t(`page.deal.fiat.label.quantity`)}</PayInfoText>
                        <PayInfoText>{parsePrice(order.amount / order.price)}</PayInfoText>
                    </InfoText>
                    <InfoText>
                        <PayInfoText>{t(`page.deal.fiat.label.orderId`)}</PayInfoText>
                        <PayInfoText>{order.id.substring(0, 20)} ...</PayInfoText>
                    </InfoText>
                    <InfoText>
                        <PayInfoText>{t(`page.deal.fiat.label.createdDate`)}</PayInfoText>
                        <PayInfoText>{formatDateTime(order.createdDate)}</PayInfoText>
                    </InfoText>
                </CustomCard>
                <CustomCard color={COLORS.LightBlue} style={{ padding: "15px 0px", margin: "10px 0px" }}>
                    <PayInfo>
                        <PayInfoText>{t(`page.deal.fiat.label.payType`)}</PayInfoText>
                        <PaymentItem payment={usePayment.type}>{t(`page.menu.c2cManage.payment.title.${usePayment.type}`)}</PaymentItem>
                    </PayInfo>
                    <Divider style={{ margin: "10px 0px" }} />
                    <InfoText>
                        <PayInfoText>{t(`page.deal.fiat.label.account`)}</PayInfoText>
                        <PayInfoText>{order.sellUser.account}</PayInfoText>
                    </InfoText>
                    {getPaymentDetail(usePayment)}
                </CustomCard>
            </StatusContainer>
            <BottomContainer>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <BaseButton
                        color="gray"
                        style={{ width: "34%", fontSize: 14, display: blockCancelButton ? "none" : "" }}
                        onClick={() => {
                            switch (order.status) {
                                case OtcOrderStatus.WAIT_FOR_SELLER:
                                    setCancelModalVisible(true); break;
                                case OtcOrderStatus.WAIT_FOR_SEND:
                                    setAppealModalVisible(true);
                                    break;
                                case OtcOrderStatus.WAIT_FOR_BUYER:
                                    setCancelModalVisible(true); break;
                                case OtcOrderStatus.WAIT_FOR_PAID:
                                    setCancelModalVisible(true); break;
                                case OtcOrderStatus.FINISH:
                                    if ( side==="buy" ){
                                        dispatch(otcOrderActions.updateBuying({} as OtcOrder))
                                    }
                                    else {
                                        dispatch(otcOrderActions.updateSelling({} as OtcOrder))
                                    }
                                    stackNavigate("jump"); break;
                                default:
                                    alert(`otc order status error ${order.status}`)
                            }
                        }}
                    >
                        {t(`page.deal.fiat.${side}.cancel.${order.status === 1 ? "-2" : order.status}`)}
                    </BaseButton>
                    <BaseButton
                        style={{ width: blockCancelButton ? "100%" : "64%", fontSize: 14 }}
                        disabled={blockButton}
                        color={side==="buy" ? "green" : "red"}
                        onClick={() => {
                            switch (order.status) {
                                case OtcOrderStatus.WAIT_FOR_SELLER:
                                    otcOrderService.check(order.id); break;
                                case OtcOrderStatus.WAIT_FOR_SEND:
                                    setConfirmModalVisible(true); break;
                                case OtcOrderStatus.WAIT_FOR_BUYER:
                                    otcOrderService.check(order.id); break;
                                case OtcOrderStatus.WAIT_FOR_PAID:
                                    setCheckPaidModalVisible(v => !v); break;
                                case OtcOrderStatus.FINISH:
                                    stackNavigate("jump"); break;
                                default:
                                    alert(`otc order status error ${order.status}`)
                            }
                        }}
                    >
                        {t(`page.deal.fiat.${side}.button.${order.status}`)}
                    </BaseButton>
                </div>
            </BottomContainer>
            {appealModalVisible && (
                <Modal>
                    <ConfirmContainer>
                        <ConfirmContentContainer>
                            <ConfirmContentTitle>
                                {t("page.deal.fiat.button.appeal")}
                            </ConfirmContentTitle>
                            <ConfirmContent>
                                {t("page.deal.fiat.alert.appeal")}
                            </ConfirmContent>
                        </ConfirmContentContainer>
                        <ConfirmCheckbox>
                            <CheckboxCancel
                                onClick={() => {
                                    setAppealModalVisible(false);
                                }}
                            >
                                {t("page.deal.fiat.button.cancel")}
                            </CheckboxCancel>
                            <CheckboxConfirm
                                onClick={() => {
                                    otcOrderService.appeal(order.id).then(() => {
                                        setAppealModalVisible(false);
                                    })
                                }}
                            >
                                {t("page.deal.fiat.button.confirm")}
                            </CheckboxConfirm>
                        </ConfirmCheckbox>
                    </ConfirmContainer>
                </Modal>
            )}
            {checkPaidModalVisible && (
                <Modal>
                    <ConfirmContainer>
                        <ConfirmContentContainer>
                            <ConfirmContentTitle>
                                {t("page.deal.fiat.alert.paidYet")}
                            </ConfirmContentTitle>
                            <ConfirmContent>
                                {t("page.deal.fiat.alert.payConfirmMsg")}
                            </ConfirmContent>
                        </ConfirmContentContainer>
                        <ConfirmCheckbox>
                            <CheckboxCancel
                                onClick={() => {
                                    setCheckPaidModalVisible(false);
                                }}
                            >
                                {t("page.deal.fiat.button.cancel")}
                            </CheckboxCancel>
                            <CheckboxConfirm
                                onClick={() => {
                                    otcOrderService.paid(order.id, { payment: usePayment }).then(() => {
                                        setCheckPaidModalVisible(false);
                                    })
                                }}
                            >
                                {t("page.deal.fiat.button.confirm")}
                            </CheckboxConfirm>
                        </ConfirmCheckbox>
                    </ConfirmContainer>
                </Modal>
            )}
            {confirmModalVisible && (
                <Modal>
                    <ConfirmContainer>
                        <ConfirmContentContainer>
                            <ConfirmContentTitle>{t("page.deal.fiat.alert.transferToken")}</ConfirmContentTitle>
                            <ConfirmContent>
                                {t("page.deal.fiat.alert.receiveMoneyTransfer")}
                            </ConfirmContent>
                        </ConfirmContentContainer>
                        <ConfirmCheckbox>
                            <CheckboxCancel onClick={() => {
                                setConfirmModalVisible(v => !v)
                            }}>
                                {t("page.deal.fiat.button.cancel")}
                            </CheckboxCancel>
                            <CheckboxConfirm onClick={() => {
                                otcOrderService.confirm(order.id);
                                setConfirmModalVisible(v => !v)
                            }}>
                                {t("page.deal.fiat.button.confirm")}
                            </CheckboxConfirm>
                        </ConfirmCheckbox>
                    </ConfirmContainer>
                </Modal>
            )}
            {cancelModalVisible && (
                <Modal>
                    <ConfirmContainer>
                        <ConfirmContentContainer>
                            <ConfirmContentTitle>{t("page.deal.fiat.alert.cancel")}</ConfirmContentTitle>
                            <ConfirmContent>
                                {
                                    t("page.deal.fiat.alert.cancelMemo")
                                }
                            </ConfirmContent>
                        </ConfirmContentContainer>
                        <ConfirmCheckbox>
                            <CheckboxCancel
                                onClick={() => {
                                    setCancelModalVisible(false);
                                }}
                            >
                                {t("page.deal.fiat.button.cancel")}
                            </CheckboxCancel>
                            <CheckboxConfirm
                                onClick={() => {
                                    otcOrderService.cancel(order.id).then(() => {
                                        stackNavigate("jump");
                                    })
                                }}
                            >
                                {t("page.deal.fiat.button.confirm")}
                            </CheckboxConfirm>
                        </ConfirmCheckbox>
                    </ConfirmContainer>
                </Modal>
            )}
        </CommonPage>
    );
};

export default MerchantBuying;
