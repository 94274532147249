import CommonPage from "@/components/LayoutPage";
import { Header } from "@/components/typography/Header";
import { Text } from "@/components/typography/Text";
import { useTranslation } from "react-i18next";

const XEBit = () => {
    const { t } = useTranslation()
    return (
        <CommonPage title={"關於XEBIT"} returnPath={"/about"}>
            <div style={{ padding: "0px 15px" }}>
                <Text size="sm" style={{ textAlign: "right" }}>{t("page.menu.about.privacy.label.createdDate")}</Text>
                <Header size="md">關於我們</Header>
                <Header size="sm" style={{ lineHeight: 2 }}>XEBIT是一家根據塞席爾的法律在塞席爾共和國註冊成立的公司，是一個讓用戶交易數位貨幣資產且提供相關服務的平台，由最優秀的工程與資安團隊以及耐心的客服組成，我們的首要任務是確保用戶資產安全，並且提供最讓您安新的數位資產交易模式。
XEBIT採用公正第三方提供的數位資產錢包，嚴格遵守 KYC、AML 規範，確保交易平台的合法合規性。我們致力於提供安全、便捷的加密貨幣交易環境，讓您盡情掌握財富增值的機會。 </Header>
                <Header size="md">我們的使命</Header>
                <Header size="sm" style={{ lineHeight: 2 }}>
我們的目標是提供快速且安全的交易系統，同時賦予買賣方相等的權力，讓您完全掌握資產的流向。
我們的宗旨是建立用戶的財務自由性，相信在未來世界經濟趨向為財務開放模式，我們也能透過加密貨幣讓財務更趨向自由，並創造更多機會。
我們的使命是搭建傳統中心化金流與加密貨幣的橋樑，提供世界級的虛擬資產交易服務，開啟一扇大眾接觸虛擬通貨和區塊鏈的大門。</Header>
            </div>
        </CommonPage>
    );
};

export default XEBit;
