import { COLORS } from "@/constants/colors";
import React, { ReactNode } from "react";
import styled from "styled-components";

export const TextLabel = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: 23px;
  color: ${COLORS.Gray};
  font-size: 13px;
`;

export const InputContainer = styled.p`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const TextInput = styled.input`
  padding: 20px;
  background: none;
  opacity: 0.6;
  border-radius: 4px;
  width: 90%;
  margin-left: 20px;
  background: ${COLORS.EXLight_gray};
  color: ${COLORS.Gray};
  border: none;
  height: 20px;
`;

const LabelContainer: React.FC<{
    label: string, 
    children: ReactNode
}> = ({label, children}) => {
    return (
        <div style={{ width: "100%" }}>
            <TextLabel>{label}</TextLabel>
            <InputContainer>
                {children}
            </InputContainer>
        </div>
    )
}

export default LabelContainer