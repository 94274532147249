import { useCallback, useEffect, useState } from "react";
import QuizImage from "@/assets/quiz.png";
import CommonPage from "@/components/LayoutPage";
import { useTranslation } from "react-i18next";
import useStackNavigate from "@/hooks/useStackNavigate";
import QuizBackground from "@/assets/quiz-background.svg"
import { Header } from "@/components/typography/Header";
import { BaseButton } from "@/components/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileLines } from "@fortawesome/free-regular-svg-icons";
import { Quiz } from "@/interfaces/exchange.interface";
import { investorService } from "@/services";
import { useAppSelector } from "@/hooks";

const QuizPage = () => {
    const { t } = useTranslation()
    const { stackNavigate } = useStackNavigate()
    const [quizStatus, setQuizStatus] = useState<Quiz>({} as Quiz)
    const token = useAppSelector((state)=>state.user.token)

    const [c2cQuestions,] = useState<Question[]>([
        {
            subject: "當我收到買家的訂單款項後，我應該做什麼？",
            answer: 1,
            options: ["將訂單置放著，會自動放行訂單", "點選“放行訂單”的按鈕", "不理會買家，去做我的合約交易"],
        },
        {
            subject: "當訂單發生申訴時應該採取什麼動作?",
            answer: 0,
            options: ["提供有利於買賣的證據、以及紀錄", "當作沒發生，申訴會自動失效", "私訊買方(賣方)與他大吵一架"],
        },
        {
            subject: "當買家聲明確實付款但您未收到款項，下列何者錯誤?",
            answer: 1,
            options: ["請買家提供明細以及匯款完成的證據", "客戶至上，先放幣在說", "利用申訴管道"],
        },
        {
            subject: "關於C2C交易，何者錯誤?",
            answer: 1,
            options: ["是一種透過法幣轉換成加密貨幣的管道", "是一種投資性的交易", "是一種客戶對客戶的交易模式"],
        },
        {
            subject: "當客戶要求與你在平台以外交易，該怎麼做?",
            answer: 2,
            options: ["請對方現提供匯款帳號或交易地址", "要求對方先付款或先轉幣", "請他從平台上交易，才能保障雙方資金安全"],
        },
    ]);

    const [contractQuestions,] = useState<Question[]>([
        {
            subject: "關於合約交易的風險，下列何者有誤?",
            answer: 0,
            options: [
                "合約交易只需考慮手續費成本",
                "因為自由運用槓桿，風險相對高",
                "幣價浮動極高，已設立止盈止損",
            ],
        },
        {
            subject: "當幣價波動過大，此時還有倉位應如何處理?",
            answer: 0,
            options: [
                "適自己情況以及資金比例而定",
                "繼續持倉，幣價遲早會回來",
                "在加碼，等幣價回調可以賺翻倍",
            ],
        },
        {
            subject: "從事合約交易，可能發生的最大虧損為何？",
            answer: 1,
            options: [
                "合約錢包的百分之十",
                "合約錢包中的所有資金",
                "合約錢包的百分之七十五",
            ],
        },
        {
            subject: "關於槓桿調整的敘述，何者正確?",
            answer: 2,
            options: [
                "槓桿越高，風險越低",
                "槓桿越低，需要的保證金越低",
                "槓桿越高，需要的保證金越低",
            ],
        },
        {
            subject: "關於逐全倉的意思，下列何者正確",
            answer: 0,
            options: [
                "全倉可能會損失所有合約錢包的資金",
                "逐倉相較全倉可承受更大浮動",
                "全倉沒有強平機制",
            ],
        },
        {
            subject: "進行合約交易應保持何種心態?",
            answer: 2,
            options: [
                "投機心態，快進快出",
                "了解部分規則即可開始，剩餘為實際操作經驗",
                "充分了解規則及風險",
            ],
        },
        {
            subject: "關於止盈止損單的敘述，何者正確？",
            answer: 1,
            options: [
                "成交價格一定等於我設定的觸發價格",
                "價格因市場變動快速，所以觸發價與成交價不一定相同",
                "成交價格一定小於觸發價格",
            ],
        },
        {
            subject: "假設使用逐倉模式開二十倍的槓桿，購買價格35000USDT的BTC一顆，保證金需要多少?",
            answer: 1,
            options: ["3500USDT", "1750USDT", "合約錢包裡的全部資金"],
        },
        {
            subject: "關於持倉以及委託，何者正確?",
            answer: 0,
            options: [
                "下單時會在委託欄搓合，成交才會到持倉欄位",
                "下單時會在持倉欄搓合，成交才會到委託欄位",
                "委託攔以及持倉攔是根據價格浮動決定在哪個位置",
            ],
        },
        {
            subject:
                "關於合約交易，必須勇往直前，決不退縮，想要在市場上賺錢，必須放手一搏，這你同意嗎?",
            answer: 1,
            options: ["同意", "不同意"],
        },
    ]);

    const [walletQuestions,] = useState<Question[]>([
        {
            subject: "關於充值與提現的敘述，何者錯誤?",
            answer: 1,
            options: [
                "一旦提現是不可逆轉的",
                "充質地址末四碼為核對用，不需要輸入",
                "提現為數位資產的轉帳，充值為數位資產的收款",
            ],
        },
        {
            subject: "關於區塊鏈網路，下列何者正確?",
            answer: 2,
            options: [
                "Trc20與Erc20為同一區塊鏈",
                "若轉錯區塊鏈網路不要緊張，過一小時就會返環數位資產",
                "每個區塊鏈都不同，也務必要同一個區塊鏈才能充提",
            ],
        },
        {
            subject: "當遇上陌生人士提供您地址請您提現給他，應該怎麼做?",
            answer: 1,
            options: [
                "積極配合",
                "若為陌生人切勿隨意提現",
                "看對方的目的，若為盈利目的，那就應該提現給他",
            ],
        },
        {
            subject: "關於充值地址，何者正確",
            answer: 2,
            options: [
                "地址受政府監管，所以無須擔心",
                "地址都是公開且透明化的，找的到個人",
                "地址雖然透明化，但無法公開身分",
            ],
        },
        {
            subject: "假設提現出去才驚覺詐騙，應該怎麼做?",
            answer: 2,
            options: [
                "到銀行索取明細",
                "到超商用條碼輸入搜尋",
                "依法報警，但資產找回機率渺茫",
            ],
        },
    ]);

    const [symbolQuestions,] = useState<Question[]>([
        {
            subject: "何謂XRP(瑞波幣)?",
            answer: 1,
            options: [
                "是一個權益證明區塊鏈專案",
                "主要專注於數字支付網路和貨幣兌換網路來處理金融交易",
                "是一種比特幣現金",
            ],
        },
        {
            subject: "何謂FC Porto 粉絲代幣(PORTO)?",
            answer: 0,
            options: [
                "是FC PORTO足球俱樂部的一種BEP-20功能型代幣",
                "是一個專注於區塊鏈邊玩邊賺遊戲的遊戲公會",
                "是區塊鏈瀏覽器的替代方案",
            ],
        },
        {
            subject: "何謂LTC(萊特幣)?",
            answer: 1,
            options: [
                "是一個以區塊鏈為基礎的去中心化音樂串流平台",
                "由前 Google 工程師 Charlie Lee 於 2011 年創立的一種 BTC 之外主流數位貨幣",
                "是一個建構在以太坊區塊鏈上的去中心化影片協定",
            ],
        },
        {
            subject: "何謂SAND?",
            answer: 0,
            options: [
                "項目是邊賺邊玩的區塊鏈遊戲",
                "是一種受監管及以法幣為後盾的穩定幣",
                "是一個區塊鏈專案",
            ],
        },
        {
            subject: "何謂比特幣現金(BCH)?",
            answer: 2,
            options: [
                "為分散式賬本數據庫之上建立支付和交易網絡",
                "是一組在以太坊區塊鏈上執行的電腦程式，允許交換去中心化代幣。",
                "由一群不滿比特幣發展計劃的開發人員、投資者、企業家及礦工所創立。",
            ],
        },
        {
            subject: "何謂ADA?",
            answer: 1,
            options: [
                "是針對以太坊的擴展解決方案",
                "是一個權益證明區塊鏈專案",
                "是目前最受歡迎的穩定幣之一",
            ],
        },
        {
            subject: "什麼是 Filecoin (FIL)?",
            answer: 0,
            options: [
                "是一個使用區塊鏈技術的去中心化點對點數位儲存空間市場",
                "是一種用於合成加密資產的 DeFi 協議",
                "是流行的 NFT 遊戲 Axie Infinity 中的一種 ERC-20 遊戲代幣",
            ],
        },
        {
            subject: "何謂Uniswap(UNI)?",
            answer: 0,
            options: [
                "是一個去中心化金融 (DeFi) 專案",
                "是一組在以太坊區塊鏈上執行的電腦程式",
                "是一個以食物為主題的DeFi項目",
            ],
        },
    ]);

    // const optionList: MenuOption[] = [
    //     {
    //         text: t("page.quiz.label.c2c"),
    //         needToken: true,
    //         onClick: () => navigate("/exam", { state: { questions: c2cQuestions, quizName: t("page.quiz.label.c2c") } })
    //     },
    //     {
    //         text: t("page.quiz.label.contract"),
    //         needToken: true,
    //         onClick: () => navigate("/exam", { state: { questions: contractQuestions, quizName: t("page.quiz.label.contract") } })
    //     },
    //     {
    //         text: t("page.quiz.label.wallet"),
    //         needToken: true,
    //         onClick: () => navigate("/exam", { state: { questions: walletQuestions, quizName: t("page.quiz.label.wallet") } })
    //     },
    //     {
    //         text: t("page.quiz.label.symbol"),
    //         needToken: true,
    //         onClick: () => navigate("/exam", { state: { questions: symbolQuestions, quizName: t("page.quiz.label.symbol") } })
    //     }
    // ];

    const getQuizStatus = useCallback(()=>{
        investorService.getQuizStatus().then((response)=>{
            if ( response.data ){
                setQuizStatus(response.data)
            }
            else {
                setQuizStatus({
                    id: 0,
                    c2c: false,
                    symbol: false,
                    wallet: false,
                    contract: false
                })
            }
        })
    }, [])

    useEffect(()=>{
        getQuizStatus()
    },[getQuizStatus])

    return (
        <CommonPage title={""} returnPath={"/home"} style={{ backgroundImage: `url(${QuizBackground}`, backgroundSize: "cover" }} headerStyle={{backgroundColor: "#eafdf9"}}>
            <div style={{ width: "100%", padding: "0px 15px" }}>
                <Header size="lg">完成新手測驗即可獲得1000USDT</Header>
                <Header size="md" style={{ fontWeight: 400 }}>增進交易知識，完成以下測驗即可賺取加密貨幣</Header>
                <BaseButton color="yellow" onClick={() => stackNavigate("/quiz-rule")}>
                    <FontAwesomeIcon icon={faFileLines} style={{ marginRight: 5 }} />
                    活動規則
                </BaseButton>
                <img src={QuizImage} alt="" style={{padding: "15px 0px", width: "100%"}}/>
                <div style={{ display: "flex", justifyContent: "space-around", alignItems: "center", padding: "15px 0px", textAlign: "center" }}>
                    <div>
                        <Header
                            size="md"
                            style={{ fontWeight: 400 }}
                            onClick={() => {
                                if (!token) {
                                    alert(t("page.menu.login.alert.loginFirst"))
                                    return;
                                }
                                !quizStatus.c2c && stackNavigate("/exam", { state: { questions: c2cQuestions, quizName: "c2c" } })
                            }}
                        >
                            {`${t("page.quiz.label.c2c")}${quizStatus.c2c ? "(已通過)" : ""}`}
                        </Header>
                        <Header
                            size="md"
                            style={{ fontWeight: 400 }}
                            onClick={() => {
                                if (!token) {
                                    alert(t("page.menu.login.alert.loginFirst"))
                                    return;
                                }
                                !quizStatus.wallet && stackNavigate("/exam", { state: { questions: walletQuestions, quizName: "wallet" } })
                            }}
                        >
                            {`${t("page.quiz.label.wallet")}${quizStatus.wallet ? "(已通過)" : ""}`}
                        </Header>
                    </div>
                    <div>
                        <Header
                            size="md"
                            style={{ fontWeight: 400 }}
                            onClick={() => {
                                if (!token) {
                                    alert(t("page.menu.login.alert.loginFirst"))
                                    return;
                                }
                                !quizStatus.contract && stackNavigate("/exam", { state: { questions: contractQuestions, quizName: "contract" } })
                            }}
                        >
                            {`${t("page.quiz.label.contract")}${quizStatus.contract ? "(已通過)" : ""}`}
                        </Header>
                        <Header
                            size="md"
                            style={{ fontWeight: 400 }}
                            onClick={() => {
                                if (!token) {
                                    alert(t("page.menu.login.alert.loginFirst"))
                                    return;
                                }
                                !quizStatus.symbol && stackNavigate("/exam", { state: { questions: symbolQuestions, quizName: "symbol" } })
                            }}
                        >
                            {`${t("page.quiz.label.symbol")}${quizStatus.symbol ? "(已通過)" : ""}`}
                        </Header>
                    </div>
                </div>
            </div>
        </CommonPage>
    );
};

export default QuizPage;

