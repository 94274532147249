import { useState, useEffect, useCallback } from "react";
import useStackNavigate from "@/hooks/useStackNavigate";
import { COLORS } from "@/constants/colors";
import Modal from "@/components/UI/CoverModal";
import { useTranslation } from "react-i18next";
import { Payment } from "@/interfaces/exchange.interface";
import { useAppSelector } from "@/hooks";
import { otcOrderService } from "@/services";
import { OtcOrderStatus } from "@/constants";
import CommonPage from "@/components/LayoutPage";
import { BottomContainer, CheckboxCancel, CheckboxConfirm, ConfirmCheckbox, ConfirmContainer, ConfirmContent, ConfirmContentContainer, ConfirmContentTitle, PayInfo, PayInfoText, PayStepContainer, PayTime, PayTimeTitle, SideText, StatusContainer, StatusTitle, StepCircle, StepContainer, StepText } from "@/styled-components/deal-fiat";
import CustomCard from "@/components/CustomCard";
import LetterAvatar from "react-letter-avatar";
import { CryptoIcon } from "@/pages/fund/components";
import { Divider } from "antd";
import { BaseButton } from "@/components/Button";
import { PaymentItem } from "@/styled-components/deal";
import PaymentDetail from "./PaymentDetail";
import { formatTime } from "@/utils/parse";


const FiatDealing = () => {
    const side = useAppSelector((state) => state.c2c.dealingSide)
    const order = useAppSelector((state) => side === "buy" ? state.c2c.buying : state.c2c.selling)
    const [cancelModalVisible, setCancelModalVisible] = useState(false);
    const [confirmModalVisible, setConfirmModalVisible] = useState(false);
    const [blockButton, setBlockButton] = useState(false);
    const [blockCancelButton, setBlockCancelButton] = useState(false);
    const [checkPaidModalVisible, setCheckPaidModalVisible] = useState(false);
    const [appealModalVisible, setAppealModalVisible] = useState(false);
    const [usePayment, setUsePayment] = useState<Payment>({} as Payment);
    const [leftTime, setLeftTime] = useState(0)

    const { stackNavigate } = useStackNavigate();

    const { t } = useTranslation();

    useEffect(() => {
        otcOrderService.getPayments(order.id).then((response) => {
            setUsePayment(response[0])
        })
    }, [order.id]);

    const getLeftTime = useCallback(() => {
        const current = Date.now();
        const createDate = order.createdDate;
        const limit = order.paymentTimeLimit;

        setLeftTime(limit - (current - createDate));
    }, [order.createdDate, order.paymentTimeLimit]);

    useEffect(() => {
        const timer = setInterval(() => {
            getLeftTime()
        }, 1000);

        return () => clearInterval(timer);
    }, [getLeftTime]);

    useEffect(() => {
        switch (order.status) {
            case OtcOrderStatus.WAIT_FOR_SELLER:
                setBlockButton(side === "buy" ? true : false); 
                break;
            case OtcOrderStatus.WAIT_FOR_PAID:
                setBlockButton(side === "buy" ? false : true); 
                setBlockCancelButton(side === "buy" ? false : true); 
                break;
            case OtcOrderStatus.WAIT_FOR_BUYER:
                setBlockButton(side === "buy" ? false : true); break;
            case OtcOrderStatus.WAIT_FOR_SEND:
                setBlockButton(side === "buy" ? true : false); 
                setBlockCancelButton(side==="buy" ? true : false)
                break;
            case OtcOrderStatus.FINISH:
                stackNavigate("/deal/fiat/finish"); break;
            case OtcOrderStatus.CANCEL:
                alert("訂單已取消");
                stackNavigate("jump");
                break;
            case OtcOrderStatus.APPEAL:
                alert("訂單申訴中");
                stackNavigate("jump");
                break;
            default:
                alert(`otc order status error ${order.status}`)
        }
    }, [order.status, stackNavigate, side]);

    const getNickName = () => {
        if ( side === "buy" ) {
            return !order.sellUser.nickName ? order.sellUser.account : order.sellUser.nickName;
        }
        else {
            return !order.buyUser.nickName ? order.buyUser.account : order.buyUser.nickName;
        }
    }

    return (
        <CommonPage title={""} returnPath={"jump"}>
            <StatusContainer>
                <StatusTitle>{t(`page.deal.fiat.${side}.title.${order.status}`)}</StatusTitle>
                <div>
                    <PayTimeTitle>
                        {t("page.deal.fiat.label.timeLeft")}
                    </PayTimeTitle>
                    <PayTime>
                        {formatTime(leftTime)}
                    </PayTime>
                </div>
                <CustomCard color={COLORS.LightBlue} style={{ padding: "15px 0px", margin: "10px 0px" }}>
                    <div style={{ padding: "5px", display: "flex", alignItems: "center" }}>
                        <LetterAvatar name={getNickName()} size={25} radius={60} />
                        <div style={{ padding: "0px 5px" }}>{getNickName()}</div>
                    </div>
                    <dl style={{ fontSize: 12, color: COLORS.Mid_gray, fontWeight: 600 }}>
                        <li style={{ padding: "3px 10px" }}>{t("page.deal.fiat.alert.lock")}</li>
                        <li style={{ padding: "3px 10px" }}>{t("page.deal.fiat.alert.customerService")}</li>
                    </dl>
                </CustomCard>
            </StatusContainer>
            <PayStepContainer visible={order.status !== OtcOrderStatus.WAIT_FOR_SELLER}>
                <div style={{ display: "flex", fontWeight: 600, fontSize: 14, alignItems: "center" }}>
                    <CryptoIcon name={"usdt"} size={20} />
                    <SideText side={side}>{t(`page.deal.fiat.title.${side}`)}</SideText>
                    <div>USDT</div>
                </div>
                <Divider style={{ margin: "10px 0px" }} />
                <StepContainer>
                    <StepCircle>1</StepCircle>
                    <StepText>{t(`page.deal.fiat.${side}.steps.${usePayment.type}.1`)}</StepText>
                </StepContainer>
                <StepContainer>
                    <StepCircle>2</StepCircle>
                    <StepText>{t(`page.deal.fiat.${side}.steps.${usePayment.type}.2`)}</StepText>
                    <StepText style={{ color: COLORS.Red }}>NT$ {order.amount}</StepText>
                    <CustomCard color={COLORS.LightBlue} style={{ padding: "15px 0px", margin: "10px", width: "100%" }}>
                        <PayInfo>
                            <PayInfoText>{t(`page.deal.fiat.label.payType`)}</PayInfoText>
                            <PaymentItem payment={usePayment.type}>{t(`page.menu.c2cManage.payment.title.${usePayment.type}`)}</PaymentItem>
                        </PayInfo>
                        <Divider style={{ margin: "10px 0px" }} />
                        <PaymentDetail payment={usePayment} />
                    </CustomCard>
                </StepContainer>
                <StepContainer>
                    <StepCircle>3</StepCircle>
                    <StepText>{t(`page.deal.fiat.${side}.steps.${usePayment.type}.3`)}</StepText>
                </StepContainer>
            </PayStepContainer>
            <BottomContainer>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                <BaseButton
                        color="gray"
                        style={{ width: "34%", fontSize: 14, display: blockCancelButton ? "none" : "" }}
                        onClick={() => {
                            switch (order.status) {
                                case OtcOrderStatus.WAIT_FOR_SELLER:
                                    setCancelModalVisible(true); break;
                                case OtcOrderStatus.WAIT_FOR_SEND:
                                    setAppealModalVisible(true);
                                    break;
                                case OtcOrderStatus.WAIT_FOR_BUYER:
                                    setCancelModalVisible(true); break;
                                case OtcOrderStatus.WAIT_FOR_PAID:
                                    setCancelModalVisible(true); break;
                                case OtcOrderStatus.FINISH:
                                    stackNavigate("jump"); break;
                                default:
                                    alert(`otc order status error ${order.status}`)
                            }
                        }}
                    >
                        {t(`page.deal.fiat.${side}.cancel.${order.status === 1 ? "-2" : order.status}`)}
                    </BaseButton>
                    <BaseButton
                        style={{ width: blockCancelButton ? "100%" : "64%", fontSize: 12 }}
                        disabled={blockButton}
                        color={side === "buy" ? "green" : "red"}
                        onClick={() => {
                            switch (order.status) {
                                case OtcOrderStatus.WAIT_FOR_BUYER:
                                    otcOrderService.check(order.id); break;
                                case OtcOrderStatus.WAIT_FOR_PAID:
                                    setCheckPaidModalVisible(v => !v); break;
                                case OtcOrderStatus.WAIT_FOR_SEND:
                                    setConfirmModalVisible(true); break;
                                default:
                                    alert(`otc order status error ${order.status}`)
                            }
                        }}
                    >
                        {t(`page.deal.fiat.${side}.button.${order.status}`)}
                    </BaseButton>
                </div>
            </BottomContainer>
            {appealModalVisible && (
                <Modal>
                    <ConfirmContainer>
                        <ConfirmContentContainer>
                            <ConfirmContentTitle>
                                {t("page.deal.fiat.button.appeal")}
                            </ConfirmContentTitle>
                            <ConfirmContent>
                                {t("page.deal.fiat.alert.appeal")}
                            </ConfirmContent>
                        </ConfirmContentContainer>
                        <ConfirmCheckbox>
                            <CheckboxCancel
                                onClick={() => {
                                    setAppealModalVisible(false);
                                }}
                            >
                                {t("page.deal.fiat.button.cancel")}
                            </CheckboxCancel>
                            <CheckboxConfirm
                                onClick={() => {
                                    otcOrderService.appeal(order.id).then(() => {
                                        setAppealModalVisible(false);
                                    })
                                }}
                            >
                                {t("page.deal.fiat.button.confirm")}
                            </CheckboxConfirm>
                        </ConfirmCheckbox>
                    </ConfirmContainer>
                </Modal>
            )}
            {checkPaidModalVisible && (
                <Modal>
                    <ConfirmContainer>
                        <ConfirmContentContainer>
                            <ConfirmContentTitle>
                                {t("page.deal.fiat.alert.paidYet")}
                            </ConfirmContentTitle>
                            <ConfirmContent>
                                {t("page.deal.fiat.alert.payConfirmMsg")}
                            </ConfirmContent>
                        </ConfirmContentContainer>
                        <ConfirmCheckbox>
                            <CheckboxCancel
                                onClick={() => {
                                    setCheckPaidModalVisible(false);
                                }}
                            >
                                {t("page.deal.fiat.button.cancel")}
                            </CheckboxCancel>
                            <CheckboxConfirm
                                onClick={() => {
                                    otcOrderService.paid(order.id, { payment: usePayment }).then(() => {
                                        setCheckPaidModalVisible(false);
                                    })
                                }}
                            >
                                {t("page.deal.fiat.button.confirm")}
                            </CheckboxConfirm>
                        </ConfirmCheckbox>
                    </ConfirmContainer>
                </Modal>
            )}
            {confirmModalVisible && (
                <Modal>
                    <ConfirmContainer>
                        <ConfirmContentContainer>
                            <ConfirmContentTitle>{t("page.deal.fiat.alert.transferToken")}</ConfirmContentTitle>
                            <ConfirmContent>
                                {t("page.deal.fiat.alert.receiveMoneyTransfer")}
                            </ConfirmContent>
                        </ConfirmContentContainer>
                        <ConfirmCheckbox>
                            <CheckboxCancel onClick={() => {
                                setConfirmModalVisible(v => !v)
                            }}>
                                {t("page.deal.fiat.button.cancel")}
                            </CheckboxCancel>
                            <CheckboxConfirm onClick={() => {
                                otcOrderService.confirm(order.id);
                            }}>
                                {t("page.deal.fiat.button.confirm")}
                            </CheckboxConfirm>
                        </ConfirmCheckbox>
                    </ConfirmContainer>
                </Modal>
            )}
            {cancelModalVisible && (
                <Modal>
                    <ConfirmContainer>
                        <ConfirmContentContainer>
                            <ConfirmContentTitle>{t("page.deal.fiat.alert.cancel")}</ConfirmContentTitle>
                            <ConfirmContent>
                            {t("page.deal.fiat.alert.cancelMemo")}
                            </ConfirmContent>
                        </ConfirmContentContainer>
                        <ConfirmCheckbox>
                            <CheckboxCancel
                                onClick={() => {
                                    setCancelModalVisible(false);
                                }}
                            >
                                {t("page.deal.fiat.button.cancel")}
                            </CheckboxCancel>
                            <CheckboxConfirm
                                onClick={() => {
                                    otcOrderService.cancel(order.id).then(() => {
                                        stackNavigate("jump");
                                    })
                                }}
                            >
                                {t("page.deal.fiat.button.confirm")}
                            </CheckboxConfirm>
                        </ConfirmCheckbox>
                    </ConfirmContainer>
                </Modal>
            )}
        </CommonPage>
    );
};

export default FiatDealing;
