import { configureStore } from '@reduxjs/toolkit'
import storage from 'redux-persist-indexeddb-storage';
import { persistReducer, persistStore } from 'redux-persist';

import {
    userReducer,
    positionsReducer,
    futuresReducer,
    marketReducer,
    registerReducer,
    tradingReducer,
    otcOrderReducer,
    advertisementReducer,
    pathStackReducer
} from '@/store/slice'

const persistC2cConfig = {
    key: 'c2c',
    storage: storage('localdb'),
}

const persistUserConfig = {
    key: 'user',
    storage: storage('localdb'),
}

export const store = configureStore({
    reducer: {
        c2c: persistReducer(persistC2cConfig, otcOrderReducer),
        user: persistReducer(persistUserConfig, userReducer),
        positions: positionsReducer,
        futures: futuresReducer,
        register: registerReducer,
        trading: tradingReducer,
        market: marketReducer,
        advertisement: advertisementReducer,
        pathStack: pathStackReducer
    }
});

export const persistor =  persistStore(store)