import axios from 'axios';

export const request = (
    options,
    isReturnAllRes = false
) => {
    const combineOptions = {
        method: 'GET',
        ...options
    };

    return new Promise((resolve, reject) => {
        axios(combineOptions)
            .then(res => {
                if (isReturnAllRes) {
                    resolve(res);
                } else {
                    resolve(res.data.data);
                }
            })
            .catch(err => {
                reject(err.response ? err.response : err);
            });
    });
};

export default request;
