/* 引入styled components */
import styled from "styled-components";
import { useState } from "react"
import useStackNavigate from "@/hooks/useStackNavigate";
import { useTranslation } from "react-i18next";
import CommonPage from "@/components/LayoutPage";
import { userService } from "@/services";
import PasswordInput from "@/components/UI/PasswordInput";
import { BaseButton } from "@/components/Button";
import { Header } from "@/components/typography/Header";

const AreaSpace = styled.div`
  padding: 10px;
  background: #fff ;
  opacity: 0.6;
  border-radius: 4px;
  background:#F4F4F6;
  margin-bottom: 15px;
`;

const AreaContent = styled.div`
  display:flex;
  justify-content: space-between;
`;

const SaveButtonWord = styled.div`
  color:#FFFFFF;
  font-size:14px;
  text-align:center;
  border: none ;
`;

const FormBox = styled.div`
    width: 100%;
    padding: 0px 15px;
    margin-top: 20px;
`

const SetTheFundPassword = () => {
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const { stackNavigate } = useStackNavigate()
    const [loading, setLoading] = useState(false);
    const [originPassword, setOriginPassword] = useState("")
    const { t } = useTranslation();

    return (
        <CommonPage active={loading} title={t("page.resetPassword.title")} returnPath={"/safe-setting"}>
            <FormBox>
                <Header size={"sm"}>{t("page.resetPassword.input.password")}</Header>
                <AreaSpace>
                    <AreaContent>
                        <PasswordInput placeholder={t("page.resetPassword.placeholder.password")} onChange={(e) => {
                            setOriginPassword(e.target.value)
                        }} />
                    </AreaContent>
                </AreaSpace>
                <Header size={"sm"}>{t("page.resetPassword.input.newPassword")}</Header>
                <AreaSpace
                >
                    <AreaContent>
                        <PasswordInput placeholder={t("page.resetPassword.placeholder.newPassword")} onChange={(e) => {
                            setPassword(e.target.value)
                        }} />
                    </AreaContent>
                </AreaSpace>

                <Header size={"sm"}>{t("page.resetPassword.input.checkNewPassword")}</Header>
                <AreaSpace
                >
                    <AreaContent>
                        <PasswordInput placeholder={t("page.resetPassword.placeholder.checkNewPassword")} onChange={(e) => {
                            setConfirmPassword(e.target.value)
                        }} />
                    </AreaContent>
                </AreaSpace>

                <BaseButton
                    style={{ width: "100%", height: 40, marginTop: 15 }}
                    onClick={() => {
                        if (!originPassword) {
                            alert(t("page.resetPassword.alert.password"))
                        } else if (!password) {
                            alert(t("page.resetPassword.alert.newPassword"))
                        } else if (password.length < 8) {
                            alert(t("page.resetPassword.alert.eightLimit"))
                        } else if (!confirmPassword) {
                            alert(t("page.resetPassword.alert.checkNewPassword"))
                        } else if (password !== confirmPassword) {
                            alert(t("page.resetPassword.alert.difference"))
                        } else {
                            setLoading(true)
                            userService.resetPassword({
                                current: originPassword,
                                changed: password
                            }).then(() => {
                                alert(t("page.resetPassword.alert.success"))
                                stackNavigate(-1)
                            }).catch((error) => {
                                alert(error.response.data.msg)
                            }).finally(() => {
                                setLoading(false)
                            })
                        }

                    }
                    }>
                    <SaveButtonWord>{t("page.resetPassword.button.reset")}</SaveButtonWord>
                </BaseButton>
            </FormBox >
        </CommonPage>
    );
}

export default SetTheFundPassword 
