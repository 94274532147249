import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { COLORS } from "@/constants/colors";
// import funds from "@/assets/icon/Deal/funds.png";
import Drawer from "@/components/UI/Drawer";
import check from "@/assets/profile/Tick.png";
import ChangeCurrency from "@/components/fund/ChangeCurrency";
import cancel from "@/assets/icon/cancel.png";
import useStackNavigate from "@/hooks/useStackNavigate";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "@/hooks";
import { advertisementActions } from "@/store/slice";
import CommonPage from "@/components/LayoutPage";
import { advertisementService, userService } from "@/services";
import { Advertisement } from "@/interfaces/otc.interface";
import CustomInput from "@/components/Input/CustomInput";
import { RightOutlined } from "@ant-design/icons";
import { CryptoIcon } from "@/pages/fund/components";
import { Divider } from "antd";
import { AdvertisementStatus } from "@/constants";


const NextButton = styled.div<{ isValid: boolean }>`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: right;
  letter-spacing: 0.4px;
  color: ${COLORS.Red};
  opacity: ${(props) => (props.isValid ? 1 : 0.5)};
  border: none;
`;

const StatusContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  margin-top: 8px;
  position: relative;
  width: 100%;
`;
const StatusItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;
const StatusIcon = styled.div`
  width: 16px;
  height: 16px;
  border: 4px solid #dedde3;
  border-radius: 8px;
`;
const StatusDes = styled.p`
  font-weight: 700;
  font-size: 13px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: 0.4px;
  color: #dedde3;
  margin-top: 6px;
`;
const StatusSplit = styled.div`
  height: 2px;
  width: 32%;
  background: #dedde3;
  border-radius: 3px;
  margin-bottom: 8%;
  position: absolute;
  bottom: 9%;
  left: 14.7%;
`;
const DealContainer = styled.div`
  width: 100%;
  height: 165px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  margin-top: 16px;
`;
const DealTitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${COLORS.EXLight_gray};
`;
const DealTitle = styled.div<{ status: number; adType: number }>`
  width: 100%;
  height: 52px;
  font-weight: ${(props) => (props.adType === props.status ? 700 : 500)};
  font-size: 14px;
  line-height: 52px;
  letter-spacing: 0.012em;
  color: ${COLORS.Dark_gray};
  border-bottom: ${(props) =>
        props.adType === props.status
            ? `2px solid
    ${props.status === 0 ? COLORS.Green : COLORS.Red}`
            : "none"};
  text-align: center;
`;
const DealActionContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
`;
const AmountContainer = styled.div`
  width: 100%;
  height: 336px;
  display: flex;
  flex-direction: column;
  padding: 16px;
  background: #fff;
  margin-top: 10px;
`;
const AmountTitle = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: ${COLORS.Dark_gray};
`;
const AmountDesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const AmountTitleContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const AmountDesTitle = styled.div`
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.004em;
  color: ${COLORS.Gray};
`;
const AmountDesContent = styled.p`
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: ${COLORS.Dark_gray};
`;
const DealAmountContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px;
  background: #fff;
`;
const DealAmountTitle = styled.p`
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.4px;
  color: ${COLORS.Gray};
`;
const DealAmountInputSplit = styled.p`
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.004em;
  padding: 20px 5px;
  color: ${COLORS.Gray};

`;
const DepthTitle = styled.div`
  width: 100%;
  text-align: center;
  line-height: 24px;
  font-weight: 600;
`;
const DepthUnitItem = styled.div`
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #f4f4f6;
  height: 55px;
  line-height: 55px;
  color: ${COLORS.Dark_gray};
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
`;
const UnitCheck = styled.img`
  width: 17.6px;
  height: 13.4px;
`;
const DrawerFullWarehouseTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const DrawerFullWarehouseImage = styled.img`
  width: 28px;
  height: 28px;
`;

const DrawerFullWarehouseTitle = styled.p`
  flex: 1;
  height: 24px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${COLORS.Dark_gray};
  text-align: center;
`;
const CancelButton = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #8f8da2;
`;
const DrawerDeadTimeContainer = styled.div`
  width: 100%;
  height: 200px;
  overflow: scroll;
`;

const FiatAdPage = () => {
    const { t } = useTranslation();
    const { stackNavigate } = useStackNavigate()
    const advertisement = useAppSelector((state) => state.advertisement.creating)
    const [advertisementList, setAdvertisementList] = useState<Advertisement[]>([])
    const editId = useAppSelector((state) => state.advertisement.editId)
    const dispatch = useAppDispatch();
    const [selectedAdType, setSelectedAdType] = useState(advertisement.type ?? 0);
    const [unit, setUnit] = useState(advertisement.cryptoAsset ?? "USDT");
    const [currencyUnit, setCurrencyUnit] = useState(advertisement.fiatCurrency ?? "TWD");
    const [fixPriceStatus, setFixPriceStatus] = useState<"fixed" | "float">("fixed");
    const [fixPrice, setFixPrice] = useState(advertisement.price ?? "");
    const [dealAmount, setDealAmount] = useState(advertisement.totalTradingAmount ?? "");
    const [minPrice, setMinPrice] = useState(advertisement.orderLimitMin ?? "");
    const [maxPrice, setMaxPrice] = useState(advertisement.orderLimitMax ?? "");
    const [deadTimePay, setDeadTimePay] = useState(15);
    const account = useAppSelector((state) => state.user.detail.account)
    const [availableBalance, setAvailableBalance] = useState(0);

    const [currencyDrawer, setCurrencyDrawer] = useState(false);
    const [changeUnit, setChangeUnit] = useState(false);
    const [fixPriceDrawer, setFixPriceDrawer] = useState(false);
    const [deadTimePayDrawer, setDeadTimePayDrawer] = useState(false);

    const currencyDrawerHandler = () => {
        setCurrencyDrawer((v) => !v);
    };
    const currencyFixPriceHandler = () => {
        setFixPriceDrawer((v) => !v);
    };
    const deadTimePayDrawerHandler = () => {
        setDeadTimePayDrawer((v) => !v);
    };
    const selectHandler = (select: string) => {
        setUnit(select);
        setCurrencyDrawer((v) => !v);
    };

    const getUserInfo = useCallback(() => {
        userService.getOtcUserInfo(account).then((response) => {
            const walletBalance = response.wallet.coins.find((x) => x.symbol === "USDT")?.balance ?? 0
            const freeze = response.wallet.coins.find((x) => x.symbol === "USDT")?.freeze ?? 0
            setAvailableBalance(selectedAdType === 0 ? 10000 : walletBalance - freeze)
        })
    }, [account, selectedAdType])

    const changeType = useCallback((type: number) => {
        if ( editId!=="" ){
            return;
        }
        if (advertisementList.length === 1) {
            if (advertisementList[0].type === type) {
                alert(t("page.menu.advertisementManage.alert.createAdvertisement.change"))
                return;
            }
            else {
                setSelectedAdType(advertisementList[0].type === 0 ? 1 : 0)
            }
        }
        else {
            setSelectedAdType(type)
        }
    }, [advertisementList, t, editId])

    const getActiveAdvertisements = useCallback(() => {
        advertisementService.getAdvertisement({
            my: true
        }).then((response) => {
            const ads = response.filter((ad) => ad.status !== -1);
            if (ads.length === 2 && editId==="") {
                alert(t("page.menu.advertisementManage.alert.createAdvertisement.full"))
                dispatch(advertisementActions.clean())
                dispatch(advertisementActions.cleanEdit())
                stackNavigate(-1)
            }
            else if (ads.length === 1 && editId==="" ) {
                setSelectedAdType(ads[0].type === 0 ? 1 : 0)
            }
            else {

            }
            setAdvertisementList(ads)
        })
    }, [dispatch, t, editId])

    useEffect(() => {
        getUserInfo()
        getActiveAdvertisements()
    }, [getUserInfo, getActiveAdvertisements])

    const checkUserInput = (): boolean => {
        const numberRegex = /^(?:0*[1-9]\d*(?:\.\d+)?|0+\.\d*[1-9]\d*)$/;
        const userInput: string[] = [fixPrice, dealAmount, minPrice, maxPrice]
        for (let index = 0; index < userInput.length; index++) {
            if (userInput[index] === "" || !numberRegex.test(userInput[index])) {
                return false;
            }
        }

        if (!isDealAmountValid()) {
            return false;
        }

        if (!isTotalAmountValid()) {
            return false;
        }

        if (!isMinPriceValid()) {
            return false;
        }
        
        if (!isMaxPriceValid()) {
            return false;
        }

        return true
    }

    const parseToNumber = (value: string): number => parseFloat(value ? value : "0");

    const isDealAmountValid = () => parseToNumber(dealAmount) <= availableBalance
    const isTotalAmountValid = () => parseToNumber(dealAmount) * parseToNumber(fixPrice) >= 100 || ( dealAmount==="" || fixPrice==="")
    const isMaxPriceValid = (): boolean => parseToNumber(maxPrice) <= parseToNumber(dealAmount) * parseToNumber(fixPrice)
    const isMinPriceValid = (): boolean => minPrice === "" || ( parseToNumber(minPrice) >= 100 && ( maxPrice === "" || parseToNumber(minPrice) < parseToNumber(maxPrice)))

    const getNextButton = () => {
        return (
            <NextButton
                isValid={checkUserInput()}
                onClick={() => {
                    if (checkUserInput()) {
                        dispatch(advertisementActions.set({
                            type: editId === "" ? selectedAdType : undefined,
                            cryptoAsset: editId === "" ? unit : undefined,
                            fiatCurrency: editId === "" ? currencyUnit : undefined,
                            priceType: fixPriceStatus === "fixed" ? 0 : 1,
                            price: fixPrice,
                            totalTradingAmount: dealAmount,
                            orderLimitMin: minPrice,
                            orderLimitMax: maxPrice,
                            paymentTimeLimit: deadTimePay * 60 * 1000,
                            conditionCompleteOrders: advertisement.conditionCompleteOrders,
                            conditionRegisteredDays: advertisement.conditionRegisteredDays,
                            financePassword: "",
                            payments: advertisement.payments ?? [],
                            terms: advertisement.terms
                        }))
                        stackNavigate("/advertisement/step-two");
                    }
                }}
            >
                {t("page.menu.advertisementManage.button.next")}
            </NextButton>
        )
    }
    return changeUnit ? (
        <ChangeCurrency
            currencyUnit={currencyUnit}
            setCurrencyUnit={setCurrencyUnit}
            setChangeUnit={setChangeUnit}
        />
    ) : (
        <CommonPage 
            title={t(`page.menu.advertisementManage.label.${editId === "" ? "publish" : "edit"}`)} 
            returnPath={-1} 
            returnOption={{state: editId==="" ? AdvertisementStatus.ONLINE : AdvertisementStatus.OFFLINE }} 
            option={getNextButton()}
        >
            <StatusContainer>
                <StatusItem>
                    <StatusIcon style={{ border: `4px solid ${COLORS.Red}` }} />
                    <StatusDes style={{ color: `${COLORS.Dark_gray}` }}>
                        {t("page.menu.advertisementManage.label.priceNumber")}
                    </StatusDes>
                </StatusItem>
                <StatusSplit />
                <StatusItem>
                    <StatusIcon />
                    <StatusDes>{t("page.menu.advertisementManage.label.tradeType")}</StatusDes>
                </StatusItem>
                <StatusSplit style={{ left: "53.3%" }} />
                <StatusItem>
                    <StatusIcon />
                    <StatusDes>{t("page.menu.advertisementManage.label.adsConfirm")}</StatusDes>
                </StatusItem>
            </StatusContainer>
            <DealContainer>
                <DealTitleContainer>
                    <DealTitle
                        adType={selectedAdType}
                        status={0}
                        style={{ marginLeft: "5%" }}
                        onClick={() => {
                            changeType(0);
                        }}
                    >
                        {t("page.menu.advertisementManage.label.wantBuy")}
                    </DealTitle>
                    <DealTitle
                        adType={selectedAdType}
                        status={1}
                        style={{ marginRight: "5%" }}
                        onClick={() => {
                            changeType(1);
                        }}
                    >
                        {t("page.menu.advertisementManage.label.wantSell")}
                    </DealTitle>
                </DealTitleContainer>
                <DealActionContainer>
                    <CustomInput
                        inputType={"solid"}
                        label={t("page.menu.advertisementManage.label.tokenType")}
                        placeholder={unit}
                        right={<RightOutlined style={{ padding: "0px 5px" }} />}
                        onClick={currencyDrawerHandler}
                        disable={true}
                        style={{ width: "95%" }}
                    />
                    <CustomInput
                        inputType={"solid"}
                        label={t("page.menu.advertisementManage.label.fundFiat")}
                        placeholder={currencyUnit}
                        right={<RightOutlined style={{ padding: "0px 5px" }} />}
                        onClick={() => setChangeUnit((v) => !v)}
                        disable={true}
                        style={{ width: "95%" }}
                    />
                </DealActionContainer>
            </DealContainer>
            <AmountContainer>
                <AmountTitle>{t("page.menu.advertisementManage.label.price")}</AmountTitle>
                <CustomInput
                    inputType={"solid"}
                    label={t("page.menu.advertisementManage.label.priceMethod")}
                    value={t("page.menu.advertisementManage.label.fixedPrice")}
                    disable={true}
                />
                <CustomInput
                    inputType={"solid"}
                    label={t("page.menu.advertisementManage.label.fixedPrice")}
                    placeholder={t("page.menu.advertisementManage.placeholder.fixedPrice")}
                    onChange={(e) => setFixPrice(e.target.value)}
                    value={fixPrice}
                    right={<div style={{ padding: "0px 5px" }}>{currencyUnit}</div>}
                />
                <AmountDesContainer style={{ marginTop: 24 }}>
                    <AmountDesTitle>{t("page.menu.advertisementManage.label.marketReference")}</AmountDesTitle>
                    <AmountDesTitle>{`${fixPrice} ${currencyUnit}/${unit}`}</AmountDesTitle>
                </AmountDesContainer>
                <AmountDesContainer style={{ marginTop: 16 }}>
                    <AmountDesTitle>{t("page.menu.advertisementManage.label.dealPrice")}</AmountDesTitle>
                    <AmountDesContent>{`${fixPrice} ${currencyUnit}/${unit}`}</AmountDesContent>
                </AmountDesContainer>
            </AmountContainer>
            <DealAmountContainer>
                <AmountTitle>{t("page.menu.advertisementManage.label.amount")}</AmountTitle>
                <CustomInput
                    inputType={"solid"}
                    label={t("page.menu.advertisementManage.label.tradeAmount")}
                    placeholder={t("page.menu.advertisementManage.label.enterTradeAmount")}
                    value={dealAmount}
                    onChange={(e) => setDealAmount(e.target.value)}
                    right={
                        <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            <div>USDT</div>
                            <Divider type="vertical" style={{ padding: "0px 2px" }}/>
                            <div style={{color: COLORS.Blue}} onClick={()=> setDealAmount(`${availableBalance}`)}>最大</div>
                        </div>
                    }
                    down={<div style={{color: COLORS.Dark_gray, padding: "3px 0px", fontSize: 12, fontWeight: 700}}>可出售 {availableBalance} USDT</div>}
                    valid={isDealAmountValid() && isTotalAmountValid()}
                    invalidMessage={`${t("page.menu.advertisementManage.alert.limit")}${availableBalance}`}
                />
                <AmountTitleContainer>
                    <DealAmountTitle>{t("page.menu.advertisementManage.label.onceLimit")}</DealAmountTitle>
                </AmountTitleContainer>
                <div style={{ display: "flex", justifyContent: "space-between", maxWidth: "100%", boxSizing: "border-box" }}>
                    <CustomInput
                        inputType={"solid"}
                        placeholder={t("page.menu.advertisementManage.label.min")}
                        value={minPrice}
                        onChange={(e) => setMinPrice(e.target.value)}

                        right={<div style={{ padding: "0px 5px" }}>TWD</div>}
                        valid={isMinPriceValid()}
                        invalidMessage={t(`page.menu.advertisementManage.alert.${parseToNumber(minPrice) > parseToNumber(maxPrice) ? "minLimit" : "minPositive"}`)}
                    />
                    <DealAmountInputSplit>～</DealAmountInputSplit>
                    <CustomInput
                        inputType={"solid"}
                        placeholder={t("page.menu.advertisementManage.label.max")}
                        value={maxPrice}
                        onChange={(e) => setMaxPrice(e.target.value)}
                        right={<div style={{ padding: "0px 5px" }}>TWD</div>}
                        valid={isMaxPriceValid()}
                        invalidMessage={t("page.menu.advertisementManage.alert.maxLimit")}
                    />
                </div>
                <CustomInput
                    label={t("page.menu.advertisementManage.label.permitTime")}
                    inputType={"solid"}
                    placeholder={t("page.menu.advertisementManage.label.permitTime")}
                    value={`${deadTimePay}${t("page.menu.advertisementManage.label.minutes")}`}
                    onClick={deadTimePayDrawerHandler}
                    right={<RightOutlined style={{ padding: "0px 5px" }} />}
                />
            </DealAmountContainer>
            <Drawer
                isVisible={currencyDrawer}
                selectVisible={currencyDrawerHandler}
                height={252}
            >
                <DepthTitle>{t("page.menu.advertisementManage.label.tokenType")}</DepthTitle>
                <DepthUnitItem onClick={selectHandler.bind(null, "USDT")}>
                    <CryptoIcon name={"usdt"} size={30} />
                    <p style={{ flex: 1, textAlign: "left", marginLeft: 12 }}>USDT</p>
                    {unit === "USDT" && <UnitCheck src={check} alt="USDT" />}
                </DepthUnitItem>
                <DepthUnitItem onClick={selectHandler.bind(null, "BTC")}>
                    <CryptoIcon name={"btc"} size={30} />
                    <p style={{ flex: 1, textAlign: "left", marginLeft: 12 }}>BTC</p>
                    {unit === "BTC" && <UnitCheck src={check} alt="USDT" />}
                </DepthUnitItem>
                <DepthUnitItem onClick={selectHandler.bind(null, "ETH")}>
                    <CryptoIcon name={"eth"} size={30} />
                    <p style={{ flex: 1, textAlign: "left", marginLeft: 12 }}>ETH</p>
                    {unit === "ETH" && <UnitCheck src={check} alt="USDT" />}
                </DepthUnitItem>
            </Drawer>
            <Drawer
                isVisible={fixPriceDrawer}
                selectVisible={currencyFixPriceHandler}
                height={196}
            >
                <DepthTitle>{t("page.menu.advertisementManage.label.priceMethod")}</DepthTitle>
                <DepthUnitItem
                    onClick={() => {
                        setFixPriceStatus(t("page.menu.advertisementManage.label.fixedPrice"));
                        currencyFixPriceHandler();
                    }}
                >
                    <p
                        style={{
                            flex: 1,
                            textAlign: "center",
                            color:
                                fixPriceStatus === "fixed"
                                    ? COLORS.Red
                                    : COLORS.Gray,
                        }}
                    >
                        {t("page.menu.advertisementManage.label.fixedPrice")}
                    </p>
                </DepthUnitItem>
                <DepthUnitItem
                    onClick={() => {
                        setFixPriceStatus("float");
                        currencyFixPriceHandler();
                    }}
                >
                    <p
                        style={{
                            flex: 1,
                            textAlign: "center",
                            color:
                                fixPriceStatus === "float"
                                    ? COLORS.Red
                                    : COLORS.Gray,
                        }}
                    >
                        {t("page.menu.advertisementManage.label.floatPrice")}
                    </p>
                </DepthUnitItem>
            </Drawer>
            <Drawer
                isVisible={deadTimePayDrawer}
                selectVisible={deadTimePayDrawerHandler}
                height={309}
            >
                <DrawerFullWarehouseTitleContainer>
                    <DrawerFullWarehouseImage
                        src={cancel}
                        alt="cancel"
                        onClick={deadTimePayDrawerHandler}
                    />
                    <DrawerFullWarehouseTitle>{t("page.menu.advertisementManage.label.permitTime")}</DrawerFullWarehouseTitle>
                    <CancelButton
                        onClick={deadTimePayDrawerHandler}
                    >{t("page.menu.advertisementManage.button.OK")}</CancelButton>
                </DrawerFullWarehouseTitleContainer>
                <DrawerDeadTimeContainer>
                    {[
                        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
                        20,
                    ].map((item, i) => {
                        return (
                            <DepthUnitItem
                                onClick={() => {
                                    setDeadTimePay(item);
                                }}
                            >
                                <p
                                    style={{
                                        flex: 1,
                                        textAlign: "center",
                                        color:
                                            deadTimePay === item
                                                ? COLORS.Red
                                                : COLORS.Gray,
                                    }}
                                >
                                    {item}
                                </p>
                            </DepthUnitItem>
                        );
                    })}
                </DrawerDeadTimeContainer>
            </Drawer>
        </CommonPage>
    );
};

export default FiatAdPage;
