import { UserSecurity } from "@/interfaces/exchange.interface"

export const checkSecurity = (securitySetting: UserSecurity) => {
    Object.getOwnPropertyNames(securitySetting).forEach((securityProperty) => {
        if (!securitySetting[securityProperty]) {
            throw new Error(securityProperty)
        }
    })
    if (securitySetting.kyc !== "PERMIT") {
        throw new Error(securitySetting.kyc)
    }
}