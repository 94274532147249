/* 引入styled components */
import { useCallback } from "react";
import { useState } from "react";
// import { NONAME } from "dns";

import * as React from "react";
// import DialogActions from '@mui/material/DialogActions';
import { useTranslation } from "react-i18next";
import { Payment } from "@/interfaces/exchange.interface";
import { userService } from "@/services";
import CommonPage from "@/components/LayoutPage";
import CustomCard from "@/components/CustomCard";
import { COLORS } from "@/constants/colors";
import { PaymentItem } from "@/styled-components/deal";
import { CheckCircleOutlined, EditFilled, EditOutlined } from "@ant-design/icons";
import { Text } from "@/components/typography/Text";
import { PayContentDesContainerItem, PayContentItemContent, PayContentItemName } from "@/styled-components/order";
import { BaseButton } from "@/components/Button";
import styled from "styled-components";
import { checkSecurity } from "@/utils";
import useStackNavigate from "@/hooks/useStackNavigate";

const BottomContainer = styled.div`
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    padding: 20px;
`;

const AccountSetting = () => {
    const [payments, setPayments] = useState<Payment[]>([]);
    const { stackNavigate, stageStackNavigate } = useStackNavigate()
    const { t } = useTranslation();
    const [isEdited, setIsEdited] = useState(false)

    const getAccount = useCallback(() => {
        userService.getPayments().then((response) => {
            setPayments(response.data)
        })
    }, [])

    React.useEffect(()=>{
        getAccount()
    },[getAccount])

    const getOption = () => {
        return (
            isEdited ?
                <EditFilled onClick={() => {
                    setIsEdited((prev) => !prev)
                }} /> :
                <EditOutlined onClick={() => {
                    setIsEdited((prev) => !prev)
                }} />
        )
    }

    const getPaymentDetail = (payment: Payment) => {
        const columns = {
            "LINE_PAY": ["lineId", "phone", "qrCode"],
            "BANK": ["code", "account", "bankName"],
            "CASH": ["contract"],
            "STREET_PAY": ["streetId", "phone", "qrCode"]
        }
        return columns[payment.type].map((column) => {
            return (
                <PayContentDesContainerItem>
                    <PayContentItemName
                        style={{
                            fontSize: 13,
                            fontWeight: 500,
                            color: COLORS.Mid_gray
                        }}
                    >
                        {t(`page.menu.c2cManage.payment.input.${column}`)}
                    </PayContentItemName>
                    <PayContentItemContent
                        style={{ fontWeight: 400, color: COLORS.Dark_gray }}
                    >
                        {column === "qrCode" ? (
                            <img src={payment.qrCode} alt="qrCode" style={{ height: 100, width: 100 }} />
                        ) : payment[column]}
                    </PayContentItemContent>
                </PayContentDesContainerItem>
            )
        })
    }

    return (
        <CommonPage
            title={t("page.menu.c2cManage.payment.title.setPayment")}
            returnPath={"/c2c"}
            style={{ backgroundColor: COLORS.EXLight_gray }}
            headerStyle={{ backgroundColor: COLORS.EXLight_gray }}
            option={getOption()}
        >
            <div style={{ width: "100%" }}>
                {
                    payments.map((payment) => {
                        return (
                            <CustomCard style={{ marginBottom: 10 }}>
                                <div style={{ padding: 10, display: "flex", justifyContent: "space-between" }}>
                                    <PaymentItem payment={payment.type}>{t(`page.menu.c2cManage.payment.title.${payment.type}`)}</PaymentItem>
                                    <Text size="xs" style={{ padding: 0 }}>
                                        <CheckCircleOutlined style={{ marginRight: 3 }} />
                                        <span>{t("page.menu.c2cManage.payment.button.enable")}</span>
                                    </Text>
                                </div>
                                <div style={{ padding: 10 }}>
                                    {
                                        getPaymentDetail(payment)
                                    }
                                </div>
                                {
                                    isEdited &&
                                    <div style={{ padding: 10, display: "flex", justifyContent: "space-between" }}>
                                        <BaseButton
                                            style={{ width: "100%" }}
                                            color="red"
                                            onClick={() => {
                                                userService.deletePayments(payment.id).then(()=>{
                                                    getAccount()
                                                })
                                            }}
                                        >
                                            {t("page.menu.c2cManage.payment.button.delete")}
                                        </BaseButton>
                                    </div>
                                }
                            </CustomCard>
                        );
                    })
                }
            </div>
            <BottomContainer>
                <BaseButton
                    style={{ width: "100%" }}
                    onClick={() => {
                        userService.getSecurity().then((response) => {
                            checkSecurity(response.data)
                            stageStackNavigate("/choose-pay-type")
                        }).catch((e) => {
                            alert(t(`page.menu.c2cManage.payment.alert.${e.message}`))
                            switch (e.message) {
                                case "DENY": 
                                case "kyc": 
                                    stageStackNavigate("/id-verify"); 
                                    break;
                                case "phone": stackNavigate("/reset-phone-number"); break;
                                case "googleAuth": stageStackNavigate("/google-code"); break;
                                case "financePwd": stackNavigate("/set-the-fund-password"); break;
                                default: console.log(e.message)
                            }
                        })
                    }}
                >
                    {t("page.menu.c2cManage.payment.button.addPayment")}
                </BaseButton>
            </BottomContainer>
        </CommonPage>
    );
};

export default AccountSetting;
