import { useAppSelector } from "@/hooks";
import { useTranslation } from "react-i18next";
import noItemIcon from "@/assets/icon/no-data.svg";
import styled from "styled-components";
import { COLORS } from "@/constants/colors";
import { ContractSide } from "@/constants";
import { futureService } from "@/services";
import { parsePrice } from "@/utils/parse";

const TradingItemContainer = styled.div`
    width: "100%";
    flex: 1;
    display: "flex";
    flex-direction: "column";
    align-items: "space-between";
    justify-content: "space-between";
    padding: 0px 0 16px 0;
    border-bottom: 1px solid ${COLORS.EXLight_gray};
    margin-bottom: 16px;
    text-align: "left";
    font-family: "Open Sans";
`;


const PositionBoxHeader = styled.div`
    display: flex;
    flex-direction: "row";
    justify-content: space-between;
    margin-bottom: 10px;
`;

const HeaderLeft = styled.div`
    font-weight: 800;
    text-align: left;
`;

const SymbolTitle = styled.div<{ side: ContractSide }>`
    font-size: 18px;
    color: ${(props) => props.side === "BUY" ? COLORS.Green : COLORS.Red};
`;

const HeaderRight = styled.div`
    text-align: right;
    font-size: 14px;
    justify-content: flex-end;
`;

const FutureBoxBody = styled.div`
    display: flex;
    flex-direction: "row";
    justify-content: space-between;
    flex-wrap: wrap;
`

const FutureAttributeName = styled.div`
    font-size: 12px;
    color: ${COLORS.Mid_gray};
    font-weight: 400;
    margin-bottom: 5px;
`

const FutureAttributeValue = styled.div`
    font-size: 15px;
    color: ${COLORS.Gray};
    font-weight: 600;
    margin-bottom: 10px;
`

const FutureDataColumn = styled.div`
    width: 33%;
`

const FutureList = () => {
    const futures = useAppSelector((state) => state.futures.create)
    const token = useAppSelector((state)=>state.user.token)
    const { t } = useTranslation()

    return (
        <div
            style={{
                flex: 1,
                padding: "0px 0 80px 0",
            }}
        >
            {token && futures.map((future, index) => {
                return (
                    <TradingItemContainer key={index}>
                        <PositionBoxHeader>
                            <HeaderLeft>
                                <SymbolTitle side={future.side}>
                                    {future.symbol}
                                </SymbolTitle>
                            </HeaderLeft>
                            <HeaderRight>
                                <button
                                    style={{
                                        background: "#F4F4F6",
                                        borderRadius: 4,
                                        fontWeight: 500,
                                        fontSize: 14,
                                        textAlign: "center",
                                        letterSpacing: "0.012em",
                                        color: "#5F5C70",
                                        border: "none",
                                        marginTop: 0
                                    }}
                                    onClick={() => {
                                        futureService.cancel({
                                            orderId: future.orderId
                                        }).then((response)=>{
                                            alert(response.msg)
                                        })
                                    }}
                                >
                                    {t("page.deal.contract.button.cancelOrder")}
                                </button>
                            </HeaderRight>
                        </PositionBoxHeader>
                        <FutureBoxBody>
                            <FutureDataColumn>
                                <FutureAttributeName>{t("modal.future.type")}</FutureAttributeName>
                                <FutureAttributeValue>{`${t(`modal.future._type.${future.type}`)}/${t(`modal.future.side.${future.side}`)}`}</FutureAttributeValue>
                            </FutureDataColumn>
                            <FutureDataColumn>
                                <FutureAttributeName>{t("modal.future.origQty")}</FutureAttributeName>
                                <FutureAttributeValue>{future.origQty}</FutureAttributeValue>
                            </FutureDataColumn>
                            <FutureDataColumn>
                                <FutureAttributeName>{t("modal.future.dealRate")}</FutureAttributeName>
                                <FutureAttributeValue>0</FutureAttributeValue>
                            </FutureDataColumn>
                            <FutureDataColumn>
                                <FutureAttributeName>{t("modal.future.stopPrice")}</FutureAttributeName>
                                <FutureAttributeValue>{parsePrice(future.stopPrice)}</FutureAttributeValue>
                            </FutureDataColumn>
                            <FutureDataColumn>
                                <FutureAttributeName>{t("modal.future.price")}</FutureAttributeName>
                                <FutureAttributeValue>{parsePrice(future.price)}</FutureAttributeValue>
                            </FutureDataColumn>
                            <FutureDataColumn>
                                    <FutureAttributeName>{t("modal.future.avgPrice")}</FutureAttributeName>
                                    <FutureAttributeValue>{parsePrice(future.status==="CREATE" ? 0 : future.price)}</FutureAttributeValue>
                                </FutureDataColumn>
                        </FutureBoxBody>
                        <FutureBoxBody>

                        </FutureBoxBody>
                    </TradingItemContainer>
                );
            })}
            {(futures.length === 0) && (
                <div style={{textAlign: "center"}}>
                    <img
                        style={{ width: 100, height: 100, marginTop: "30px" }}
                        src={noItemIcon}
                        alt="no info"
                    />
                    <p
                        style={{
                            padding: "30px 0",
                            color: "#BDBCC8",
                            fontWeight: 500,
                            fontSize: 13,
                        }}
                    >
                        {token ? t("page.positionList.label.empty") : t("page.deal.contract.label.checkAfterLogin")}
                    </p>
                </div>
            )}
        </div>
    )
}

export default FutureList