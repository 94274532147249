import { SpotContractTransferRequest, WithdrawRequest } from "@/interfaces/request.interface";
import { ExchangeResponse } from "@/interfaces/response.interface";
import http from "@/utils/http-common";

class WalletService {
    static serviceApiPrefix = "/wallet"

    transfer(request: SpotContractTransferRequest){
        return http.post<SpotContractTransferRequest, ExchangeResponse<any>>(`${WalletService.serviceApiPrefix}/transfer`, request);
    }

    withdraw(request: WithdrawRequest){
        return http.post<SpotContractTransferRequest, ExchangeResponse<any>>(`${WalletService.serviceApiPrefix}/withdraw`, request);
    }

    sendWithdrawVerifyMail(email: string){
        return http.post<SpotContractTransferRequest, ExchangeResponse<any>>(`${WalletService.serviceApiPrefix}/withdraw/verify-code`, {email: email});
    }

    getWithdrawFee(){
        return http.get<SpotContractTransferRequest, ExchangeResponse<number>>(`${WalletService.serviceApiPrefix}/withdraw/fee`);
    }
}

export default new WalletService();