import CommonPage from "@/components/LayoutPage";
import { Header } from "@/components/typography/Header";
import { Text } from "@/components/typography/Text";
import { useTranslation } from "react-i18next";

const Privacy = () => {
    const { t } = useTranslation()
    return (
        <CommonPage title={t("page.menu.about.privacy.title")} returnPath={"/about"}>
            <div style={{ padding: "0px 15px" }}>
                <Header size="lg">{t("page.menu.about.privacy.label.subject1")}</Header>
                <Text size="sm" style={{ textAlign: "right" }}>{t("page.menu.about.privacy.label.createdDate")}</Text>
                <Header size="md">{t("page.menu.about.privacy.label.subject1")}</Header>
                <Header size="sm" style={{ lineHeight: 2 }}>{t("page.menu.about.privacy.label.content1")} </Header>
                <Header size="md">{t("page.menu.about.privacy.label.subject2")}</Header>
                <Header size="sm" style={{ lineHeight: 2 }}>{t("page.menu.about.privacy.label.content2")}</Header>
                <Header size="xs" style={{ display: "flex", marginBottom: 5 }}>
                    <div>{t("page.menu.about.privacy.label.subject2-1")}</div>
                    <div>{t("page.menu.about.privacy.label.content2-1")}</div>
                </Header>
                <Header size="xs" style={{ display: "flex", marginBottom: 5 }}>
                    <div>{t("page.menu.about.privacy.label.subject2-2")}</div>
                    <div>{t("page.menu.about.privacy.label.content2-2")}</div>
                </Header>
                <Header size="xs" style={{ display: "flex", marginBottom: 5 }}>
                    <div>{t("page.menu.about.privacy.label.subject2-3")}</div>
                    <div>{t("page.menu.about.privacy.label.content2-3")}</div>
                </Header>
                <Header size="xs" style={{ display: "flex", marginBottom: 5 }}>
                    <div>{t("page.menu.about.privacy.label.subject2-4")}</div>
                    <div>{t("page.menu.about.privacy.label.content2-4")}</div>
                </Header>
                <Header size="xs" style={{ display: "flex", marginBottom: 5 }}>
                    <div style={{ lineHeight: 2 }}>{t("page.menu.about.privacy.label.subject2-5")}</div>
                    <div style={{ lineHeight: 2 }}>{t("page.menu.about.privacy.label.content2-5")}</div>
                </Header>
                <Header size="xs" style={{ display: "flex", marginBottom: 5 }}>
                    <div>{t("page.menu.about.privacy.label.subject2-6")}</div>
                    <div>{t("page.menu.about.privacy.label.content2-6")}</div>
                </Header>
                <Header size="xs" style={{ display: "flex", marginBottom: 5 }}>
                    <div style={{ lineHeight: 2 }}>{t("page.menu.about.privacy.label.subject2-7")}</div>
                    <div style={{ lineHeight: 2 }}>{t("page.menu.about.privacy.label.content2-7")}</div>
                </Header>
                <Header size="md">{t("page.menu.about.privacy.label.subject3")}</Header>
                <Header size="sm" style={{ lineHeight: 2 }}>{t("page.menu.about.privacy.label.content3")}</Header>
                <Header size="md">{t("page.menu.about.privacy.label.subject4")}</Header>
                <Header size="sm" style={{ lineHeight: 2 }}>{t("page.menu.about.privacy.label.content4-1")}</Header>
                <Header size="sm" style={{ lineHeight: 2 }}>{t("page.menu.about.privacy.label.content4-2")}</Header>
            </div>
        </CommonPage>
    );
};

export default Privacy;
