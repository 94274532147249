import { useAppSelector } from "@/hooks";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { CopyOutlined } from "@ant-design/icons";
import { formatDateTime } from "@/utils/parse";
import CommonPage from "@/components/LayoutPage";
import { Header } from "@/components/typography/Header";
import { Text } from "@/components/typography/Text";


const UserInfoItem = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0px;
`

const MemberDetail = () => {
    const detail = useAppSelector(state => state.user.detail)
    // $ init data
    const { t } = useTranslation();
    const copyToClipboard = (text) => {
        var textField = document.createElement('textarea')
        textField.innerText = text
        document.body.appendChild(textField)
        textField.select()
        document.execCommand('copy')
        textField.remove()
    }

    return (
        <CommonPage title={""} returnPath={-1}>
            <div style={{ width: "100%", padding: "10px 15px" }}>
                <Header size={"lg"}>{t("page.profile.title")}</Header>
                <UserInfoItem>
                    <Header size="sm">{t("modal.user.account")}</Header>
                    <Text size="sm">{detail.account}</Text>
                </UserInfoItem>
                <UserInfoItem>
                    <Header size="sm">{t("modal.user.id")}</Header>
                    <Text size="sm">
                        {detail.investor && detail.investor.investorId}
                        <CopyOutlined
                            style={{
                                fontSize: 14,
                                marginLeft: 2
                            }}
                            onClick={() => {
                                copyToClipboard(detail.investor && detail.investor.investorId)
                                alert(t("page.profile.alert.copiedSuccess"))
                            }}
                        />
                    </Text>
                </UserInfoItem>
                <UserInfoItem>
                    <Header size="sm">{t("modal.user.createdDate")}</Header>
                    <Text size="sm">{formatDateTime(detail.createdDate)}</Text>

                </UserInfoItem>
                <UserInfoItem>
                    <Header size="sm">{t("modal.user.regin")}</Header>
                    <Text size="sm"></Text>
                </UserInfoItem>
            </div>
        </CommonPage>
    );
};

export default MemberDetail;
