import useWebSocket from "react-use-websocket";
import { useAppDispatch } from '@/hooks/redux-hooks'
import { marketActions } from "@/store/slice";
import defaultSettings from "@/defaultSettings";
const  { marketDomain, marketSsl } = defaultSettings;
const baseWsUrl = marketSsl ? `wss://${marketDomain}` : `ws://${marketDomain}`

const useMarketWebsocket = () => {
    const dispatch = useAppDispatch();
    const debug = true;

    useWebSocket(`${baseWsUrl}/market/ws/latest`, {
        onOpen: () => {
            debug && console.log('open market connection')
        },
        onMessage: ( message )=>{
            if ( message ) {
                const wsResponse = JSON.parse(message.data)
                dispatch(marketActions.set(wsResponse))
            }
        },
        shouldReconnect: () => true,
        reconnectInterval: 1000,
        onClose: () => debug && console.log('close market connection'),
    });
}

export default useMarketWebsocket