import { useState, useRef } from "react";
import styled from "styled-components";
import { useAppSelector, useAppDispatch } from "@/hooks";
import { useTranslation } from "react-i18next";
import noItemIcon from "@/assets/icon/no-data.svg";
import { COLORS } from "@/constants/colors";
import { ContractSide } from "@/constants";
import { positionsActions } from "@/store/slice";
import { positionService } from "@/services";
import { formatDateTime, parsePrice } from "@/utils/parse";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUpRightFromSquare, faImage, faLink, faRotate, faShare } from "@fortawesome/free-solid-svg-icons";
import { Modal, message } from "antd";
import SharedImage from "@/assets/share.svg"
import SVGInline from "react-svg-inline";
import html2canvas from "html2canvas";
import { Position } from "@/interfaces/exchange.interface";
import defaultSettings from "@/defaultSettings";
import { BaseButton } from "@/components/Button";
import qrcode from "qrcode-svg";
import "./css/position-list.css"
import useStackNavigate from "@/hooks/useStackNavigate";

const { clientDomain, ssl } = defaultSettings;
const baseApiUrl = ssl ? `https://${clientDomain}` : `http://${clientDomain}`

const TradingItemContainer = styled.div`
    width: "100%";
    flex: 1;
    display: "flex";
    flex-direction: "column";
    align-items: "space-between";
    justify-content: "space-between";
    padding: 0px 0 16px 0;
    border-bottom: 1px solid ${COLORS.EXLight_gray};
    margin-bottom: 16px;
    text-align: "left";
    font-family: "Open Sans";
`;

const PositionBoxHeader = styled.div`
    display: flex;
    flex-direction: "row";
    justify-content: space-between;
    margin-bottom: 15px;
`;

const HeaderLeft = styled.div`
    font-weight: 800;
    text-align: left;
`;

const SymbolTitle = styled.div<{ side: ContractSide }>`
    font-size: 18px;
    color: ${(props) => props.side === "BUY" ? COLORS.Green : COLORS.Red};
    margin-bottom: 5px;
`;

const PositionType = styled.div`
    font-size: 14px;
    color: ${COLORS.Gray};
`

const PositionColumn = styled.div`
    width: 33%;
    margin: 8px 0px;
`

const HeaderRight = styled.div`
    text-align: right;
    font-size: 14px;
    justify-content: flex-end;
`;

const ProfitAndLossTitle = styled.div`
    font-weight: 600;
    color: ${COLORS.Gray};
    margin-bottom: 5px;
`
const ProfitAndLossValue = styled.div<{ positive: Boolean }>`
    font-weight: 800;
    color: ${(props) => props.positive ? COLORS.Green : COLORS.Red};
    font-size: 16px;
`;

const PositionBoxBody = styled.div`
    display: flex;
    flex-direction: "row";
    justify-content: space-between;
    margin-bottom: 10px;
    flex-wrap: wrap;
`

const PositionAttributeName = styled.div`
    font-size: 13px;
    color: ${COLORS.Mid_gray};
    font-weight: 400;
    margin-bottom: 5px;
`

const PositionAttributeValue = styled.div`
    font-size: 14px;
    color: ${COLORS.Gray};
    font-weight: 600;
`

const PositionRooter = styled.div`
    width: 100%;
    height: 26px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const OperationButton = styled.button`
    width: 164px;
    height: 20px;
    background: ${COLORS.EXLight_gray};
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 14px;
    color: ${COLORS.Gray};
    border: none;
`

const CustomIcon = styled(FontAwesomeIcon)`
    background: ${COLORS.Green};
    border-radius: 90px !important;
    font-size: 16px;
    padding: 12px; 
    color: ${COLORS.White};

    &:active {
        background-color: ${COLORS.GreenActive} !important;
    }
`

const PositionList = () => {
    const token = useAppSelector((state)=>state.user.token)
    const positions = useAppSelector((state) => state.positions.trading)
    const market = useAppSelector((state) => state.market.price)
    const inviteCode = useAppSelector((state => token ? state.user.detail.investor.inviteCode : ""))
    const [, setLoading] = useState(false)
    const dispatch = useAppDispatch();
    const { t } = useTranslation()
    const { stackNavigate } = useStackNavigate()
    const [open, setOpen] = useState(false);
    const [svgContent, setSvgContent] = useState("");
    const [shareLink,] = useState(`${baseApiUrl}/#/register/${inviteCode}`)
    const [active, setActive] = useState(false)

    const showModal = () => {
        setOpen(true);
    };

    const hideModal = () => {
        setOpen(false);
    };

    const createShareImage = (selected: Position) => {
        const currentPrice = market.find((c) => c.s === selected.symbol)
        // 解析 SVG 字串並插入文字
        fetch(SharedImage)
            .then((response) => response.text())
            .then((svgString) => {
                const parser = new DOMParser();
                const svgDocument = parser.parseFromString(svgString, "image/svg+xml");
                const qr = new qrcode({
                    content: shareLink,
                    padding: 0,
                    width: 120,
                    height: 120,
                });
                const qrCodeSvgString = qr.svg();

                const content = [
                    {
                        text: "XEBit",
                        fontSize: "50",
                        gap: 8
                    },
                    {
                        text: `${formatDateTime(Date.now())} (GMT+8)`,
                        fontSize: "25",
                        gap: 15
                    },
                    {
                        text: selected.symbol,
                        fontSize: "40",
                        gap: 8
                    },
                    {
                        text: `${t("page.positionList.label.do")}${t(`position.side.${selected.side}`)} | ${selected.leverage}X`,
                        fontSize: "35",
                        color: selected.side === "BUY" ? COLORS.Green : COLORS.Red,
                        gap: 20
                    },
                    {
                        text: `${((selected.profitAndLoss / selected.avgPrice) * 100).toPrecision(2)}%`,
                        fontSize: "70",
                        gap: 15,
                        color: selected.profitAndLoss >= 0 ? (selected.profitAndLoss > 0 ? COLORS.Green : COLORS.White) : COLORS.Red
                    },
                    {
                        text: `${t("page.positionList.label.openPrice")}   $${parsePrice(selected.avgPrice)}`,
                        fontSize: "30",
                        gap: 8
                    },
                    {
                        text: `${t("page.positionList.label.currentPrice")}   $${currentPrice ? parsePrice(parseFloat(currentPrice.c)) : 0}`,
                        fontSize: "30"
                    },
                ]
                let y = 15;
                content.forEach((item, index) => {
                    const exchangeName = document.createElementNS(
                        "http://www.w3.org/2000/svg",
                        "text"
                    );
                    exchangeName.textContent = item.text;
                    exchangeName.setAttribute("x", "8%");
                    exchangeName.setAttribute("y", `${y}%`);
                    exchangeName.setAttribute("fill", item.color ?? "white");
                    exchangeName.setAttribute("font-size", item.fontSize);
                    exchangeName.setAttribute("font-weight", "700");
                    svgDocument.documentElement.appendChild(exchangeName);
                    y = y + (item.gap ?? 10 * index);
                })

                const inviteCodeText = document.createElementNS(
                    "http://www.w3.org/2000/svg",
                    "text"
                );
                inviteCodeText.textContent = `${t("page.positionList.label.inviteCode")}: ${inviteCode}`;
                inviteCodeText.setAttribute("x", "55%");
                inviteCodeText.setAttribute("y", "90%");
                inviteCodeText.setAttribute("fill", "white");
                inviteCodeText.setAttribute("font-size", "30");
                inviteCodeText.setAttribute("font-weight", "700");
                svgDocument.documentElement.appendChild(inviteCodeText);

                const parserQR = new DOMParser();
                const qrCodeDocument = parserQR.parseFromString(qrCodeSvgString, "image/svg+xml");
                const qrCodeElement = qrCodeDocument.documentElement;
                qrCodeElement.setAttribute("x", "85%"); // 設定 X 座標位置
                qrCodeElement.setAttribute("y", "80%"); // 設定 Y 座標位置
                svgDocument.documentElement.appendChild(qrCodeElement);

                const serializer = new XMLSerializer();
                setSvgContent(serializer.serializeToString(svgDocument));
            });
    }

    // 點擊按鈕時觸發下載動作
    // const handleDownload = () => {
    //     const svgBlob = new Blob([svgContent], { type: "image/svg+xml" });
    //     const url = URL.createObjectURL(svgBlob);
    //     const link = document.createElement("a");
    //     link.href = url;
    //     link.download = "download.svg";
    //     document.body.appendChild(link);
    //     link.click();
    //     document.body.removeChild(link);
    //     URL.revokeObjectURL(url); // Clean up the object URL to release resources
    // };

    const svgContainerRef = useRef<HTMLDivElement>(null);

    const handleDownload = () => {
        const svgContainer = svgContainerRef.current;

        if (svgContainer) {
            setActive(true)
            // 使用 html2canvas 將 SVG 元素轉換為 Canvas
            html2canvas(svgContainer, { width: 300, height: 180 }).then((canvas) => {
                // 創建新的 Canvas 元素並設定寬高
                const newCanvas = document.createElement("canvas");
                newCanvas.width = canvas.width - 40; // 原寬度減去左右 20 個像素
                newCanvas.height = canvas.height;

                // 獲取新 Canvas 的 2D 繪圖上下文
                const ctx = newCanvas.getContext("2d");

                ctx && ctx.drawImage(
                    canvas, // 原 Canvas 元素
                    20, // 原始圖像的開始 X 座標位置，跳過左邊的 20 像素
                    0, // 原始圖像的開始 Y 座標位置，從頂部開始
                    canvas.width - 40, // 原始圖像的寬度，減去左右各 20 像素
                    canvas.height, // 原始圖像的高度
                    0, // 目標 Canvas 上的開始 X 座標位置，從左邊開始
                    0, // 目標 Canvas 上的開始 Y 座標位置，從頂部開始
                    canvas.width - 40, // 目標 Canvas 上繪製的寬度，保持不變
                    canvas.height // 目標 Canvas 上繪製的高度，保持不變
                );

                // 將新 Canvas 轉換為圖片
                const imageUrl = newCanvas.toDataURL("image/png");
                // window.ReactNativeWebView.postMessage(imageUrl)
                const image = new Image();
                image.src = imageUrl;

                image.onload = () => {
                    const a = document.createElement("a");
                    a.href = imageUrl;
                    a.download = "download.png";

                    // 創建一個點擊事件，模擬用戶點擊下載按鈕
                    const event = new MouseEvent("click");
                    a.dispatchEvent(event);
                };
            }).finally(() => {
                setActive(false)
            });
        }
    };

    const handleCopiedLink = () => {
        var textField = document.createElement('textarea')
        textField.innerText = shareLink
        document.body.appendChild(textField)
        textField.select()
        document.execCommand('copy')
        textField.remove()
        message.info(t("page.positionList.notice.copiedSuccess"))
    };

    return (
        <div>
            {
                token && positions.filter(p => p.status === "TRADING").map((position, index) => {
                    return (
                        <TradingItemContainer key={index}>
                            <PositionBoxHeader>
                                <HeaderLeft>
                                    <SymbolTitle side={position.side}>
                                        {`${t(`modal.position.side.${position.side}`)} ${position.symbol}`}
                                    </SymbolTitle>
                                    <PositionType>
                                        {`${t(`modal.position.type.${position.type}`)} ${position.leverage}X`}
                                    </PositionType>
                                </HeaderLeft>
                                <HeaderRight>
                                    <ProfitAndLossTitle>
                                        {t(`modal.position.profitAndLoss`)}
                                    </ProfitAndLossTitle>
                                    <ProfitAndLossValue positive={position.profitAndLoss >= 0}>
                                        {parsePrice(position.profitAndLoss)}
                                    </ProfitAndLossValue>
                                </HeaderRight>
                            </PositionBoxHeader>
                            <PositionBoxBody>
                                <PositionColumn>
                                    <PositionAttributeName>{t("modal.position.quantity")}</PositionAttributeName>
                                    <PositionAttributeValue>{position.quantity}</PositionAttributeValue>
                                </PositionColumn>
                                <PositionColumn>
                                    <PositionAttributeName>{t("modal.position.avgPrice")}</PositionAttributeName>
                                    <PositionAttributeValue>{parsePrice(position.avgPrice)}</PositionAttributeValue>
                                </PositionColumn>
                                <PositionColumn>
                                    <PositionAttributeName>{t("modal.position.markPrice")}</PositionAttributeName>
                                    <PositionAttributeValue>{parsePrice(parseFloat(market.find((c) => c.s === position.symbol)?.m ?? "0"))}</PositionAttributeValue>
                                </PositionColumn>
                                <PositionColumn>
                                    <PositionAttributeName>{t("modal.position.forceClose")}</PositionAttributeName>
                                    <PositionAttributeValue>{parsePrice(position.forceClose)}</PositionAttributeValue>
                                </PositionColumn>
                                <PositionColumn>
                                    <PositionAttributeName>{t("modal.position.margin")}</PositionAttributeName>
                                    <PositionAttributeValue>{parsePrice(position.margin)}</PositionAttributeValue>
                                </PositionColumn>
                                <PositionColumn>
                                    <PositionAttributeName>{`${t("modal.position.profitPrice")}/${t("modal.position.lossPrice")}`}</PositionAttributeName>
                                    <PositionAttributeValue>{`${parsePrice(position.profitPrice ?? "0")}/${parsePrice(position.lossPrice ?? "0")}`}</PositionAttributeValue>
                                </PositionColumn>
                            </PositionBoxBody>
                            <PositionRooter>
                                <OperationButton
                                    onClick={() => {
                                        stackNavigate("/deal/StopLossStopEarning", { state: { position: position } })
                                    }}
                                >
                                    {t("modal.position.operation.stopProfitLoss")}
                                </OperationButton>
                                <OperationButton
                                    onClick={() => {
                                        var yes = window.confirm(t("page.deal.contract.notice.close"));

                                        if ( !yes ) return;
                                        
                                        dispatch(positionsActions.close(position.positionId))
                                        setLoading(true);
                                        positionService.close({
                                            positionId: position.positionId
                                        }).finally(() => {
                                            setLoading(false);
                                        })
                                    }}
                                >
                                    {t("modal.position.operation.close")}
                                </OperationButton>
                                <FontAwesomeIcon
                                    icon={faArrowUpRightFromSquare}
                                    onClick={() => {
                                        createShareImage(position)
                                        showModal()
                                    }}
                                />
                            </PositionRooter>
                        </TradingItemContainer>
                    );
                })}
            {positions.length === 0 && (
                <div style={{ textAlign: "center" }}>
                    <img
                        style={{ width: 100, height: 100, marginTop: "30px" }}
                        src={noItemIcon}
                        alt="no info"
                    />
                    <p
                        style={{
                            padding: "30px 0",
                            color: "#BDBCC8",
                            fontWeight: 500,
                            fontSize: 13,
                        }}
                    >
                        {token ? t("page.positionList.label.empty") : t("page.deal.contract.label.checkAfterLogin")}
                    </p>
                </div>
            )}
            <Modal
                centered
                width={"300px"}
                visible={open}
                footer={<BaseButton style={{ width: "100%" }} onClick={hideModal}>取消</BaseButton>}
                closable={false}
                onCancel={hideModal}
                bodyStyle={{ padding: 0 }}
            >
                <div style={{ height: "100%" }} ref={svgContainerRef}>
                    <SVGInline svg={svgContent} />
                </div>
                <div style={{ display: "flex", justifyContent: "space-around", padding: "20px 0px" }}>
                    <div style={{ textAlign: "center" }} onClick={handleDownload}>
                        {
                            active ? <CustomIcon icon={faRotate} spin /> : <CustomIcon icon={faImage} />
                        }
                        <div style={{ fontWeight: 600, color: COLORS.Mid_gray }}>
                            {t(`page.positionList.label${active ? "downloading" : "saveImage"}`)}
                        </div>
                    </div>
                    <div style={{ textAlign: "center" }}>
                        <CustomIcon icon={faShare} />
                        <div style={{ fontWeight: 600, color: COLORS.Mid_gray }}>
                            {t(`page.positionList.button.share`)}
                        </div>
                    </div>
                    <div style={{ textAlign: "center" }} onClick={handleCopiedLink}>
                        <CustomIcon icon={faLink} />
                        <div style={{ fontWeight: 600, color: COLORS.Mid_gray }}>
                        {t(`page.positionList.button.copiedLink`)}
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default PositionList