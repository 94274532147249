import { useTranslation } from "react-i18next";
import CommonPage from "@/components/LayoutPage";
import { LabelInput } from "@/components/Input";
import { useState } from "react";
import { CheckOutlined } from "@ant-design/icons";
import { COLORS } from "@/constants/colors";
import { userService } from "@/services";
import { useAppDispatch, useAppSelector } from "@/hooks";
import { message } from "antd";
import useStackNavigate from "@/hooks/useStackNavigate";
import { userActions } from "@/store/slice";
import Modal from "@/components/UI/CoverModal";
import { CheckboxCancel, CheckboxConfirm, ConfirmCheckbox, ConfirmContainer, ConfirmContent, ConfirmContentContainer, ConfirmContentTitle } from "@/styled-components/deal";

const EditName = () => {
    const account = useAppSelector((state)=>state.user.detail.account)
    const [ visible, setVisible] = useState(false)
    const [nickname, setNickname] = useState("")
    const { t } = useTranslation();
    const { stackNavigate } = useStackNavigate()
    const dispatch = useAppDispatch()

    return (
        <CommonPage
            title={t("page.menu.c2cManage.info.edit.title")}
            returnPath={"/memberCenter"}
            option={
                <CheckOutlined 
                    style={{color: nickname ? COLORS.Green : COLORS.Mid_gray}} 
                    onClick={()=>{
                        if (nickname===""){
                            return;
                        }
                        userService.getOtcUserInfo(account).then((response) => {
                            const now = Date.now()
                            const after = now - response.nicknameUpdatedDate;
                            if ( after < 15783000000 ) {
                                message.error(t("page.menu.c2cManage.info.edit.alert.error"))
                            }
                            else {
                                setVisible(true)
                            }
                        })
                    }}
                />
            }
        >
            <LabelInput
                label={t("page.menu.c2cManage.info.edit.input.nickname")}
                placeholder={t("page.menu.c2cManage.info.edit.placeholder.nickname")}
                value={nickname}
                onChange={(e) => {
                    setNickname(e.target.value)
                }}
            />
            <div style={{ padding: 20, width: "100%" }}>
                <p style={{ color: "#8F8DA2", fontSize: 13, fontWeight: 500, marginTop: 10 }}>{t("page.menu.c2cManage.info.edit.notice.nickname")}</p>
            </div>
            {visible && (
                <Modal>
                    <ConfirmContainer>
                        <ConfirmContentContainer style={{padding: 16}}>
                            <ConfirmContentTitle>
                                {t("page.menu.c2cManage.info.edit.label.confirmEdit")}
                            </ConfirmContentTitle>
                            <ConfirmContent>
                                {t("page.menu.c2cManage.info.edit.label.editMemo")}
                            </ConfirmContent>
                        </ConfirmContentContainer>
                        <ConfirmCheckbox>
                            <CheckboxCancel
                                onClick={() => {
                                    setVisible(false);
                                }}
                            >
                                {t("page.menu.c2cManage.info.edit.button.cancel")}
                            </CheckboxCancel>
                            <CheckboxConfirm
                                onClick={() => {
                                    userService.updateOtcUserInfo(account,{nickName: nickname}).then(()=>{
                                        message.info(t("page.menu.c2cManage.info.edit.alert.success"))
                                    }).then(()=>{
                                        dispatch(userActions.setNickname(nickname))
                                        stackNavigate(-1)
                                    })
                                    .catch((error)=>{
                                        message.error(error.response.data.msg)
                                    })
                                }}
                            >
                                {t("page.menu.c2cManage.info.edit.button.confirm")}
                            </CheckboxConfirm>
                        </ConfirmCheckbox>
                    </ConfirmContainer>
                </Modal>
            )}
        </CommonPage>
    );
};

export default EditName;
