import request from './request';
import defaultSettings from "@/defaultSettings";
const  { marketDomain, marketSsl } = defaultSettings;
const baseWsUrl = marketSsl ? `https://${marketDomain}` : `http://${marketDomain}`

interface getKLineArgs {
    symbol: string;
    intv: string;
    start?: number | null | undefined;
    end?: number | null | undefined;
    limit?: number | null | undefined;
}

export const getKline = (
    {
        symbol,
        intv,
        start = 0,
        end = 0,
        limit = 0
    }: getKLineArgs
) => {
    const mainUrl = `${baseWsUrl}/market/kline`;
    const optionParam = `?intv=${intv}&symbol=${symbol}${start ? `&start=${start}` : ''}${end ? `&end=${end}` : ''}${limit ? `&limit=${limit}` : ''}`;
    const url = `${mainUrl}${optionParam}`;

    return request({
        url
    }, false);
};