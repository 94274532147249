import { COLORS } from "@/constants/colors";
import { CSSProperties, ReactNode, useState } from "react";
import styled from "styled-components";

const TabContainer = styled.div`
    display: flex;
    /* justify-content: space-between; */
    width: 100%;
    border-bottom: 1px solid ${COLORS.EXLight_gray};
`

const TabPane = styled.div<{ active: boolean, color?: string }>`
    padding: 5px 8px;
    font-size: 14px;
    text-align: center;
    font-weight: ${props => props.active ? 600 : 400};
    border-bottom: 3px solid ${props => props.active ? props.color ?? COLORS.Green : COLORS.White};
`;

export interface TabPaneProps {
    key: number
    title: string
    onClick?: Function
    children?: ReactNode
}

const Tab: React.FC<{ 
    color?: string
    panes: TabPaneProps[], 
    defaultKey?: number, 
    style?: CSSProperties, 
    paneStyle?: CSSProperties,
}> = ({ color, panes, defaultKey, style, paneStyle }) => {
    const [activePane, setActivePane] = useState(defaultKey ? defaultKey : 0)

    return (
        <div style={{ width: "100%" }}>
            <TabContainer style={style}>
                {
                    panes.map((pane, index) => {
                        return (
                            <TabPane
                                style={paneStyle}
                                key={index}
                                active={activePane === pane.key}
                                onClick={() => {
                                    pane.onClick && pane.onClick()
                                    setActivePane(pane.key)

                                }}
                                color={color}
                            >
                                {pane.title}
                            </TabPane>
                        )
                    })
                }
            </TabContainer>
            <div>
                {
                    panes.find(pane => pane.key === activePane)?.children
                }
            </div>
        </div>
    )
}

export default Tab;