// import request from './request';
import axios from 'axios';
import { binanceBaseURL } from '@/constants/api';

export const getBinanceLimitedDepth = (
    symbol: string,
    limit: string | number
) => {
    return axios.get(`${binanceBaseURL}/api/v3/depth?symbol=${symbol}&limit=${limit}`);
};
