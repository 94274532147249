import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import UserService from "@/services/user.service";
import { User, UserInfo } from '@/interfaces/exchange.interface';
import { OtcUser } from '@/interfaces/otc.interface';
import { Country, Currency } from '@/interfaces/system.interface';

const initialState: UserInfo = {
    token: "",
    detail: {} as User,
    favorite: [] as string[],
    otc: {} as OtcUser,
    country: {
        code: "TW",
        callingCode: "886",
        phoneRegExp: "^(09\\d{8}|9\\d{8})$"
    } as Country,
    currency: {
        name: "TWD",
        rate: 0
    } as Currency,
    language: "tw",
    isRemind: false
}

const userSlice = createSlice({
    name: "user",
    initialState: initialState,
    reducers: {
        setToken(state, action: PayloadAction<string>) {
            state.token = action.payload
        },
        setUser(state, action: PayloadAction<User>) {
            state.detail = action.payload
        },
        logout(state) {
            state.token = ""
            state.detail = {} as User
            state.otc = {} as OtcUser
            state.favorite = []
        },
        setFavorite(state, action: PayloadAction<string[]>) {
            state.favorite = action.payload
        },
        addFavorite(state, action: PayloadAction<string>) {
            state.favorite = state.favorite.concat(action.payload)
        },
        removeFavorite(state, action: PayloadAction<string>) {
            state.favorite = state.favorite.filter(symbol => symbol !== action.payload)
        },
        setCountry(state, action: PayloadAction<Country>) {
            state.country = action.payload
        },
        setCurrency(state, action: PayloadAction<Currency>) {
            state.currency = action.payload
        },
        setLanguage(state, action: PayloadAction<string>) {
            state.language = action.payload
        },
        setRemind(state) {
            state.isRemind = !state.isRemind
        },
        setOtcSmsNotification(state, action: PayloadAction<boolean>) {
            state.otc.smsNotification = action.payload
        },
        setOtcMailNotification(state, action: PayloadAction<boolean>) {
            state.otc.mailNotification = action.payload
        },
        setNickname(state, action: PayloadAction<string>) {
            state.otc.nickName = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchUser.fulfilled, (state, action) => {
            state.detail = action.payload
            fetchOtcUser(action.payload.account)
        })
        builder.addCase(fetchOtcUser.fulfilled, (state, action) => {
            state.otc = action.payload
        })
    }
});

export const userActions = userSlice.actions;

export const fetchUser = createAsyncThunk('user/fetchUser', async () => {
    const response = await UserService.getUserInfo()
    return response.data;
});

export const fetchOtcUser = createAsyncThunk<OtcUser, string>('user/fetchOtcUser', async (payload) => {
    const response = await UserService.getOtcUserInfo(payload)
    return response;
});

export default userSlice.reducer;