import { useTranslation } from "react-i18next";
import { PhotoButtonContainer, PhotoButtonContent, PhotoPNG } from "@/styled-components/profile";
import { useAppSelector } from "@/hooks";
import { useCallback, useEffect, useState } from "react";
import { Payment } from "@/interfaces/exchange.interface";
import { userService } from "@/services";
import useStackNavigate from "@/hooks/useStackNavigate";
import UploadPhotoZone from "@/pages/menu/components/UploadPhotoZone"
import CommonPage from "@/components/LayoutPage";
import { LabelContainer, LabelInput } from "@/components/Input";
import { BottomContainer } from "@/components/Other";
import { BaseButton } from "@/components/Button";
import { Drawer, List, message } from "antd";
import { CenterListItem } from "@/styled-components/deal";
import { Header } from "@/components/typography/Header";
import CustomInput from "@/components/Input/CustomInput";

const PayTypeLine = () => {
    const { t } = useTranslation();
    const { stackNavigate } = useStackNavigate()
    const user = useAppSelector((state) => state.user.detail)
    const [application, setApplication] = useState<Payment>({} as Payment)
    const [loading, setLoading] = useState(false)
    const [qrCodeSrc, setQrCodeSrc] = useState("");
    const [file, setFile] = useState(null);
    const [isDisable, setIsDisable] = useState(true)
    const [code, setCode] = useState("")
    const [visible, setVisible] = useState(false);

    const readFile = (e: any) => {
        setFile(e.target.files[0])
        var reader = new FileReader();
        reader.onload = function (event: any) {
            // The file's text will be printed here
            setQrCodeSrc(event.target.result);
        };
        reader.readAsDataURL(e.target.files[0]);
    };

    const createApplication = () => {
        if (application.contract === "") {
            alert(t("page.menu.c2cManage.payment.alert.data"))
            return;
        }
        setLoading(true)
        const formData: any = new FormData();
        formData.append("lineId", application.lineId)
        formData.append("phone", application.phone)
        formData.append("qrCode", file)
        formData.append("type", "LINE_PAY")
        formData.append("ga", code)
        userService.createPayment(formData).then(() => {
            alert(t("page.menu.c2cManage.payment.alert.success"))
            stackNavigate("jump")
        }).catch((error)=>{
            message.error(error.response.data.msg)
        }).finally(() => {
            setLoading(false)
        })
    }

    const checkoutUserInput = useCallback(() => {
        setIsDisable( () => {
            return !user?.kyc || !application.lineId  || !file;
        } )
    }, [user, application, file])

    useEffect(()=>{
        checkoutUserInput()
    },[checkoutUserInput])

    return (
        <CommonPage active={loading} title={`${t("page.menu.c2cManage.payment.title.add")} ${t("page.menu.c2cManage.payment.title.LINE_PAY")}`} returnPath={"/choose-pay-type"}>
            <LabelInput
                label={t("page.menu.c2cManage.payment.input.name")}
                value={user.kyc && user.kyc.name}
                disable={true}
            />
            <LabelInput
                label={t("page.menu.c2cManage.payment.input.lineId")}
                placeholder={t("page.menu.c2cManage.payment.placeholder.lineId")}
                onChange={(e) => {
                    const value = e.target.value
                    setApplication((prev) => {
                        return {
                            ...prev,
                            lineId: value
                        }
                    })
                }}
            />
            <LabelInput
                label={t("page.menu.c2cManage.payment.input.phone")}
                placeholder={t("page.menu.c2cManage.payment.placeholder.linePhone")}
                onChange={(e) => {
                    const value = e.target.value
                    setApplication((prev) => {
                        return {
                            ...prev,
                            phone: value
                        }
                    })
                }}
            />
            <LabelContainer label={t("page.menu.c2cManage.payment.input.qrCode")}>
                <PhotoButtonContainer>
                    <PhotoButtonContent onChange={e => readFile(e)} htmlFor="formId">
                        <input name="" type="file" id="formId" hidden />
                        {
                            qrCodeSrc ? (
                                <PhotoPNG src={qrCodeSrc} alt="照片" />
                            ) : (
                                <UploadPhotoZone title={t('page.menu.c2cManage.payment.button.upload')} />
                            )
                        }
                    </PhotoButtonContent>
                </PhotoButtonContainer>
            </LabelContainer>
            <BottomContainer>
                <BaseButton disabled={isDisable} onClick={() => setVisible(true)}>
                    {t("page.menu.c2cManage.payment.button.add")}
                </BaseButton>
            </BottomContainer>
            <Drawer
                placement={"bottom"}
                closable={false}
                onClose={() => setVisible(false)}
                key={"ga"}
                visible={visible}
                bodyStyle={{ padding: 0 }}
                height={"auto"}
                footer={
                    <BaseButton
                        style={{ width: "100%" }}
                        onClick={createApplication}
                    >
                        {t("page.menu.c2cManage.payment.button.confirm")}
                    </BaseButton>
                }
            >
                <List style={{ width: "100%" }}>
                    <CenterListItem>{t("page.menu.c2cManage.payment.label.security")}</CenterListItem>
                    <div style={{ width: "100%", margin: "0px 15px" }}>
                        <Header size="sm" style={{marginLeft: 5}}>
                            {t("page.menu.c2cManage.payment.input.googleAuth")}
                        </Header>
                        <CustomInput
                            inputType="line"
                            placeholder={t("page.menu.c2cManage.payment.placeholder.googleAuth")}
                            value={code}
                            onChange={(e) => setCode(e.target.value)}
                        />
                    </div>
                </List>
            </Drawer>
        </CommonPage>
    )
}

export default PayTypeLine