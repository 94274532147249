import { MarketPrice, MarketEntity } from "@/interfaces/market.interface";

export const parsePrice = (price: number | null | undefined) => {
    if (typeof price === 'number') {
        const finalPrice = (price > 0 && price < 0.006) ? price.toFixed(6) :
            (price >= 0.006 && price < 0.1) ? price.toFixed(5) :
                (price >= 0.1 && price < 1) ? price.toFixed(4) :
                    (price >= 1 && price < 50) ? price.toFixed(3) : price.toFixed(2)
        return parseFloat(finalPrice)
    }
    else {
        return "0"
    }

}

export const formatDate = (timestamp) => {
    if (!timestamp) {
        return "";
    }
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
}

export const formatTime = (timestamp) => {
    if (!timestamp) {
        return "";
    }
    const date = new Date(timestamp);

    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${minutes}:${seconds}`;
}

export const formatDateTime = (timestamp) => {
    if (!timestamp) {
        return "";
    }
    const date = new Date(timestamp);

    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    const formattedDate = `${formatDate(timestamp)} ${hours}:${minutes}:${seconds}`;

    return formattedDate;
}

export const toMarketEntity = (originalMarketUnit: MarketPrice) => {
    var entity: MarketEntity = {
        eventType: originalMarketUnit.e,
        eventTime: originalMarketUnit.E,
        symbol: originalMarketUnit.s,
        priceChange: parseFloat(originalMarketUnit.p),
        priceChangePercent: parseFloat(originalMarketUnit.P),
        averagePrice: parseFloat(originalMarketUnit.w),
        latestPrice: parseFloat(originalMarketUnit.c),
        volume: parseFloat(originalMarketUnit.v),
        markPrice: parseFloat(originalMarketUnit.m),
    }

    return entity;
}

export const toMarketPrice = (renamedMarketUnit: MarketEntity) => {
    var entity: MarketPrice = {
        e: renamedMarketUnit.eventType,
        E: renamedMarketUnit.eventTime,
        s: renamedMarketUnit.symbol,
        p: `${renamedMarketUnit.priceChange}`,
        P: `${renamedMarketUnit.priceChangePercent}`,
        w: `${renamedMarketUnit.averagePrice}`,
        c: `${renamedMarketUnit.latestPrice}`,
        v: `${renamedMarketUnit.volume}`,
        m: `${renamedMarketUnit.markPrice}`
    }

    return entity;
}

export const  formatNumberWithChineseUnit = (number: number): string => {
    const units = ['', '萬', '億', '兆'];
    const unitIndex = Math.floor(Math.log10(Math.abs(number)) / 4);
    const unit = units[unitIndex];
    const formattedNumber = (number / Math.pow(10, unitIndex * 4)).toFixed(2);
  
    return `${formattedNumber}${unit}`;
  }

export const roundDown = ( num, decimal ) => { return Math.floor( ( num + Number.EPSILON ) * Math.pow( 10, decimal ) ) / Math.pow( 10, decimal ); }