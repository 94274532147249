import {
    useState,
    useEffect,
    useCallback,
} from "react";

// ? Self-packed Components || Functions
import Footer from "@/components/footer/HomeFooter";
import { COLORS } from "@/constants/colors";
import StopLossDrawer from "@/components/fund/StoplossDrawer";
import Drawer from "@/components/UI/Drawer";
import Slider from "@/components/UI/Slider";
import LeverSlider from "@/components/UI/leverSlider";
import OrderTypeDrawer from "@/components/fund/orderTypeDrawer";
import CounterPartyPriceDrawer from "@/components/fund/CounterpartyPriceDrawer";
import { DealPriceZone } from '@/pages/Deal/components/DealPriceZone';

// - Images
import chart from "@/assets/icon/Deal/chart.svg";
import transfer from "@/assets/icon/Deal/transfer.png";
import arrowDown from "@/assets/icon/Deal/arrowDown.png";
import cancel from "@/assets/icon/cancel.png";
import checkIcon from "@/assets/icon/Deal/check.png";
import minus from "@/assets/icon/Deal/remove.png";
import add from "@/assets/icon/Deal/add.png";
import buyBg from '@/assets/deal/buy_bg.jpg';
import sellBg from '@/assets/deal/sell_bg.jpg';

// ^ Plugins
import { isEmpty, find, get } from "lodash"
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay-ts";

// = Styled Component
import { FlexAlignCenterDiv } from '@/styled-components';
import {
    PageContainer,
    TitleContainer,
    TitleLeftButton,
    TitleRightButton,
    DealPairContainer,
    DealPairChange,
    ContractContainer,
    CurrentContractContainer,
    CurrentContractName,
    CurrentContractImageList,
    CurrentContractImage,
    CurrentContractButtonContainer,
    CurrentContractButton,
    TradeUnitButtonHalf,
    OperateContainer,
    OperateButtonList,
    TradeFunctionContainer,
    TradeFunctionLeftSide,
    OperateCurrencyButton,
    OperateDealBgContainer,
    OperateLeftButton,
    OperateRightButton,
    OperateUnitButton,
    DrawerFullPositionTitleContainer,
    DrawerFullPositionImage,
    DrawerFullPositionTitle,
    DrawerFullPositionSelectContainer,
    DrawerFullPositionSelectButton,
    DrawerFullPositionSelectTitle,
    DrawerFullPositionContent,
    DrawerFullPositionContentTitle,
    DrawerFullPositionContentDescription,
    DrawerFullPositionConfirm,
    DrawerLeverButtonContainer,
    DrawerLeverButtonTitle,
    DrawerLeverButtonImg,
    DrawerLeverSliderScale,
    DrawerLeverSliderScaleText,
    TradeFunctionLeftContainer,
    TradeFunctionLeftItem,
    CounterPriceItem,
    TradeFunctionDisplayContainer,
    TradeFunctionButtonContainer,
    PositionPriceInputContainer,
    PositionPriceInput,
    PositionCountInput,
    PositionFunctionContainer,
    PositionTransferContainer,
    AvailableContent
} from '@/styled-components/deal'

import FutureList from "./FutureList";
import PositionList from "./PositionList";
import { useAppDispatch, useAppSelector } from "@/hooks";
import { futureService, investorService } from "@/services";
import { ContractSide } from "@/constants";
import { tradingActions } from "@/store/slice";
import { CreateFutureRequest } from "@/interfaces/request.interface";
import { CaretDownOutlined, SearchOutlined } from "@ant-design/icons";
import { BaseButton } from "@/components/Button";
import useStackNavigate from "@/hooks/useStackNavigate";
import { parsePrice } from "@/utils/parse";
/**
 * @author odin
 * @level Layout/Deal
 * @description 交易頁面
*/
const Deal = () => {
    // $ init data
    const location = useLocation().pathname;
    const { stackNavigate } = useStackNavigate()
    // % context
    const marketArr = useAppSelector((state) => state.market.price)
    const dispatch = useAppDispatch()
    const tradeSymbol = useAppSelector((state) => state.trading.request.symbol)
    const token = useAppSelector((state) => state.user.token)
    // # states
    const [currencyOption, setCurrencyOption] = useState(0);
    const [dealOption, setDealOption] = useState(0);
    // const [currencyAmount, setCurrencyAmount] = useState(0);
    const [leverRatio, setLeverRatio] = useState(1);
    const [templeverRatio, setTempLeverRatio] = useState(1);
    const [leverage, setLeverage] = useState(0);
    const [slider, setSlider] = useState(0);
    const [positionDrawerVisible, setPositionDrawerVisible] = useState(false);
    const [leverageDrawerVisible, setLeverageDrawerVisible] = useState(false);
    const [orderType, setOrderType] = useState(false);
    const [counterPartyPriceDrawer, setCounterPartyPriceDrawer] = useState(false);
    const [drawerType, setDrawerType] = useState("");
    const [stopLossDrawerVisible, setStopLossDrawerVisible] = useState(false);
    // const [commissionFunctionSelect, setCommissionFunctionSelect] = useState(0);
    const [fullPositionSelect, setFullPositionSelect] = useState(0);
    const [isBaseOnUsdt, setIsBaseOnUsdt] = useState(false);
    const [orderTypeSelect, setOrderTypeSelect] = useState(0);
    const [counterPartyPrice, setCounterPartyPrice] = useState(0);
    const [positionPrice, setPositionPrice] = useState("");
    const [userInputPrice, setUserInputPrice] = useState("");
    const [triggerPrice, setTriggerPrice] = useState("");
    const [stopLoss, setStopLoss] = useState<string | number>("");
    const [stopLossPrice, setStopLossPrice] = useState<string | number>("");
    const [stopEarning, setStopEarning] = useState<string | number>("");
    const [stopEarningPrice, setStopEarningPrice] = useState<string | number>("");
    const { t } = useTranslation();
    const [fullPositionDisplay, setFullPositionDisplay] = useState(false);
    const [positionUnitDisplay, setPositionUnitDisplay] = useState(false);
    const [functionLeftCheckbox, setFunctionLeftCheckbox] = useState(false);
    const [isUserTyping, setIsUserTyping] = useState(false);
    const [inputAmount, setInputAmount] = useState("");
    const [loading, setLoading] = useState(false);
    // const [position, setPosition] = useState(false);
    const [balance, setBalance] = useState(0);

    // 當前交易對 24小時價格變化(百分比)
    const [nowChange, setNowChange] = useState(0);

    // 當前交易對 最新成交價格
    const [price, setPrice] = useState("");

    // 當前交易對 標記價格
    const [remarkPrice, setRemarkPrice] = useState("");

    const [canOpen, setCanOpen] = useState(0);

    // - methods
    const handleUnitOption = (index: number) => {
        setDealOption(index);
        console.log(functionLeftCheckbox);
        console.log(leverage)
    };

    const handlerDrawer = (type: string) => {
        setDrawerType(type);
        if (type === "Position") {
            setFullPositionDisplay(!!fullPositionSelect);
            setPositionDrawerVisible((v) => !v);
        } else if (type === "leverage") {
            setLeverage(leverRatio);
            setLeverageDrawerVisible((v) => !v);
        } else {
            setPositionDrawerVisible((v) => !v);
        }
    };

    const handlerCheckFullPosition = (type: number) => {
        setFullPositionSelect(type);
    };

    const handlerCalculate = (move: string) => {
        move === "add" ? setTempLeverRatio((v) => v + 1 >= 125 ? 125 : v + 1) : setTempLeverRatio((v) => v - 1 < 1 ? 1 : v - 1);
    };

    const handlerOrderDrawer = () => {
        setOrderType((v) => !v);
    };

    const getBalance = useCallback((side: ContractSide) => {
        investorService.getAvailableQuantity(tradeSymbol, side).then((response) => {
            setCanOpen(response.data);
        })

        investorService.getAvailableBalance().then((response) => {
            setBalance(response.data);
        })
    }, [tradeSymbol])

    const getLeverage = (symbol: string) => {
        investorService.getLeverage(symbol).then((response) => {
            setLeverRatio(response.data)
            setTempLeverRatio(response.data)
        })
    };

    const getCostMargin = () => {
        if ( isBaseOnUsdt ){
            return inputAmount==="" ? `0 USDT` : `${parseFloat(inputAmount) / leverRatio} USDT`
        }
        const orderPrice = orderTypeSelect===0 ? userInputPrice : price;
        const orderAmount = inputAmount;
        if ( orderPrice==="" || leverRatio===0 || price==="" || orderAmount==="") {
            return `0 USDT`;
        }

        return `${parseFloat(orderPrice)*parseFloat(orderAmount) / leverRatio} USDT`
    }

    const getAfterTransfer = () => {
        const orderPrice = orderTypeSelect===0 ? userInputPrice : price;
        const orderAmount = inputAmount;
        const base = tradeSymbol.split("-")[0];
        if ( orderPrice==="" || orderAmount==="" || price==="")
        {
            return `≈ 0${base}`
        }
        return `≈ ${parsePrice(parseFloat(inputAmount)/parseFloat(orderPrice))}${base}`
    }

    /**
     * @author odin
     * @description 改變單位
    */
    const handleUnitChange = (isUnitUSDT: boolean) => {
        setIsBaseOnUsdt((prev) => !prev)
        setPositionUnitDisplay(isUnitUSDT);
        setSlider(0);
    };

    useEffect(() => {
        setInputAmount("");
    }, [positionUnitDisplay])

    // * hooks
    /**
     * @author odin
     * 1. Remark Price
     * 2. Remark Change
    */
    useEffect(() => {
        if (!isEmpty(marketArr)) {
            const t = tradeSymbol ? tradeSymbol.split("-")[0] + "-USDT" : tradeSymbol
            const remark = find(marketArr, function (o) { return o.s === t });

            // console.log('marketArr', marketArr);
            const remarkChange = parseFloat(get(remark, 'P', 0));

            setRemarkPrice(remark ? remark!.m : "0")
            setPrice(remark ? remark!.c : "0");
            setNowChange(remarkChange);
        }
    }, [marketArr, tradeSymbol]);

    useEffect(() => {
        const t = tradeSymbol ? tradeSymbol.split("-")[0] + "-USDT" : tradeSymbol
        const remark = find(marketArr, function (o) { return o.s === t })
        const price = remark ? remark!.c.toString().slice(0, -2) : 0;
        setPositionPrice(price);
        // if ( price!==0 ){
        //     setUserInputPrice(userInputPrice==="" ? price : userInputPrice)
        // }
    }, [marketArr, tradeSymbol, userInputPrice])

    useEffect(() => {
        if (token) {
            getBalance(currencyOption === 0 ? "BUY" : "SELL")
            getLeverage(tradeSymbol ? tradeSymbol.split("-")[0] + "-USDT" : tradeSymbol)
            const interval = setInterval(() => {
                getBalance(currencyOption === 0 ? "BUY" : "SELL")
            }, 1000);
            return () => clearInterval(interval);
        }
    }, [tradeSymbol, currencyOption, getBalance, token]);

    useEffect(()=>{
        setIsUserTyping(false)
        setUserInputPrice("")
    },[orderTypeSelect])

    useEffect(()=>{
        if ( orderTypeSelect===0 && userInputPrice==="" && price!=="" && !isUserTyping){
            setUserInputPrice(`${parsePrice(parseFloat(price))}`)
        }
    },[orderTypeSelect, userInputPrice, price, isUserTyping])

    return (
        <>
            <LoadingOverlay active={loading} spinner>
                <PageContainer>
                    <TitleContainer>
                        <TitleLeftButton>{t("page.deal.contract.title")}</TitleLeftButton>
                        <div onClick={() => stackNavigate("/fiat-deal")}>
                            <TitleRightButton>{t("page.deal.contract.label.tradeFiat")}</TitleRightButton>
                        </div>
                    </TitleContainer>

                    {/* 交易對 Container */}
                    <DealPairContainer>
                        <CurrentContractContainer>
                            <CurrentContractName>
                                {/* 搜尋 */}
                                <div onClick={() => stackNavigate("/deal/search-deal", { state: "/deal" })}>
                                    {/* <CurrentContractImage style={{ marginLeft: 0, marginRight: 10 }} src={search} alt="search" /> */}
                                    <SearchOutlined style={{ marginLeft: 0, marginRight: 5, color: COLORS.Black }} />
                                </div>

                                {/* 交易對 */}
                                <div style={{ marginRight: 5 }}>
                                    {tradeSymbol}
                                </div>

                                {/* 變動％數 */}
                                <DealPairChange nowChange={nowChange}>
                                    {
                                        nowChange >= 0
                                            ? `+${nowChange}%`
                                            : `${nowChange}%`
                                    }
                                </DealPairChange>
                            </CurrentContractName>
                            {/* 圖表按鈕連結 */}
                            <CurrentContractImageList>
                                <div>
                                    <CurrentContractImage src={chart} alt="chart" onClick={() => {
                                        stackNavigate("/deal/chart", { state: tradeSymbol })
                                    }} />
                                </div>
                            </CurrentContractImageList>
                        </CurrentContractContainer>
                    </DealPairContainer>

                    {/* 全倉以及倍數 */}
                    <ContractContainer>
                        <CurrentContractButtonContainer>
                            <CurrentContractButton
                                onClick={handlerDrawer.bind(null, "Position")}
                            >
                                {fullPositionDisplay ? t("modal.position.type.EACH") : t("modal.position.type.FULL")}
                                <CaretDownOutlined style={{ fontSize: 8, padding: "0px 2px" }} />
                            </CurrentContractButton>

                            <CurrentContractButton
                                onClick={() => {
                                    handlerDrawer("leverage")
                                }}
                            >
                                {`${leverRatio ? Math.round(leverRatio) : 1}X`}
                                <CaretDownOutlined style={{ fontSize: 8, padding: "0px 2px" }} />
                            </CurrentContractButton>
                        </CurrentContractButtonContainer>
                    </ContractContainer>

                    {/* 操作 */}
                    <OperateContainer orderTypeSelect={orderTypeSelect}>
                        <OperateButtonList>

                            <OperateDealBgContainer
                                currencyOption={currencyOption}
                                style={{
                                    backgroundImage: `url(${currencyOption === 0 ? buyBg : sellBg})`,
                                }}
                            >
                                {/* 買入 */}
                                <OperateLeftButton
                                    currencyOption={currencyOption}
                                    index={0}
                                    onClick={() => setCurrencyOption(0)}
                                >
                                    {t("page.deal.contract.label.buy")}
                                </OperateLeftButton>

                                {/* 賣出 */}
                                <OperateRightButton
                                    currencyOption={currencyOption}
                                    index={1}
                                    onClick={() => setCurrencyOption(1)}
                                >
                                    {t("page.deal.contract.label.sell")}
                                </OperateRightButton>
                            </OperateDealBgContainer>

                            <div style={{ display: "flex", justifyContent: "space-between", width: "46%", alignItems: "center" }}>
                                <OperateCurrencyButton
                                    style={{
                                        fontSize: 10,
                                        paddingLeft: 16
                                    }}
                                    currencyOption={currencyOption}
                                    index={2}
                                // onClick={() => setCurrencyOption(2)}
                                >
                                    <p>{t("page.deal.contract.label.price")}</p>
                                    <p style={{ fontFamily: "Open Sans", fontWeight: 500 }}>{"(USDT)"}</p>
                                </OperateCurrencyButton>
                                <OperateCurrencyButton
                                    style={{
                                        fontSize: 10,
                                        textAlign: "right"
                                    }}
                                    currencyOption={currencyOption}
                                    index={3}
                                // onClick={() => setCurrencyOption(3)}
                                >
                                    <p>{t("page.deal.contract.label.amount")}</p>
                                    <p style={{ fontFamily: "Open Sans", fontWeight: 500 }}>({tradeSymbol.split("-")[0]})</p>
                                </OperateCurrencyButton>
                            </div>
                        </OperateButtonList>

                        {/* 交易內容區塊 */}
                        <TradeFunctionContainer>
                            <TradeFunctionLeftSide>
                                <TradeFunctionLeftContainer orderTypeSelect={orderTypeSelect}>
                                    {/* 限價 */}
                                    <TradeFunctionLeftItem onClick={handlerOrderDrawer}>
                                        <p style={{ color: COLORS.Dark_gray }}>
                                            {orderTypeSelect === 0
                                                ? t("modal.future._type.LIMIT")
                                                : orderTypeSelect === 1
                                                    ? t("modal.future._type.MARKET")
                                                    : orderTypeSelect === 2
                                                        ? t("modal.future._type.STOP_LIMIT")
                                                        : t("modal.future._type.STOP_MARKET")}

                                        </p>
                                        <img
                                            src={arrowDown}
                                            alt="arrow-down"
                                            style={{ width: 10, height: 5 }}
                                        />
                                    </TradeFunctionLeftItem>

                                    {/* 價格區塊，根據限價的其他選項而變動 */}
                                    <TradeFunctionLeftItem style={{ padding: "12px 10px" }}>
                                        {orderTypeSelect === 0 ? (
                                            <PositionPriceInputContainer content="USDT">
                                                <PositionPriceInput
                                                    value={userInputPrice}
                                                    onChange={(e) => {
                                                        setIsUserTyping(true)
                                                        setUserInputPrice(e.target.value);
                                                    }}
                                                    placeholder={t("page.deal.contract.placeholder.price")}
                                                />
                                            </PositionPriceInputContainer>
                                        ) : orderTypeSelect === 1 ? (
                                            <CounterPriceItem
                                            // onClick={() => setCounterPartyPriceDrawer((v) => !v)}
                                            >
                                                <p style={{ color: COLORS.Mid_gray }}>
                                                    {counterPartyPrice === 0
                                                        ? t("modal.future._type.MARKET")
                                                        : `${t("page.deal.contract.label.perfect")}${counterPartyPrice}${t("page.deal.contract.label.numbers")}`}
                                                </p>
                                                <p style={{ fontFamily: "Open Sans", fontWeight: 500 }}>USDT</p>
                                            </CounterPriceItem>
                                        ) : (
                                            <PositionPriceInputContainer content="USDT">
                                                <PositionPriceInput
                                                    value={triggerPrice}
                                                    onChange={(e) => {
                                                        setTriggerPrice(e.target.value);
                                                    }}
                                                    placeholder={t("page.deal.contract.placeholder.conditionPrice")}
                                                />
                                            </PositionPriceInputContainer>
                                        )}
                                    </TradeFunctionLeftItem>

                                    {orderTypeSelect === 2 && (
                                        <TradeFunctionLeftItem>
                                            <PositionPriceInputContainer content="USDT">
                                                <PositionPriceInput
                                                    value={userInputPrice}
                                                    onChange={(e) => {
                                                        setUserInputPrice(e.target.value);
                                                    }}
                                                    placeholder={t("page.deal.contract.placeholder.price")}
                                                />
                                            </PositionPriceInputContainer>
                                        </TradeFunctionLeftItem>
                                    )}

                                    {/* 交易單位按鈕 */}
                                    <TradeFunctionLeftItem>
                                        {/* <TradeUnitButton onClick={handlerDrawer.bind(null, "Unit")}>
                      {positionUnitDisplay ? "USDT" : nowTrade.split("-")[0]}
                    </TradeUnitButton> */}

                                        {/* 現在交易對的名稱 */}
                                        <TradeUnitButtonHalf isActive={!positionUnitDisplay} side={currencyOption} onClick={() => { handleUnitChange(false) }}>
                                            {tradeSymbol.split("-")[0]}
                                        </TradeUnitButtonHalf>

                                        {/* 現在交易對的名稱 */}
                                        <TradeUnitButtonHalf isActive={positionUnitDisplay} side={currencyOption} onClick={() => { handleUnitChange(true) }}>
                                            USDT
                                        </TradeUnitButtonHalf>
                                    </TradeFunctionLeftItem>

                                    <TradeFunctionLeftItem>
                                        <PositionPriceInputContainer content={!positionUnitDisplay ? `${tradeSymbol.split("-")[0]}(${slider}%)` : `USDT(${slider}%)`}>

                                            <PositionCountInput
                                                // style={{
                                                //   color:
                                                //   inputPrice > 0.2 ? COLORS.Red : "#8f8da2",
                                                // }}
                                                value={inputAmount}
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    if ( value==="" ){
                                                        setSlider(0);
                                                        setInputAmount("")
                                                        return;
                                                    }
                                                    if (token) {
                                                        if (isBaseOnUsdt) {
                                                            if (parseFloat(e.target.value) > balance) {
                                                                const full = balance.toFixed(0);
                                                                setInputAmount(full);
                                                                setSlider(100)
                                                            } else {
                                                                setInputAmount(value);
                                                                // console.log(e.target.value)
                                                                setSlider(parseFloat(((parseFloat(value)) / (balance) * 100).toFixed(0)) ? parseFloat(((parseFloat(value)) / (balance) * 100).toFixed(0)) : 0)
                                                            }
                                                        } else {
                                                            if (parseFloat(value) > balance * leverRatio / parseFloat(positionPrice)) {
                                                                var price = (balance * leverRatio / parseFloat(positionPrice))
                                                                setInputAmount(price.toString());
                                                                setSlider(100)
                                                            } else {
                                                                setInputAmount(value);
                                                                // console.log(e.target.value)
                                                                setSlider(parseFloat(((parseFloat(value) * parseFloat(positionPrice)) / (balance * leverRatio) * 100).toFixed(0)) ? parseFloat(((parseFloat(value) * parseFloat(positionPrice)) / (balance * leverRatio) * 100).toFixed(0)) : 0)
                                                            }
                                                        }

                                                    } else {
                                                        setInputAmount(e.target.value);
                                                        setSlider(0)
                                                    }

                                                }}
                                                precision={3}
                                                placeholder={`${t("page.deal.contract.placeholder.amount")}`}
                                            />
                                        </PositionPriceInputContainer>
                                    </TradeFunctionLeftItem>
                                </TradeFunctionLeftContainer>
                                {
                                    positionUnitDisplay &&
                                    <p style={{ fontSize: "12px", color: "#8f8da2", textAlign: "right", fontWeight: 500, fontFamily: "Open Sans" }}>
                                        {getAfterTransfer()}
                                    </p>
                                }
                                {/* 進度條 */}
                                <div>
                                    <Slider
                                        Amount={slider}
                                        handleCurrencyAmount={setSlider}
                                        setInputPrice={setInputAmount}
                                        total={positionUnitDisplay ? (balance.toFixed(4)) : canOpen.toFixed(4)}
                                    />
                                    <DrawerLeverSliderScale>
                                        <DrawerLeverSliderScaleText>0%</DrawerLeverSliderScaleText>
                                        <DrawerLeverSliderScaleText>25%</DrawerLeverSliderScaleText>
                                        <DrawerLeverSliderScaleText>50%</DrawerLeverSliderScaleText>
                                        <DrawerLeverSliderScaleText>75%</DrawerLeverSliderScaleText>
                                        <DrawerLeverSliderScaleText style={{ marginRight: "-10px" }}>100%</DrawerLeverSliderScaleText>
                                    </DrawerLeverSliderScale>
                                </div>

                                <TradeFunctionDisplayContainer>
                                    {/* 可用 */}
                                    <FlexAlignCenterDiv>
                                        <div onClick={() => stackNavigate("/fund/transfer")} style={{ display: "flex", alignItems: "center" }}>
                                            {t("page.deal.contract.label.availableU")}
                                            {/* 資金劃轉 */}
                                            <CurrentContractImage src={transfer} alt="unit" />
                                        </div>

                                        <AvailableContent>
                                            <p style={{ color: "#383743", fontWeight: 600, fontFamily: "Open Sans" }}>
                                                {balance.toFixed(4)} USDT
                                            </p>
                                        </AvailableContent>
                                    </FlexAlignCenterDiv>

                                    {/* 可開 */}
                                    <FlexAlignCenterDiv>
                                        <div>{t("page.deal.contract.label.availableQ")}</div>

                                        <AvailableContent>
                                            <p style={{ color: "#383743", fontWeight: 600, fontFamily: "Open Sans" }}>
                                                {isBaseOnUsdt ? `${balance * leverRatio} USDT` : `${canOpen} ${tradeSymbol.split("-")[0]}`}
                                            </p>
                                        </AvailableContent>

                                    </FlexAlignCenterDiv>

                                    {/* 保證金 */}
                                    <FlexAlignCenterDiv>
                                        <div>{t("page.deal.contract.label.margin")}</div>

                                        <AvailableContent>
                                            <p style={{ color: "#383743", fontWeight: 600, fontFamily: "Open Sans" }}>
                                                {getCostMargin()}
                                            </p>
                                        </AvailableContent>

                                    </FlexAlignCenterDiv>
                                </TradeFunctionDisplayContainer>

                                {/* 買入、賣出按鈕區塊 */}
                                <TradeFunctionButtonContainer>
                                    {currencyOption === 0 ?
                                        <BaseButton
                                            color={token ? "green" : "dark-gray"}
                                            style={{
                                                width: "100%"
                                            }}
                                            onClick={() => {
                                                if (token) {
                                                    if (!positionPrice) {
                                                        alert(t("page.deal.contract.alert.price"));
                                                    } else if (!inputAmount) {
                                                        alert(t("page.deal.contract.alert.amount"));
                                                    } else {
                                                        var number = parseFloat(inputAmount)
                                                        if (positionUnitDisplay) {
                                                            number = parseFloat((parseFloat(inputAmount) * leverRatio / parseFloat(positionPrice)).toString().substring(0, (parseFloat(inputAmount) * leverRatio / parseFloat(positionPrice)).toString().indexOf(".") + 3))
                                                        }
                                                        setLoading(true);
                                                        var obj: CreateFutureRequest = orderTypeSelect === 0 ?
                                                            {
                                                                price: parseFloat(userInputPrice),
                                                                origQty: number,
                                                                side: "BUY",
                                                                symbol: tradeSymbol,
                                                                type: "LIMIT",
                                                                stopPrice: null
                                                            } : orderTypeSelect === 1 ? {
                                                                origQty: number,
                                                                side: "BUY",
                                                                symbol: tradeSymbol,
                                                                type: "MARKET",
                                                                stopPrice: null,
                                                                price: null
                                                            } : orderTypeSelect === 2 ? {
                                                                price: parseFloat(userInputPrice),
                                                                origQty: number,
                                                                side: "BUY",
                                                                symbol: tradeSymbol,
                                                                type: "STOP_LIMIT",
                                                                stopPrice: parseFloat(triggerPrice)
                                                            } : {
                                                                origQty: number,
                                                                side: "BUY",
                                                                symbol: tradeSymbol,
                                                                type: "STOP_MARKET",
                                                                stopPrice: parseFloat(triggerPrice),
                                                                price: null
                                                            }
                                                        futureService.openOrder(obj).then(() => {
                                                            setInputAmount("")
                                                            setTriggerPrice("")
                                                            setSlider(0)
                                                        }).catch((error)=>{
                                                            error.response.status===403 && alert(t("page.deal.contract.alert.forbidden"));
                                                        }).finally(() => {
                                                            setLoading(false);
                                                        })
                                                    }
                                                } else {
                                                    alert(t("page.deal.contract.alert.login"));
                                                }
                                            }}
                                        >
                                            {t("page.deal.contract.label.buy")}
                                        </BaseButton> :
                                        <BaseButton
                                            color={token ? "red" : "dark-gray"}
                                            style={{
                                                width: "100%"
                                            }}
                                            onClick={() => {
                                                if (token) {
                                                    if (!positionPrice) {
                                                        alert(t("page.deal.contract.alert.price"));
                                                    } else if (!inputAmount) {
                                                        alert(t("page.deal.contract.alert.amount"));
                                                    } else {
                                                        setLoading(true);
                                                        var number = parseFloat(inputAmount)
                                                        if (positionUnitDisplay) {
                                                            number = parseFloat((parseFloat(inputAmount) * leverRatio / parseFloat(positionPrice)).toString().substring(0, (parseFloat(inputAmount) * leverRatio / parseFloat(positionPrice)).toString().indexOf(".") + 3))
                                                        }
                                                        var obj: CreateFutureRequest = orderTypeSelect === 0 ?
                                                            {
                                                                price: parseFloat(positionPrice),
                                                                origQty: number,
                                                                side: "SELL",
                                                                symbol: tradeSymbol,
                                                                type: "LIMIT",
                                                                stopPrice: null
                                                            } : orderTypeSelect === 1 ? {
                                                                origQty: number,
                                                                side: "SELL",
                                                                symbol: tradeSymbol,
                                                                type: "MARKET",
                                                                price: null,
                                                                stopPrice: null
                                                            } : orderTypeSelect === 2 ? {
                                                                price: parseFloat(positionPrice),
                                                                origQty: number,
                                                                side: "SELL",
                                                                symbol: tradeSymbol,
                                                                type: "STOP_LIMIT",
                                                                stopPrice: parseFloat(triggerPrice)
                                                            } : {
                                                                origQty: number,
                                                                side: "SELL",
                                                                symbol: tradeSymbol,
                                                                type: "STOP_MARKET",
                                                                stopPrice: parseFloat(triggerPrice),
                                                                price: null
                                                            }
                                                        futureService.openOrder(obj).then(() => {
                                                            setInputAmount("")
                                                            setTriggerPrice("")
                                                            setSlider(0)
                                                        }).finally(() => {
                                                            setLoading(false);
                                                        })
                                                    }
                                                } else {
                                                    alert(t("page.deal.contract.alert.login"));
                                                }
                                            }}
                                        >
                                            {t("page.deal.contract.label.sell")}
                                        </BaseButton>}
                                </TradeFunctionButtonContainer>
                            </TradeFunctionLeftSide>

                            {/* 右半邊 */}
                            <DealPriceZone
                                price={price}
                                remarkPrice={remarkPrice}
                                symbol={tradeSymbol.split("-")[0] + 'USDT'}
                            />
                        </TradeFunctionContainer>
                    </OperateContainer>

                    <PositionFunctionContainer>
                        <PositionTransferContainer>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    fontWeight: 500,
                                    width: "100%"
                                }}
                            >
                                {/* 當前持倉 */}
                                <OperateUnitButton
                                    currencyOption={dealOption}
                                    index={0}
                                    onClick={handleUnitOption.bind(null, 0)}
                                >
                                    {t("page.deal.contract.label.activePosition")}
                                </OperateUnitButton>

                                {/* 當前委託 */}
                                <OperateUnitButton
                                    currencyOption={dealOption}
                                    index={1}
                                    onClick={handleUnitOption.bind(null, 1)}
                                >
                                    {t("page.deal.contract.label.activeOrder")}
                                </OperateUnitButton>
                            </div>

                        </PositionTransferContainer>

                        {dealOption === 1 ? <FutureList /> : <PositionList />}
                    </PositionFunctionContainer>

                    <Drawer
                        isVisible={positionDrawerVisible}
                        selectVisible={handlerDrawer.bind(null, "other")}
                        height={292}
                    >
                        <DrawerFullPositionTitleContainer>
                            <DrawerFullPositionImage
                                src={cancel}
                                alt="cancel"
                                onClick={handlerDrawer.bind(null, "other")}
                            />
                            <DrawerFullPositionTitle>
                                {t("page.deal.contract.label.marginMode")}
                            </DrawerFullPositionTitle>
                        </DrawerFullPositionTitleContainer>
                        <DrawerFullPositionSelectContainer>
                            <DrawerFullPositionSelectButton
                                type={0}
                                isChecked={
                                    fullPositionSelect
                                }
                                onClick={
                                    handlerCheckFullPosition.bind(null, 0)
                                }
                            >
                                <DrawerFullPositionSelectTitle
                                    type={0}
                                    isChecked={fullPositionSelect}
                                >
                                    {t("modal.position.type.FULL")}
                                </DrawerFullPositionSelectTitle>
                                <img
                                    src={checkIcon}
                                    alt="cancel"
                                    style={{ width: 24, height: 24 }}
                                />
                            </DrawerFullPositionSelectButton>
                            <DrawerFullPositionSelectButton
                                type={1}
                                isChecked={fullPositionSelect}
                                onClick={() => {
                                    alert(t("modal.position.notice.each"))
                                }}
                            >
                                <DrawerFullPositionSelectTitle
                                    type={1}
                                    isChecked={fullPositionSelect}
                                >
                                    {t("modal.position.type.EACH")}
                                </DrawerFullPositionSelectTitle>
                                <img
                                    src={checkIcon}
                                    alt="cancel"
                                    style={{ width: 24, height: 24 }}
                                />
                            </DrawerFullPositionSelectButton>
                        </DrawerFullPositionSelectContainer>
                        {drawerType === "Position" ? (
                            <DrawerFullPositionContent>
                                {t("page.deal.contract.notice.FULL")}
                            </DrawerFullPositionContent>
                        ) : (
                            <DrawerFullPositionContent>
                                <p>{`${tradeSymbol.split("-")[0]}/USDT ${t("page.deal.contract.label.perpetualContract")}`}</p>
                                <p>{`1 ${tradeSymbol.split("-")[0]} ≈ ${positionPrice} USDT`}</p>
                            </DrawerFullPositionContent>
                        )}
                        <DrawerFullPositionConfirm
                            onClick={() => {
                                if (isBaseOnUsdt) {
                                    setPositionUnitDisplay(false)
                                } else {
                                    setPositionUnitDisplay(true)
                                }
                                setInputAmount("")
                                setSlider(0)
                                handlerDrawer("Position");
                            }}
                        >{t("page.deal.contract.button.confirm")}</DrawerFullPositionConfirm>
                    </Drawer>
                    <Drawer
                        isVisible={leverageDrawerVisible}
                        selectVisible={handlerDrawer.bind(null, "leverage")}
                        height={425}
                    >
                        <DrawerFullPositionTitleContainer>
                            <DrawerFullPositionImage
                                src={cancel}
                                alt="cancel"
                                onClick={handlerDrawer.bind(null, "leverage")}
                            />
                            <DrawerFullPositionTitle>{t("page.deal.contract.label.leverage")}</DrawerFullPositionTitle>
                        </DrawerFullPositionTitleContainer>
                        <DrawerLeverButtonContainer>
                            <DrawerLeverButtonImg
                                src={minus}
                                alt="cancel"
                                onClick={handlerCalculate.bind(null, "minus")}
                            />
                            <DrawerLeverButtonTitle>{`${templeverRatio ? (templeverRatio <= 1 ? 1 : Math.round(templeverRatio)) : 1
                                }X`}</DrawerLeverButtonTitle>
                            <DrawerLeverButtonImg
                                src={add}
                                alt="cancel"
                                onClick={handlerCalculate.bind(null, "add")}
                            />
                        </DrawerLeverButtonContainer>
                        <LeverSlider
                            Amount={templeverRatio}
                            handleCurrencyAmount={setTempLeverRatio}
                        />
                        <DrawerLeverSliderScale>
                            <DrawerLeverSliderScaleText>1x</DrawerLeverSliderScaleText>
                            <DrawerLeverSliderScaleText>25x</DrawerLeverSliderScaleText>
                            <DrawerLeverSliderScaleText style={{ marginLeft: "10px" }}>50x</DrawerLeverSliderScaleText>
                            <DrawerLeverSliderScaleText style={{ marginLeft: "10px" }}>75x</DrawerLeverSliderScaleText>
                            <DrawerLeverSliderScaleText style={{ marginLeft: "10px" }}>100x</DrawerLeverSliderScaleText>
                            <DrawerLeverSliderScaleText style={{ marginRight: "-10px" }}>125x</DrawerLeverSliderScaleText>
                        </DrawerLeverSliderScale>
                        <DrawerFullPositionContent style={{ height: 136 }}>
                            <DrawerFullPositionContentTitle>{t("page.deal.contract.notice.leverageMsg")}</DrawerFullPositionContentTitle>
                            <DrawerFullPositionContentDescription>{`${t("page.deal.contract.notice.afterAdjustLeverage")} ${tradeSymbol.split("-")[0]} ${t("page.deal.contract.notice.newPerpetualContract")}：`}</DrawerFullPositionContentDescription>
                            <DrawerFullPositionContentDescription>{`${balance.toString().substring(0, balance.toString().indexOf('.') + 3)} USDT ${t("page.deal.contract.label.positionMargin")}`}</DrawerFullPositionContentDescription>
                            <DrawerFullPositionContentDescription>{`${balance.toString().substring(0, balance.toString().indexOf('.') + 3)} USDT ${t("page.deal.contract.label.availableMargin")}`}</DrawerFullPositionContentDescription>
                        </DrawerFullPositionContent>
                        <DrawerFullPositionConfirm
                            onClick={() => {
                                futureService.adjustLeverage({
                                    symbol: tradeSymbol,
                                    leverage: templeverRatio
                                }).then(() => {
                                    dispatch(tradingActions.setLeverage(templeverRatio))
                                    handlerDrawer("leverage")
                                    setLeverRatio(templeverRatio)
                                }).catch((error) => {
                                    alert(error.response.data.msg)
                                })
                            }}
                        >{t("page.deal.contract.button.confirm")}</DrawerFullPositionConfirm>
                    </Drawer>
                    <OrderTypeDrawer
                        isVisible={orderType}
                        selectVisible={setOrderType}
                        height={300}
                        setOrderTypeSelect={setOrderTypeSelect}
                        orderTypeSelect={orderTypeSelect}
                    />
                    <CounterPartyPriceDrawer
                        isVisible={counterPartyPriceDrawer}
                        selectVisible={setCounterPartyPriceDrawer}
                        height={375}
                        setOrderTypeSelect={setCounterPartyPrice}
                        orderTypeSelect={counterPartyPrice}
                    />
                    <StopLossDrawer
                        isVisible={stopLossDrawerVisible}
                        selectVisible={setStopLossDrawerVisible}
                        height={553}
                        stopLoss={stopLoss}
                        setStopLoss={setStopLoss}
                        stopLossPrice={stopLossPrice}
                        setStopLossPrice={setStopLossPrice}
                        stopEarning={stopEarning}
                        setStopEarning={setStopEarning}
                        stopEarningPrice={stopEarningPrice}
                        setStopEarningPrice={setStopEarningPrice}
                        setFunctionLeftCheckbox={setFunctionLeftCheckbox}
                    />
                    <Footer locationPage={location} />
                </PageContainer>
                {/* {revokeIsVisual && (
                    <Modal>
                        <ConfirmContainer>
                            <ConfirmContentContainer>
                                <ConfirmContentTitle>撤銷委託單？</ConfirmContentTitle>
                                <ConfirmContent>
                                    確定撤銷委託單後，將無法再次回復該筆委託單內容。
                                </ConfirmContent>
                            </ConfirmContentContainer>
                            <ConfirmCheckbox>
                                <CheckboxCancel onClick={() => setRevokeIsVisual((v) => !v)}>
                                    {t("cancel")}
                                </CheckboxCancel>
                                <CheckboxConfirm
                                    onClick={() => {
                                        setLoading(true);
                                        api
                                            .post("/order/futures/cancel-order", { orderId })
                                            .then((x) => {
                                                setLoading(false);
                                            });
                                        setRevokeIsVisual((v) => !v);
                                    }}
                                >
                                    {t("OK")}
                                </CheckboxConfirm>
                            </ConfirmCheckbox>
                        </ConfirmContainer>
                    </Modal>
                )} */}
            </LoadingOverlay>
        </>
    );
};

export default Deal;
