import { useTranslation } from "react-i18next";
import CommonPage from "@/components/LayoutPage";

const CustomerService = () => {
    const { t } = useTranslation()
    return (
        <CommonPage title={t("page.customerService")} returnPath={-1}>
            <iframe
                title={"cs"}
                src="https://tawk.to/chat/64b9e7a9cc26a871b029bacf/1h5r31sfg"
                style={{
                    height: "100%",
                    width: "100%"
                }}
            ></iframe>
        </CommonPage>
    );
};

export default CustomerService;