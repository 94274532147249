import { useState, useEffect, useCallback } from "react";
import { COLORS } from "@/constants/colors";
import Drawer from "@/components/UI/Drawer";
import CryptoIcon from "@/pages/fund/components/CryptoIcon";

// - Images
import cancel from "@/assets/icon/cancel.png";
import noItemIcon from "@/assets/icon/no-data.svg";
import { Divider, Switch } from "antd";
// ^ Plugins
import useStackNavigate from "@/hooks/useStackNavigate";
import Moment from "moment";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "@/hooks";
// = Styled Component
import {
    CurrencyContainer,
    CurrencyListContainer,
    NoDealContainer,
    RecordCard,
    RecordHeaderContainer,
    CurrencyCardKey,
    CurrencyCardKeyDescription,
    CurrencyCardTotalAmount,
    CurrencyCardContentDescription,
    DrawerContainer,
    DepthTitle,
    DrawerTitleContainer,
    CancelIcon,
    PayContentDesContainerItem,
    PayContentItemName,
    PayContentItemContent,
    PayContentItemSplit,
    PayStatusText,
    NoItemIcon,
    NoItemStatus,
    NoItemDes,
    PayConfirmButton,
    RecordNavigationButton,
    RecordHeaderLeft,
    RecordHeaderRight,
    RecordContentContainer,
    RecordContentLeft,
    RecordContentRight,
    OrderDetail
} from '@/styled-components/order';
import { OtcOrder } from "@/interfaces/otc.interface";
import { otcOrderService } from "@/services";
import { otcOrderActions } from "@/store/slice";
import CommonPage from "@/components/LayoutPage";
import { RightOutlined } from "@ant-design/icons";
import { roundDown } from "@/utils/parse";

const FiatOrders = () => {

    // $ init data
    const { t } = useTranslation();
    const { stackNavigate, stageStackNavigate } = useStackNavigate()
    const dispatch = useAppDispatch();
    const account = useAppSelector((state) => state.user.detail.account)
    const buying = useAppSelector((state) => state.c2c.buying)
    const selling = useAppSelector((state) => state.c2c.selling)

    const [records, setRecords] = useState<OtcOrder[]>([]);
    const [selectedRecords, setSelectedRecords] = useState<OtcOrder[]>([]);
    const [, setLoading] = useState(false);
    const menuOptions = ["processing", "all", "buy", "sell"];
    const [activeOption, setActiveOption] = useState("processing");
    // # states
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [completeDrawerVisible, setCompleteDrawerVisible] = useState(false);
    const [itemDetail, setItemDetail] = useState("buy");
    const [selectedOrder, setSelectedOrder] = useState<OtcOrder>({} as OtcOrder);

    // - methods
    const handlerDrawer = () => {
        setDrawerVisible(v => !v);
    };

    const handlerCompleteDrawer = (status: string) => {
        setItemDetail(status);
        setCompleteDrawerVisible(v => !v);
    };

    const getRecord = useCallback(() => {
        setLoading(true)
        if (Object.keys(buying).length !== 0 || Object.keys(selling).length !== 0) {
            console.log("update")
        }
        otcOrderService.list({
            all: false
        }).then((orders) => {
            setRecords(orders)
        }).finally(() => {
            setLoading(false)
        })
    }, [buying, selling]);

    useEffect(() => {
        getRecord()
    }, [getRecord]);

    useEffect(() => {
        setSelectedRecords(records.filter(order => {
            switch (activeOption) {
                case "buy": return account === order.buyUser.account;
                case "sell": return account === order.sellUser.account;
                case "all": return order.status === 2 || order.status < 0;
                case "processing": return order.status !== 2 && order.status >= 0;
                default: return false;
            }
        }))
    }, [account, activeOption, records])

    return (
        <CommonPage title={t("page.record.fiat.title")} returnPath={"/orders"}>
            <Divider style={{ margin: 0 }} />
            <CurrencyContainer>
                {
                    menuOptions.map(option => {
                        return (
                            <RecordNavigationButton
                                active={option === activeOption}
                                onClick={() => {
                                    setActiveOption(option);
                                }}
                            >
                                {t(`page.record.fiat.label.${option}`)}
                            </RecordNavigationButton>
                        )
                    })
                }
            </CurrencyContainer>
            <Divider style={{ margin: 0 }} />
            <div style={{ display: "flex", justifyContent: "space-between", width: "100%", padding: "3px 0px" }}>
                <div style={{ display: "flex", alignItems: "center", padding: "5px 10px", fontSize: 12, color: COLORS.Mid_gray }}>
                    {t("page.record.fiat.button.showUnread")}
                </div>
                <div style={{ display: "flex", alignItems: "center", padding: "5px 10px" }}>
                    <Switch size="small"></Switch>
                </div>
            </div>
            <div style={{ width: "100%", padding: "4px 0px", backgroundColor: COLORS.EXLight_gray }}></div>
            {
                selectedRecords.length === 0 ? (
                    <NoDealContainer>
                        <NoItemIcon src={noItemIcon} alt="no deal" />
                        <NoItemStatus>{t("page.record.fiat.label.empty")}</NoItemStatus>
                        <NoItemDes>{t("page.record.fiat.label.emptyDescription")}</NoItemDes>
                        <PayConfirmButton
                            onClick={() => {
                                stackNavigate("/fiat-deal");
                            }}
                        >
                            {t("page.record.fiat.button.trading")}
                        </PayConfirmButton>
                    </NoDealContainer>
                ) : (
                    <CurrencyListContainer>
                        {
                            selectedRecords.map((order) => {
                                return (
                                    <>
                                        <RecordCard
                                            key={order.id}
                                            onClick={() => {
                                                if (activeOption === "processing") {
                                                    if (order.buyUser.account === account) {
                                                        dispatch(otcOrderActions.updateBuying(order))
                                                        stageStackNavigate(`/orders/fiat/merchant/deal`, { state: "buy" });
                                                    }
                                                    if (order.sellUser.account === account) {
                                                        dispatch(otcOrderActions.updateSelling(order));
                                                        stageStackNavigate(`/orders/fiat/merchant/deal`, { state: "sell" });
                                                    }
                                                }
                                                else {
                                                    setSelectedOrder(order)
                                                    handlerDrawer()
                                                }
                                            }}
                                        >
                                            <RecordHeaderContainer>
                                                <RecordHeaderLeft isBuy={account === order.sellUser.account}>
                                                    <CryptoIcon name={"usdt"} size={16} />
                                                    <span style={{ marginLeft: 10 }}>{account === order.sellUser.account ? t("page.record.fiat.label.sell") : t("page.record.fiat.label.buy")}</span>
                                                    <span style={{ marginLeft: 3, color: COLORS.Black }}>USDT</span>
                                                </RecordHeaderLeft>
                                                <RecordHeaderRight>
                                                    <span>{t(`page.record.fiat.status.${account === order.sellUser.account ? "sell" : "buy"}.${order.status}`)}</span>
                                                    <RightOutlined style={{ fontSize: 10, marginLeft: 3 }} />
                                                </RecordHeaderRight>
                                            </RecordHeaderContainer>
                                            <RecordContentContainer>
                                                <RecordContentLeft>
                                                    <OrderDetail>
                                                        <CurrencyCardKey>{t("page.record.fiat.label.price")}</CurrencyCardKey>
                                                        <CurrencyCardKeyDescription>{order.price} TWD</CurrencyCardKeyDescription>
                                                    </OrderDetail>
                                                    <OrderDetail>
                                                        <CurrencyCardKey>{t("page.record.fiat.label.quantity")}</CurrencyCardKey>
                                                        <CurrencyCardKeyDescription>{order.quantity}</CurrencyCardKeyDescription>
                                                    </OrderDetail>
                                                    <OrderDetail>
                                                        <CurrencyCardKey>{t("page.record.fiat.label.handlingFee")}</CurrencyCardKey>
                                                        <CurrencyCardKeyDescription>
                                                            {
                                                                roundDown((account === order.sellUser.account ? order.buyFeeRate : order.sellFeeRate) * order.quantity, 2)
                                                            }
                                                        </CurrencyCardKeyDescription>
                                                    </OrderDetail>
                                                    <OrderDetail>
                                                        <CurrencyCardKey>{t("page.record.fiat.label.trader")}</CurrencyCardKey>
                                                        <CurrencyCardKeyDescription>
                                                            {
                                                                account === order.sellUser.account ? order.buyUser.account : order.sellUser.account
                                                            }
                                                        </CurrencyCardKeyDescription>
                                                    </OrderDetail>
                                                </RecordContentLeft>
                                                <RecordContentRight>
                                                    <CurrencyCardContentDescription>
                                                        <CurrencyCardTotalAmount>{order.amount}</CurrencyCardTotalAmount>
                                                        <CurrencyCardKeyDescription>TWD</CurrencyCardKeyDescription>
                                                    </CurrencyCardContentDescription>
                                                </RecordContentRight>
                                            </RecordContentContainer>
                                        </RecordCard>
                                        <div style={{ width: "100%", height: "8px", backgroundColor: COLORS.EXLight_gray }}></div>
                                    </>
                                );
                            })}
                    </CurrencyListContainer>
                )}
            <Drawer
                isVisible={drawerVisible}
                selectVisible={handlerDrawer}
                height={358}
            >
                <DrawerContainer>
                    <DrawerTitleContainer>
                        <div style={{ width: "5%" }}></div>
                        <DepthTitle>{t("page.record.fiat.label.orderType")}</DepthTitle>
                        <CancelIcon src={cancel} alt="cancel" onClick={handlerDrawer} />
                    </DrawerTitleContainer>
                    <PayContentDesContainerItem>
                        <PayContentItemName>{t("page.record.fiat.label.total")}</PayContentItemName>
                        <PayContentItemContent style={{ color: COLORS.Red }}>
                            NT$
                            <p
                                style={{
                                    marginLeft: 4,
                                    fontSize: 24,
                                    fontFamily: "Open Sans",
                                    fontWeight: 500
                                }}
                            >
                                {selectedOrder.amount}
                            </p>
                        </PayContentItemContent>
                    </PayContentDesContainerItem>
                    <PayContentDesContainerItem style={{ marginTop: 18 }}>
                        <PayContentItemName>{t("page.record.fiat.label.amount")}</PayContentItemName>
                        <PayContentItemContent
                            style={{
                                color: COLORS.Dark_gray,
                                fontFamily: "Open Sans",
                                fontWeight: 500
                            }}
                        >
                            {selectedOrder.quantity} USDT
                        </PayContentItemContent>
                    </PayContentDesContainerItem>
                    <PayContentDesContainerItem style={{ marginTop: 20 }}>
                        <PayContentItemName>{t("page.record.fiat.label.unitPrice")}</PayContentItemName>
                        <PayContentItemContent
                            style={{
                                color: COLORS.Dark_gray,
                                fontFamily: "Open Sans",
                                fontWeight: 500
                            }}
                        >
                            NT$
                            <p
                                style={{
                                    marginLeft: 4,
                                    fontFamily: "Open Sans",
                                    fontWeight: 500
                                }}
                            >
                                {selectedOrder.price}
                            </p>
                        </PayContentItemContent>
                    </PayContentDesContainerItem>
                    <PayContentItemSplit />
                    <PayContentDesContainerItem>
                        <PayContentItemName>{t("page.record.fiat.label.payments")}</PayContentItemName>
                        <PayStatusText>{selectedOrder.payment && t(`page.menu.c2cManage.payment.title.${selectedOrder.payment.type}`)}</PayStatusText>
                    </PayContentDesContainerItem>
                    <PayContentDesContainerItem style={{ marginTop: 18 }}>
                        <PayContentItemName>{t("page.record.fiat.label.orderId")}</PayContentItemName>
                        <PayStatusText>{selectedOrder.id}</PayStatusText>
                    </PayContentDesContainerItem>
                    <PayContentDesContainerItem style={{ marginTop: 20 }}>
                        <PayContentItemName>{t("page.record.fiat.label.createdDate")}</PayContentItemName>
                        <PayStatusText>
                            {Moment(selectedOrder.createdDate).format("YYYY-MM-DD hh:mm:ss")}
                        </PayStatusText>
                    </PayContentDesContainerItem>
                </DrawerContainer>
            </Drawer>
            <Drawer
                isVisible={completeDrawerVisible}
                selectVisible={handlerCompleteDrawer.bind(null, itemDetail)}
                height={358}
            >
                <DrawerContainer>
                    <DrawerTitleContainer>
                        <div style={{ width: "5%" }}></div>
                        <DepthTitle>{t("page.record.fiat.label.orderType")}</DepthTitle>
                        <CancelIcon
                            src={cancel}
                            alt="cancel"
                            onClick={handlerCompleteDrawer.bind(null, itemDetail)}
                        />
                    </DrawerTitleContainer>
                    <PayContentDesContainerItem>
                        <PayContentItemName>{t("page.record.fiat.label.total")}</PayContentItemName>
                        <PayContentItemContent
                            style={{
                                color:
                                    itemDetail === "buy" ? COLORS.Red : COLORS.Green
                            }}
                        >
                            NT$<p style={{ marginLeft: 4, fontSize: 24 }}>{selectedOrder.amount}</p>
                        </PayContentItemContent>
                    </PayContentDesContainerItem>
                    <PayContentDesContainerItem style={{ marginTop: 18 }}>
                        <PayContentItemName>{t("page.record.fiat.label.amount")}</PayContentItemName>
                        <PayContentItemContent style={{ color: COLORS.Dark_gray }}>
                            {selectedOrder.quantity} USDT
                        </PayContentItemContent>
                    </PayContentDesContainerItem>
                    <PayContentDesContainerItem style={{ marginTop: 20 }}>
                        <PayContentItemName>{t("page.record.fiat.label.unitPrice")}</PayContentItemName>
                        <PayContentItemContent style={{ color: COLORS.Dark_gray }}>
                            NT$<p style={{ marginLeft: 4 }}>{selectedOrder.price}</p>
                        </PayContentItemContent>
                    </PayContentDesContainerItem>
                    <PayContentItemSplit />
                    <PayContentDesContainerItem>
                        <PayContentItemName>{t("page.record.fiat.label.payments")}</PayContentItemName>
                        <PayStatusText>{selectedOrder.payment && t(`page.menu.c2cManage.payment.title.${selectedOrder.payment.type}`)}</PayStatusText>
                    </PayContentDesContainerItem>
                    <PayContentDesContainerItem style={{ marginTop: 18 }}>
                        <PayContentItemName>{t("page.record.fiat.label.orderId")}</PayContentItemName>
                        <PayStatusText>{selectedOrder.id && selectedOrder.id.slice(0, 20)}</PayStatusText>
                    </PayContentDesContainerItem>
                    <PayContentDesContainerItem style={{ marginTop: 20 }}>
                        <PayContentItemName>{t("page.record.fiat.label.createdDate")}</PayContentItemName>
                        <PayStatusText>
                            {Moment(selectedOrder.createdDate).format("YYYY-MM-DD hh:mm:ss")}
                        </PayStatusText>
                    </PayContentDesContainerItem>
                </DrawerContainer>
            </Drawer>
        </CommonPage>
    );
};

export default FiatOrders;
