import { useState, useEffect, useCallback } from "react";
import Commonheader from "@/components/header/Commonheader";
import styled from "styled-components";
import { COLORS } from "@/constants/colors";
import Drawer from "@/components/UI/Drawer";
import { Select } from 'antd';
import LoadingOverlay from "react-loading-overlay-ts";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useGetPrice } from "@/hooks";
import { positionService } from "@/services";
import { Position } from "@/interfaces/exchange.interface";
import useStackNavigate from "@/hooks/useStackNavigate";

const { Option } = Select;
const PageContainer = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const BodyContainer = styled.div`
  flex: 1;
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;
const SelectTitle = styled.p`
  margin-bottom: 4px;
  height: 20px;
  line-height: 20px;
  font-weight: 500;
  font-size: 13px;
  color: #5f5c70;
`;
const SelectInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;
const SelectInput = styled.input`
  height: 32px;
  width: 70%;
  border: none;
  background: rgb(244, 244, 246, 0.6);
  padding: 12px 16px;
  color: ${COLORS.Dark_gray};
  border-radius: 4px;
  /* padding-right: 18%; */
`;

const DepthTitle = styled.div`
  width: 100%;
  text-align: center;
  line-height: 24px;
  font-weight: 600;
`;
const DepthItem = styled.div<{ isSelect: number; index: number }>`
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #f4f4f6;
  height: 55px;
  line-height: 55px;
  color: ${(props) =>
        props.isSelect === props.index ? COLORS.Red : COLORS.Gray};
`;

const ButtonBox = styled.div`
    justify-content: space-around;
    display: flex;
`
const SubmitButton = styled.button<{ isCancel: boolean }>`
  margin-top: 50px;
  border: none;
  width: 45%;
  height: 44px;
  color: #fff;
  background: ${(props) =>
        props.isCancel ? COLORS.Red : COLORS.Green};
  border-radius: 4px;
`;

const SelectContainer = styled(Select)`
  width:10%;
`

const StopLoss = () => {
    const [stopEarning, setStopEarning] = useState("");
    const [stopLoss, setStopLoss] = useState("0");
    const [selectAmount, setSelectAmount] = useState(0.1);
    const [salePriceDrawer, setSalePriceDrawer] = useState(false);
    const [selectItem, setSelectItem] = useState(0.1);
    const [predictEarning, setPredictEarning] = useState(0);
    const [predictLoss, setPredictLoss] = useState(0);
    const [loading, setLoading] = useState(false);
    const [lossError, setLossError] = useState("");
    const [earnError, setEarnError] = useState("");
    const { position } = useLocation().state as { position: Position }
    const marketPrice = useGetPrice(position.symbol)

    const { stackNavigate } = useStackNavigate()
    const { t } = useTranslation();
    const handleIsVisible = () => {
        setSalePriceDrawer((v) => !v);
    };
    const handleMount = (index: number) => {
        setSelectItem(index);
        setSelectAmount(index);
        setSalePriceDrawer((v) => !v);
    };

    function handleChange(value) {
        console.log(`selected ${value}`);
        console.log(stopEarning)
        console.log(selectAmount)
    }

    const getPositionLossPrice = useCallback((lossPrice: string) => {
        if (lossPrice && !Number.isNaN(parseFloat(lossPrice))) {
            setStopLoss(lossPrice)
            positionService.getStopPrice({
                symbol: position.symbol,
                lossPrice: lossPrice
            }).then((response) => {
                setLossError("")
                setPredictLoss(response.data)
            }).catch((error) => {
                setLossError(error.response.data.msg)
            })
        }
        else {
            setStopLoss("")
        }
    },[position.symbol])

    const getPositionProfitPrice = useCallback((profitPrice: string) => {
        if (profitPrice && !Number.isNaN(parseFloat(profitPrice))) {
            setStopEarning(profitPrice)
            positionService.getStopPrice({
                symbol: position.symbol,
                profitPrice: profitPrice
            }).then((response) => {
                setEarnError("")
                setPredictEarning(response.data)
            }).catch((error) => {
                setEarnError(error.response.data.msg)
            })
        }
        else {
            setStopEarning("")
        }
    },[position.symbol])


    useEffect(() => {
        getPositionProfitPrice(`${position.profitPrice}`)
        getPositionLossPrice(`${position.lossPrice}`)
    }, [getPositionLossPrice, getPositionProfitPrice, position.lossPrice, position.profitPrice])

    return (
        <LoadingOverlay active={loading} spinner>

            <PageContainer>
                <Commonheader title={t("page.stopLoss.title")} returnPath={"/deal"} />
                <BodyContainer>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginBottom: "10px" }}>
                        <p style={{ color: COLORS.Gray, fontSize: "13px" }}>{t("page.stopLoss.label.entryPrice")}</p>
                        <p style={{ color: COLORS.Gray, fontSize: "13px" }}>{position.avgPrice}</p>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginBottom: "10px" }}>
                        <p style={{ color: COLORS.Gray, fontSize: "13px" }}>{t("page.stopLoss.label.marketPrice")}</p>
                        <p style={{ color: COLORS.Gray, fontSize: "13px" }}>{marketPrice.selected.c}</p>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginBottom: "10px" }}>
                        <p style={{ color: COLORS.Gray, fontSize: "13px" }}>{t("page.stopLoss.label.liqPrice")}</p>
                        <p style={{ color: COLORS.Gray, fontSize: "13px" }}>{position.forceClose}</p>
                    </div>
                    <SelectInputContainer style={{ marginTop: 24 }}>
                        <SelectTitle>{t("page.stopLoss.label.benefitPrice")}</SelectTitle>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                            <SelectInput
                                value={stopEarning}
                                onChange={(e) => {
                                    getPositionProfitPrice(e.target.value)
                                }}
                                type="text"
                                placeholder={t("page.stopLoss.placeholder.benefitPrice")}
                            />
                            <SelectContainer defaultValue={t("page.stopLoss.label.mark")} style={{ width: 80 }} onChange={handleChange}>
                                <Option value={t("page.stopLoss.label.mark")}>{t("page.stopLoss.label.mark")}</Option>
                            </SelectContainer>
                        </div>
                    </SelectInputContainer>
                    {<p style={{ fontSize: "12px", color: COLORS.Red, marginTop: "5px" }}>{earnError}</p>}

                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>

                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginBottom: "10px", marginTop: "10px", width: "90%" }}>
                            <p style={{ color: COLORS.Gray, fontSize: "13px" }}>{t("page.stopLoss.label.positionPrice")}</p>
                            <p style={{ color: COLORS.Gray, fontSize: "13px" }}>{position.avgPrice} USDT</p>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginBottom: "10px", marginTop: "10px", width: "90%" }}>
                            <p style={{ color: COLORS.Gray, fontSize: "13px" }}>{t("page.stopLoss.label.expectBenefitAndLoss")}</p>
                            <p style={{ color: COLORS.Green, fontSize: "13px" }}>{predictEarning} USDT</p>
                        </div>
                    </div>
                    <SelectInputContainer style={{ marginTop: 24 }}>
                        <SelectTitle>{t("page.stopLoss.label.lossPrice")}</SelectTitle>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                            <SelectInput
                                value={stopLoss}
                                onChange={(e) => {
                                    getPositionLossPrice(e.target.value)
                                }}
                                type="text"
                                placeholder={t("page.stopLoss.placeholder.lossPrice")}
                            />
                            <SelectContainer defaultValue={t("page.stopLoss.label.mark")} style={{ width: 80 }} onChange={handleChange}>
                                <Option value={t("page.stopLoss.label.mark")}>{t("page.stopLoss.label.mark")}</Option>
                            </SelectContainer>
                        </div>
                    </SelectInputContainer>
                    {<p style={{ fontSize: "12px", color: COLORS.Red, marginTop: "5px" }}>{lossError}</p>
                    }

                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginBottom: "10px", marginTop: "10px", width: "90%" }}>
                            <p style={{ color: COLORS.Gray, fontSize: "13px" }}>{t("page.stopLoss.label.positionPrice")}</p>
                            <p style={{ color: COLORS.Gray, fontSize: "13px" }}>{position.avgPrice} USDT</p>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginBottom: "10px", marginTop: "10px", width: "90%" }}>
                            <p style={{ color: COLORS.Gray, fontSize: "13px" }}>{t("page.stopLoss.label.expectBenefitAndLoss")}</p>
                            <p style={{ color: "#D32F2F", fontSize: "13px" }}>{predictLoss} USDT</p>
                        </div>
                    </div>

                    <ButtonBox>
                        <SubmitButton isCancel={true} onClick={() => {
                            setLoading(true);
                            positionService.setStopPrice({
                                profitPrice: "0",
                                lossPrice: "0",
                                symbol: position.symbol
                            }).then(() => {
                                alert(t("page.stopLoss.alert.setSuccess"));
                                stackNavigate(-1);
                            }).finally(() => {
                                setLoading(false)
                            })
                        }}>{t("page.stopLoss.button.remove")}</SubmitButton>
                        <SubmitButton isCancel={false} onClick={() => {
                            if (!stopEarning && !stopLoss) {
                                alert(t("page.stopLoss.alert.benefitAndLoss"))
                            } else {
                                setLoading(true);
                                positionService.setStopPrice({
                                    profitPrice: stopEarning ? stopEarning : null,
                                    lossPrice: stopLoss ? stopLoss : null,
                                    symbol: position.symbol
                                }).then(() => {
                                    alert(t("page.stopLoss.alert.setSuccess"));
                                    stackNavigate(-1);
                                }).finally(() => {
                                    setLoading(false)
                                })
                            }
                        }}>{t("page.stopLoss.button.submit")}</SubmitButton>
                    </ButtonBox>
                </BodyContainer>
                <Drawer
                    isVisible={salePriceDrawer}
                    selectVisible={handleIsVisible}
                    height={308}
                >
                    <DepthTitle>{t("page.stopLoss.label.volume")}</DepthTitle>
                    <DepthItem
                        isSelect={selectItem}
                        index={0.1}
                        onClick={handleMount.bind(null, 0.1)}
                    >
                        {`100% (0.1)`}
                    </DepthItem>
                    <DepthItem
                        isSelect={selectItem}
                        index={0.075}
                        onClick={handleMount.bind(null, 0.075)}
                    >
                        {`75% (0.075)`}
                    </DepthItem>
                    <DepthItem
                        isSelect={selectItem}
                        index={0.05}
                        onClick={handleMount.bind(null, 0.05)}
                    >
                        {`50% (0.05))`}
                    </DepthItem>
                    <DepthItem
                        isSelect={selectItem}
                        index={0.025}
                        onClick={handleMount.bind(null, 0.025)}
                    >
                        {`25% (0.025)`}
                    </DepthItem>
                </Drawer>
            </PageContainer>
        </LoadingOverlay>
    );
};

export default StopLoss;
