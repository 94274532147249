/* 引入styled components */
import styled from "styled-components";
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next";
import CommonPage from "@/components/LayoutPage";
import { userService } from "@/services";
import CustomCard from "@/components/CustomCard";
import { COLORS } from "@/constants/colors";
import { CopyOutlined, ExclamationCircleFilled } from "@ant-design/icons";
import QRCode from 'qrcode.react';
import { useAppSelector } from "@/hooks";
import CustomInput from "@/components/Input/CustomInput";
import { BaseButton } from "@/components/Button";
import useStackNavigate from "@/hooks/useStackNavigate";

const FormBox = styled.div`
    width: 100%;
    padding: 0px 15px;
`

export const Card = styled.div<{ color?: string }>`
    background-color: ${props => props.color ?? COLORS.White};
    border-radius: 10px;
`

export const CardContent = styled.div`
    padding: 5px 0px;
`

const ResetPhoneNumber = () => {
    const [secretKey, setSecretKey] = useState("")
    const [code, setCode] = useState("")

    const { stackNavigate } = useStackNavigate()
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();
    const account = useAppSelector((state) => state.user.detail.account)

    const copyToClipboard = (text) => {
        var textField = document.createElement('textarea')
        textField.innerText = text
        document.body.appendChild(textField)
        textField.select()
        document.execCommand('copy')
        textField.remove()
    }

    const getCode = () => {
        userService.getGoogleSecret().then((response) => {
            setSecretKey(response.data)
        })
    }

    useEffect(() => {
        getCode()
    }, [])

    return (
        <CommonPage active={loading} title={t("page.setGoogleAuth.title")} returnPath={"/google-code"}>
            <FormBox>
                <CustomCard color={COLORS.LightYellow} style={{ marginTop: 10, lineHeight: "20px" }}>
                    <CardContent style={{ display: "flex", alignItems: "center", color: COLORS.Yellow, fontSize: 12, padding: "15px 0px", fontWeight: 500 }}>
                        <ExclamationCircleFilled style={{ padding: "0px 5px" }} />
                        <div>{t("page.setGoogleAuth.notice.secret")}</div>
                    </CardContent>
                </CustomCard>
                <div style={{fontWeight: 600,fontSize: "small", color: COLORS.Mid_gray, margin: "15px 0px 30px 0px", lineHeight: "20px"}}>{t("page.setGoogleAuth.notice.scan")}</div>
                <div style={{ display: "flex", justifyContent: "center", padding: 20 }}>
                    <QRCode value={`otpauth://totp/${account}?secret=${secretKey}`} />
                </div>
                <CustomInput
                    inputType="solid"
                    value={secretKey}
                    left={<div style={{ color: COLORS.Light_gray }}>_</div>}
                    right={
                        <CopyOutlined style={{ padding: "0px 15px" }} onClick={() => {
                            copyToClipboard(secretKey)
                            alert(t("page.setGoogleAuth.alert.copied"))
                        }} />
                    }
                />

                <div style={{ fontWeight: 600, fontSize: 16, paddingTop: 40, fontFamily: "Open Sans"}}>{t("page.setGoogleAuth.input.code")}</div>
                <CustomInput
                    valueType="number"
                    inputType="line"
                    style={{ marginTop: 10, border: "none" }}
                    value={code}
                    onChange={(e) => {
                        setCode(e.target.value)
                    }}
                    placeholder={t(`page.setGoogleAuth.placeholder.code`)}
                />
                <BaseButton
                    style={{ width: "100%", marginTop: 15, height: 40 }}
                    onClick={() => {
                        if (!code) {
                            alert(t("page.setGoogleAuth.alert.code"))
                        } else {
                            setLoading(true);
                            userService.setGoogleSecret({ code: code }).then(() => {
                                userService.verifyGoogleSecret({ code: code }).then(() => {
                                    stackNavigate("jump")
                                });
                            }).catch((error) => {
                                alert(error.response.data.msg)
                            }).finally(() => {
                                setLoading(false);
                            })
                        }
                    }}
                >
                    {t("page.setGoogleAuth.button.submit")}
                </BaseButton>
            </FormBox>
        </CommonPage>
    );
}

export default ResetPhoneNumber
