import { useState, useEffect } from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import useStackNavigate from "@/hooks/useStackNavigate";
import { useTranslation } from "react-i18next";
import { walletService } from "@/services";
import CommonPage from "@/components/LayoutPage";
import { useAppSelector } from "@/hooks";
import { Drawer, List } from "antd";
import { BaseButton } from "@/components/Button";
import { CenterListItem } from "@/styled-components/deal";
import { Header } from "@/components/typography/Header";
import CustomInput from "@/components/Input/CustomInput";

const FormBox = styled.div`
    width: 100%;
    padding: 0px 15px;
`

const Withdraw = () => {
    const pagePrefix = "page.withdraw";
    const { stackNavigate } = useStackNavigate()
    const account = useAppSelector((state) => state.user.detail.account)
    const [loading, setLoading] = useState(false)
    const search = useLocation().search
    const [currency, setCurrency] = useState("")
    const [address, setAddress] = useState("")
    const [amount, setAmount] = useState(0)
    const [count, setCount] = useState(0)
    const [emailCode, setEmailCode] = useState("");
    const [emailSend, setEmailSend] = useState(false);
    const [withdrawFee, setWithdrawFee] = useState(0);
    const [visible, setVisible] = useState(false);
    const [code, setCode] = useState("")

    const { t } = useTranslation();

    const createApplication = () => {
        if (!address) {
            alert(t(`${pagePrefix}.alert.address`))
        } else if (!amount) {
            alert(t(`${pagePrefix}.alert.amount`))
        } else {
            setLoading(true)
            walletService.withdraw({ amount: amount, address: address, code: emailCode, ga: code }).then((response) => {
                alert(t(`${pagePrefix}.notice.success`))
                stackNavigate("/fund/overview")
            }).catch((error) => {
                alert(error.response.data.msg)
            }).finally(() => {
                setLoading(false)
            })
        }
    }


    useEffect(() => {
        setCurrency(search.split("=")[1])
        walletService.getWithdrawFee().then((response) => {
            setWithdrawFee(response.data)
        })
    }, [search])

    useEffect(() => {
        if (emailSend) {
            setTimeout(() => {
                if (count > 0) {
                    setCount(c => c - 1)
                }
            }, 1000)
        }
        if (count === 0) {
            setEmailSend(false)
        }
    }, [count, emailSend])

    return (
        <CommonPage active={loading} title={t(`${pagePrefix}.title`)} returnPath={-1}>
            <FormBox>
                <CustomInput
                    label={`${currency} ${t(`${pagePrefix}.input.address`)}`}
                    inputType="solid"
                    placeholder={t(`${pagePrefix}.placeholder.address`)}
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                />
                <CustomInput
                    label={t(`${pagePrefix}.input.network`)}
                    inputType="solid"
                    value={"TRC (TRC20)"}
                    disable={true}
                />
                <CustomInput
                    label={t(`${pagePrefix}.input.amount`)}
                    valueType="number"
                    inputType="solid"
                    placeholder={t(`${pagePrefix}.placeholder.amount`)}
                    value={`${amount}`}
                    onChange={(e) => setAmount(parseFloat(e.target.value === '' ? "0" : e.target.value))}
                />
                <CustomInput
                    label={t(`${pagePrefix}.input.emailCode`)}
                    inputType="solid"
                    right={
                        emailSend ? <div style={{padding: "0px 5px"}}>{`${count}s`}</div> :
                            <div onClick={() => {
                                setLoading(true)
                                walletService.sendWithdrawVerifyMail(account).then((response) => {
                                    alert(response.msg)
                                    setCount(60)
                                    setEmailSend(true)
                                }).catch((error) => {
                                    alert(error.response.data ? error.response.data.msg : t("error"))
                                }).finally(() => {
                                    setLoading(false)
                                })
                            }}>
                                {t(`${pagePrefix}.button.sendMail`)}
                            </div>
                    }
                    placeholder={t(`${pagePrefix}.placeholder.emailCode`)}
                    value={emailCode}
                    onChange={(e) => setEmailCode(e.target.value)}
                />
                <div style={{ padding: "16px", display: "flex", justifyContent: "space-between", flexDirection: "column", flex: 1 }}>
                    <div style={{ display: "flex", justifyContent: "space-between", flexDirection: "row", marginTop: "20px" }}>
                        <p style={{ color: "#8F8DA2", fontSize: "13px", fontWeight: 500 }}>{t(`${pagePrefix}.label.fee`)}</p>
                        <p style={{ color: "#383743", fontSize: "15px", fontWeight: 400 }}>{withdrawFee.toFixed(6)} {currency}</p>
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between", flexDirection: "row", marginTop: "20px" }}>
                        <p style={{ color: "#8F8DA2", fontSize: "13px", fontWeight: 500 }}>{t(`${pagePrefix}.label.receivedAmount`)}</p>
                        <p style={{ color: "#383743", fontSize: "24px", fontWeight: 700 }}>{amount - withdrawFee < 0 ? 0 : amount - withdrawFee} {currency}</p>
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between", flexDirection: "row", marginTop: "20px" }}>
                        <BaseButton
                            style={{ width: "100%" }}
                            onClick={() => setVisible(true)}
                        >
                            {t(`${pagePrefix}.button.confirm`)}
                        </BaseButton>
                    </div>
                </div>
            </FormBox>
            <Drawer
                placement={"bottom"}
                closable={false}
                onClose={() => setVisible(false)}
                key={"ga"}
                visible={visible}
                bodyStyle={{ padding: 0 }}
                height={"auto"}
                footer={
                    <BaseButton
                        style={{ width: "100%" }}
                        onClick={createApplication}
                    >
                        {t("page.menu.c2cManage.payment.button.confirm")}
                    </BaseButton>
                }
            >
                <List style={{ width: "100%" }}>
                    <CenterListItem>{t("page.menu.c2cManage.payment.label.security")}</CenterListItem>
                    <div style={{ width: "100%", margin: "0px 15px" }}>
                        <Header size="sm" style={{ marginLeft: 5 }}>
                            {t("page.menu.c2cManage.payment.input.googleAuth")}
                        </Header>
                        <CustomInput
                            inputType="line"
                            placeholder={t("page.menu.c2cManage.payment.placeholder.googleAuth")}
                            value={code}
                            onChange={(e) => setCode(e.target.value)}
                        />
                    </div>
                </List>
            </Drawer>
        </CommonPage>
    );
};

export default Withdraw;
