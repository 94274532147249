import { useTranslation } from "react-i18next";
import { useAppSelector } from "@/hooks";
import { useCallback, useEffect, useState } from "react";
import { Payment } from "@/interfaces/exchange.interface";
import { userService } from "@/services";
import useStackNavigate from "@/hooks/useStackNavigate";
import { BaseButton } from "@/components/Button";
import CommonPage from "@/components/LayoutPage";
import { LabelInput, LabelTextArea } from "@/components/Input";
import { BottomContainer } from "@/components/Other";
import { Drawer, List, message } from "antd";
import { CenterListItem } from "@/styled-components/deal";
import { Header } from "@/components/typography/Header";
import CustomInput from "@/components/Input/CustomInput";


const PayTypeCash = () => {
    const { t } = useTranslation();
    const { stackNavigate } = useStackNavigate()
    const user = useAppSelector((state) => state.user.detail)
    const [application, setApplication] = useState<Payment>({} as Payment)
    const [loading, setLoading] = useState(false)
    const [isDisable, setIsDisable] = useState(true)
    const [code, setCode] = useState("")
    const [visible, setVisible] = useState(false);

    const createApplication = () => {
        if (application.contract === "") {
            alert(t("page.menu.c2cManage.payment.alert.contract"))
            return;
        }
        setLoading(true)
        const formData = new FormData();
        formData.append("contract", application.contract)
        formData.append("type", "CASH")
        formData.append("ga", code)
        userService.createPayment(formData).then(() => {
            alert(t("page.menu.c2cManage.payment.alert.success"))
            stackNavigate("jump")
        }).catch((error)=>{
            message.error(error.response.data.msg)
        }).finally(() => {
            setLoading(false)
        })
    }
    
    const checkoutUserInput = useCallback(() => {
        setIsDisable( () => {
            return !user?.kyc || !application.contract;
        } )
    }, [user, application])

    useEffect(()=>{
        checkoutUserInput()
    },[checkoutUserInput])

    return (
        <CommonPage active={loading} title={`${t("page.menu.c2cManage.payment.title.add")} ${t("page.menu.c2cManage.payment.title.CASH")}`} returnPath={"/choose-pay-type"}>
            <LabelInput
                label={t("page.menu.c2cManage.payment.input.name")}
                value={user.kyc && user.kyc.name}
                disable={true}
            />
            <LabelTextArea
                label={t("page.menu.c2cManage.payment.input.contract")}
                placeholder={t("page.menu.c2cManage.payment.placeholder.contract")}
                onChange={(e) => {
                    const value = e.target.value
                    setApplication((prev) => {
                        return {
                            ...prev,
                            type: "CASH",
                            contract: value
                        }
                    })
                }}
            />
            <BottomContainer>
                <BaseButton disabled={isDisable} onClick={() => setVisible(true)}>
                    {t("page.menu.c2cManage.payment.button.add")}
                </BaseButton>
            </BottomContainer>
            <Drawer
                placement={"bottom"}
                closable={false}
                onClose={() => setVisible(false)}
                key={"ga"}
                visible={visible}
                bodyStyle={{ padding: 0 }}
                height={"auto"}
                footer={
                    <BaseButton
                        style={{ width: "100%" }}
                        onClick={createApplication}
                    >
                        {t("page.menu.c2cManage.payment.button.confirm")}
                    </BaseButton>
                }
            >
                <List style={{ width: "100%" }}>
                    <CenterListItem>{t("page.menu.c2cManage.payment.label.security")}</CenterListItem>
                    <div style={{ width: "100%", margin: "0px 15px" }}>
                        <Header size="sm" style={{marginLeft: 5}}>
                            {t("page.menu.c2cManage.payment.input.googleAuth")}
                        </Header>
                        <CustomInput
                            inputType="line"
                            placeholder={t("page.menu.c2cManage.payment.placeholder.googleAuth")}
                            value={code}
                            onChange={(e) => setCode(e.target.value)}
                        />
                    </div>
                </List>
            </Drawer>
        </CommonPage>
    )
}

export default PayTypeCash