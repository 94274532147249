import { BaseButton } from "@/components/Button";
import { LabelInput, LabelTextArea } from "@/components/Input"
import CommonPage from "@/components/LayoutPage"
import { CreateFeedbackRequest } from "@/interfaces/request.interface";
import { investorService } from "@/services";
import { message } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next"
import useStackNavigate from "@/hooks/useStackNavigate";
import styled from "styled-components";

const BottomContainer = styled.div`
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    padding: 20px;
`;

const FeedBack = () => {
    const { t } = useTranslation()
    const { stackNavigate } = useStackNavigate()
    message.config({
        top: 300, // 距離頂部的距離，單位是像素
        duration: 2, // 持續時間，單位是秒
    });

    const [ feedback, setFeedback ] = useState<CreateFeedbackRequest>({subject: "", content: ""} as CreateFeedbackRequest)
    return (
        <CommonPage title={t("page.feedback.title")} returnPath={"/about"}>
            <div style={{ width: "100%" }}>
                <LabelInput 
                    label={t("page.feedback.label.subject")} 
                    onChange={(e) => {
                        const value = e.target.value
                        setFeedback((prev) => {
                            return {
                                ...prev,
                                subject: value
                            }
                        })
                    }}
                />
                <LabelTextArea 
                    label={t("page.feedback.label.content")} 
                    onChange={(e) => {
                        const value = e.target.value
                        setFeedback((prev) => {
                            return {
                                ...prev,
                                content: value
                            }
                        })
                    }}
                />
            </div>
            <BottomContainer>
            <BaseButton
                    style={{ width: "100%" }}
                    onClick={() => {
                        if ( feedback.subject.length<5 || feedback.content.length<5){
                            alert(t("page.feedback.alert.content"))
                            return ;
                        }
                        investorService.createFeedback(feedback).then(()=>{
                            message.info(t("page.feedback.alert.success"))
                            stackNavigate(-1)
                        })
                    }}
                >
                    {t("page.feedback.button.confirm")}
                </BaseButton>
            </BottomContainer>
        </CommonPage>
    )
}

export default FeedBack