export const COLORS = {
  // basic
    White: '#fff',
    Black: '#000',
    EXLight_gray: '#F4F4F6',
    Light_gray: '#DEDDE3',
    Mid_gray: '#8F8DA2',
    Dark_gray: '#383743',
    Gray: '#5F5C70',
    Green: '#5FBC89',
    GreenActive: '#4ab07b',
    LIGHT_GREEN: "#a6e8d0",
    Red: '#E45463',
    RedActive: "#db4d5c",
    Yellow: '#f0b60d',
    LightYellow: '#fcfcd4',
    BinanceYellow: "#f3ba2f",
    BinanceYellowActive: "#cc9c27",
    // theme
    Primary: '#65E9B9',
    Primary_bg: '#65e9b973',
    PrimaryActive: "#50bf97",
    // PRIMARY_COLOR: '#D32F2F',
    Blue: "#007FFF",
    MidBlue: "#1E88E5",
    LightBlue: "#F0F8FF",
    // text
    Text_white: '#FDFDFD',
};
