import { useState, Dispatch, SetStateAction, useEffect } from "react";
import styled from "styled-components";
import { COLORS } from "@/constants/colors";
import BackIcon from "@/assets/icon/Deal/backArrow.png";
// import line_pay from "@/assets/icon/Deal/line_pay.png";
import coolicon from "@/assets/icon/Deal/coolicon.png";
// import arrowRight from "@/assets/icon/Deal/arrowRight.png";
// import useStackNavigate from "@/hooks/useStackNavigate";
import { useTranslation } from "react-i18next";
import { userService } from "@/services";
import { Payment } from "@/interfaces/exchange.interface";
import { CreditCardOutlined } from "@ant-design/icons";
import { PayType } from "@/constants";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMoneyBill1 } from '@fortawesome/free-regular-svg-icons';
import { faReceipt } from '@fortawesome/free-solid-svg-icons';
import { faLine } from '@fortawesome/free-brands-svg-icons';
import { useAppDispatch } from "@/hooks";
import { advertisementActions } from "@/store/slice";
const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  padding: 16px;
  justify-content: space-between;
  align-items: center;
  background: #fff;
`;
const NavImage = styled.img`
  width: 16px;
  height: 16px;
  margin-left: 12px;
`;
const LogoImage = styled.p`
  font-weight: 600;
  font-size: 16px;
  color: #383743;
  flex: 1;
  text-align: center;
`;
const AddPayContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px;
  background: #fff;
`;
const AddPayTitle = styled.p`
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.004em;
  color: ${COLORS.Gray};
`;
const AddPayItem = styled.div`
  display: flex;
  align-items: center;
  margin-top: 32px;
`;
const AddPayContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const AddPayContent = styled.p`
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.004em;
  color: ${COLORS.Gray};
`;
const AddPayContentDes = styled.p`
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.4px;
  color: ${COLORS.Mid_gray};
`;
const TradeFunctionCheckBox = styled.div<{ check: boolean; id: string }>`
  width: 18px;
  height: 18px;
  background-color: ${props => (props.check ? "#5F5C70" : "#fff")};
  border: 1.5px solid #dedde3;
  border-radius: 9px;
  margin: 0 0 0 auto;
`;
const CheckItemIcon = styled.img`
  width: 18px;
  height: 18px;
  margin: 0 0 0 auto;
`;

const AddPayWay: React.FC<{
    setAddPayWayStatus: Dispatch<SetStateAction<boolean>>;
}> = ({
    setAddPayWayStatus
}) => {
        const { t } = useTranslation();
        const dispatch = useAppDispatch()
        const [payments, setPayments] = useState<Payment[]>([]);
        const [checkedState, setCheckedState] = useState(
            new Array(payments.length).fill(false)
        );
        // const [id,setId] = useState("")
        const getAccount = () => {
            userService.getPayments().then((response) => {
                setPayments(response.data);
            })
        };

        useEffect(() => {
            getAccount();
        }, []);

        useEffect(() => {
            setCheckedState(new Array(payments.length).fill(false));
        }, [payments]);

        const getPaymentIcon = (type: PayType) => {
            switch (type) {
                case "CASH":
                    return <FontAwesomeIcon icon={faMoneyBill1} style={{ marginRight: 15 }} />
                case "BANK":
                    return <CreditCardOutlined style={{ marginRight: 15 }} />
                case "LINE_PAY":
                    return <FontAwesomeIcon icon={faLine} style={{ marginRight: 15 }} />
                case "STREET_PAY":
                    return <FontAwesomeIcon icon={faReceipt} style={{ marginRight: 15 }} />
                default: return <></>
            }
        }

        const getPaymentDetail = (payment: Payment) => {
            switch (payment.type) {
                case "BANK":
                    return `(${payment.code}) ${payment.account}`;
                case "CASH":
                    return payment.contract;
                case "LINE_PAY":
                    return payment.lineId;
                case "STREET_PAY":
                    return payment.streetId;
                default: return ""
            }
        }

        return (
            <>
                <TitleContainer>
                    <NavImage
                        src={BackIcon}
                        alt="language"
                        onClick={() => {
                            setAddPayWayStatus(false);
                            var a: Payment[] = [];
                            for (let i = 0; i < checkedState.length; i++) {
                                if (checkedState[i] === true) {
                                    a.push(payments[i]);
                                }
                            }
                            dispatch(advertisementActions.setPayments(a))
                        }}
                    />
                    <LogoImage>{t("page.menu.c2cManage.payment.title.type")}</LogoImage>
                </TitleContainer>
                <AddPayContainer>
                    <AddPayTitle>{t("page.menu.c2cManage.payment.notice.three")}</AddPayTitle>
                    {payments.map((payment, i) => {
                        return (
                            <AddPayItem
                                onClick={() => {
                                    const updatedCheckedState = checkedState.map(
                                        (item, index) => (index === i ? !item : item)
                                    );

                                    console.log(updatedCheckedState);

                                    setCheckedState(updatedCheckedState);
                                    // if (paySelect.includes("0013725399811211")) {
                                    //   setPaySelect((prev) =>
                                    //     prev.filter((e) => e !== "0013725399811211")
                                    //   );
                                    // } else {
                                    //   setPaySelect((prev) => [...prev, "0013725399811211"]);
                                    // }
                                }}
                            >
                                {getPaymentIcon(payment.type)}
                                <AddPayContentContainer>
                                    <AddPayContent>{t(`page.menu.c2cManage.payment.title.${payment.type}`)}</AddPayContent>
                                    <AddPayContentDes>{getPaymentDetail(payment)}</AddPayContentDes>
                                </AddPayContentContainer>
                                {checkedState[i] ? (
                                    <CheckItemIcon src={coolicon} alt="check" />
                                ) : (
                                    <TradeFunctionCheckBox check={false} id={payment.id} />
                                )}
                            </AddPayItem>
                        );
                    })}
                </AddPayContainer>
            </>
        );
    };

export default AddPayWay;
