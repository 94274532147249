const defaultSettings = {
    apiDomain: "api-xebit.usefordemo.com",
    clientDomain: "api-xebit.usefordemo.com",
    marketDomain: "api-xebit.usefordemo.com",
    ssl: true,
    marketSsl: true,
    // apiDomain: "192.168.0.119",
    // clientDomain: "192.168.0.119:3000",
    // marketDomain: "192.168.0.119",
    // ssl: true,
    // marketSsl: false
};

export default defaultSettings