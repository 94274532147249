import {
    useState,
    useEffect,
    useCallback,
    useMemo
} from 'react';

// ? Self-packed Components || Functions
import HomeHeader from '@/components/header/HomeHeader';
import Footer from '@/components/footer/HomeFooter';
import { FlexDirectionRowDiv } from '@/styled-components';
import Marquee from '@/components/Marquee';

// - Images
import contractDealImg from "@/assets/home/contract_deal.svg";
import inviteImg from '@/assets/home/invite_friends.svg';
import protectFund from '@/assets/home/protectFund.svg';
import realTimeTrend from '@/assets/home/realTimeTrend.svg';
import rookieBonusImg from "@/assets/home/rookieBonus.svg";
import welfareImg from '@/assets/home/welfare.svg';
import loanImg from '@/assets/home/loan.svg';
import recentEventImg from '@/assets/home/recentEvent.svg';
import C2CImg from '@/assets/home/c2c.svg';
import rechargeImg from '@/assets/home/recharge.svg';
import GoogleAuth from "@/assets/icon/google-auth.svg"
import { Header } from "@/components/typography/Header";

// ^ Plugins
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import {
    isEmpty,
    get,
    // debounce
} from 'lodash';

// = Styled Component
import {
    PageContainer,
    ChartContainer,
    Banner,
    ListContainer,
    ListHeader,
    ListButton,
    IconBtn,
    IconBtnImg,
    IconBtnText,
    TradePairContainer,
    TradePairUnit,
    TradePairName,
    TradePairPercentage,
    TradePairCurrencyToUsd,
    TradePairCurrencyFiatRate,
    HomeCarousel
} from '@/styled-components/home'
import { infoService, investorService } from '@/services';
import { Carousal } from '@/interfaces/exchange.interface';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { MarketPrice } from '@/interfaces/market.interface';
import { tradingActions, userActions } from '@/store/slice';
import { fiatCurrencySymbol } from '@/constants';
import { COLORS } from '@/constants/colors';
import Modal from 'antd/lib/modal';
import { BaseButton } from '@/components/Button';
import MarketList from './component/MarketList';
import useStackNavigate from '@/hooks/useStackNavigate';


/* desktop thw newest info style end */
/**
 * @author odin
 * @level Layout/Home
 * @description 首頁
*/
export const Home = () => {
    type MarketType = "up" | "down" | "volume" | "favorite" | "hot"
    const { t } = useTranslation();
    const token = useAppSelector((state) => state.user.token)
    const { stackNavigate, stageStackNavigate } = useStackNavigate();
    const location = useLocation().pathname;
    const isRemind = useAppSelector((state) => state.user.isRemind)
    const dispatch = useAppDispatch()
    const currency = useAppSelector((state) => state.user.currency)
    const language = useAppSelector((state) => state.user.language)

    const marketArr = useAppSelector((state) => state.market.price)
    const [open, setOpen] = useState(false);

    const showModal = () => {
        setOpen(true);
    };

    const hideModal = () => {
        setOpen(false);
        dispatch(userActions.setRemind())
    };

    // & handeld data

    const data = useMemo(() => [
        {
            title: "比特幣",
            titleRatio: "BTC",
            currencyRate: "",
            currencyToUsd: "",
            time: 24,
            dealAmount: "",
            change: 0
        },
        {
            title: "以太坊",
            titleRatio: "ETH",
            currencyRate: "",
            currencyToUsd: "",
            time: 24,
            dealAmount: "",
            change: 0
        },

        {
            title: "萊特幣",
            titleRatio: "BNB",
            currencyRate: "",
            currencyToUsd: "",
            time: 24,
            dealAmount: "",
            change: 0
        },
        {
            title: "波卡幣",
            titleRatio: "BUSD",
            currencyRate: "",
            currencyToUsd: "",
            time: 24,
            dealAmount: "",
            change: 0
        }
    ], []);

    const iconButtons = useMemo(() => [
        // 合約交易
        {
            key: 0,
            style: {},
            text: t('page.home.label.tradeFutures'),
            img: contractDealImg,
            onClick: () => {
                if (!token) {
                    alert(t("page.menu.login.alert.loginFirst"))
                    return;
                }
                dispatch(tradingActions.setRequestSymbol("BTC-USDT"))
                stackNavigate("/deal");
            }
        },
        // 邀請好友
        {
            key: 1,
            style: {},
            text: t('page.home.label.inviteFriend'),
            img: inviteImg,
            onClick: () => {
                if (!token) {
                    alert(t("page.menu.login.alert.loginFirst"))
                    return;
                }
                stackNavigate("/profile/Rebate");
            }
        },
        // 保護基金
        {
            key: 2,
            style: {},
            text: t('page.home.label.protectFund'),
            img: protectFund,
            onClick: () => { stackNavigate("/protect-fund"); }
        },
        // 即時趨勢
        {
            key: 3,
            style: {},
            text: t('page.home.label.realTimeTrend'),
            img: realTimeTrend,
            onClick: () => {
                stackNavigate("/instantTrend");
            }
        },
        // 新手獎勵
        {
            key: 4,
            style: {},
            text: t('page.home.label.rookieBonus'),
            img: rookieBonusImg,
            onClick: () => {
                stackNavigate("/quiz");
            }
        },
        // 福利中心
        {
            key: 5,
            style: {},
            text: t('page.home.label.welfare'),
            img: welfareImg,
            onClick: () => {
                if (!token) {
                    alert(t("page.menu.login.alert.loginFirst"))
                    return;
                }
                stackNavigate("/missionCenter");
            }
        },
        // 貸款
        {
            key: 6,
            style: {},
            text: t('page.home.label.stake'),
            img: loanImg,
            onClick: () => {
                if (!token) {
                    alert(t("page.menu.login.alert.loginFirst"))
                    return;
                }
                stackNavigate("/c2cApply")
            }
        },
        // 近期活動
        {
            key: 7,
            style: {},
            text: t('page.home.label.recentEvent'),
            img: recentEventImg,
            onClick: () => { stackNavigate("/activity") }
        },

    ], [t, token, dispatch, stackNavigate]);

    interface TabProps {
        key: MarketType,
        text: string
    }
    const tabs = useMemo(() => [
        // 熱門
        {
            key: "hot",
            text: t("page.home.label.hot")
        },
        // 自選
        {
            key: "favorite",
            text: t("page.home.label.favoritesList")
        },
        // 漲幅榜
        {
            key: "up",
            text: t("page.home.label.gainersList")
        },
        // 跌幅榜
        {
            key: "down",
            text: t("page.home.label.losersList")
        },
        // 24H成交量榜
        {
            key: "volume",
            text: t("page.home.label.24hVol")
        }
    ] as TabProps[], [t]);

    const defaultMarquee = useMemo(() => [
        {
            key: 0,
            text: 'odin1: 我是測試文字我是測試文字'
        },
        {
            key: 1,
            text: 'odin2'
        },
        {
            key: 2,
            text: 'odin3'
        },
    ], []);

    // # state
    // 熱門交易對
    interface HotSymbol {
        order: number
        price: MarketPrice
    }
    const [hotList, setHotList] = useState<HotSymbol[]>([]);

    // 最下方目前 active 的是哪一個選項
    const [currentList, setCurrentList] = useState<MarketType>("hot");

    const [carouselImages, setCarouselImages] = useState<Carousal[]>([]);;

    // 顯示的交易對
    const [data2, setData2] = useState([
        {
            title: "比特幣",
            titleRatio: "BTC",
            currencyRate: "",
            currencyToUsd: "",
            time: 24,
            dealAmount: "",
            change: 0
        },
        {
            title: "以太坊",
            titleRatio: "ETH",
            currencyRate: "",
            currencyToUsd: "",
            time: 24,
            dealAmount: "",
            change: 0
        },

        {
            title: "萊特幣",
            titleRatio: "BNB",
            currencyRate: "",
            currencyToUsd: "",
            time: 24,
            dealAmount: "",
            change: 0
        },
        {
            title: "波卡幣",
            titleRatio: "BUSD",
            currencyRate: "",
            currencyToUsd: "",
            time: 24,
            dealAmount: "",
            change: 0
        }
    ]);

    const [market, setMarket] = useState<MarketPrice[]>([]);
    const [favorite, setFavorite] = useState<MarketPrice[]>([]);

    // API 取得的自選交易對名稱
    const [favoriteList, setFavoriteList] = useState<string[] | []>([]);

    // currencySign init
    const [currencySign, setCurrencySign] = useState({});

    const [marquee, setMarquee] = useState<string[]>([]);

    // - methods
    /**
     * @author odin
     * @description 當 market 有變動且 favoriteList 不為空陣列的時候處理要顯示的資料
    */
    const handleFavoriteData = useCallback(() => {
        if (isEmpty(favoriteList)) return;

        let a: MarketPrice[] = [];

        for (let i = 0; i < favoriteList.length; i++) {
            for (let j = 0; j < market.length; j++) {
                if (favoriteList[i] === market[j]["s"]) {
                    a.push(market[j]);
                }
            }
        }
        setFavorite(
            a
                .sort(function (a: any, b: any) {
                    return parseFloat(a.P) - parseFloat(b.P);
                })
                .reverse()
        );
    }, [market, favoriteList]);

    /**
     * @author odin
     * @description 取得自選交易對列表並且 setState
    */
    const getFavorite = useCallback(async () => {
        try {
            investorService.getFavoriteSymbol().then((response) => {
                setFavoriteList(response.data);
            })
        } catch (e) {
            console.log('getFavoriteList Error', e);
        }
    }, []); // eslint-disable-line

    /**
     * @author odin
     * @description 取得熱門列表並且 setState
    */
    const handleGetHotList = useCallback(() => {
        try {
            if ( marketArr.length===0 ) return ;
            infoService.getSymbol(true).then((response) => {
                setHotList(response.data.map( (symbol, index) => {
                    const p = marketArr.find( marketPrice => marketPrice.s===symbol);
                    return {
                        order: index,
                        price: p
                    } as HotSymbol
                }))
            })
        } catch (e) {
            console.log('getHotList Error', e);
            alert(`getHotList Error ${e}`);
        }
    }, [marketArr]);

    const handleGetMarquee = useCallback(async () => {
        try {
            infoService.getMarquees(language).then((response) => {
                setMarquee(response.data.map(m => m.title));
            })
        } catch (e) {
            console.log('getMarquee Error', e);
        }
    }, [language]);

    const getMarketData = ( type: MarketType ) => {
        switch(type){
            case "hot": return hotList.sort((a, b) => a.order - b.order).map( h => h.price);
            case "favorite": return favorite;
            default: return marketArr;
        }
    }

    // * hooks
    /**
     * @author odin
     * @description Home Init
    */
    useEffect(() => {
        handleGetHotList();
    }, [handleGetHotList, currentList]);

    /**
     * @author odin
     * @description Marquee Init
    */
    useEffect(() => {
        handleGetMarquee();
    }, [handleGetMarquee]);

    useEffect(() => {
        getFavorite();
    }, [getFavorite]);

    useEffect(() => {
        if (marketArr) {
            let gfg = marketArr.slice(0).sort(function (a: any, b: any) {
                return parseFloat(a.P) - parseFloat(b.P);
            });
            setMarket(gfg.reverse());
            handleFavoriteData();

            for (let i = 0; i < marketArr.length; i++) {
                if (marketArr[i].s === "BTC-USDT") {
                    let newArr = [...data];
                    // let price = lastJsonMessage.data.c.slice(0, -6);
                    newArr[0].currencyRate = marketArr[i].c.slice(0, -4);
                    newArr[0].currencyToUsd =
                        parseFloat(marketArr[i].c) < 10 && parseFloat(marketArr[i].c) > 1
                            ? marketArr[i].c.slice(0, -3)
                            : parseFloat(marketArr[i].c) < 10
                                ? marketArr[i].c.slice(0, -2)
                                : marketArr[i].c.slice(0, -4);
                    newArr[0].change = parseFloat(marketArr[i].P);
                    newArr[0].dealAmount = marketArr[i].v.split(".")[0];
                    setData2(newArr);
                }
                if (marketArr[i].s === "ETH-USDT") {
                    let newArr = [...data];
                    // let price = lastJsonMessage.data.c.slice(0, -6);
                    newArr[1].currencyRate = marketArr[i].c.slice(0, -4);
                    newArr[1].currencyToUsd = marketArr[i].c.slice(0, -4);
                    newArr[1].change = parseFloat(marketArr[i].P);
                    newArr[1].dealAmount = marketArr[i].v.split(".")[0];
                    setData2(newArr);
                }
                if (marketArr[i].s === "BNB-USDT") {
                    let newArr = [...data];
                    // let price = lastJsonMessage.data.c.slice(0, -6);
                    newArr[2].currencyRate = marketArr[i].c.slice(0, -4);
                    newArr[2].currencyToUsd = marketArr[i].c.slice(0, -4);
                    newArr[2].change = parseFloat(marketArr[i].P);
                    newArr[2].dealAmount = marketArr[i].v.split(".")[0];
                    setData2(newArr);
                }
            }
        }
    }, [marketArr, data]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        infoService.getCarousals(language).then((response) => {
            setCarouselImages(response.data);
        })
    }, [language]);

    useEffect(() => {
        if (isRemind) {
            showModal()
        }
        infoService.getFiatCurrency().then((response) => {
            const fiatObj = get(response, 'data', {});
            if (isEmpty(fiatObj)) return;
            setCurrencySign(
                Object.keys(fiatObj).reduce((a, v) => ({ ...a, [v]: v }), {})
            );
        })
    }, [isRemind]);

    return (
        <PageContainer>
            <HomeHeader />
            <div style={{ padding: '5px 16px 0px' }}>
                <HomeCarousel autoplay>
                    {carouselImages.map((x: any) => {
                        return (
                            <img
                                key={x.imagePath}
                                src={x.imagePath}
                                style={{ width: "100%", borderRadius: 8 }}
                                alt=""
                                onClick={() => {
                                    if (x.announcementId) {
                                        stackNavigate("/announcement/" + x.announcementId);
                                    }
                                }}
                            />
                        );
                    })}
                </HomeCarousel>
            </div>

            {/* 跑馬燈公告 */}
            <div style={{
                padding: '0 20px'
            }}>
                <Marquee data={isEmpty(marquee) ? defaultMarquee : marquee} />
            </div>


            {/* Icon 按鈕區 */}
            <FlexDirectionRowDiv style={{
                padding: '0px 12px',
                flexWrap: 'wrap'
            }}>
                {
                    iconButtons.map((item, idx) => (
                        <IconBtn
                            idx={idx}
                            key={item.key}
                            onClick={item.onClick}
                            style={{ ...item.style }}
                        >
                            <IconBtnImg src={item.img} />
                            <IconBtnText children={item.text} />
                        </IconBtn>
                    ))
                }
            </FlexDirectionRowDiv>

            <div style={{ display: "flex", justifyContent: "space-between", marginTop: 20 }}>
                <button style={{ background: "#F4F4F6", marginLeft: 12, width: "45%", height: 50, border: "none", borderRadius: 8, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", padding: 10 }} onClick={() => {
                    stackNavigate("/deposit?symbol=USDT");
                }}>
                    <p style={{ fontWeight: 400, fontSize: 12, color: "#000000", fontFamily: "Open Sans" }}>{t('page.home.label.deposit')}</p>
                    <img src={rechargeImg} alt="" style={{ width: 45, height: 45 }} />
                </button>
                <button style={{ background: "#F4F4F6", marginRight: 12, width: "45%", height: 50, border: "none", borderRadius: 8, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", padding: 10 }} onClick={() => {
                    if (!token) {
                        alert(t("page.menu.login.alert.loginFirst"))
                        return;
                    }
                    stackNavigate("/fiat-deal");
                }}>
                    <p style={{ fontWeight: 400, fontSize: 12, color: "#000000", fontFamily: "Open Sans" }}>{t('page.home.label.c2c')}</p>
                    <img src={C2CImg} alt="" style={{ width: 45, height: 45 }} />
                </button>
            </div>

            <TradePairContainer>
                {data2.map((item, i) => {
                    return (
                        <div key={i}>
                            {item.titleRatio !== "BUSD" && (
                                <TradePairUnit
                                    onClick={() => {
                                        dispatch(tradingActions.setRequestSymbol(item.titleRatio + "-USDT"))
                                        stackNavigate("/deal");
                                    }}
                                >
                                    <TradePairName>
                                        {item.titleRatio}/USDT
                                        <TradePairPercentage isPlus={item.change >= 0}>
                                            {
                                                (item.change >= 0)
                                                    ? `+${item.change}%`
                                                    : `${item.change}%`
                                            }
                                        </TradePairPercentage>
                                    </TradePairName>

                                    <TradePairCurrencyToUsd isPlus={item.change >= 0}>
                                        {item.currencyToUsd}
                                    </TradePairCurrencyToUsd>

                                    <TradePairCurrencyFiatRate>
                                        {`${fiatCurrencySymbol[currencySign[currency.name]]}${(Number(item.currencyToUsd) * currency.rate).toFixed(2)}`}
                                    </TradePairCurrencyFiatRate>
                                </TradePairUnit>
                            )}
                        </div>
                    );
                })}
            </TradePairContainer>

            {/* 下方 Tabs & TabContent */}
            <ChartContainer>
                <Banner />
                <ListContainer>
                    <ListHeader>
                        {
                            !isEmpty(tabs) &&
                            tabs.map(item => (
                                <ListButton
                                    key={item.key}
                                    type="button"
                                    currentList={currentList}
                                    listNum={item.key}
                                    onClick={() => {
                                        setCurrentList(item.key);
                                    }}
                                >
                                    {item.text}
                                </ListButton>
                            ))
                        }
                    </ListHeader>
                    <div style={{ width: "100%", margin: "10px 0px" }}>
                        <table style={{ width: "100%", borderCollapse: "separate", borderSpacing: "0px 10px" }}>
                            <thead>
                                <tr>
                                    <th style={{ fontSize: 12, color: COLORS.Mid_gray, width: "40%", textAlign: "left" }}>{t("page.home.label.marketPair")}</th>
                                    <th style={{ fontSize: 12, color: COLORS.Mid_gray, width: "30%", textAlign: "right" }}>{t("page.home.label.lastPrice")}</th>
                                    <th style={{ fontSize: 12, color: COLORS.Mid_gray, width: "30%", textAlign: "right" }}>{t(`page.home.label.${currentList !== "volume" ? "24Hchg" : "24hVol"}`)}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <MarketList type={currentList} marketPrice={getMarketData(currentList)}/>
                            </tbody>
                        </table>
                    </div>
                </ListContainer>
            </ChartContainer>
            <Footer locationPage={location} />
            <Modal
                visible={open}
                footer={
                    <BaseButton
                        style={{ width: "100%" }}
                        onClick={() => {
                            dispatch(userActions.setRemind());
                            stageStackNavigate("/id-verify");
                        }}>
                        {t("page.home.button.binding")}
                    </BaseButton>
                }
                closable={false}
                onCancel={hideModal}
                width={"70%"}
                centered
            >
                <div style={{ width: "100%", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
                    <img src={GoogleAuth} alt="" />
                    <Header size="md">{t("page.home.button.binding")}</Header>
                </div>
            </Modal>
        </PageContainer>
    );
};

export default Home;
