import styled from "styled-components";
import { useState, useEffect } from "react"
import useStackNavigate from "@/hooks/useStackNavigate";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "@/hooks";
import { userService } from "@/services";
import CommonPage from "@/components/LayoutPage";
import CustomInput from "@/components/Input/CustomInput";
import { CaretDownOutlined } from "@ant-design/icons";
import { BaseButton } from "@/components/Button";
import { Header } from "@/components/typography/Header";
import { message } from "antd";
import { formatByCountry } from "@/utils/phone";

const FormBox = styled.div`
    width: 100%;
    margin-top: 40px;
    padding: 0px 15px;
`

const ResetPhoneNumber = () => {
    const country = useAppSelector((state) => state.user.country)
    const [phone, setPhone] = useState("")
    const [count, setCount] = useState(-1)
    const [code, setCode] = useState("")
    const { stackNavigate } = useStackNavigate()
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        setTimeout(() => {
            if (count > 0) {
                setCount(c => c - 1)
            }
        }, 1000)

    }, [count])

    return (
        <CommonPage
            active={loading}
            title={t("page.verifyPhone.title")}
            returnPath={-1}
        >
            <FormBox>
                <Header size="sm">{t("page.verifyPhone.input.phone")}</Header>
                <CustomInput
                    valueType="number"
                    inputType="line"
                    style={{ marginTop: 10, border: "none" }}
                    value={phone}
                    onChange={(e) => {
                        setPhone(e.target.value)
                    }}
                    left={
                        <div onClick={() => stackNavigate("/setting-area-zone")}>
                            <span>+{country ? country.callingCode : ""}</span>
                            <CaretDownOutlined style={{ marginLeft: 3, fontSize: "small" }} />
                        </div>
                    }
                    placeholder={t(`page.menu.securitySetting.placeholder.phone`)}
                />
                <Header size="sm">{t("page.verifyPhone.input.code")}</Header>
                <CustomInput
                    valueType="number"
                    inputType="line"
                    style={{ marginTop: 10, border: "none" }}
                    value={code}
                    onChange={(e) => {
                        setCode(e.target.value)
                    }}
                    right={
                        count > 0 ?
                            `${Math.floor(count / 60)}:${(count - Math.floor(count / 60) * 60) < 10 ? "0" + (count - Math.floor(count / 60) * 60) : (count - Math.floor(count / 60) * 60)}` :
                            <div onClick={() => {
                                if (!phone) {
                                    alert(t("page.verifyPhone.alert.phone"))
                                } else {
                                    const reg = new RegExp(country.phoneRegExp)
                                    if ( !reg.test(phone) ){
                                        message.error(t("page.verifyPhone.alert.formatError"))
                                        return ;
                                    }
                                    const fullPhone = `${country.callingCode}${formatByCountry(phone, country.code)}`
                                    setLoading(true)
                                    userService.checkPhoneExist(fullPhone).then(() => {
                                        userService.verifyPhone({
                                            phone: fullPhone
                                        }).then(() => {
                                            setCount(300)
                                        })
                                    }).catch((error) => {
                                        alert(error.response.data.msg)
                                    }).finally(() => {
                                        setLoading(false)
                                    })
                                }
                            }}>
                                {t(`page.verifyPhone.button.${count === 0 ? "reSend" : "send"}`)}
                            </div>
                    }
                    placeholder={t(`page.verifyPhone.placeholder.code`)}
                />
                {/* <AreaSpace4 onClick={() => { navigation("/setting-area-zone") }}>
                    <AreaCodeAlign>
                        <Words3>
                            +{country ? country.callingCode : ""}
                        </Words3>
                        <RightButton>
                            <RightButtonArrow src={arrow} alt="arrow" />
                        </RightButton>
                    </AreaCodeAlign>
                </AreaSpace4> */}

                {/* <AreaWord>{t("page.verifyPhone.input.phone")}</AreaWord>
                <AreaSpace>
                    <Words placeholder={t("page.verifyPhone.placeholder.phone")} onChange={(e) => { setPhone(e.target.value) }} />
                </AreaSpace>
                <AreaWord>{t("page.verifyPhone.input.code")}</AreaWord> */}

                {/* <AreaSpace3>
                    <AreaSpace2 placeholder={t("page.verifyPhone.placeholder.code")} onChange={(e) => { setCode(e.target.value) }} />
                    {startCount && count !== 0 && <Words2>
                        {
                            Math.floor(count / 60)}:{(count - Math.floor(count / 60) * 60) < 10 ? "0" + (count - Math.floor(count / 60) * 60) : (count - Math.floor(count / 60) * 60)
                        }
                    </Words2>}
                    {!startCount &&
                        <Words2 onClick={() => {
                            if (!phone) {
                                alert(t("page.verifyPhone.alert.phone"))
                            } else if (!phone.startsWith("09")) {
                                alert(t("page.verifyPhone.alert.phoneFormat"))
                            } else {
                                const fullPhone = country.callingCode + phone.substring(1, phone.length)
                                setLoading(true)
                                userService.checkPhoneExist(fullPhone).then(() => {
                                    userService.verifyPhone({
                                        phone: fullPhone
                                    }).then(() => {
                                        setStartCount(true)
                                    })
                                }).catch((error) => {
                                    alert(error.response.data.msg)
                                }).finally(() => {
                                    setLoading(false)
                                })
                            }
                        }}>
                            {t("page.verifyPhone.button.getVerifyCode")}
                        </Words2>
                    }
                    {count === 0 &&
                        <Words2 onClick={() => {
                            if (!phone.startsWith("09")) {
                                alert(t("page.verifyPhone.alert.phoneFormat"))
                            } else {
                                const fullPhone = country.callingCode + phone.substring(1, phone.length)
                                setLoading(true)
                                userService.checkPhoneExist(fullPhone).then(() => {
                                    userService.verifyPhone({
                                        phone: fullPhone
                                    }).then(() => {
                                        setStartCount(true)
                                        setCount(300)
                                    })
                                }).catch((error) => {
                                    alert(error.response.data.msg)
                                }).finally(() => {
                                    setLoading(false)
                                })
                            }
                        }}>
                            {t("page.verifyPhone.button.reSend")}
                        </Words2>
                    }
                </AreaSpace3> */}
                <BaseButton
                    style={{width: "100%", marginTop: 15, height: 40}}
                    onClick={() => {
                        if (!phone) {
                            alert(t("page.verifyPhone.alert.phone"))
                        } else if (!code) {
                            alert(t("page.verifyPhone.alert.code"))
                        } else {
                            const fullPhone = `${country.callingCode}${formatByCountry(phone, country.code)}`
                            setLoading(true)
                            userService.checkPhone({
                                phone: fullPhone,
                                code: code
                            }).then(() => {
                                alert(t("page.verifyPhone.alert.success"))
                                stackNavigate(-1)
                            }).catch((error) => {
                                alert(error.response.data.msg)
                            }).finally(() => {
                                setLoading(false)
                            })
                        }
                    }}
                >
                    {t("page.verifyPhone.button.submit")}
                </BaseButton>
            </FormBox>
        </CommonPage>
    );
}

export default ResetPhoneNumber
