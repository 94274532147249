/* 引入styled components */
import styled from "styled-components";
import { useState, useEffect } from "react"
import useStackNavigate from "@/hooks/useStackNavigate";
import { useTranslation } from "react-i18next";
import CommonPage from "@/components/LayoutPage";
import { authService, userService } from "@/services";
import { useAppSelector } from "@/hooks";

const AreaSpace = styled.div`
  padding: 10px;
  background: #fff ;
  opacity: 0.6;
  border-radius: 4px;
  background:#F4F4F6;
`;

const AreaContent = styled.div`
  display:flex;
  justify-content: space-between;
`;

const Words = styled.input`
  font-size:15px;
  color: #383743;
  padding:5px;
  border:none;
  background:none;
  width:100%;
`;

const SaveButton = styled.button`
  justify-content: center;
  border: none ;
  border-radius: 4px ;
  height: 44px ;
  width: 100% ;
  background: rgb(211,47,47,1);
  margin-top:40px;
`;

const SaveButtonWord = styled.div`
  color:#FFFFFF;
  font-size:14px;
  text-align:center;
  border: none ;
`;

const PassWord = styled.div`
  margin-top: 15px;
  color:#5F5C70;
  font-size:13px;
  margin-bottom:5px;
`;


const FormBox = styled.div`
    width: 100%;
    padding: 0px 15px;
`

const SetTheFundPassword = () => {
    const user = useAppSelector((state)=>state.user.detail)
    const [password, setPassword] = useState("")
    const { stackNavigate } = useStackNavigate()
    const [loading, setLoading] = useState(false);
    const [count, setCount] = useState(0)
    const [count2, setCount2] = useState(0)
    const [emailCode, setEmailCode] = useState("");
    const [phoneCode, setPhoneCode] = useState("");
    const [emailSend, setEmailSend] = useState(false);
    const [phoneSend, setPhoneSend] = useState(false);
    const { t } = useTranslation();
    useEffect(() => {
        if (emailSend) {
            setTimeout(() => {
                if (count > 0) {
                    setCount(c => c - 1)
                }
            }, 1000)
        }
        if (count === 0) {
            setEmailSend(false)
        }
    }, [count, emailSend])

    useEffect(() => {
        if (phoneSend) {
            setTimeout(() => {
                if (count2 > 0) {
                    setCount2(c => c - 1)
                }
            }, 1000)
        }
        if (count2 === 0) {
            setPhoneSend(false)
        }
    }, [count2, phoneSend])

    return (
        <CommonPage active={loading} title={t("page.resetGoogleAuth.title")} returnPath={"/safe-setting"}>
            <FormBox>
                <PassWord>{t("page.resetGoogleAuth.input.password")}</PassWord>
                <AreaSpace
                >
                    <AreaContent>
                        <Words type="password" placeholder={t("page.resetGoogleAuth.placeholder.password")} onChange={(e) => {
                            setPassword(e.target.value)
                        }} />
                    </AreaContent>
                </AreaSpace>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <PassWord>{t("page.resetGoogleAuth.input.emailCode")}</PassWord>
                    {emailSend ? <PassWord>{count}s</PassWord> : <PassWord onClick={() => {
                        setLoading(true)
                        authService.sendEmail({ email: user.account }).then(() => {
                            alert(t("page.resetGoogleAuth.alert.send"))
                            setCount(60)
                            setEmailSend(true)
                        }).catch((error) => {
                            alert(error.response.data.msg)
                        }).finally(() => {
                            setLoading(false)
                        })
                    }}>{t("page.resetGoogleAuth.button.sendEmail")}</PassWord>}

                </div>
                <AreaSpace
                >
                    <AreaContent>
                        <Words placeholder={t("page.resetGoogleAuth.placeholder.emailCode")} onChange={(e) => {
                            setEmailCode(e.target.value)
                        }} />
                    </AreaContent>
                </AreaSpace>

                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <PassWord>{t("page.resetGoogleAuth.input.phoneCode")}</PassWord>
                    {phoneSend ? <PassWord>{count2}s</PassWord> : <PassWord onClick={() => {
                        setLoading(true)
                        userService.verifyPhone({
                            phone: user.phone
                        }).then(() => {
                            alert(t("page.resetGoogleAuth.alert.send"))
                            setCount2(60)
                            setPhoneSend(true)
                        }).catch((error) => {
                            alert(error.response.date.msg)
                        }).finally(() => {
                            setLoading(false)
                        })
                    }}>{t("page.resetGoogleAuth.button.sendPhone")}</PassWord>}

                </div>
                <AreaSpace
                >
                    <AreaContent>
                        <Words placeholder={t("page.resetGoogleAuth.placeholder.phoneCode")} onChange={(e) => {
                            setPhoneCode(e.target.value)
                        }} />
                    </AreaContent>
                </AreaSpace>

                <SaveButton onClick={() => {
                    if (!password) {
                        alert(t("page.resetGoogleAuth.alert.password"))
                    } else if (!emailCode) {
                        alert(t("page.resetGoogleAuth.alert.emailCode"))
                    } else if (!phoneCode) {
                        alert(t("page.resetGoogleAuth.alert.phoneCode"))
                    } else {
                        setLoading(true)
                        userService.resetGoogleSecret({
                            password: password,
                            mailCode: emailCode,
                            phoneCode: user.phone ? phoneCode : null,
                        }).then(() => {
                            alert(t("page.resetGoogleAuth.alert.success"))
                            stackNavigate(-1)
                        }).catch((error) => {
                            alert(error.response.date.msg)
                        }).finally(() => {
                            setLoading(false)
                        })
                    }

                }}>
                    <SaveButtonWord>{t("page.resetGoogleAuth.button.reset")}</SaveButtonWord>
                </SaveButton>

            </FormBox >
        </CommonPage>
    );
}

export default SetTheFundPassword 
