import styled from "styled-components";


const ChartContainer = styled.div`
display: flex;
flex-direction: column;
background-color: #FFFFFF;
height: 400px;
`

export default function Layout ({ title, children }) {
  return (
    <ChartContainer
      className="k-line-chart-container">
      {/* <ChartTitle
        className="k-line-chart-title">{title}</ChartTitle> */}
      {children}
    </ChartContainer>
  )
}
