// ^ Types
import {
    QuoteUnit
} from '@/constants/type';

// ? Self-packed Components || Functions
import BaseNoData from '@/components/BaseNoData';
import {
    FlexAlignCenterDiv,
    DealFavBtn,
    DealLastValue,
    DealOrderUnit,
    DealName,
    DealRatio
} from '@/styled-components';

// & Constant
import { COLORS } from '@/constants/colors';

// ^ Plugins
import { useTranslation } from 'react-i18next';
import { FireOutlined, FireFilled } from '@ant-design/icons';
import { isEmpty } from 'lodash';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { tradingActions } from '@/store/slice';
import { formatNumberWithChineseUnit, parsePrice } from '@/utils/parse';
import useStackNavigate from '@/hooks/useStackNavigate';
import { Dispatch, SetStateAction } from 'react';

interface FavoritesListContentProps {
    showList: Array<QuoteUnit> | any;
    handleRemoveFav: Function;
    setActiveName: Dispatch<SetStateAction<string>>
}

/**
 * @author odin
 * @level Layout/FavoritesListContent
 * @description 自選名單頁面
*/
const FavoritesListContent = ({
    showList,
    handleRemoveFav,
    setActiveName
}: FavoritesListContentProps) => {
    const token = useAppSelector((state) => state.user.token)
    const currency = useAppSelector((state) => state.user.currency)
    const { stackNavigate } = useStackNavigate()
    const dispatch = useAppDispatch()
    // console.log('FavoritesListContent isLogin', isLogin);

    // $ init data
    const { t } = useTranslation();

    return (
        <>
            {
                (
                    !isEmpty(showList)
                    && showList[0].name !== ''
                ) ? (
                    showList.map(item => (
                        <tr
                            key={`fav-${item.key}`}
                            onClick={() => {
                                dispatch(tradingActions.setRequestSymbol(item.name))
                                stackNavigate("/deal/chart");
                            }}
                        >
                            <td style={{ textAlign: "left" }}>
                                <FlexAlignCenterDiv>
                                    <DealFavBtn type="button" onClick={(e) => {
                                        e.stopPropagation()
                                        handleRemoveFav(item.name)
                                    }}>
                                        {
                                            item.isFav
                                                ? <FireFilled style={{ color: COLORS.Primary }} />
                                                : <FireOutlined style={{ color: COLORS.Mid_gray }} />
                                        }
                                    </DealFavBtn>

                                    <div style={{ width: "100%" }}>
                                        <DealName>
                                            {item.name.split("-")[0]}
                                        </DealName>

                                        <DealOrderUnit>
                                            {` /USDT`}
                                        </DealOrderUnit>
                                        <div style={{ color: COLORS.Mid_gray, fontSize: 10, paddingTop: 2 }}>
                                            {`${t("page.market.label.volume")} ${formatNumberWithChineseUnit(item.volume * item.lastPrice / 10000)} USDT`}
                                        </div>
                                    </div>
                                </FlexAlignCenterDiv>
                            </td>

                            <td style={{ textAlign: "right" }}>
                                <DealLastValue>
                                    {parsePrice(parseFloat(item.lastPrice))}
                                    <div style={{ color: COLORS.Mid_gray, fontSize: 10, paddingTop: 2 }}>
                                        {`${t(`currencyUnit.${currency.name}.name`)}${t(`currencyUnit.${currency.name}.unit`)} ${parsePrice(item.lastPrice * currency.rate)}`}
                                    </div>
                                </DealLastValue>
                            </td>
                            <td style={{ textAlign: "right" }}>
                                <DealRatio
                                    change={item.percentageIn24H}
                                >
                                    {`${parseFloat(item.percentageIn24H).toFixed(2)}%`}
                                </DealRatio>
                            </td>
                        </tr>
                    ))
                ) : (
                    <tr>
                        <td style={{textAlign: "center"}} colSpan={3}><BaseNoData onClick={() => setActiveName("fundFutures")} text={token ? '' : t('page.market.notice.checkAfterLogin')} /></td>
                    </tr>
                )
            }
        </>
    );
};

export default FavoritesListContent;
