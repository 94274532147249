import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { C2cOrderType } from "@/constants";
import { CreateOtcOrderRequest } from "@/interfaces/request.interface";
import { advertisementService } from "@/services";
import { Advertisement, OtcOrder } from '@/interfaces/otc.interface';
import axios, { AxiosError } from 'axios';
import { OtcResponse } from '@/interfaces/response.interface';

const initialState = {
    advertisement: {} as Advertisement,
    buying: {} as OtcOrder,
    selling: {} as OtcOrder,
    dealingSide: "" as C2cOrderType | ""
}

const otcOrderSlice = createSlice({
    name: "otcOrder",
    initialState: initialState,
    reducers: {
        setSelectedAdvertisement(state, action: PayloadAction<Advertisement>) {
            state.advertisement = action.payload
        },
        updateBuying(state, action: PayloadAction<OtcOrder>) {
            state.buying = action.payload
        },
        updateSelling(state, action: PayloadAction<OtcOrder>) {
            state.selling = action.payload
        },
        changeDealingSide(state, action: PayloadAction<C2cOrderType | "">) {
            state.dealingSide = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(createOtcOrder.fulfilled, (state, action) => {
            if (action.payload.type === "buy") {
                state.buying = action.payload.order
            }
            else {
                state.selling = action.payload.order
            }
        })
    }
});

export const otcOrderActions = otcOrderSlice.actions;

export const createOtcOrder = createAsyncThunk<{ type: C2cOrderType, order: OtcOrder }, CreateOtcOrderRequest, { rejectValue: AxiosError<OtcResponse<any>> }>('c2c/confirmAdvertisement',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await advertisementService.createOtcOrder(payload.id, payload.orderInfo);
            return {
                type: payload.type,
                order: response
            };
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return rejectWithValue(error);
            }
            throw error;
        }
    }
);

export default otcOrderSlice.reducer;