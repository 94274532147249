import {
    useState,
    useEffect,
    useCallback
} from "react";

// ? Self-packed Components || Functions
import KlineChart from './KlineChart'
import { COLORS } from "@/constants/colors";
import { Row, Col, Divider, Dropdown, Menu } from "antd";

// ^ Plugins
import { find, cloneDeep, get } from "lodash"
import { useTranslation } from "react-i18next";
import axios from "axios";
import { KLineDataUnit } from '@/interfaces/market.interface';

// = Styled Component
import {
    DealListContainer,
    DealListTitle,
    DealItemContainer,
    DealItem,
} from '@/styled-components/deal-chart';
import { investorService, marketService } from "@/services";
import { useAppSelector } from "@/hooks";
import CommonPage from "@/components/LayoutPage";
import { parsePrice } from "@/utils/parse";
import { CenterLineItem } from "@/styled-components/deal";
import { CaretDownOutlined, StarFilled, StarOutlined, SwapOutlined } from "@ant-design/icons";
import useStackNavigate from "@/hooks/useStackNavigate";
/**
 * @author odin
 * @level Layout/Deal/Chart
 * @description K線頁面
*/
const Chart = () => {

    // $ init data
    const { t } = useTranslation();
    const { stackNavigate } = useStackNavigate()
    const trade = useAppSelector((state) => state.trading.request.symbol)
    // % context
    const market = useAppSelector((state) => state.market.price)
    const intervals: string[] = ["1m", "15m", "1h", "4h", "1d"]
    const mainTechnicalIndicatorTypes: string[] = ['MA', 'EMA', 'BOLL']
    const subTechnicalIndicatorTypes: string[] = ['VOL', 'MACD', 'KDJ']
    // # states
    const [timeScale, setTimeScale] = useState("1m");
    const [mainTechnicalIndicator, setMainTechnicalIndicator] = useState("");
    const [subTechnicalIndicator, setSubTechnicalIndicator] = useState("");
    const [price, setPrice] = useState("")
    const [bidsArray, setBidsArray] = useState<string[]>([])
    const [asksArray, setAsksArray] = useState<string[]>([])
    const [remarkPrice, setRemarkPrice] = useState("");
    const [lastDateCandle, setLastDateCandle] = useState<KLineDataUnit>({} as KLineDataUnit);
    const [favorite, setFavorite] = useState<string[]>([])
    const [selectType, setSelectType] = useState<"price" | "marketPrice">("price")
    const currency = useAppSelector((state) => state.user.currency)
    const [percent, setPercent] = useState("");


    const getDepth = useCallback(() => {
        const url = `https://api1.binance.com/api/v3/depth?symbol=${trade?.split("-")[0]}USDT&limit=15`;

        axios.get(url)
            .then((res: any) => {
                const resNew = cloneDeep(res);
                const asks = get(resNew, 'data.asks', []).reverse();
                const bids = get(resNew, 'data.bids', []);

                setAsksArray(asks);
                setBidsArray(bids);
            });
    }, [trade]);

    // * hooks
    useEffect(() => {

        getDepth();

        const interval = setInterval(() => {
            getDepth();
        }, 2000);

        return function cleanUp() { clearInterval(interval) };
    }, [getDepth]);

    const get24hTicker = useCallback(() => {
        marketService.get24HTicker(trade, "1d", 2).then((response) => {
            if (response.data.length === 1) {
                const lastCandle = JSON.parse(response.data[0]);
                setLastDateCandle({
                    timestamp: lastCandle[0],
                    open: parseFloat(lastCandle[1]),
                    high: parseFloat(lastCandle[2]),
                    low: parseFloat(lastCandle[3]),
                    close: parseFloat(lastCandle[4]),
                    volume: lastCandle[5]
                })
            }
        })
    }, [trade])

    useEffect(() => {
        if (market) {
            const t = trade ? trade.split("-")[0] + "-USDT" : "BTC-USDT"
            const remark = find(market, function (o) { return o.s === t })
            setRemarkPrice(remark ? remark.m : 0)
            setPrice(remark ? remark.c.toString() : "0")
            setPercent(remark ? remark.P : "0")
            get24hTicker()
        }
    }, [market, trade, get24hTicker]);

    const getVolume = (volume: number) => {
        volume = volume / 10000
        if (volume > 100000000) {
            return `${(Math.round(volume / 100000000))} ${t("page.chart.label.hundredMillion")}`;
        }

        if (volume > 10000) {
            return `${(Math.round(volume / 10000))} ${t("page.chart.label.tenThousand")}`;
        }

        return Math.round(volume);
    }

    const getHeaderOption = () => {
        return (
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <SwapOutlined style={{ marginRight: 5, fontWeight: 700 }} onClick={() => stackNavigate("/deal/search-deal", { state: "/deal" })} />
                <div style={{ textAlign: "center", fontWeight: 700 }}>
                    <div>{trade}</div>
                    <div style={{ fontSize: 10 }}>{t("page.chart.title")}</div>
                </div>
            </div>
        )
    }

    const getFavoriteStar = () => {
        return (
            favorite.includes(trade) ?
                <StarFilled style={{color: COLORS.Primary}} onClick={() => { handleRemoveFav() }} /> : <StarOutlined onClick={() => { handleAddFav() }} />
        )
    }

    const getFavorites = useCallback(async () => {
        investorService.getFavoriteSymbol().then((response) => {
            setFavorite(response.data)
        }).catch((error) => {
            alert(error.response.data.msg)
        })
    }, []);

    const handleRemoveFav = useCallback(() => {
        investorService.deleteFavoriteSymbol({ symbol: trade }).then(() => {
            getFavorites();
        }).catch((error) => {
            alert(error.response.data.msg)
        })
    }, [getFavorites, trade]);

    const handleAddFav = useCallback(() => {
        investorService.addFavoriteSymbol({ symbol: trade }).then(() => {
            getFavorites();
        }).catch((error) => {
            alert(error.response.data.msg)
        })
    }, [getFavorites, trade]);



    useEffect(() => {
        getFavorites()
    }, [getFavorites])

    return (
        <CommonPage title={getHeaderOption()} returnPath={-1} option={getFavoriteStar()}>
            <div
                style={{
                    width: "100%",
                    height: 111,
                    background: "#fff",
                    top: 45,
                    borderTop: "0.5px solid #F4F4F6",
                    boxShadow: "0px 2px 4px rgba(143, 141, 162, 0.07)",
                    flexDirection: "column",
                    zIndex: 100,
                }}
            >
                <Row
                    style={{
                        width: "100%",
                        height: "65%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        padding: "0 16px",
                        marginTop: "5px"
                    }}
                >
                    <Col span={9}>
                        <div>
                            <Dropdown trigger={["click"]} overlay={
                                <Menu>
                                    <Menu.Item onClick={() => setSelectType("price")}>{t("page.chart.label.price")}</Menu.Item>
                                    <Menu.Item onClick={() => setSelectType("marketPrice")}>{t("page.chart.label.marketPrice")}</Menu.Item>
                                </Menu>
                            }>
                                <div style={{ fontSize: 10 }}>
                                    {t(`page.chart.label.${selectType}`)}
                                    <CaretDownOutlined style={{ padding: "0px 2px" }} />
                                </div>
                            </Dropdown>
                        </div>
                        <h1
                            style={{
                                fontWeight: 700,
                                fontSize: 28,
                                color: COLORS.Red,
                                fontFamily: "Open Sans",
                                padding: "10px 0px"
                            }}
                        >
                            {parsePrice(selectType === "price" ? parseFloat(price) : parseFloat(remarkPrice))}
                        </h1>
                        <div style={{ display: "flex", fontSize: 12 }}>
                            <span style={{ lineHeight: "18px", fontWeight: 600, fontFamily: "Open Sans" }}>
                                {`≈${t(`currencyUnit.${currency.name}.name`)}${t(`currencyUnit.${currency.name}.unit`)} ${parsePrice(selectType === "price" ? parseFloat(price) : parseFloat(remarkPrice) * currency.rate)}`}
                            </span>
                            <span style={{ lineHeight: "18px", fontWeight: 700, fontFamily: "Open Sans", color: parseFloat(percent)>0 ? COLORS.Green : COLORS.Red, marginLeft: 3}}>
                                {`${parseFloat(percent).toFixed(2)}%`}
                            </span>
                        </div>
                    </Col>
                    <Col span={7} offset={1}>
                        <div
                            style={{
                                fontSize: 12,
                            }}
                        >
                            <div style={{padding: "5px 0px"}}>
                                <p
                                    style={{ lineHeight: "18px", color: COLORS.Mid_gray, padding: "5px 0px" }}
                                >
                                    {t("page.chart.label.high")}&nbsp;&nbsp;
                                </p>
                                <p style={{ lineHeight: "18px", fontWeight: 600, fontFamily: "Open Sans" }}>{lastDateCandle.high}</p>
                            </div>
                            <div style={{padding: "5px 0px"}}>
                                <p
                                    style={{ lineHeight: "18px", color: COLORS.Mid_gray, padding: "5px 0px" }}
                                >
                                    {t("page.chart.label.volume")}&nbsp;&nbsp;
                                </p>
                                <p style={{ lineHeight: "18px", fontWeight: 600, fontFamily: "Open Sans" }}>{getVolume(lastDateCandle.volume)}</p>
                            </div>
                        </div>
                    </Col>
                    <Col span={7}>
                        <div
                            style={{
                                fontSize: 12,
                            }}
                        >
                            <div style={{padding: "5px 0px"}}>
                                <p
                                    style={{ lineHeight: "18px", color: COLORS.Mid_gray, padding: "5px 0px" }}
                                >
                                    {t("page.chart.label.low")}&nbsp;&nbsp;
                                </p>
                                <p style={{ lineHeight: "18px", fontWeight: 600, fontFamily: "Open Sans" }}>{lastDateCandle.low}</p>
                            </div>
                            <div style={{padding: "5px 0px"}}>
                                <p
                                    style={{ lineHeight: "18px", color: COLORS.Mid_gray, padding: "5px 0px" }}
                                >
                                    {`${t("page.chart.label.volume")}(U)`}&nbsp;&nbsp;
                                </p>
                                <p style={{ lineHeight: "18px", fontWeight: 600, fontFamily: "Open Sans" }}>{getVolume(lastDateCandle.volume * (price ? parseFloat(price) : 1))}</p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
            <DealListContainer>
                <Row style={{
                    width: "100%",
                    padding: "0 16px",
                    alignItems: "center",
                    fontSize: 13,
                    marginBottom: 3
                }}>
                    <Col span={24} style={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}>
                        {
                            intervals.map((intv) => {
                                return (
                                    <div
                                        style={{
                                            paddingRight: 24,
                                            color: timeScale === intv ? "#000" : COLORS.Mid_gray,
                                            fontWeight: 500
                                        }}
                                        onClick={() => {
                                            setTimeScale(intv);
                                        }}
                                    >
                                        {t(`page.chart.interval.${intv}`)}
                                    </div>
                                )
                            })
                        }
                    </Col>
                </Row>
                <KlineChart trade={trade} timeScale={timeScale} mainTechnicalIndicatorType={mainTechnicalIndicator} subTechnicalIndicatorType={subTechnicalIndicator} />
                <Divider style={{ margin: "12px 0" }} />
                <Row style={{
                    width: "100%",
                    padding: "0 16px",
                    alignItems: "center",
                    fontSize: 13,
                }}>
                    <Col span={24} style={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}>
                        {
                            mainTechnicalIndicatorTypes.map((type) => {
                                return (
                                    <div
                                        style={{
                                            paddingRight: 24,
                                            color: mainTechnicalIndicator === type ? "#000" : COLORS.Mid_gray,
                                            fontWeight: 500
                                        }}
                                        onClick={() => {
                                            setMainTechnicalIndicator(type);
                                        }}
                                    >
                                        {type}
                                    </div>
                                )
                            })
                        }
                        <Divider type="vertical" />
                        {
                            subTechnicalIndicatorTypes.map((type) => {
                                return (
                                    <div
                                        style={{
                                            paddingRight: 24,
                                            color: subTechnicalIndicator === type ? "#000" : COLORS.Mid_gray,
                                            fontWeight: 500
                                        }}
                                        onClick={() => {
                                            setSubTechnicalIndicator(type);
                                        }}
                                    >
                                        {type}
                                    </div>
                                )
                            })
                        }
                    </Col>
                </Row>
                <DealListTitle>
                    <p style={{ fontWeight: 600, fontSize: 16, color: "#383743" }}>
                        {t("page.chart.label.orderBook")}
                    </p>
                </DealListTitle>
                <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                    <div style={{ display: "flex", justifyContent: "space-between", width: "50%", flexWrap: "wrap", padding: "0px 2px" }}>
                        <CenterLineItem style={{ color: COLORS.Mid_gray, fontSize: 12, fontWeight: 500, padding: "10px 0px" }}>{t("page.chart.label.buy")}</CenterLineItem>
                        {asksArray.map((item: string, i) => {
                            const price = item[0];
                            const quantity = item[1];
                            return (
                                <DealItemContainer key={i.toString()}>
                                    <DealItem style={{ paddingRight: 30, fontWeight: 600, fontFamily: "Open Sans" }}>{quantity.slice(0, -5)}</DealItem>
                                    <DealItem style={{ color: COLORS.Green, fontWeight: 600, fontFamily: "Open Sans" }}>
                                        {parsePrice(parseFloat(price))}
                                    </DealItem>
                                </DealItemContainer>
                            );
                        })}
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between", width: "50%", flexWrap: "wrap", padding: "0px 2px" }}>
                        <CenterLineItem style={{ color: COLORS.Mid_gray, fontSize: 12, fontWeight: 500, padding: "10px 0px" }}>{t("page.chart.label.sell")}</CenterLineItem>
                        {bidsArray.map((item: string, i) => {
                            const price = item[0];
                            const quantity = item[1];
                            return (
                                <DealItemContainer key={i.toString()}>
                                    <DealItem style={{ color: COLORS.Red }}>
                                        {parsePrice(parseFloat(price))}
                                    </DealItem>
                                    <DealItem>{quantity.slice(0, -5)}</DealItem>
                                </DealItemContainer>
                            );
                        })}
                    </div>
                </div>
            </DealListContainer>
            {/* <Drawer
                isVisible={selectChange}
                selectVisible={selectDepthHandler}
                height={302}
            >
                <DepthTitle>深度</DepthTitle>
                <DepthItem
                    isSelect={selectDepth}
                    index={0.1}
                    onClick={depthHandler.bind(null, 0.1)}
                >
                    0.1
                </DepthItem>

                <DepthItem
                    isSelect={selectDepth}
                    index={1}
                    onClick={depthHandler.bind(null, 1)}
                >
                    1
                </DepthItem>
                <DepthItem
                    style={{ border: "none" }}
                    isSelect={selectDepth}
                    index={10}
                    onClick={depthHandler.bind(null, 10)}
                >
                    10
                </DepthItem>
                <CancelButton onClick={selectDepthHandler}>{t("cancel")}</CancelButton>
            </Drawer> */}
        </CommonPage>
    );
};

export default Chart;
