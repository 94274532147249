import * as React from "react";

// ? Self-packed Components || Functions
import Layout from "@/components/Layout";

// ? Pages
import Home from "@/pages/home/Home";
import Member from "@/pages/menu/Member";
import AdvertisementManage from "@/pages/menu/advertisement-manage/AdvertisementManage";
import AccountSetting from "@/pages/menu/c2c-manage/account-setting";
import SafeSetting from "@/pages/menu/security-setting/safe-setting";
import SetTheFundPassword from "@/pages/menu/security-setting/set-the-fund-password";
import ResetPhonenumber from "@/pages/menu/security-setting/phone/reset-phone-number";
import IdVerify from "@/pages/menu/kyc/id-verify";
import IDdocuments from "@/pages/menu/kyc/id-documents";
import Chart from "@/pages/Deal/Chart";
import SearchDeal from "@/pages/Deal/SearchDeal";
import Fund from "@/pages/fund";
import Spot from "@/pages/fund/spot";
import Contract from "@/pages/fund/contract";
import FiatCurrency from "@/pages/fund/fiat";
import FundTransfer from "@/pages/fund/components/FundTransfer";
import Deal from "@/pages/Deal";
import Orders from "@/pages/orders/Orders";
import FiatOrders from "@/pages/orders/FiatOrders";
import Announcements from "@/pages/menu/Announcements";
import HomeAnnouncement from "@/pages/home/Announcement";
import Question from "@/pages/menu/question";
import Privacy from "@/pages/menu/about/Privacy";
import About from "@/pages/menu/about/about";
import FiatDeal from "@/pages/Deal/fiat/FiatDeal";
import StopLossStopEarning from "@/pages/Deal/StopLoss";
import FundHistory from "@/pages/fund/components/FundHistory";
import Deposit from "@/pages/fund/spot/Deposit";
import Withdraw from "@/pages/fund/spot/withdraw";
import FiatAdPage from "@/pages/menu/advertisement-manage/FiatAdPage";
import FiatAdDealHint from "@/pages/menu/advertisement-manage/FiatAdDealHint";
import FiatAdConfirm from "@/pages/menu/advertisement-manage/FiatAdConfirm";
import Commission from "@/pages/menu/CommissionManage";
import Register from "@/pages/menu/register/Register";
import Login from "@/pages/menu/login/Login";
import Forget from "@/pages/menu/login/Forget";
import SendEmail from "@/pages/menu/register/EmailVerify";
import SettingAreaZone from "@/pages/menu/security-setting/phone/setting-area-zone";
import GoogleVerify from "@/pages/menu/security-setting/googleAuth/google-verify";
import GoogleCode from "@/pages/menu/security-setting/googleAuth/google-code";
import UpdateTheFundPassword from "@/pages/menu/security-setting/update-the-fund-password";
import UpdateGoogle from "@/pages/menu/security-setting/googleAuth/update-google";
import ResetPassword from "@/pages/menu/security-setting/reset-password";
import C2c from "@/pages/menu/c2c-manage/c2c";
import MemberCenter from "@/pages/menu/c2c-manage/memberCenter";
import C2cApply from "@/pages/menu/c2c-manage/c2cApply";
import EditName from "@/pages/menu/c2c-manage/EditName";
import C2cNotification from "@/pages/menu/c2c-manage/c2cNotification";
import C2cHelp from "@/pages/menu/c2c-manage/c2cHelp";
import Quote from '@/pages/Quote';
import MissionCenter from "@/pages/home/MissionCenter";
import InstantTrend from "@/pages/home/InstantTrend";
import ChoosePayType from "@/pages/menu/c2c-manage/ChoosePayType";
import PayTypeLine from "@/pages/menu/c2c-manage/pay-type/PayTypeLine";
import PayTypeBank from "@/pages/menu/c2c-manage/pay-type/PayTypeBank";
import PayTypeCash from "@/pages/menu/c2c-manage/pay-type/PayTypeCash";
import PayTypeStreet from "@/pages/menu/c2c-manage/pay-type/PayTypeStreet";
// ^ Plugins
import { Routes, Route, Navigate } from "react-router-dom";
import MemberDetail from "@/pages/menu/user-info/MemberDetail";
import { useMarketWebsocket, useExchangeWebsocket, useOtcWebsocket } from "@/hooks";
import CustomerService from "@/pages/home/CustomerService";
import TermOfService from "@/pages/menu/register/TermOfService";
import MessageBox from "@/pages/menu/MessageBox";
import CurrencySetting from "@/pages/menu/CurrencySetting";
import LanguageSetting from "@/pages/menu/LanguageSetting";
import CreateOtcOrder from "@/pages/Deal/fiat/CreateOtcOrder";
import FiatDealing from "@/pages/Deal/fiat/FiatDealing";
import FiatDealFinish from "@/pages/Deal/fiat/FiatDealFinish";
import MerchantDealing from "@/pages/orders/MerchantDealing";
import ProtectFund from "@/pages/home/ProtectFund";
import Activity from "@/pages/home/Activity";
import QuizPage from "../home/Quiz";
import Exam from "../home/Exam";
import QuizRule from "../home/QuizRule";
import FeedBack from "../menu/about/Feedback";
import XEBit from "../menu/about/XEBit";
import Terms from "../menu/about/Terms";

function Router() {
    useOtcWebsocket()
    useMarketWebsocket()
    useExchangeWebsocket()

    return (
        <Routes>
            {/* Redirection */}
            <Route path="/" element={<Navigate to="/home" />} />

            {/* Pages */}
            <Route path="/home" element={<Layout children={<Home />} />} />
            <Route path="/member" element={<Layout children={<Member />} />} />
            <Route path="/member/detail" element={<Layout children={<MemberDetail />} />} />
            <Route path="/register" element={<Layout children={<Register />} />} />
            <Route path="/register/:inviteCode" element={<Layout children={<Register />} />} />
            <Route path="/login" element={<Layout children={<Login />} />} />
            <Route path="/quote" element={<Layout children={<Quote />} />} />
            <Route path="/forget" element={<Layout children={<Forget />} />} />
            <Route path="/emailVerify" element={<Layout children={<SendEmail />} />} />
            <Route path="/missionCenter" element={<Layout children={<MissionCenter />} />} />
            <Route path="/instantTrend" element={<Layout children={<InstantTrend />} />} />
            <Route path="/customer-service" element={<Layout children={<CustomerService />} />} />
            <Route path="/term-of-service" element={<Layout children={<TermOfService />} />} />
            <Route path="/message-box" element={<Layout children={<MessageBox />} />} />
            <Route path="/currency-setting" element={<Layout children={<CurrencySetting />} />} />
            <Route path="/language-setting" element={<Layout children={<LanguageSetting />} />} />
            <Route path="/protect-fund" element={<Layout children={<ProtectFund />} />} />
            <Route path="/activity" element={<Layout children={<Activity />} />} />
            <Route path="/quiz" element={<Layout children={<QuizPage />} />} />
            <Route path="/exam" element={<Layout children={<Exam />} />} />
            <Route path="/quiz-rule" element={<Layout children={<QuizRule />} />} />

            <Route path="/advertisement-manage" element={<Layout children={<AdvertisementManage />} />} />

            <Route path="/account-setting" element={<Layout children={<AccountSetting />} />} />
            <Route path="/safe-setting" element={<Layout children={<SafeSetting />} />} />
            <Route path="/choose-pay-type" element={<Layout children={<ChoosePayType />} />} />
            <Route path="/pay/cash" element={<Layout children={<PayTypeCash />} />} />
            <Route path="/pay/bank" element={<Layout children={<PayTypeBank />} />} />
            <Route path="/pay/line_pay" element={<Layout children={<PayTypeLine />} />} />
            <Route path="/pay/street_pay" element={<Layout children={<PayTypeStreet />} />} />

            <Route path="/set-the-fund-password" element={<Layout children={<SetTheFundPassword />} />} />
            <Route path="/update-the-fund-password" element={<Layout children={<UpdateTheFundPassword />} />} />
            <Route path="/update-google" element={<Layout children={<UpdateGoogle />} />} />
            <Route path="/reset-password" element={<Layout children={<ResetPassword />} />} />

            <Route path="/setting-area-zone" element={<Layout children={<SettingAreaZone />} />} />
            <Route path="/reset-phone-number" element={<Layout children={<ResetPhonenumber />} />} />
            <Route path="/id-verify" element={<Layout children={<IdVerify />} />} />

            <Route path="/id-documents" element={<Layout children={<IDdocuments />} />} />
            <Route path="/fund/overview" element={<Layout children={<Fund />} />} />
            <Route path="/fund/spot" element={<Layout children={<Spot />} />} />
            <Route path="/fund/future" element={<Layout children={<Contract />} />} />
            <Route path="/fund/otc" element={<Layout children={<FiatCurrency />} />} />
            <Route path="/fund/transfer" element={<Layout children={<FundTransfer />} />} />
            <Route path="/fund/history" element={<Layout children={<FundHistory />} />} />
            <Route path="/deal" element={<Layout children={<Deal />} />} />

            <Route path="/fiat-deal" element={<Layout children={<FiatDeal />} />} />
            <Route path="/deal/chart" element={<Layout children={<Chart />} />} />
            <Route path="/deal/search-deal" element={<Layout children={<SearchDeal />} />} />


            <Route path="/deal/stopLossStopEarning" element={<Layout children={<StopLossStopEarning />} />} />
            <Route path="/deal/FiatAdPage" element={<Layout children={<FiatAdPage />} />} />
            <Route path="/advertisement/step-two" element={<Layout children={<FiatAdDealHint />} />} />
            <Route path="/advertisement/step-three" element={<Layout children={<FiatAdConfirm />} />} />

            <Route path="/deal/fiat/create-otc-order" element={<Layout children={<CreateOtcOrder />} />} />
            <Route path="/deal/fiat" element={<Layout children={<FiatDealing />} />} />
            <Route path="/deal/fiat/finish" element={<Layout children={<FiatDealFinish />} />} />

            <Route path="/orders/fiat/merchant/deal" element={<Layout children={<MerchantDealing />} />} />
            <Route path="/orders" element={<Layout children={<Orders />} />} />
            <Route path="/fiat-orders" element={<Layout children={<FiatOrders />} />} />

            <Route path="/fiat-orders/:status" element={<Layout children={<FiatOrders />} />} />
            <Route path="/announcement" element={<Layout children={<Announcements />} />} />
            <Route path="/announcement/:id" element={<Layout children={<HomeAnnouncement />} />} />
            <Route path="/question" element={<Layout children={<Question />} />} />
            <Route path="/about/privacy" element={<Layout children={<Privacy />} />} />
            <Route path="/about" element={<Layout children={<About />} />} />
            <Route path="/about/feedback" element={<Layout children={<FeedBack />} />} />
            <Route path="/about/xebit" element={<Layout children={<XEBit />} />} />
            <Route path="/about/terms" element={<Layout children={<Terms />} />} />

            <Route path="/withdraw" element={<Layout children={<Withdraw />} />} />
            <Route path="/deposit" element={<Layout children={<Deposit />} />} />
            <Route path="/profile/Rebate" element={<Layout children={<Commission />} />} />
            <Route path="/google-verify" element={<Layout children={<GoogleVerify />} />} />
            <Route path="/google-code" element={<Layout children={<GoogleCode />} />} />
            <Route path="/c2c" element={<Layout children={<C2c />} />} />
            <Route path="/memberCenter" element={<Layout children={<MemberCenter />} />} />

            <Route path="/c2cApply" element={<Layout children={<C2cApply />} />} />
            <Route path="/editName" element={<Layout children={<EditName />} />} />
            <Route path="/c2cNotification" element={<Layout children={<C2cNotification />} />} />
            <Route path="/c2cHelp" element={<Layout children={<C2cHelp />} />} />
        </Routes>
    );
}

export default Router;
