import styled from "styled-components";
import { COLORS } from "@/constants/colors";

export const FlexAlignCenterDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 33%;
`;

export const FlexDirectionRowDiv = styled.div`
  display: flex;
  flex-direction: roe;
`;

export const CtrlBar = styled.div`
  display: flex;
  align-items: center;
  padding: 24px 10px;
`;

export const BaseNavContainer = styled.div`
    display: flex;
    padding: 0px 10px;
    width: 100%;
    border-bottom: 3px solid ${COLORS.EXLight_gray};
`

export const BaseNavItem = styled.span<{ active: boolean }>`
    padding: 5px 8px;
    font-size: 14px;
    font-weight: ${props => props.active ? 600 : 400};
    border-bottom: 3px solid ${props => props.active ? COLORS.Green : COLORS.White};
    color: ${props => props.active ? COLORS.Black : COLORS.Mid_gray};
    font-family: PingFang TC;
`

export const CtrlBarBtn = styled.button<{ active: boolean }>`
  border: none;
  background-color: ${COLORS.White};
  color: ${p => (
    p.active ? COLORS.Dark_gray : COLORS.Mid_gray)};
  font-size: 16px;
  font-weight: 600;
  font-family: PingFang TC;
`;

export const DealList = styled.div`
  overflow: scroll;
`;

export const DealTitle = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${COLORS.Mid_gray};
  font-size: 12px;
  font-weight: 500;
  padding: 10px 14px;
`;

export const DealFavBtn = styled.button`
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 6px;
  border: none;
  background-color: transparent;
  font-family: Open Sans;
`;

export const DealListItem = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 2vw;
  color: ${COLORS.Dark_gray};
`;

export const DealLastValue = styled.p`
  font-weight: 600;
  font-family: "Open Sans";
  text-align: right;
`;

export const DealName = styled.span`
  font-weight: 600;
  font-size: 16px;
  font-family: "Open Sans";
`;

export const DealOrderUnit = styled.span`
  font-weight: 500;
  font-size: 14px;
  color: ${COLORS.Mid_gray};
  margin-top: 3px;
  margin-left: 4px;
  line-height: 25px;
  font-family: ${COLORS.Mid_gray};
`;

export const DealRatio = styled.div<{ change: number }>`
  width: 70%;
  border-radius: 4px;
  background-color: ${props => (props.change < 0 ? COLORS.Red : COLORS.Green)};
  color: #fff;
  font-weight: 600;
  font-size: 13px;
  line-height: 29px;
  text-align: center;
  font-family: Open Sans;
  margin-left: auto;
`;

// ^ Reset Series
export const ResetBtn = styled.button`
  border-radius: 0px;
  border: none;
  background-color: transparent;
  padding: 0;
  margin: 0;
`;
