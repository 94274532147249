import { Payment } from '@/interfaces/exchange.interface';
import { PaymentInfo } from '@/interfaces/otc.interface';
import { CreateAdvertisementRequest } from '@/interfaces/request.interface';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
    creating: {} as CreateAdvertisementRequest,
    editId: ""
}

const advertisementSlice = createSlice({
    name: "advertisement",
    initialState: initialState,
    reducers: {
        set(state, action: PayloadAction<CreateAdvertisementRequest>) {
            state.creating = action.payload
        },
        setEdit(state, action: PayloadAction<string>) {
            state.editId = action.payload
        },
        cleanEdit(state){
            state.editId = ""
        },
        setPayments(state, action: PayloadAction<Payment[] | PaymentInfo[]>) {
            state.creating.payments = action.payload.map(p=>{
                return {
                    type: p.type,
                    id: p.id
                }
            })
        },
        deletePayment(state, action: PayloadAction<string>) {
            state.creating.payments = state.creating.payments.filter(p => p.id!==action.payload)
        },
        clean(state){
            state.creating = {}  as CreateAdvertisementRequest
        }
    }
});

export const advertisementActions = advertisementSlice.actions;

export default advertisementSlice.reducer;