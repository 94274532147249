import { AxiosRequestConfig } from "axios";
import { checkPhoneRequest, ResetFinancePasswordRequest, SetGoogleSecretRequest, VerifyPhoneRequest, SetFinancePasswordRequest, ResetPasswordRequest, GetOtcOrderDayReportRequest, UpdateOtcUserRequest, ResetGoogleSecretRequest } from "@/interfaces/request.interface";
import http from "@/utils/http-common";
import { ExchangeResponse } from "@/interfaces/response.interface";
import { Notification, Payment, User, UserSecurity } from "@/interfaces/exchange.interface";
import { AdvertisementCondition, BalanceFlowInfo, OtcUser, RefundApplication, UserOtcOrderReport } from "@/interfaces/otc.interface";

class UserService {
    static serviceApiPrefix = "/user"

    getNotification() {
        return http.get<any, ExchangeResponse<Notification[]>>(`${UserService.serviceApiPrefix}/notification`);
    }

    readNotification(id: number) {
        return http.patch<any, ExchangeResponse<Notification[]>>(`${UserService.serviceApiPrefix}/notification/${id}`);
    }

    getUserInfo() {
        return http.get<any, ExchangeResponse<User>>(`${UserService.serviceApiPrefix}`);
    }

    getPayments() {
        return http.get<any, ExchangeResponse<Payment[]>>(`${UserService.serviceApiPrefix}/payment`);
    }

    deletePayments(id: string) {
        return http.delete<any, ExchangeResponse<Payment[]>>(`${UserService.serviceApiPrefix}/payment/${id}`);
    }

    createPayment(request: FormData) {
        /*
         *  axios send form-data bug, downgrade to version 0.26.1 and use below config to fix
         */
        const config: AxiosRequestConfig<any> = {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            transformRequest: data => {
                return data
            }
        }
        return http.post<any, ExchangeResponse<any>>(`${UserService.serviceApiPrefix}/payment`, request, config);
    }
    verifyPhone(request: VerifyPhoneRequest) {
        return http.post<any, ExchangeResponse<any>>(`${UserService.serviceApiPrefix}/phone/verify-code`, request);
    }

    checkPhone(request: checkPhoneRequest) {
        return http.post<any, ExchangeResponse<any>>(`${UserService.serviceApiPrefix}/phone/check-code`, request);
    }

    deletePhone() {
        return http.delete<any, ExchangeResponse<any>>(`${UserService.serviceApiPrefix}/phone`);
    }

    getGoogleSecret() {
        return http.get<any, ExchangeResponse<string>>(`${UserService.serviceApiPrefix}/google-auth`);
    }

    setGoogleSecret(request: SetGoogleSecretRequest) {
        return http.post<any, ExchangeResponse<any>>(`${UserService.serviceApiPrefix}/google-auth`, request);
    }

    verifyGoogleSecret(request: SetGoogleSecretRequest) {
        return http.post<any, ExchangeResponse<any>>(`${UserService.serviceApiPrefix}/google-auth/verify`, request);
    }

    applyKyc(request: FormData) {
        /*
         *  axios send form-data bug, downgrade to version 0.26.1 and use below config to fix
         */
        const config: AxiosRequestConfig<any> = {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            transformRequest: data => {
                return data
            }
        }
        return http.post<any, ExchangeResponse<any>>(`${UserService.serviceApiPrefix}/kyc`, request, config);
    }

    getSecurity() {
        return http.get<any, ExchangeResponse<UserSecurity>>(`${UserService.serviceApiPrefix}/security`);
    }

    setFinancePassword(request: SetFinancePasswordRequest) {
        return http.post<SetFinancePasswordRequest, ExchangeResponse<any>>(`${UserService.serviceApiPrefix}/finance-password`, request);
    }

    resetFinancePassword(request: ResetFinancePasswordRequest) {
        return http.patch<ResetFinancePasswordRequest, ExchangeResponse<any>>(`${UserService.serviceApiPrefix}/reset/finance-password`, request);
    }

    resetPassword(request: ResetPasswordRequest){
        return http.post<ResetPasswordRequest, ExchangeResponse<any>>(`${UserService.serviceApiPrefix}/reset/password`, request);
    }

    resetGoogleSecret(request: ResetGoogleSecretRequest){
        return http.post<ResetGoogleSecretRequest, ExchangeResponse<any>>(`${UserService.serviceApiPrefix}/reset/google-auth`, request);
    }

    checkPhoneExist(phone: string){
        return http.post<ResetGoogleSecretRequest, ExchangeResponse<any>>(`${UserService.serviceApiPrefix}/phone/check`, {phone: phone});
    }

    static otcServiceApiPrefix = "/otc/api/user"

    getOtcUserInfo(account: string) {
        return http.get<any, OtcUser>(`${UserService.otcServiceApiPrefix}/${account}/`);
    }

    updateOtcUserInfo(account: string, request: UpdateOtcUserRequest) {
        return http.put<UpdateOtcUserRequest, OtcUser>(`${UserService.otcServiceApiPrefix}/${account}`, request);
    }

    getAdvertisementCondition(account: string) {
        return http.get<any, AdvertisementCondition>(`${UserService.otcServiceApiPrefix}/${account}/advertiserLevel/condition`);
    }

    setAdvertisementLevel(account: string, level: number){
        return http.put<any, OtcUser>(`${UserService.otcServiceApiPrefix}/${account}/advertiserLevel/${level}`);
    }

    getOtcOrderReport(account: string, request: GetOtcOrderDayReportRequest){
        let queryParams = ""
        if (request.all!==undefined) queryParams = `${queryParams}${queryParams ? "&" : "?"}all=${request.all}`
        if (request.status!==undefined) queryParams = `${queryParams}${queryParams ? "&" : "?"}status=${request.status}`
        if (request.type!==undefined) queryParams = `${queryParams}${queryParams ? "&" : "?"}type=${request.type}`
        return http.get<any, UserOtcOrderReport>(`${UserService.otcServiceApiPrefix}/${account}/otcOrders/statistics${queryParams}`);
    }

    getOtcBalanceFlow(account: string) {
        return http.get<any, BalanceFlowInfo[]>(`${UserService.otcServiceApiPrefix}/${account}/balanceFlows/`);
    }

    updateNotificationSetting(account: string, type: "sms" | "email") {
        return http.put<UpdateOtcUserRequest, OtcUser>(`${UserService.otcServiceApiPrefix}/${account}/${type}`);
    }

    createRefundApplication() {
        return http.post<any, any>(`${UserService.otcServiceApiPrefix}/refundApplication`);
    }

    getRefundApplication(account: string){
        return http.get<any, RefundApplication | null>(`${UserService.otcServiceApiPrefix}/${account}/refundApplication/`);
    }
}

export default new UserService();